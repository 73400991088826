import React from "react";
import PropsType from "prop-types";
import styled from "styled-components";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import { get, omit } from "lodash";
import { FormattedMessage } from "react-intl";
import DraftjsEditor from "../DraftjsEditor";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";

const Container = styled.div`
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: ${props => props.readOnly ? '20px' : '10px 0px'};
  border-color: ${props => props.readOnly ? '#ccc' : 'transparent'};
`;

const WrapperTop = styled.div`
  display: flex;
  max-width: 400px;
	margin-left: auto;
	flex: 1;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'row'}
`;

const TitleWrapper = styled.div`
  display: flex;
  justifyContent: space-between;
`;

const Title = styled.div`
	padding-bottom: 5px;
	font-size: 20px;
	font-weight: 600;
	color: #324856;
`;

const SlideLabel = styled.label`
  min-width: 40px;
  font-size: 14px;
  text-align: ${props => props.textAlign};
`;
const Timer = styled.div`
  display:none;
  text-align: right;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
`;

const SecondSpot = styled.div`
  display:none;
  text-align: right;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  color: #007bff;
  font-weight: 600;
  & > strong {
    margin-right: 5px;
    font-weight: 600;
  }
`;
const TextareaContainer = styled.div `
  position: relative;
`
const ContainerDisabled = styled.div `
  position: absolute;
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(255, 255, 255, .5);
`
const MIN_SLIDER = 120;
const MAX_SLIDER = 210;
const DEFAULT_SLIDER = 180;

const Handle = Slider.Handle;

const handle = ({ value, dragging, index, ...restProps }) => (
  <Tooltip
    parent={null}
    prefixCls="rc-slider-tooltip"
    overlay={value + " words per minute"}
    visible={dragging}
    placement="top"
    key={index}>
    <Handle value={value} {...restProps} />
  </Tooltip>
);

const TextareaEvaluator = props => {
  let { text, timer, onChangeSlider, onChangeEditorCallback, title, isOrderLength, process, disabled} = props;
  let otherProps = omit(props, ["onChangeSlider", "onChangeEditorCallback", "onUpdateSeconds", "onEvaluatorCallback", "disabled"]);
  return (
    <TextareaContainer>
      {
        disabled && <ContainerDisabled />
      }
      {isOrderLength && (
        <TitleWrapper>
          <Title>{title}</Title>
          <WrapperTop flexDirection={"column"} className="evaluator-order-length">
            {
              (get(process, "data.fields.order_length") || get(process, "_source.order_length"))
              && <SecondSpot>
                  <strong>{get(process, "data.fields.order_length.value", get(process, "_source.order_length"))}</strong>
                  <FormattedMessage id="textarea evaluator > seconds spot" />
                </SecondSpot>
            }
          </WrapperTop>
        </TitleWrapper>
      )}
      <Container {...otherProps}>
        {!props.readOnly && (
          <WrapperTop>
            <Timer>{timer}</Timer>
          </WrapperTop>
        )}
        {(!props.readOnly && !props.noTimer) && (
          <TitleWrapper>
            <Title>{title}</Title>
            <WrapperTop>
              <SlideLabel textAlign="left">
              Slow
                {/* <FormattedMessage id="textarea evaluator > slow" /> */}
              </SlideLabel>
              <Slider
                min={MIN_SLIDER}
                max={MAX_SLIDER}
                handle={handle}
                defaultValue={DEFAULT_SLIDER}
                onChange={onChangeSlider}
              />
              <SlideLabel textAlign="right">
              Fast
                {/* <FormattedMessage id="textarea evaluator > fast" /> */}
              </SlideLabel>
            </WrapperTop>
          </TitleWrapper>
        )}
        <DraftjsEditor
          text={text}
          onChangeEditor={onChangeEditorCallback}
          {...otherProps}
        />
      </Container>
    </TextareaContainer>
  );
};

TextareaEvaluator.propsType = {
  text: PropsType.string.isRequired,
  timer: PropsType.string.isRequired,
  onChangeSlider: PropsType.func.isRequired,
  onChangeEditorCallback: PropsType.func,
  wordsPerMinute: PropsType.number.isRequired,
  title: PropsType.string,
  disabled: PropsType.bool
};

TextareaEvaluator.defaultProps = {
  wordsPerMinute: 120,
  isOrderLength: false,
  onChangeEditorCallback: () => {},
  disabled: false
};
export default TextareaEvaluator;
