import React, { useRef } from 'react';
import classes from './ClassSchedule.module.scss';
import TimePicker from 'components/TimePicker';
import cs from 'classnames';
import moment from 'moment';
import Alert from 'components/Alert';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CustomRemoveIcon from '../../../../SvgIcons/CustomRemoveIcon';

const convertFormatDate = 'YYYY-MM-DD HH:mm:ss';

const WeeklyRecurringItem = (props) => {
  const { day, values, setFieldValue } = props;
  const { step, process, template, user, valueChanged } = props;
  const endTimeRef = useRef(null);

  const hasError =
    moment(day.to).format('X') - moment(day.from).format('X') > 0
      ? false
      : true;

  let recurring_repeat_on = null;

  if (step)
    recurring_repeat_on = step.fields.find(
      (f) => f.field === 'recurring_repeat_on'
    );

  return (
    <div className={classes.repeatHours}>
      <span className={classes.shortDay}>{day.shortValue}</span>
      <TimePicker
        className={classes.time}
        name={`start_time-${day.id}`}
        id={`start_time-${day.id}`}
        selected={moment(day.from, convertFormatDate).toDate()}
        onChange={(data) => {
          const hh = moment(data).format('HH');
          const mm = moment(data).format('mm');
          const ss = moment(data).format('ss');
          const dateFrom = moment(day.to)
            .hour(hh)
            .minute(mm)
            .second(ss)
            .format('YYYY-MM-DD HH:mm:ss');

          const dateTo = moment(day.to)
            .hour(hh)
            .minute(mm)
            .second(ss)
            .add(1, 'hour')
            .format('YYYY-MM-DD HH:mm:ss');

          const dataUpdate = {
            ...values.recurring_repeat_on,
            [day.id]: {
              id: day.id,
              label: day.label,
              value: day.value,
              shortValue: day.shortValue,
              from: dateFrom,
              to: dateTo,
            },
          };
          setFieldValue('recurring_repeat_on', dataUpdate);
          endTimeRef.current.setOpen(true);

          if (step)
            valueChanged(recurring_repeat_on, process, template, user, {
              value: dataUpdate,
            });
        }}
      />
      <span className="ml-2 mr-2">-</span>
      <TimePicker
        name={`end_time-${day.id}`}
        id={`end_time-${day.id}`}
        error={hasError}
        selected={moment(day.to, convertFormatDate).toDate()}
        ref={endTimeRef}
        onChange={(data) => {
          const hh = moment(data).format('HH');
          const mm = moment(data).format('mm');
          const ss = moment(data).format('ss');
          const date = moment(day.from)
            .hour(hh)
            .minute(mm)
            .second(ss)
            .format('YYYY-MM-DD HH:mm:ss');

          const dataUpdate = {
            ...values.recurring_repeat_on,
            [day.id]: {
              id: day.id,
              label: day.label,
              value: day.value,
              shortValue: day.shortValue,
              from: day.from,
              to: date,
            },
          };
          setFieldValue('recurring_repeat_on', dataUpdate);

          if (step)
            valueChanged(recurring_repeat_on, process, template, user, {
              value: dataUpdate,
            });
        }}
      />
      <div style={{padding: '1rem'}}>
        {day.isDuplicate ? (
          <Button
            className={classes.removeButton}
            onClick={() => {
              const data = { ...values.recurring_repeat_on };
              delete data[day.id];
              setFieldValue('recurring_repeat_on', data);
            }}
          >
            <CustomRemoveIcon />
          </Button>
        ) : (
          <Button
            className={classes.removeButton}
            onClick={() => {
              const lastDay = Object.values(values.recurring_repeat_on)
                .filter((i) => i)
                .filter((item) => item.shortValue === day.shortValue)
                .pop();
              const start = moment(lastDay.from)
                .add(1, 'hour')
                .format('YYYY-MM-DD HH:mm:ss');
              const end = moment(lastDay.to)
                .add(1, 'hour')
                .format('YYYY-MM-DD HH:mm:ss');
              const id = `${day.id}#${moment(start).format('HH:mm')}#${moment(
                end
              ).format('HH:mm')}`;
              setFieldValue('recurring_repeat_on', {
                ...values.recurring_repeat_on,
                [id]: {
                  id: id,
                  label: day.label,
                  value: day.value,
                  shortValue: day.shortValue,
                  from: start,
                  to: end,
                  isDuplicate: true,
                },
              });
            }}
          >
            <AddIcon />
          </Button>
        )}
      </div>
      {hasError && (
        <div className={cs(classes.alert, 'ml-2')}>
          <Alert
            className="warning"
            content="Event must end after it starts."
          />
        </div>
      )}
    </div>
  );
};
export default WeeklyRecurringItem;
