import history from '../../components/History';
import * as Const from '../../utils/constants';
import Api from '../../utils/api';
import URL from '../../utils/urls';
import store from 'store';

// import { getClassStatus } from "../../utils/helpers";
import get from 'lodash/get';
import omit from 'lodash/omit';
import forIn from 'lodash/forIn';

import {
  INIT_NEW_PROCESS,
  PROCESS_VALUE_CHANGED,
  SAVE_FORM_VALUES_REQUEST,
  SAVE_FORM_VALUES_SUCCESS,
  SAVE_FORM_VALUES_ERROR,
  // SAVE_PROCESS_REQUEST, SAVE_PROCESS_SUCCESS, SAVE_PROCESS_ERROR,
  FETCH_PROCESSES_REQUEST,
  FETCH_PROCESSES_SUCCESS,
  FETCH_PROCESSES_ERROR,
  FETCH_PROCESS_REQUEST,
  FETCH_PROCESS_SUCCESS,
  FETCH_PROCESS_ERROR,
  DELETE_PROCESS_REQUEST,
  DELETE_PROCESS_SUCCESS,
  DELETE_PROCESS_ERROR,
  // UPDATE_PROCESS_REQUEST, UPDATE_PROCESS_SUCCESS, UPDATE_PROCESS_ERROR
  SET_ACTIVE_TAB,
  FORGET_PRCESSES,
  SET_TAB_TO_EDIT,
  RESET_TAB,
  FETCH_PROCESSES_INCOMING_REQUEST,
  FETCH_PROCESSES_INCOMING_SUCCESS,
  GET_MY_CALENDAR_REQUEST,
  GET_MY_CALENDAR_SUCCESS,
  GET_MY_CALENDAR_ERROR,
} from '../types/processes';
import {
  SET_MESSAGES,
  REMOVE_ALL_PROCESS_DISMISSABLE_NOTIFICATIONS,
} from '../types/messages';
const queryString = require('query-string');
export function formValueChanged(payload) {
  return {
    type: PROCESS_VALUE_CHANGED,
    payload,
  };
}

export const getProcess = (payload) => async (dispatch) => {
  let err = '';
  let { process_id } = payload;
  try {
    dispatch({ type: FETCH_PROCESS_REQUEST, payload });
    if (!process_id) {
      dispatch(fetchProcessSuccess({ id: undefined, data: {} }));
      return;
    }
    let response = await Api.doCall(Const.GET_PROCESS(process_id), 'GET');

    if (response.status === 200) {
      if (!response.data.error) {
        // we're getting the messages in the same request
        // add them to the known messages store
        if (response.data.messages) {
          const messages = response.data.messages.map((m) => ({
            message: m._source,
            m_id: m._id,
          }));
          dispatch({
            type: SET_MESSAGES,
            payload: { messages },
          });
          dispatch({
            type: REMOVE_ALL_PROCESS_DISMISSABLE_NOTIFICATIONS,
            payload: { process_id },
          });
        }
        let fields = {};
        Object.keys(response.data).map(
          (key) => (fields[key] = { value: response.data[key] })
        );

        dispatch(fetchProcessSuccess({ id: process_id, data: fields }));
        return;
      }
      err = response.data.error;
    }
  } catch (error) {
    err = error.toString();
  }
  dispatch(fetchProcessError({ data: err }));
};

export const initNewProcess = (payload) => (dispatch) => {
  dispatch({
    type: INIT_NEW_PROCESS,
    payload,
  });
};

export const setActiveTab = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_TAB,
    payload,
  });
};

export const setActiveEditTab = (payload) => (dispatch) => {
  dispatch({
    type: SET_TAB_TO_EDIT,
    payload,
  });
};

const create_message = (payload, changed_step_data) => {
  let { process, template, step, user } = payload;

  let message = {
    delta: changed_step_data,
    from: {
      id: user.id,
      name: user.name,
      avatar_url: user.avatar_url,
    },
    course_studios: process.data.fields.course_studios.value,
    template: template.key,
    // process: process.key,
    order_title: process.data.fields.order_title
      ? process.data.fields.order_title.value
      : 'untitled',
    from_step: step.key,
    to_step: process.data.fields.process_step
      ? process.data.fields.process_step.value
      : '',
    team: process.data.fields.team ? process.data.fields.team.value : [],
    still_required_users: process.data.fields.still_required_users
      ? process.data.fields.still_required_users.value
      : [],
    next_users: process.data.fields.next_users
      ? process.data.fields.next_users.value
      : [],
  };
  return message;
};

export const saveFormValues = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_FORM_VALUES_REQUEST,
      payload,
    });

    let { process, template, step, valid, save_all_dirty } = payload;
    if (payload.chat_message) {
      // generate local message to show until the
      // sent message is bounced back from the server
      dispatch({
        type: SET_MESSAGES,
        payload: {
          messages: [
            {
              m_id: ('' + Math.random()).replace('0.', ''),
              message: {
                ...payload.chat_message,
                process: 'current',
                m_type: 'chat',
                process_key: 'current',
                process_id: 'current',
                created_ts: new Date(),
              },
            },
          ],
        },
      });
    }
    let changed_step_data = {};
    if (process.data.fields.process_step) {
      let step_value = process.data.fields.process_step;

      let processStepIndex = 0;
      // eslint-disable-next-line
      Object.keys(template.steps).map((key, ind) => {
        if (step_value.value === template.steps[key].key) {
          processStepIndex = template.steps[key].process_step_index;
        }
      });
      if (step_value.value === 'done' && processStepIndex === 0) {
        processStepIndex = 100;
      }
      //add process_step_index
      changed_step_data.process_step_index = processStepIndex; //step.process_step_index
    } else {
      changed_step_data.process_step_index = 0;
    }
    // add last saved step
    changed_step_data.last_saved_step = step.key;

    // add class studios
    if (
      process.data.fields.course_studios &&
      process.data.fields.course_studios.is_dirty
    )
      changed_step_data.course_studios =
        process.data.fields.course_studios.value;

    // add the template key on doc creation
    if (
      !process.data.fields.template_key ||
      process.data.fields.template_key.is_dirty
    )
      changed_step_data.template_key = template.key;

    // add the process step
    if (process.data.fields.process_step)
      changed_step_data.process_step = valid
        ? process.data.fields.process_step.value
        : step.key;

    // add the process next users
    if (
      process.data.fields.next_users &&
      process.data.fields.next_users.is_dirty
    )
      changed_step_data.next_users = process.data.fields.next_users.value;

    // add the process still required users
    if (
      process.data.fields.still_required_users &&
      process.data.fields.still_required_users.is_dirty
    )
      changed_step_data.still_required_users =
        process.data.fields.still_required_users.value;

    // add the process team
    if (process.data.fields.team && process.data.fields.team.is_dirty)
      changed_step_data.team = process.data.fields.team.value;

    // get changed team
    let all_changed_users = process.data.fields.all_changed_users
      ? process.data.fields.all_changed_users.value
      : [];

    // add the process spots
    if (process.data.fields.spots && process.data.fields.spots.is_dirty)
      changed_step_data.spots = process.data.fields.spots.value;

    // add the process order type(sold.spec)
    //const type = process.data.fields;
    if (process.data.fields.order_sold) {
      if (process.data.fields.order_sold.value)
        changed_step_data.order_sold = true;
      else changed_step_data.order_sold = false;
    } else {
      changed_step_data.order_sold = true;
    }

    changed_step_data.active = true;

    // add all the dirty step fields
    if (step && !save_all_dirty)
      step.fields.map((field) => {
        if (
          field.field &&
          process.data.fields[field.field] &&
          process.data.fields[field.field].is_dirty
        ) {
          changed_step_data[field.field] =
            process.data.fields[field.field].value;
        }
        // button fields that change other steps values
        if (field.fields) {
          field.fields.map((field) => {
            if (
              field.key &&
              process.data.fields[field.key] &&
              process.data.fields[field.key].is_dirty
            ) {
              changed_step_data[field.key] =
                process.data.fields[field.key].value;
            }
            return false;
          });
        }
        return false;
      });
    else {
      // add all dirty fields regardless of steps
      Object.keys(process.data.fields).map((field_key) => {
        let field = process.data.fields[field_key];
        if (field.is_dirty) changed_step_data[field_key] = field.value;
        return true;
      });
    }

    if (!process.data.fields.key) changed_step_data.active = true;
    /**
     * for clone the class
     */
    const getQueryString = queryString.parse(window.location.search);
    const getAction = get(getQueryString, 'action', null) || null;
    const cloneStep = get(getQueryString, 'step', null) || null;
    const clone_id =
      getAction === 'clone' ? process.data.fields.key.value : null;
    if (getAction && getAction === 'clone' && cloneStep !== '2')
      delete process.data.fields.key;
    let process_key = process.data.fields.key
      ? process.data.fields.key.value
      : '__new';

    let message = payload.chat_message
      ? payload.chat_message
      : create_message(payload, changed_step_data);
    let post_data = {
      meta: { process_key, step_key: step.key, message, all_changed_users },
      data: { ...changed_step_data },
    };
    let response = '';
    let clonePayload = {};

    if (getAction === 'clone' && cloneStep !== '2') {
      process_key = 'clone';
      clonePayload.clone_id = clone_id;
      clonePayload.capacity = get(
        payload,
        'process.data.fields.class_capacity.value'
      );
      clonePayload.level = get(
        payload,
        'process.data.fields.class_level.value'
      );
      clonePayload.name = get(payload, 'process.data.fields.class_name.value');
      response = await Api.doCall(
        Const.GET_PROCESS(process_key),
        'POST',
        clonePayload
      );
    } else {
      response = await Api.doCall(
        Const.GET_PROCESS(process_key),
        'PUT',
        post_data
      );
    }

    if (response.status === 200) {
      if (payload.success_callback) payload.success_callback(response);
      // after clone the class
      if (getAction && getAction === 'clone' && cloneStep !== '2') {
        history.replace(
          URL.CREATE_CLONE({
            template_id: process.data.fields.template_key.value,
            process_id: response.data.data.class.key, //process.data.fields.key.value
            step: '2',
          })
        );
      }
      // dont change to view mode when sending message or submitting on order detail tab
      // if(!get(payload, "chat_message")){
      //     if(get(payload, "step.key") !== "course_details"){
      //         history.replace(
      //             URL.VIEW_PROCESS({
      //                 template_id: process.data.fields.template_key.value,
      //                 process_id:  response.data.key //process.data.fields.key.value
      //             })
      //         );
      //     } else {
      //         history.replace(
      //             URL.UPDATE_PROCESS({
      //                 template_id: process.data.fields.template_key.value,
      //                 process_id:  response.data.key //process.data.fields.key.value
      //             })
      //         );
      //     }
      // }
      if (payload.chat_message) {
        // set message is bounced back from the server
        get(response, 'data.messages', []).unshift({
          _source: {
            ...message,
            m_type: 'chat',
          },
        });
        // refresh processes when adding new message
        dispatch({
          type: FORGET_PRCESSES,
        });
      }
      dispatch(
        saveFormValuesSuccess({
          ...changed_step_data,
          ...response.data,
          chat_message: payload.chat_message,
        })
      );
    } else {
      dispatch(saveFormValuesError(response.error.toString()));
    }
  } catch (error) {
    dispatch(saveFormValuesError(error.toString()));
  }
};

export const duplicate = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE_FORM_VALUES_REQUEST,
      payload,
    });
    let url = window.location.href;
    let { post_data, process_key, success_callback } = payload;
    let response = await Api.doCall(
      Const.DUPLICATE_PROCESS(process_key),
      'PUT',
      post_data
    );
    if (response.status === 200) {
      if (success_callback) payload.success_callback(response.data);
      dispatch(saveFormValuesSuccess({}));
    } else {
      dispatch(saveFormValuesError(response.error.toString()));
    }
  } catch (error) {
    dispatch(saveFormValuesError(error.toString()));
  }
};

export function saveFormValuesSuccess(payload) {
  return {
    type: SAVE_FORM_VALUES_SUCCESS,
    payload,
  };
}

export function saveFormValuesError(payload) {
  return {
    type: SAVE_FORM_VALUES_ERROR,
    payload,
  };
}

export function fetchProcessSuccess(payload) {
  return {
    type: FETCH_PROCESS_SUCCESS,
    payload,
  };
}

export function fetchProcessError(payload) {
  return {
    type: FETCH_PROCESS_ERROR,
    payload,
  };
}

export const deleteProcess = (id, cb) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: DELETE_PROCESS_REQUEST });
    let response = await Api.doCall(Const.GET_PROCESS(id), 'DELETE');
    if (response.status === 200) {
      if (!response.error) {
        dispatch({ type: DELETE_PROCESS_SUCCESS, payload: id });
        if (cb) cb(response);
        else history.push(URL.PROCESSES());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: DELETE_PROCESS_ERROR, payload: errorStr });
};

export const getProcesses = (payload) => async (dispatch) => {
  let errorStr = '';
  try {
    const auth = store.getState().auth;
    dispatch({ type: FETCH_PROCESSES_REQUEST, payload });
    let url =
      Const.GET_PROCESSES(payload.filter) +
      '?sort=' +
      get(payload, 'sort', '') +
      '&page=' +
      get(payload, 'page', '') +
      '&rpp=' +
      get(payload, 'rpp', '') +
      '&search=' +
      get(payload, 'search', '') +
      '&sortTotally' +
      get(payload, 'sortTotally', '');
    if ('classType' in payload) {
      url = `${url}&classType=${payload.classType}`;
    }
    if (auth.selectedUser) {
      url = `${url}&user_id=${auth.selectedUser}`;
    }
    forIn(payload, (value, param) => {
      if (param.indexOf('filter_') === 0) {
        url += `&${param}=${value}`;
      }
    });

    let response = await Api.doCall(url, 'GET');
    if (response.status === 200) {
      if (!response.data.error) {
        dispatch(
          fetchProcessesSucces({
            ...omit(payload, ['page']),
            page: get(response, 'data.page', 1),
            pages: get(response, 'data.pages', 1),
            data: get(response, 'data.items', []),
          })
        );
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchProcessesError({ filter: payload.filter, error: errorStr }));
};

export function fetchProcessesSucces(payload) {
  return {
    type: FETCH_PROCESSES_SUCCESS,
    payload,
  };
}

export function fetchProcessesError(payload) {
  return {
    type: FETCH_PROCESSES_ERROR,
    payload,
  };
}

export function resetTab() {
  return {
    type: RESET_TAB,
  };
}

export const getProcessesInComing = (payload) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: FETCH_PROCESSES_INCOMING_REQUEST, payload });
    const url =
      Const.GET_PROCESSES(payload.filter) + '?process_id=' + payload.process_id;
    let response = await Api.doCall(url, 'GET');
    if (response.status === 200) {
      if (!response.data.error) {
        //if(get(response, "data.items", []).length > 0){
        dispatch({
          type: FETCH_PROCESSES_INCOMING_SUCCESS,
          payload: {
            filter: payload.filter,
            data: get(response, 'data.items', []),
          },
        });
        return;
        //}
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchProcessesError({ filter: payload.filter, error: errorStr }));
};

export const getMyCalendar = () => async (dispatch) => {
  dispatch({ type: GET_MY_CALENDAR_REQUEST });
  const auth = store.getState().auth;

  let url = Const.GET_MY_CALENDAR();
  if (auth.selectedUser) {
    url = `${url}?user_id=${auth.selectedUser}`;
  }
  let response = await Api.doCall(url, 'GET');

  if (get(response, 'status') === 200) {
    const data = get(response, 'data');
    dispatch({
      type: GET_MY_CALENDAR_SUCCESS,
      payload: {
        data,
      },
    });

    return data;
  } else {
    dispatch({ type: GET_MY_CALENDAR_ERROR });
  }
};

export const getProcessById = async (id, cb) => {
  let response = await Api.doCall(Const.GET_PROCESS(id), 'GET');

  if (response.status === 200) {
    cb(response.data);
    return true;
  }
  cb(null);
  return false;
};

export const updateProcessById = async (id, data, cb) => {
  let response = await Api.doCall(Const.GET_PROCESS(id), 'PUT', data);

  if (response.status === 200) {
    cb(response.data);
    return true;
  }
  cb(null);
  return false;
};

export const deleteEventById = async (id, type, data, cb) => {
  let response = await Api.doCall(Const.DELETE_PROCESS(id, type), 'POST', data);

  if (response.status === 200) {
    cb(response.data);
    return true;
  }
  cb(null);
  return false;
};
