import { SET_MESSAGES, SET_HEADER_NOTIFICATIONS, HEADER_NOTIFICATIONS_SEEN, REMOVE_HEADER_NOTIFICATIONS, REMOVE_ALL_PROCESS_DISMISSABLE_NOTIFICATIONS } from "../types/messages";
import { LOGOUT_USER } from "../types/auth";
import unionBy from "lodash/unionBy";

const initialState = {
  messages: [],
  notifications: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGES:
      let messages = unionBy(action.payload.messages,state.messages, "m_id")
      messages = messages.filter(m => m.message.created_ts)

      messages = messages.sort((b, a) => (a.message.created_ts > b.message.created_ts) ? 1 : ((b.message.created_ts > a.message.created_ts) ? -1 : 0));
      if (action.payload.messages.length === 1 && action.payload.messages[0].message.process_key === 'current') {
        // own chat message
      }
      else {
        messages = messages.filter(m => m.message.process_key !== 'current')
      }
      return { ...state, messages: messages }

    case REMOVE_ALL_PROCESS_DISMISSABLE_NOTIFICATIONS:
      let process_notifications = [...state.notifications]
      let process_index = process_notifications.findIndex(m=>m.message.process_key === action.payload.process_id);
      while (process_index > -1) {
        process_notifications.splice(process_index, 1);
        process_index = process_notifications.findIndex(m=>m.message.process_key === action.payload.process_id);
      }
      return { ...state, notifications: process_notifications }
                
    case REMOVE_HEADER_NOTIFICATIONS:
      let existing_notifications = [...state.notifications]
      let index = existing_notifications.findIndex(m=>m.m_id === action.payload.m_id);
      if (index > -1) {
        existing_notifications.splice(index, 1);
      }
      return { ...state, notifications: existing_notifications }
    case SET_HEADER_NOTIFICATIONS:
      
      if (!action.payload.notifications)
        return state;
      // suggested fix
      if(state.notifications.find(m => m.m_id === action.payload.notifications[0].m_id))
        return state;
      let notifications = [...action.payload.notifications, ...state.notifications]
      notifications = notifications.sort((b, a) => (a.message.created_ts > b.message.created_ts) ? 1 : ((b.message.created_ts > a.message.created_ts) ? -1 : 0));
      notifications = notifications.slice(0, 10)
      return { ...state, notifications, unwatched: true }


    case HEADER_NOTIFICATIONS_SEEN:
      // make sure the bell keeps ringing only as long as there are dismissable notifications 
      let unwatched = state.notifications.map(n=>n.message.needs_confirmation && n.message.needs_confirmation.indexOf(action.payload.id)>-1).length>0
      return { ...state, unwatched }

    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}