import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import get from 'lodash/get';
import { Row, Col } from 'reactstrap';

import classes from 'pages/PreviewLiveMode/PreviewLiveMode.module.scss';
import downIcon from 'assets/img/icons/icon/Icon/triangle_down.svg';
import upIcon from 'assets/img/icons/icon/Icon/triangle_up.svg';
import DisplayPerformance from 'pages/NotificationsBarIframe/DisplayPerformance';

const AudienceView = (props) => {
  const { data } = props;

  const [showPerformed, setShowPerformed] = useState(false);
  const toogle = () => setShowPerformed(!showPerformed);

  const courses = useSelector(({ auth }) => {
    const courses = get(auth, 'info.courses', []);
    return courses || [];
  });

  const performances = get(data, 'performances', []) || [];
  const columns = [
    {
      id: 4,
      name: 'Performing',
      data: performances.filter((p) => p.status === 4),
    },
    {
      id: 3,
      name: 'On Deck',
      data: performances.filter((p) => p.status === 3),
    },
    {
      id: 2,
      name: 'Getting Ready',
      data: performances.filter((p) => p.status === 2),
    },
    {
      id: 1,
      name: 'Schedule',
      data: performances.filter((p) => p.status === 1),
    },
    {
      id: -1,
      name: 'Performed',
      data: performances.filter((p) => p.status === -1),
    },
  ];

  const displayPerformance = (item, index) => {
    return <DisplayPerformance item={item} key={index} courses={courses} />;
  };

  const performedSection = (item) => {
    const filterPerformance = item.data;

    return (
      <Row key={`box-${item.id}`}>
        <div className="w-100">
          <Col sm={12} className={classes.container}>
            <div className={cs(classes.header, classes.viewer)}>
              <div>
                {item.name} <span>({filterPerformance.length})</span>
              </div>

              {filterPerformance.length > 0 && (
                <>
                  {showPerformed ? (
                    <img src={upIcon} alt="up " onClick={() => toogle()} />
                  ) : (
                    <img src={downIcon} alt="down" onClick={() => toogle()} />
                  )}
                </>
              )}
            </div>

            {showPerformed &&
              filterPerformance.map((item, index) =>
                displayPerformance(item, index)
              )}
          </Col>
        </div>
      </Row>
    );
  };

  return (
    <div className={classes.sendNofitication}>
      {columns.map((item, index) => {
        if (item.id === -1) return performedSection(item);

        const filterPerformance = item.data;

        return (
          <Row key={`box-${index}`}>
            <div className="w-100">
              <Col sm={12} className={classes.container}>
                <div
                  className={cs(
                    classes.header,
                    classes.viewer,
                    item.name === 'Performing' && classes.perfomning
                  )}
                >
                  {item.name}
                </div>
                {filterPerformance.map((item, index) =>
                  displayPerformance(item, index)
                )}
              </Col>
            </div>
          </Row>
        );
      })}
    </div>
  );
};

export default React.memo(AudienceView);
