import React, { lazy, useEffect } from 'react';
import get from 'lodash/get';
import { Redirect, Switch } from 'react-router-dom';
import URL from 'utils/urls';

import { UpdateProcessPage } from 'pages/Process/UpdateProcess';
import { ViewProcessPage } from 'pages/Process/ViewProcess';
import { AddProcessPage } from 'pages/Process/AddProcess';
import { isAuthenticated } from 'utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from 'store/actions/auth';
import useTabActive from 'hooks/useTabActive';
import { isTokenExpired } from 'utils/helpers';

const Membership = lazy(() => import('pages/Membership'));
const SetupStudio = lazy(() => import('pages/StudioDetails/SetupStudio'));
const InviteUsers = lazy(() => import('pages/User/invite_users'));
const PreviewLiveMode = lazy(() => import('pages/PreviewLiveMode'));
const SetupRecital = lazy(() => import('pages/SetupRecital'));
const DefaultUsers = lazy(() => import('pages/Onboarding/DefaultUsers/view'));
const Onboarding = lazy(() => import('pages/Onboarding'));
const StudioDetails = lazy(() => import('pages/StudioDetails'));
const StylesTest = lazy(() => import('pages/StylesTest'));
const Messages = lazy(() => import('pages/Messages'));
const Help = lazy(() => import('pages/Help'));
const Settings = lazy(() => import('pages/Settings'));
const MyProfile = lazy(() => import('pages/MyProfile'));
const Evaluator = lazy(() => import('pages/Evaluator'));
const UploadFile = lazy(() => import('pages/UploadFile'));
const AdminImport = lazy(() => import('pages/AdminImport'));
const Processes = lazy(() => import('pages/Processes'));
const AddUpdateUserPage = lazy(() => import('pages/AddUpdateUser'));
const UserPage = lazy(() => import('pages/User'));
const AddUpdateStudioPage = lazy(() => import('pages/StudioDetails'));
const StudioPage = lazy(() => import('pages/Studio'));
const UserParents = lazy(() => import('pages/UserParents'));
const AddParents = lazy(() => import('pages/AddParents'));
const UserStaff = lazy(() => import('pages/UserStaff'));
const AddStaff = lazy(() => import('pages/AddStaff'));
const UserChecklist = lazy(() => import('pages/UserChecklist'));
const Calendar = lazy(() => import('pages/Calendar'));
const LayoutRoute = lazy(() => import('components/Layout/LayoutRoute'));
const MainLayout = lazy(() => import('components/Layout/MainLayout'));

const NotificationPage = lazy(() => import('pages/Notification'));
const Feed = lazy(() => import('pages/Feed'));
const Sales = lazy(() => import('pages/Sales'));

const Recitals = lazy(() => import('pages/Recitals'));
const Admins = lazy(() => import('pages/Admins'));
const ResourceCenter = lazy(() => import('pages/ResourceCenter'));
const RecitalLink = lazy(() => import('pages/RecitalLink'));
const CalenderSchedule = lazy(() => import('pages/CalenderSchedule'));
const SeasonForm = lazy(() =>
  import('components/ProcessesList/Seasons/SeasonForm')
);

const SetupSchedule = lazy(() => import('pages/SetupSchedule'));
const Profile = lazy(() => import('pages/Profile'));
const ManageUsers = lazy(() => import('pages/ManageUsers'));
const UsersList = lazy(() => import('pages/Users'));
const StudioAvailable = lazy(() => import('pages/StudioAvailable'));
const Dashboard = lazy(() => import('pages/DashboardScreen'));

const ProtectedRoutes = ({ ...baseProps }) => {
  const isSessionAuthenticated = isAuthenticated();
  const isAuth =
    useSelector((state) => get(state, 'auth.authenticated', false)) || false;

  const auth = useSelector((state) => get(state, 'auth', false)) || false;
  const authenticated = isSessionAuthenticated && isAuth;
  const roleInfo = get(auth, 'user.role.id', 0);
  const token = auth.token;
  const dispatch = useDispatch();
  const isTabActive = useTabActive();

  useEffect(() => {
    const checkTokenValidity = () => {
      const isTokenActive = token && isTokenExpired(token);
      if (isTokenActive) dispatch(logoutUser());
    };

    if (isTabActive) checkTokenValidity();
  }, [isTabActive]);
  return (
    <Switch>
      {!authenticated && !token && <Redirect to={URL.LOGIN({ path: true })} />}
      <LayoutRoute
        exact
        component={SeasonForm}
        layout={MainLayout}
        path={URL.SEASON_CREATE({ path: true })}
      />

      <LayoutRoute
        exact
        path={URL.PROCESSES({ path: true })}
        layout={MainLayout}
        component={Processes}
      />
      <LayoutRoute
        exact
        path={URL.ADMIN({ path: true })}
        layout={MainLayout}
        component={AdminImport}
      />
      <LayoutRoute
        exact
        path={URL.PROCESSES({ filter: 'active' })}
        layout={MainLayout}
        component={Processes}
      />
      <LayoutRoute
        exact
        path={URL.STUDIO_PAGE({ path: true })}
        layout={MainLayout}
        component={StudioPage}
      />
      <LayoutRoute
        exact
        path={URL.ADD_STUDIO({ path: true })}
        layout={MainLayout}
        component={AddUpdateStudioPage}
      />
      <LayoutRoute
        exact
        path={URL.UPDATE_STUDIO({ path: true })}
        layout={MainLayout}
        component={AddUpdateStudioPage}
      />
      <LayoutRoute
        exact
        path={URL.UPDATE_STUDIO_SETTINGS({ path: true })}
        layout={MainLayout}
        component={AddUpdateStudioPage}
      />
      <LayoutRoute
        exact
        path={URL.SETUP_STUDIO({ path: true })}
        layout={MainLayout}
        component={SetupStudio}
      />
      <LayoutRoute
        exact
        path={`/studio-setting/:action?`}
        layout={MainLayout}
        component={SetupStudio}
      />
      <LayoutRoute
        exact
        path={URL.SETUP_SCHEDULE({ path: true })}
        layout={MainLayout}
        component={SetupSchedule}
      />
      <LayoutRoute
        exact
        path={URL.RECITAL_LINK({ path: true })}
        layout={MainLayout}
        component={RecitalLink}
      />
      <LayoutRoute
        exact
        path={URL.RESOURCE_CENTER({ path: true })}
        layout={MainLayout}
        component={ResourceCenter}
      />
      <LayoutRoute
        exact
        path={URL.UPDATE_PROCESS({ path: true })}
        layout={MainLayout}
        component={UpdateProcessPage}
      />
      <LayoutRoute
        exact
        path={URL.VIEW_PROCESS({ path: true })}
        layout={MainLayout}
        component={ViewProcessPage}
      />
      <LayoutRoute
        exact
        path={URL.ADD_PROCESS({ path: true })}
        layout={MainLayout}
        component={AddProcessPage}
      />
      <LayoutRoute
        exact
        path={URL.USER_DETAILS({ path: true })}
        layout={MainLayout}
        component={UserPage}
      />
      <LayoutRoute
        exact
        path={URL.ADD_USER({ path: true })}
        layout={MainLayout}
        component={AddUpdateUserPage}
      />
      <LayoutRoute
        exact
        path={URL.UPDATE_USER({ path: true })}
        layout={MainLayout}
        component={AddUpdateUserPage}
      />
      <LayoutRoute
        exact
        path={URL.UPLOAD_FILE({ path: true })}
        layout={MainLayout}
        component={UploadFile}
      />
      <LayoutRoute
        path={URL.EVALUATOR({ path: true })}
        layout={MainLayout}
        component={Evaluator}
      />
      {(roleInfo === 6 || roleInfo === 7 || roleInfo === 3) && (
        <LayoutRoute
          path={URL.DASHBOARD({ path: true })}
          layout={MainLayout}
          component={Dashboard}
        />
      )}
      <LayoutRoute
        path={URL.MY_PROFILE({ path: true })}
        layout={MainLayout}
        component={MyProfile}
      />
      <LayoutRoute
        path={URL.SETTINGS({ path: true })}
        layout={MainLayout}
        component={Settings}
      />
      <LayoutRoute
        path={URL.HELP({ path: true })}
        layout={MainLayout}
        component={Help}
      />
      <LayoutRoute
        path={URL.MESSAGES({ path: true })}
        layout={MainLayout}
        component={Messages}
      />
      <LayoutRoute
        path={URL.ADMINS({ path: true })}
        layout={MainLayout}
        component={Admins}
      />
      <LayoutRoute
        path={URL.STYLES_TEST({ path: true })}
        layout={MainLayout}
        component={StylesTest}
      />
      <LayoutRoute
        exact
        path={URL.ON_BOARDING({ path: true })}
        layout={MainLayout}
        component={Onboarding}
      />

      <LayoutRoute
        exact
        path={URL.DEFAULT_USERS({ path: true })}
        layout={MainLayout}
        component={DefaultUsers}
      />
      <LayoutRoute
        path={URL.STUDIO_DETAILS({ path: true })}
        layout={MainLayout}
        component={StudioDetails}
      />
      <LayoutRoute
        exact
        path={URL.USERS({ path: true })}
        layout={MainLayout}
        component={UsersList}
      />
      <LayoutRoute
        exact
        path={'/schedule'}
        layout={MainLayout}
        component={Calendar}
      />
      <LayoutRoute
        path={URL.FEED({ path: true })}
        layout={MainLayout}
        component={Feed}
      />
      <LayoutRoute
        path={URL.SALES({ path: true })}
        layout={MainLayout}
        component={Sales}
      />
      <LayoutRoute
        path={URL.STUDIO_AVAILABLE({ path: true })}
        layout={MainLayout}
        component={StudioAvailable}
      />
      <LayoutRoute
        path={URL.NOTIFICATION({ path: true })}
        layout={MainLayout}
        component={NotificationPage}
      />
      <LayoutRoute
        exact
        path={URL.CALENDAR({ path: true })}
        layout={MainLayout}
        component={CalenderSchedule}
      />
      <LayoutRoute
        exact
        path={URL.USERCHECKLIST({ path: true })}
        layout={MainLayout}
        component={UserChecklist}
      />
      <LayoutRoute
        exact
        path={URL.ADDSTAFF({ path: true })}
        layout={MainLayout}
        component={AddStaff}
      />
      <LayoutRoute
        exact
        path={URL.USERSTAFF({ path: true })}
        layout={MainLayout}
        component={UserStaff}
      />
      <LayoutRoute
        exact
        path={URL.ADDPARENTS({ path: true })}
        layout={MainLayout}
        component={AddParents}
      />
      <LayoutRoute
        exact
        path={URL.USERPARENTS({ path: true })}
        layout={MainLayout}
        component={UserParents}
      />
      <LayoutRoute
        exact
        path={URL.USERFAMILIES({ path: true })}
        layout={MainLayout}
        component={ManageUsers}
      />
      <LayoutRoute
        exact
        path={URL.PROFILE({ path: true })}
        layout={MainLayout}
        component={Profile}
      />
      <LayoutRoute
        exact
        path={URL.INVITE_USERS({ path: true })}
        layout={MainLayout}
        component={InviteUsers}
      />
      <LayoutRoute
        exact
        path={URL.MEMBERSHIP({ path: true })}
        layout={MainLayout}
        component={Membership}
      />
      <LayoutRoute
        exact
        path={URL.RECITALS({ path: true })}
        layout={MainLayout}
        component={Recitals}
      />
      <LayoutRoute
        exact
        path={URL.SETUP_RECITALS({ path: true })}
        layout={MainLayout}
        component={SetupRecital}
      />

      <LayoutRoute
        exact
        path={URL.PREVIEW_RECITAL({ path: true })}
        layout={MainLayout}
        component={PreviewLiveMode}
      />
      {(roleInfo === 6 || roleInfo === 7 || roleInfo === 3) ? (<Redirect to={URL.DEFAULT_ROUTE()} />) : (<Redirect to={URL.PARENT_DEFAULT_ROUTE()} />)}
    </Switch>
  );
};

export default ProtectedRoutes;
