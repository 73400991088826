import * as React from 'react';
import PropsType from 'prop-types';
import Slider from 'rc-slider';
import { get, fromPairs, map } from "lodash";
import classnames from 'classnames';
import IoIconPlay from 'react-icons/lib/io/ios-play';
import IoIconPause from 'react-icons/lib/io/ios-pause';
import MdVolumeUp from "react-icons/lib/md/volume-up";
import MdVolumeOff from "react-icons/lib/md/volume-off";
import { getBubbleMessages } from "./helper"
import 'rc-slider/assets/index.css';
import Tooltip from "rc-tooltip";
import { formatSeconds } from "./helper";
import { getSortUserName } from "utils/helpers";
const MIN_SLIDER = 0;
const MIN_SLIDER_VOLUME = 0;
const MAX_SLIDER_VOLUME = 1;

const PlayerUI = ({
    isShowVolume,
    setIsShowVolume,
    onClickPlaying,
    volume,
    setVolume,
    width,
    height,
    toggleSetVolume,
    playedSeconds,
    duration,
    playing,
    onSeekMouseUp,
    currentBubble,
    setCurrentBubble,
    setPlayedSeconds,
    isBubble,
    process
}) => {
    const messages = getBubbleMessages(process, isBubble);
    const onSetActiveMessage = (status, message) => {
        const element =  document.getElementById(message._id);
        if(element){
            if(status){
                element.classList.add("message-active");
                setCurrentBubble(message);
            }else{
                element.classList.remove("message-active");
                setCurrentBubble(null);
            }
        }
    }
    const marksArray = (messages || []).map(message => {
        return {
            key: parseFloat(get(message, "_source.player_played_seconds", 0)),
            value: (
                <Tooltip
                    placement="top"
                    trigger={['click', 'hover']}
                    overlay={<span className="rc-slider-tooltip-message">{get(message, "_source.chat", "")}</span>}
                    onVisibleChange={(status) => onSetActiveMessage(status, message)}
                >

                <div className={classnames("rc-slider-tooltip-inner", {
                    'tooltip-active': get(currentBubble, "_id") === get(message, "_id")
                })}>
                        <div
                            className="tooltip-user"
                            onClick={() => {
                                onClickPlaying(!playing);
                            }}
                        >
                            {
                                get(message, "_source.from.avatar_url")
                                ? (
                                    <img className="rc-slider-tooltip-user-avatar" alt="" src={get(message, "_source.from.avatar_url")} />
                                ): (
                                    <span className="rc-slider-tooltip-user-name">{getSortUserName(get(message, "_source.from.name"))}</span>
                                )
                            }
                        </div>

                </div>
                </Tooltip>

            )
        }
    })
    const marks = fromPairs(map(marksArray, i => [i.key, i.value]));
  return (
    <div
        className="player-container-ui"
        onMouseLeave={() => setIsShowVolume(false)}
        style={{
            width: `${width}px`,
            height: `${height}px`,
        }}
    >
        <div
            className="button-play"
            onClick={() => onClickPlaying(!playing)}
        >
            {
                playing
                ? (
                    <IoIconPause size={25} color={'#fff'} />
                ) : (
                    <IoIconPlay size={25} color={'#fff'} />
                )
            }
        </div>
        <Slider
            className="rc-slider-timer"
            value={playedSeconds}
            onChange={(value) => {
                onSeekMouseUp(value);
                setPlayedSeconds(value);
            }}
            marks={marks}
            min={MIN_SLIDER}
            max={ duration }
        />
        <div className="player-timer">
            <span>{formatSeconds(playedSeconds)}</span>
            <em className="divide">/</em>
            <span>{formatSeconds(duration)}</span>
        </div>
        <div
            className={classnames("slider-volume", {
                "slider-volume-active": isShowVolume
            })}
            onMouseEnter={() => setIsShowVolume(true)}
            onMouseLeave={() => setIsShowVolume(false)}
        >
            <Slider
                className="rc-slider-volume"
                value={volume}
                onChange={setVolume}
                min={MIN_SLIDER_VOLUME}
                max={MAX_SLIDER_VOLUME}
                step={0.001}
            />
        </div>
        <div
            className="player-volume"
            onMouseEnter={() => setIsShowVolume(true)}
            onClick={toggleSetVolume}
        >
            {
                volume === 0
                ? ( <MdVolumeOff size={25} color={'#fff'} />)
                : (<MdVolumeUp size={25} color={'#fff'} />)
            }
        </div>
    </div>
  );
}
PlayerUI.defaultProps = {
    width: '100%',
    height: 55,
    volume: 1,
    setVolume: () => {},
    playedSeconds: 0,
    loadedSeconds: 0,
    played: 0,
    playing: false,
    isBubble: false,
    process: null,
}
PlayerUI.propsType = {
    width: PropsType.any,
    height: PropsType.any,
    volume: PropsType.number.isRequired,
    setVolume: PropsType.func.isRequired,
    onClickPlaying: PropsType.func.isRequired,
    playedSeconds: PropsType.number,
    loadedSeconds: PropsType.number,
    played: PropsType.number,
    playing: PropsType.bool,
    isBubble: PropsType.bool,
    process: PropsType.any
}
export default PlayerUI;