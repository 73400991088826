export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_LOAD_MORE_USERS_SUCCESS = 'GET_LOAD_MORE_USERS_SUCCESS';
export const GET_USERS_ERROR = "GET_USERS_ERROR";

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_CARD_SUCCESS = 'GET_USER_CARD_SUCCESS';
export const GET_USER_BANK_SUCCESS = 'GET_USER_BANK_SUCCESS';
export const GET_USER_PAYMENTS_SUCCESS = 'GET_USER_PAYMENTS_SUCCESS';
export const GET_USER_RECURRING_PAYMENTS_SUCCESS = 'GET_USER_RECURRING_PAYMENTS_SUCCESS';
export const GET_USER_AUTH_SUCCESS = 'GET_USER_AUTH_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_MEMBER_ERROR = 'GET_USER_MEMBER_ERROR';
export const GET_USER_TRANSACTION_SUCCESS = 'GET_USER_TRANSACTION_SUCCESS';
export const GET_MEMBER_PROCESSES_REQUEST = 'GET_MEMBER_PROCESSES_REQUEST';
export const GET_MEMBER_PROCESSES_SUCCESS = 'GET_MEMBER_PROCESSES_SUCCESS';
export const GET_MEMBER_PROCESSES_ERROR = 'GET_MEMBER_PROCESSES_ERROR';
export const GET_MEMBER_ELIGIBLE_PROCESSES_REQUEST = 'GET_MEMBER_ELIGIBLE_PROCESSES_REQUEST';
export const GET_MEMBER_ELIGIBLE_PROCESSES_SUCCESS = 'GET_MEMBER_ELIGIBLE_PROCESSES_SUCCESS';
export const GET_MEMBER_ELIGIBLE_PROCESSES_ERROR = 'GET_MEMBER_ELIGIBLE_PROCESSES_ERROR';
export const SET_RETRY_FAILED_TRANSACTION_SUCCESS = 'SET_RETRY_FAILED_TRANSACTION_SUCCESS';
export const RESET_MEMBER_DATA = 'RESET_MEMBER_DATA';
export const GET_MEMBERSHIP_DETAIL = 'GET_MEMBERSHIP_DETAIL';
export const GET_MEMBERSHIPDETAIL_SUCCESS = 'GET_MEMBERSHIPDETAIL_SUCCESS';
