import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledModal = styled(Dialog)`
  .modal-content {
    padding: 0rem !important;
    border: none;
    border-radius: 0.5rem;
  }
  .MuiDialogContent-root {
    padding: 0rem;
  }
  .modal-class {
    border-radius: 20%;
  }
  &.model-dialog {
    height: 706px;
    width: 735px;
    border-radius: 20px;
  }
  .header {
    border-bottom: 0px;
    position: relative;
    padding: 2rem;
    .modal-title {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      text-align: center;
      color: #22294e;
      padding: 0.5rem;
    }
  }
  .content {
    padding: 1rem 0rem;
  }
  .flexEnd {
    justify-content: flex-end !important ;
  }
  .footer {
    border-top: 1px solid #f8e6df;
    padding: 0.6rem 1rem;
    justify-content: space-between;
    .link-button {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      color: #ea734f;
    }
    .default-event-class {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
      color: #ffffff;
      width: 146px;
    }
    .cancel-default {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: #9c9ca3;
      background: #ffffff;
    }
  }
`;

const SeacondaryDialog = ({
  flexEnd = false,
  overflow = 'auto',
  footer,
  paper,
  open,
  maxWidth,
  headerNode,
  classObject,
  closeDialog,
  dialogTitle,
  actions,
  children,
  dismissContent,
  footerNode = false,
}) => {
  return (
    <StyledModal
      open={open}
      aria-labelledby="alert-dialog-title"
      sx={{ '& .MuiDialog-paper': paper }}
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      classes={classObject}
    >
      {headerNode ? (
        headerNode
      ) : (
        <DialogTitle className={'title'} disableTypography>
          {dialogTitle}
        </DialogTitle>
      )}
      {children && (
        <DialogContent sx={{ overflow }}>
          <DialogContentText className={'displayNewLine'}>
            {children}
          </DialogContentText>
        </DialogContent>
      )}
      {footerNode
        ? footerNode
        : footer && (
            <DialogActions className={`footer ${flexEnd && 'flexEnd'}`}>
              {dismissContent && (
                <Button
                  className="cancel-default"
                  color="primary"
                  onClick={closeDialog}
                >
                  {dismissContent}
                </Button>
              )}
              {actions.map((action) => (
                <LoadingButton
                  color="primary"
                  onClick={action.event}
                  variant={action.variant || 'contained'}
                  loading={action.loading || false}
                  disabled={action.disabled || false}
                  style={action.style || {}}
                  className={action.className || 'default-event-class'}
                >
                  {action.title}
                </LoadingButton>
              ))}
            </DialogActions>
          )}
    </StyledModal>
  );
};

SeacondaryDialog.defaultProps = {
  open: false,
  dismissContent: 'close',
  dialogTitle: '',
  maxWidth: 'md',
  className: 'modal-container',
  actions: [],
  style: {},
  headerNode: false,
  footer: true,
  bordered: false,
  paper: { width: 1000, maxHeight: 800, borderRadius: '20px' },
};

SeacondaryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  className: PropTypes.any,
  style: PropTypes.any,
  headerNode: PropTypes.any,
  footerNode: PropTypes.node,
  paper: PropTypes.shape({
    width: PropTypes.number,
    maxHeight: PropTypes.number,
  }),
  actions: PropTypes.shape({
    title: PropTypes.string,
    event: PropTypes.func,
    className: PropTypes.any,
  }),
};

export default SeacondaryDialog;
