import pathToRegexp from 'path-to-regexp';

// This function process any url/path given path regex and config options
// - pathRegex: is the regex by which we can construct actual url, format is similar to react-router route urls
// - config options: when we pass { path: true } in options it will return plain path regex otherwise it will return compiled path with actual parameter values
// For e.g. when we define react-router routes , we need path regex with parameter placement holder
// so at that time pass { path : true } with will directly return pathRegex
// Suppose in case we need actual url to redirect at that time pass parameters values with { path : false } & it will return actual url to redirect
const processURL = (pathRegex, { path, ...params } = {}) => {
  if (path) {
    return pathRegex;
  }
  // if pathRegex = `servers/:id` and params = {id: '123'}
  // then this function will return `servers/123`
  const toPath = pathToRegexp.compile(pathRegex);
  return toPath(params, { encode: (value, token) => value });
};

const abstractURL = (pathRegex) => (options) => processURL(pathRegex, options);

export default {
  HOME: abstractURL('/'),
  WALL: abstractURL('/walls/:filter?'),
  CALENDAR: abstractURL('/calendar'),
  ADMIN: abstractURL('/admin'),
  LOGIN: abstractURL('/login'),
  CALLBACK: abstractURL('/callback'),
  SIGN_UP: abstractURL('/signup'),
  COMING_SOON: abstractURL('/comingsoon'),
  INSIGHTS: abstractURL('/insights'),
  DASHBOARD: abstractURL('/dashboard'),
  // SERVERS: abstractURL("/servers"),
  SERVER_DETAILS: abstractURL('/servers/details/:id'),
  ADD_SERVER: abstractURL('/servers/add'),
  UPDATE_SERVER: abstractURL('/servers/update/:id?'),

  // STUDIOS: abstractURL("/studios"),
  STUDIO_PAGE: abstractURL('/studios/details/:id'),
  ADD_STUDIO: abstractURL('/studios/add'),
  UPDATE_STUDIO: abstractURL('/studios/update/:id'),
  UPDATE_STUDIO_SETTINGS: abstractURL('/studios/update/:id/:action'),
  STUDIO_SETTING: abstractURL('/studio-setting'),
  STUDIO_CATALOG: abstractURL('/studio-catalog/:id'),
  PUNCHPASSES: abstractURL('/passes/:id'),

  USERS: abstractURL('/users'),
  USER_DETAILS: abstractURL('/users/details/:id'),
  INVITE_USERS: abstractURL('/users/invite-users'),
  ADD_USER: abstractURL('/users/add'),
  UPDATE_USER: abstractURL('/users/update/:id?'),

  TEMPLATE: abstractURL('/template'),
  TEMPLATES: abstractURL('/templates'),
  UPDATE_TEMPLATES: abstractURL('/templates/update/:template_id'),
  SEASONSDETAIL: abstractURL('/seasons-detail'),
  PRIZE: abstractURL('/stores'),
  PRIZES: abstractURL('/store/:filter?'),
  UPDATE_PRIZES: abstractURL('/store/edit/:prizes_id'),
  ADD_PRIZES: abstractURL('/store/add/:template_id'),
  VIEW_PRIZES: abstractURL('/store/view/:prizes_id'),

  PROCESS: abstractURL('/process'),
  PROCESSES: abstractURL('/processes/:filter?'),
  UPDATE_PROCESS: abstractURL('/processes/edit/:template_id/:process_id'),
  CREATE_CLONE: abstractURL(
    '/processes/edit/:template_id/:process_id/?action=clone&step=:step'
  ),
  ADD_PROCESS: abstractURL('/setup-schedule/add/:template_id'),
  VIEW_PROCESS: abstractURL('/processes/view/:template_id/:process_id'),

  UPLOAD_FILE: abstractURL('/upload/file'),

  EVALUATOR: abstractURL('/evaluator'),

  MY_PROFILE: abstractURL('/profile'),
  SETTINGS: abstractURL('/settings'),
  HELP: abstractURL('/help'),
  MESSAGES: abstractURL('/messages'),
  ADMINS: abstractURL('/admins'),
  STYLES_TEST: abstractURL('/styles_test'),
  ON_BOARDING: abstractURL('/onboarding'),
  SETUP_STUDIO: abstractURL('/setup-studio'),
  FAMILY_ONBOARDING: abstractURL('/family-onboarding'),
  DEFAULT_USERS: abstractURL('/onboarding/users/default'),
  STUDIO_DETAILS: abstractURL('/studio_details'),

  FORGOT_PASSWORD: abstractURL('/forgot-password'),
  DELETE_USER: abstractURL('/delete-user'),
  PASSWORD_RESET: abstractURL('/password/reset'),
  INVITE_ACCEPT: abstractURL('/invite/accept'),
  CLASS_CREATE: abstractURL('/classes/create'),
  SEASON_CREATE: abstractURL('/season/:action?/:id?'),
  FEED: abstractURL('/message'),
  USERCHECKLIST: abstractURL('/UserChecklist'),
  STUDIO_PRICING: abstractURL('/studio-pricing'),
  ADDSTAFF: abstractURL('/AddStaff'),
  USERSTAFF: abstractURL('/UserStaff'),
  ADDPARENTS: abstractURL('/AddParents'),
  USERPARENTS: abstractURL('/UserParents'),
  USERFAMILIES: abstractURL('/UserFamilies'),
  PROFILE: abstractURL('/UserProfile/:id?'),
  MEMBERSHIP: abstractURL('/user-membership/:id?'),
  USER_PROFILE: abstractURL('/UserProfile/:id'),
  SETUP_SCHEDULE: abstractURL('/setup-schedule/:action?/:id?'),
  CHAT: abstractURL('/message/chat'),
  EMAIL: abstractURL('/message/email'),
  AUDITION: abstractURL('users?type=audition'),
  LEADS: abstractURL('/users?type=leads'),
  CLASS_DETAILS: abstractURL('/classes/details/:id'),
  RECITALS: abstractURL('/recitals'),
  SETUP_RECITALS: abstractURL('/setup-recital/:action?/:id?'),
  PREVIEW_RECITAL: abstractURL('/preview-recital/:id'),
  NOTIFICATION_BAR_IFRAME: abstractURL('/notificaions-bar/:id'),
  RECITAL_LINK: abstractURL('/recital-link/:id?'),
  RESOURCE_CENTER: abstractURL('/resources/:id?'),
  STUDIO_AVAILABLE: abstractURL('/studio-available'),
  SHOP: abstractURL('/sales/shop'),
  PAYMENT: abstractURL('/sales/payment'),
  WAIVER: abstractURL('/waiver/:id'),
  SIGN_WAIVERS: abstractURL('/sign-waivers'),
  CLASSES: abstractURL('/processes/my_action_items'),
  SEASON: abstractURL('/processes/my_action_items?tab=2'),
  DEFAULT_ROUTE: abstractURL('/dashboard'),
  PARENT_DEFAULT_ROUTE: abstractURL('/processes/my_action_items'),
  NOTIFICATION: abstractURL('/notification'),
  CREATE_SHOP: abstractURL('/sales/shop/create'),
  EDIT_SHOP: abstractURL('/shop/edit'),
  CLASS_EDIT: abstractURL('/setup-schedule/edit/:id'),
  SALES: abstractURL('/sales'),
  CREATE_INVOICE: abstractURL('/sales/invoice/create-invoice'),
  INVOICE_LIST: abstractURL('/sales/invoice'),
};
