import React, { useState, useEffect } from 'react';
import classes from './ReviewCalendar.module.scss';
import { Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cs from 'classnames';
import Modal, { ModalFooter, ModalBody } from 'components/Modal';
import back from 'assets/img/icons/back_circle.svg';
import MainContentReview from './components/MainContentReview';
import get from 'lodash/get';
import moment from 'moment';
import * as Const from 'utils/constants';
import Api from 'utils/api';
import { useSelector } from 'react-redux';

const ReviewCalendar = (props) => {
  const {
    validateData,
    classStatus,
    loading,
    onSaveChange,
    onSaveDraft,
    values,
  } = props;
  const [isOpenModal, setOpenModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [otherEvents, setOtherEvents] = useState([]);

  const toggle = () => setOpenModal(!isOpenModal);

  const paths = document.location.pathname.split('/');
  const id = paths[paths.length - 1];

  const data = useSelector(({ classes }) => get(classes, 'class.data', null));

  useEffect(() => {
    Api.doCall(Const.OTHER_EVENTS_IN_CLASS(id), 'GET', null, (response) => {
      if (response.status === 200) {
        const others = get(response.data, 'data.data', []);

        let oEvents = [];
        others.map((item) => {
          const calendar = item.calendar;

          for (const property in calendar) {
            let event = get(calendar, `${property}.0`);
            const time = event.time.split(' - ');

            event.allDay = false;
            event.start = new Date(`${property} ${time[0]}`);
            event.end = new Date(`${property} ${time[1]}`);
            event.room = get(item, '_source.room.room_name');
            event.location = get(item, '_source.location.label');
            event.title = get(item, '_source.class_name');
            event.color = '#FFFFFF';
            event.background = '#9C9CA3';

            const statusClass = get(
              item,
              '_source.course_details_status',
              'draft'
            );

            if (statusClass === 'draft') {
              event.color = '#9C9CA3';
              event.background = 'white';
              event.borderColor = event.color;
            }
            oEvents.push(event);
          }

          return true;
        });

        setOtherEvents(oEvents);
      }
    });
  }, []);
  
  useEffect(() => {
    let post = { ...data, ...values };

    if (get(post, 'start_date') && isOpenModal === true)
      Api.doCall(
        Const.EVENTS_IN_CLASS(),
        'POST',
        { class: post },
        (response) => {
          if (response.status === 200) {
            let listEvents = [...otherEvents];
            for (const property in response.data.data) {
              let event = get(response.data.data, `${property}.0`);
              const time = event.time.split(' - ');

              event.allDay = false;

              const start = moment(
                `${property} ${time[0]}`,
                'YYYY-MM-DD hh:mm A'
              ).format();
              event.start = new Date(start);

              const end = moment(
                `${property} ${time[1]}`,
                'YYYY-MM-DD hh:mm A'
              ).format();
              event.end = new Date(end);
              event.location = get(post, 'location.label');
              event.room = get(post, 'room.room_name');
              event.title = get(post, 'class_name');

              listEvents.push(event);
            }

            setEvents(listEvents);
          }
        }
      );
  }, [isOpenModal]);

  return (
    <div className={classes.root}>
      <div className={classes.reviewCalendarButton}>
        <Button
          className={cs('btn-link', classes.calendarButton)}
          onClick={() => {
            if (validateData() === false) return false;
            setOpenModal(true);
          }}
        >
          <FormattedMessage id="classes > form > Review Calendar" />
        </Button>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggle={toggle}
        className={'reviewCalendarModal'}
      >
        <div className={classes.modal}>
          <ModalBody className={classes.body}>
            <MainContentReview events={events} />
          </ModalBody>

          <ModalFooter className={classes.footer}>
            <div className="flex-grow-1">
              <Button
                className={cs('btn-link', classes.backButton, 'mr-4')}
                onClick={() => toggle()}
              >
                <img src={back} alt="back" className="mr-2" /> Back to
                Scheduling
              </Button>
            </div>
            {classStatus === 'draft' && (
              <Button
                className={cs('btn-link', classes.draftButton, 'mr-4')}
                onClick={async (e) => {
                  if (validateData() === false) return false;
                  onSaveDraft(e);
                }}
              >
                <FormattedMessage id="classes > form > Save as Draft" />
              </Button>
            )}

            <div className={classes.scheduleButton}>
              <Button
                color="primary "
                block={true}
                disabled={loading}
                onClick={(e) => {
                  if (validateData() === false) return false;
                  onSaveChange(e);
                }}
              >
                {loading ? (
                  'Saving...'
                ) : classStatus === 'draft' ? (
                  <FormattedMessage id="classes > form > Schedule Now" />
                ) : (
                  <FormattedMessage id="classes > form > Save Changes" />
                )}
              </Button>
            </div>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

ReviewCalendar.defaultProps = {};

export default injectIntl(ReviewCalendar);
