

import React from "react";
import { SvgIcon } from "@mui/material";

const EditPencil = (props) => (
    <SvgIcon
        fontSize="small"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6695 3.32884L10.589 4.40936C10.4788 4.51952 10.3007 4.51952 10.1905 4.40936L7.58886 1.80769C7.4787 1.69753 7.4787 1.5194 7.58886 1.40924L8.66938 0.328724C9.10768 -0.109575 9.82021 -0.109575 10.2609 0.328724L11.6695 1.73737C12.1102 2.17567 12.1102 2.8882 11.6695 3.32884ZM6.66069 2.3374L0.505718 8.49233L0.00882005 11.3401C-0.0591518 11.7245 0.27602 12.0573 0.660413 11.9917L3.5082 11.4924L9.66317 5.33752C9.77333 5.22736 9.77333 5.04922 9.66317 4.93906L7.06149 2.3374C6.94898 2.22724 6.77085 2.22724 6.66069 2.3374ZM2.90817 7.96497C2.77926 7.83605 2.77926 7.6298 2.90817 7.50088L6.51772 3.89137C6.64663 3.76245 6.85289 3.76245 6.9818 3.89137C7.11071 4.02028 7.11071 4.22654 6.9818 4.35545L3.37226 7.96497C3.24334 8.09388 3.03709 8.09388 2.90817 7.96497ZM2.06204 9.93614H3.18709V10.787L1.6753 11.0518L0.946363 10.3229L1.21122 8.81109H2.06204V9.93614Z" fill="#9C9CA3" />
        </svg>
    </SvgIcon>
);

export default EditPencil;
