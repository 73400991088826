import { capitalize } from 'lodash';
import { ROLES_OBJECT } from 'utils/constants';
import URL from 'utils/urls';
export const NAV_LINKS = {
  AUDITION: {
    name: 'AUDITION',
    label: 'auditions',
  },
  LEAD: {
    name: 'LEAD',
    label: 'leads',
  },
};

export const getRoleId = ({ qp, id, isRole }) => {
  if (id) {
    let roleInfo = ROLES_OBJECT.Instructor.id === id ? '1-2' : '';
    if (qp === NAV_LINKS.AUDITION.label) {
      roleInfo = NAV_LINKS.AUDITION.label;
    }
    if (qp === NAV_LINKS.LEAD.label) {
      roleInfo = NAV_LINKS.LEAD.label;
    }
    if (qp === ROLES_OBJECT.Student.header) {
      roleInfo = 4;
    }
    if(isRole) {
        if (qp === ROLES_OBJECT['Parent/Guardian'].header) {
            roleInfo = '1';
        }
        if (qp === ROLES_OBJECT['Parent/Guardian (Secondary)'].header) {
            roleInfo = '2';
        }
    } else {
        if (qp === ROLES_OBJECT['Parent/Guardian'].header) {
            roleInfo = '1-2';
        }
    }

    if (qp === ROLES_OBJECT.Staff.name) {
      roleInfo = '3-5-6-7';
    }
    return roleInfo;
  }
};

export const getBreadCrum = ({ selectedNavType }) => {
  if (selectedNavType === NAV_LINKS.AUDITION.name) {
    selectedNavType = 'auditions';
  }
  if (selectedNavType)
    return [
      {
        name: 'Users',
        route: URL.USERS(),
      },
      {
        name: capitalize(selectedNavType),
        active: true,
      },
    ];

  return [
    {
      name: 'Users',
      route: URL.USERS(),
    },
    {
      name: 'All',
      active: true,
    },
  ];
};
