import React from 'react';
import classes from './ClassSchedule.module.scss';
import DatePicker from 'components/DatePicker';
import ToggleSwitch from 'components/ToggleSwitch';
import get from 'lodash/get';
import moment from 'moment';
import TimePicker from 'components/TimePicker';
import cs from 'classnames';
import Alert from 'components/Alert';

const convertFormatDate = 'YYYY-MM-DD HH:mm:ss';

const DateTime = (props) => {
  const { values, setFieldValue, validateDate, validateTime } = props;
  const { step, process, template, user, valueChanged } = props;

  const isAdd = document.location.pathname.split('/')[2] === 'add';

  let start_date = null;
  let end_date = null;
  let recurring_event = null;

  if (step) {
    start_date = step.fields.find((f) => f.field === 'start_date');
    end_date = step.fields.find((f) => f.field === 'end_date');
    recurring_event = step.fields.find((f) => f.field === 'recurring_event');
  }

  return (
    <div className={classes.dateTime}>
      <div className="mb-2 d-flex">
        <DatePicker
          className="mr-2"
          hasCalendarIcon={false}
          value={moment(values.start_date, convertFormatDate).toDate()}
          changeHandler={(data) => {
            const hh = moment(values.start_date).format('HH');
            const mm = moment(values.start_date).format('mm');
            const ss = moment(values.start_date).format('ss');
            const date = moment(data)
              .hour(hh)
              .minute(mm)
              .second(ss)
              .format('YYYY-MM-DD HH:mm:ss');
            setFieldValue('start_date', date);

            if (step)
              valueChanged(start_date, process, template, user, {
                value: date,
              });
          }}
        />

        <TimePicker
          name="start_time"
          id="start_time"
          className={cs(classes.input, 'mr-2')}
          selected={moment(values.start_date, convertFormatDate).toDate()}
          onChange={(data) => {
            const date = moment(data).format('YYYY-MM-DD HH:mm:ss');
            setFieldValue('start_date', date);

            if (step)
              valueChanged(start_date, process, template, user, {
                value: date,
              });
          }}
        />
      </div>

      <span className="mr-2">to</span>

      <div
        className={cs(classes.endDateTime, 'flex-column', 'align-items-start')}
      >
        <div className="d-flex align-items-center flex-wrap">
          <TimePicker
            name="end_time"
            id="end_time"
            className={cs(classes.input, 'mr-2', {
              [classes.error]: validateTime === false,
            })}
            selected={moment(values.end_date, convertFormatDate).toDate()}
            onChange={(data) => {
              const date = moment(data).format('YYYY-MM-DD HH:mm:ss');
              setFieldValue('end_date', date);

              if (step)
                valueChanged(end_date, process, template, user, {
                  value: date,
                });
            }}
          />

          <DatePicker
            hasCalendarIcon={false}
            className={cs({
              error: validateDate === false,
            })}
            value={moment(values.end_date, convertFormatDate).toDate()}
            changeHandler={(data) => {
              const hh = moment(values.end_date).format('HH');
              const mm = moment(values.end_date).format('mm');
              const ss = moment(values.end_date).format('ss');
              const date = moment(data)
                .hour(hh)
                .minute(mm)
                .second(ss)
                .format('YYYY-MM-DD HH:mm:ss');
              setFieldValue('end_date', date);

              if (step)
                valueChanged(end_date, process, template, user, {
                  value: date,
                });
            }}
          />

        </div>

        {(validateDate === false || validateTime === false) && (
          <div
            className={cs(classes.alert, {
              [classes.errorDate]: validateDate === false,
              [classes.errorTime]: validateTime === false,
            })}
          >
            <Alert
              className="warning"
              content="Event must end after it starts."
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DateTime;
