import React from 'react';
import classes from './InputText.module.scss';
import cs from 'classnames';
import { Input } from 'reactstrap';
import errorIcon from 'assets/img/icons/error.svg';
import InputMask from 'react-input-mask';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import Calendar from 'react-calendar';
import moment from 'moment';
import calendar_next from 'assets/img/icons/calendar_next.svg';
import calender_previous from 'assets/img/icons/calendar_previous.svg';
import Editor from 'components/Editor';

const InputText = (props) => {
  const {
    rootClassName,
    className,
    error,
    type,
    label,
    rightComponent,
    mask,
    selected,
    disabled,
    showErrorIcon = true,
    ...rest
  } = props;
  if (type === 'calendar') {
    return (
      <div className={cs(classes.root, rootClassName)}>
        {label}

        <div
          style={{
            marginLeft: 20,
            marginTop: -10,
            position: 'absolute',
            zIndex: 1,
          }}
          className={classes.container}
        >
          <Calendar
            value={selected}
            onChange={rest.onChange}
            formatShortWeekday={(locale, date) => date.toString()[0]}
            tileClassName={'calendar-tile'}
            calendarType={'US'}
            next2Label={null}
            nextLabel={<img src={calendar_next} alt={'calender_next'} />}
            prev2Label={null}
            prevLabel={
              <img src={calender_previous} alt={'calender_previous'} />
            }
          />
        </div>
      </div>
    );
  }
  if (type === 'date') {
    return (
      <div className={cs(classes.root, rootClassName)}>
        {label}

        <div className={classes.container}>
          <DatePicker
            value={
              selected
                ? selected instanceof Date
                  ? selected
                  : moment(selected, rest.format || 'YYYY-MM-DD').toDate()
                : null
            }
            changeHandler={(value) => {
              rest.onChange(new Date(value));
            }}
            className={cs(
              className || 'inner-input form-control start-end-date',
              { [classes.error]: error }
            )}
            maxDate={rest.maxDate}
            minDate={rest.minDate}
            hasCalendarIcon={rest.hasCalendarIcon || false}
            dayPlaceholder="DD"
            monthPlaceholder="MM"
            yearPlaceholder="YYYY"
            disabled={disabled}
            calednderColor={rest.calednderColor}
            onBlur={rest.onBlur}
          />
          {error && (
            <>
              {showErrorIcon && (
                <img
                  className={classes.errorIconDatePicker}
                  src={errorIcon}
                  alt="error"
                />
              )}
              <div className={classes.errorMessage}>{error}</div>
            </>
          )}
        </div>
      </div>
    );
  } else if (type === 'time') {
    return (
      <div className={cs(classes.root, rootClassName)}>
        {label}

        <div className={classes.container}>
          {props.minTime ? (
            <TimePicker
              name={props.name}
              className={cs(className, { [classes.error]: error })}
              id={props.id}
              selected={props.selected}
              placeholderText={props.placeholderText}
              onChange={props.onChange}
              onBlur={props.onBlur}
              minTime={props.minTime}
              maxTime={props.maxTime}
            />
          ) : (
            <TimePicker
              name={props.name}
              className={cs(className, { [classes.error]: error })}
              id={props.id}
              selected={props.selected}
              placeholderText={props.placeholderText}
              onChange={props.onChange}
              onBlur={props.onBlur}
            />
          )}

          {!error && rightComponent}

          {error && (
            <>
              <img
                className={classes.errorIconDatePicker}
                src={errorIcon}
                alt="error"
              />
              <div className={classes.errorMessage}>{error}</div>
            </>
          )}
        </div>
      </div>
    );
  } else if (type === 'editor') {
    return (
      <div className={cs(classes.root, rootClassName)}>
        <div className="d-flex">
          {label}

          <div className={cs(classes.container, 'ml-2')}>
            {error && <div className={classes.errorMessage}>{error}</div>}
          </div>
        </div>

        <div className={classes.container}>
          <div className={cs(className, { [classes.error]: error })}>
            <Editor error={error} {...rest} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={cs(classes.root, rootClassName)}>
      {label}

      <div className={classes.container}>
        {mask ? (
          <InputMask
            type={type}
            className={cs(className, { [classes.error]: error })}
            mask={mask}
            {...rest}
          />
        ) : (
          <Input
            type={type}
            className={cs(className, { [classes.error]: error })}
            autoComplete="nope"
            disabled={disabled}
            {...rest}
          />
        )}

        {!error && rightComponent}

        {error && (
          <>
            {showErrorIcon && (
              <img src={errorIcon} alt="error" className={classes.errorIcon} />
            )}
            <div className={classes.errorMessage}>{error}</div>
          </>
        )}
      </div>
    </div>
  );
};

InputText.defaultProps = {
  type: 'text',
  value: '',
  className: '',
  onChange: () => {},
  error: false,
  mask: '',
  disabled: false,
};

export default InputText;
