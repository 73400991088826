import React, {Component} from 'react';
import {
    Row,
    Col,
    Form,
} from "reactstrap";
import PropTypes from 'prop-types';

import Dropdown from "components/Dropdown";
import AvatarCard from 'components/Card/AvatarCard';
import UserListItem from 'components/Card/UserListItem';

class Participants extends Component {

    getFieldRefs = () => {
        const {template} = this.props;
        const step = template.steps.participants;
        const participants = step.fields.find(f => f.field === "participants");
        return {
            participants
        };
    };

    renderForm = () => {
        const {userList, process, roles, valueChanged, template, user} = this.props;
        const courseStudios = process.data.fields.course_studios ? process.data.fields.course_studios.value : [];
        const hasCourseStudios = courseStudios.length > 0;

        const {participants} = this.getFieldRefs();
        const selectedParticipants = process.data.fields.participants && process.data.fields.participants.value ? process.data.fields.participants.value : [];

        let userOptions = [];
        userList.forEach(user => {
            user.studios.forEach(studio => {
                const userRoles = Object.values(studio.user_roles);
                // temporary allow adding users from all studios
                if (//courseStudios.includes(studio.current_studio) && 
                    userRoles.find(userRole => roles.includes(userRole))
                    && !selectedParticipants.includes(user.id)) {
                    userOptions.push({label: user.name, value: user.id});
                }
            })
        });
        return (
            <div className="text-left">
                <Form>
                    <Row>
                        <Col>
                            <Dropdown
                                name="participants"
                                options={userOptions}
                                label={
                                    <label>
                                        Select Participant
                                    </label>
                                }
                                value=""
                                onChange={selectedOption => {
                                    valueChanged(
                                        participants,
                                        process,
                                        template,
                                        user,
                                        selectedParticipants.concat(selectedOption.value)
                                    )
                                }}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    };

    renderView = () => {
        const {
            process, userList, mode, valueChanged, template, user, step
        } = this.props;

        const {participants} = this.getFieldRefs();
        const selectedParticipants = process.data.fields.participants && process.data.fields.participants.value ? process.data.fields.participants.value : [];
        const filteredUsers = userList.filter(user => selectedParticipants.includes(user.id));
        return (
            <div className="text-left">
                {filteredUsers.map(filteredUser =>
                    <Row>
                        <Col>
                            <UserListItem avatar={filteredUser.avatar_url} title={filteredUser.name}
                                          className="text-black-50"
                                          showDelete={mode === 'edit'} id={filteredUser.id}
                                          onDeleteClick={() => {
                                              valueChanged(
                                                  participants,
                                                  process,
                                                  template,
                                                  user,
                                                  selectedParticipants.filter(x => x !== filteredUser.id)
                                              )
                                          }}/>
                        </Col>
                    </Row>
                )}
            </div>
        )
    };

    render() {
        const {mode} = this.props;
        return (
            <div className="orderForm">
                {mode === 'edit' && this.renderForm()}
                {this.renderView()}
            </div>
        )
    }
}

Participants.propTypes = {
    mode: PropTypes.string
};

Participants.defaultProps = {
    mode: "edit"
};

export default Participants;