import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueChanged, saveFormValues } from "store/actions/processes";
import SelectStudios from "./view";

const Enhanced = connect(
  (state, props) => ({
    ...props,
    user: state.auth.user,
    auth: state.auth,
  }),
  {
    formValueChanged,
    saveFormValues,
  }
)(SelectStudios);
export default withRouter(Enhanced);
