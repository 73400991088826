export const AUTH_USER = 'AUTH_USER';
export const TOKEN_USER = 'TOKEN_USER';
export const ID_TOKEN_USER = 'ID_TOKEN_USER';

export const LOGOUT_USER = 'LOGOUT_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_INFO = 'USER_INFO';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_COURSES = 'USER_INFO_COURSES';
export const USER_INFO_COURSES_UPDATE = 'USER_INFO_COURSES_UPDATE';
export const REQUEST_USER_INFO_COURSES = 'REQUEST_USER_INFO_COURSES';
export const REQUEST_AUTH_USER = 'REQUEST_AUTH_USER';
export const USER_INFO_PUNCHPASS = 'USER_INFO_PUNCHPASS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const STOP_AUTH_LOADER = 'STOP_AUTH_LOADER';
export const START_AUTH_LOADER = 'START_AUTH_LOADER';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const USER_CART = 'USER_CART';
