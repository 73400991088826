import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';

const CalendarIcon = ({ color }) => (
  <svg width="16" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2222 1.8H13.3333V0H11.5556V1.8H4.44444V0H2.66667V1.8H1.77778C0.791111 1.8 0.00888888 2.61 0.00888888 3.6L0 16.2C0 17.19 0.791111 18 1.77778 18H14.2222C15.2 18 16 17.19 16 16.2V3.6C16 2.61 15.2 1.8 14.2222 1.8ZM14.2222 16.2H1.77778V6.3H14.2222V16.2Z"
      fill={color}
    />
  </svg>
);

const View = ({
  disabled,
  error,
  white,
  hasCalendarIcon,
  changeHandler,
  value,
  minDate,
  maxDate,
  className,
  calednderColor = '#9C9CA3',
  ...rest
}) => {
  let classNames = [className];
  if (white) {
    classNames.push('react-date-picker-white');
  }
  if (error) {
    classNames.push('error');
  }
  // DOES NOT FOUND THE ERROR OF ONCHANGE
  // REFRENCE https://github.com/wojtekmaj/react-date-picker/issues/288

  const datePicker = useRef();

  // useEffect(() => {
  //   if (!datePicker.current) {
  //     return;
  //   }
  //   // Replace DatePicker's onOutsideAction with our own that takes Gatsby's focus wrapper into account
  //   const _this = datePicker.current;
  //
  //   _this.onOutsideAction = (event) => {
  //     if (
  //       _this.wrapper &&
  //       !_this.wrapper.contains(event.target) && event.target.className && event.target.className.indexOf &&
  //       !event.target.className.indexOf('react-calendar') // fix for safari calendar bug
  //     ) {
  //       _this.closeCalendar()
  //       event.stopPropagation()
  //     }
  //   }
  // });

  return (
    <DatePicker
      ref={datePicker}
      calendarType={'US'}
      next2Label={null}
      nextLabel={
        <img
          src={require('../../assets/img/icons/calendar_next.svg')}
          alt={'calender_next'}
        />
      }
      prev2Label={null}
      prevLabel={
        <img
          src={require('../../assets/img/icons/calendar_previous.svg')}
          alt={'calender_previous'}
        />
      }
      formatShortWeekday={(locale, date) => date.toString()[0]}
      tileClassName={'calendar-tile'}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          datePicker.current.closeCalendar();
        }
      }}
      onChange={changeHandler}
      value={value}
      clearIcon={null}
      calendarIcon={
        hasCalendarIcon ? (
          <CalendarIcon color={disabled ? '#9C9CA3' : calednderColor} />
        ) : null
      }
      disabled={disabled}
      className={classNames}
      //minDetail={'month'}
      minDate={minDate}
      maxDate={maxDate}
      popperPlacement="bottom-start"
      {...rest}
    />
  );
};
View.propTypes = {
  /* label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.string,
    closeHandler: PropTypes.func,
    changeHandler: PropTypes.func.isRequired,
    enableAlpha: PropTypes.bool */
  hasCalendarIcon: PropTypes.bool,
};
View.defaultProps = {
  hasCalendarIcon: false,
  changeHandler: () => {},
  value: new Date(),
  minDate: null,
  maxDate: null,
};
export default View;
