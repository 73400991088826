import ConfirmationModal from "./view";
import { compose,lifecycle } from "recompose";
import { get, isFunction } from "lodash";

export default compose(
    lifecycle({
        componentDidUpdate(prevProps){
            if(
                get(this.props, "isOpen")
                && get(this.props, "state") === 'success'
                && isFunction(prevProps.onCancel)
            ){
                setTimeout(() => {
                    prevProps.onCancel();
                }, 2000);
            }
        }
    })
)(ConfirmationModal);
