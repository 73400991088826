import React, { useEffect, useState } from 'react';
import classes from './EventDetail.module.scss';
import { injectIntl } from 'react-intl';
import Input from 'components/FormFields/InputText';
import cs from 'classnames';
import locationIcon from 'assets/img/icons/location.svg';
import seasonsImg from 'assets/img/icons/seasonsimg.png';
import roomIcon from 'assets/img/icons/room.svg';
import noteIcon from 'assets/img/icons/note.svg';
import Dropdown from 'components/Dropdown';
import Select from 'components/FormFields/Select';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Autosuggest from 'react-autosuggest';
import sortBy from 'lodash/sortBy';
import doneIcon from 'assets/img/icons/done.svg';
import { getStudio } from 'store/actions/studios';
import { userInfo } from 'store/actions/auth';
import Modal from 'components/Modal';
import SetupSeasons from 'pages/StudioDetails/SetupStudio/components/SetupSeasons';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { Col, FormGroup, Row } from 'reactstrap';
import TextInput from '../../../../../FormFields/InputText';
import { InputLabel } from '../../../../../../pages/StudioDetails/styles';
import { Box } from '@mui/system';
import PrimaryButton, {
  DefaultButtonLink,
} from '../../../../../StyledUI/Buttons';
import SeacondaryDialog from '../../../../../StyledUI/SeacondaryDialog';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { addRoom } from 'store/actions/studios';
import { noop } from 'lodash';
import { classType } from 'pages/SetupSchedule/util';

const HeaderNode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f8e6df;
  height: 76px;
  padding: 0rem 1rem;
  .seacondary-close {
    padding: 1rem;
    svg {
      font-size: 10px;
    }
  }
`;

const headerNode = ({ handleClose }) => (
  <HeaderNode sx={{ px: 2 }}>
    <Typography variant={'h4Regular'}>Create Rooms</Typography>
    <Box>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  </HeaderNode>
);

const schema = Yup.object().shape({
  rooms: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().min(4, 'too short').required('Required'), // these constraints take precedence
      location: Yup.string(), // these constraints take precedence
    })
  ),
});
const EventDetail = ({
  values,
  step,
  process,
  template,
  user,
  valueChanged,
  setFieldValue,
  classTypeValue = null,
  hideTextArea = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const t = props.intl.formatMessage;
  const [roomOptions, setRoomOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [roomPlaceholder, setRoomPlaceholder] = useState('Select Room');
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [newSeasonName, setNewSeasonName] = useState(false);
  const [warningSeason, setWarningSeason] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const toggle = () => setOpenModal(!isOpenModal);
  const [open, setOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      rooms: [
        {
          name: '',
          location: get(values, 'location.id', ''),
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      const locationid = get(values, 'location.id', '');
      if (!locationid) return false;
      actions.setSubmitting(true);
      const apis = data.rooms.map((i) =>
        dispatch(
          addRoom({
            location_id: locationid,
            room_capacity: '',
            room_name: i.name,
          })
        )
      );
      const res = await Promise.all(apis);
      const rooms = res
        .at(-1)
        .data.data.locations.find((r) => r.id === locationid)
        .rooms.map((i) => ({ value: i.id, label: i.room_name, ...i }));

      setRoomOptions(
        rooms.concat({ value: 'create_new', label: 'Create New Room' })
      );

      actions.setSubmitting(false);
      actions.resetForm();
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const studio_id = useSelector(({ auth }) => {
    return get(auth, `info.current_studio_id`);
  });

  const studio = useSelector(({ auth }) => {
    return get(auth, `info.studios`, []).find(
      (item) => item.studio_id === studio_id
    );
  });
  const seasons = sortBy(get(studio, 'seasons', []) || [], (e) => e.name).map(
    (item) => {
      return { label: item.name, value: item.id };
    }
  );

  const locationObj = useSelector((state) =>
    get(state, `studios.studio[${studio_id}]data.data`)
  );
  const locationsOptions = locations.map((l) => ({
    label: l.label,
    value: l.id,
  }));
  useEffect(() => {
    const location = ((locationObj && locationObj.locations) || []).filter(
      (studio) => {
        studio.value = studio.value || studio.id;
        studio.label =
          studio.label ||
          `${studio.location_name} - ${studio.address}, ${studio.city}, ${
            studio.state.split(',')[0]
          } ${studio.zipcode}`;
        return studio;
      }
    );
    setLocations(location);
    setFilteredLocation(location);
    if (values.location && values.location.id) {
      handleRoom(values.location, !values.room, location);
    }
  }, [locationObj]);

  useEffect(() => {
    dispatch(getStudio(studio_id));
  }, [studio_id]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? locations
      : locations.filter((lang) =>
          lang.label.toLowerCase().includes(inputValue)
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion;

  // Use your imagination to render suggestions.

  let event_note = null;
  let location = null;
  let room = null;

  if (step) {
    event_note = step.fields.find((f) => f.field === 'event_note');
    location = step.fields.find((f) => f.field === 'location');
    room = step.fields.find((f) => f.field === 'room');
  }

  const handleRoom = (selectedLocation, reset, location = locations) => {
    if (
      Object.keys(selectedLocation).length === 0 &&
      roomOptions.length === 0
    ) {
      return;
    }
    const roomArr =
      get(
        locations.find((l) => l.id === selectedLocation.id),
        'rooms',
        []
      ) || [];
    const rooms = roomArr.filter((room) => {
      room.value = room.id;
      room.label = room.room_name;
      return room;
    });

    setRoomOptions(
      [classType.private_session.value, classType.studio_rental.value].includes(
        classTypeValue
      )
        ? rooms.concat({ value: 'create_new', label: 'Create New Room' })
        : rooms
    );

    if (reset) {
      setFieldValue('room', '');

      if (step)
        valueChanged(room, process, template, user, {
          value: '',
        });
    }
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: t({ id: 'classes > form > Add Location - placehoder' }),
    value:
      values.location && values.location.label ? values.location.label : '',
    onChange: (e, { newValue }) => {
      if (newValue && newValue.id) {
        handleRoom(newValue, true);
        setFieldValue('location', newValue);

        if (step)
          valueChanged(location, process, template, user, {
            value: newValue,
          });
      } else {
        handleRoom({}, true);
        setFieldValue('location', {
          value: 0,
          id: 0,
          label: newValue,
        });

        if (step)
          valueChanged(location, process, template, user, {
            value: {
              value: 0,
              id: 0,
              label: newValue,
            },
          });
      }
    },
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    setFilteredLocation(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setFilteredLocation([]);
  };
  return (
    <div className={classes.root}>
      {props.from === 'event' && (
        <div className="d-flex align-items-baseline">
          <img
            src={seasonsImg}
            alt="Season"
            className={cs('mr-2 mt-2', classes.icon)}
          />
          <div className={cs(classes.SeasonBox)}>
            <Select
              error={
                props.touched.season && props.errors.season
                  ? props.errors.season
                  : ''
              }
              name="season"
              text="season"
              options={seasons}
              placeholder="Select a Season"
              placeholderFocus="Type to search seasons"
              value={get(values, 'season', '')}
              onChange={(data) => {
                setFieldValue('season', data);
              }}
              onBlur={(e) => {
                props.handleBlur(e);
                // props.setTouched({ ...props.touched, season: true });
              }}
              onCreate={async (data) => {
                toggle();
                setNewSeasonName(data);
                props.setTouched({ ...props.touched, season: false });
              }}
            />

            {warningSeason && (
              <div className={classes.warningMessage}>
                <img src={doneIcon} alt="Done" className={classes.icon} /> New
                seasons added to your studio.{' '}
                <button
                  onClick={() => {
                    setWarningSeason(false);
                  }}
                  className={classes.gotIt}
                >
                  Got It!
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="d-flex align-items-start mt-3">
        <img
          src={locationIcon}
          alt="location"
          className={cs('mr-2 mt-2', classes.icon)}
        />
        <div className={cs(classes.location)}>
          <Autosuggest
            suggestions={filteredLocation}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={(suggestion) => <div>{suggestion.label}</div>}
            inputProps={inputProps}
            shouldRenderSuggestions={(val) => true}
            // alwaysRenderSuggestions
          />
        </div>
      </div>
      {values.location && values.location.id ? (
        <div className="d-flex align-items-start mt-3">
          <img
            src={roomIcon}
            alt="room"
            className={cs('mr-2 mt-2', classes.icon)}
          />

          <div className={cs(classes.room)}>
            {![
              classType.private_session.value,
              classType.studio_rental.value,
            ].includes(classTypeValue) ? (
              <Dropdown
                label={null}
                placeholder={roomPlaceholder}
                outline={true}
                hideSelectedOptions={true}
                name="room"
                onChange={(selectedOption) => {
                  setFieldValue('room', selectedOption);
                  if (step)
                    valueChanged(room, process, template, user, {
                      value: selectedOption,
                    });
                }}
                onMenuOpen={(val) => setRoomPlaceholder('Type to search...')}
                onMenuClose={(val) => setRoomPlaceholder('Select Room')}
                value={values.room}
                options={roomOptions}
              />
            ) : (
              <Dropdown
                label={null}
                placeholder={roomPlaceholder}
                outline={true}
                name="room"
                allowNew
                hideSelectedOptions={true}
                isMulti
                closeMenuOnSelect={true}
                autoFocus={true}
                isFocused={true}
                formatCreateLabel={() => 'Not Found!'}
                onChange={(selectedOption) => {
                  if (
                    selectedOption.filter(
                      (i) => i.__isNew__ || i.value === 'create_new'
                    ).length > 0
                  ) {
                    handleClickOpen();
                    return;
                  }
                  setFieldValue('room', selectedOption);
                  if (step)
                    valueChanged(room, process, template, user, {
                      value: selectedOption,
                    });
                }}
                value={values.room}
                options={roomOptions}
              />
            )}
          </div>
        </div>
      ) : null}
      {!hideTextArea && (
        <div className="d-flex align-items-start mt-3">
          <img
            src={noteIcon}
            alt="note"
            className={cs('mr-2 mt-2', classes.icon)}
          />
          <Input
            type="textarea"
            placeholder={t({ id: 'classes > form > note - placehoder' })}
            value={values.event_note}
            className={cs(classes.note)}
            onChange={(e) => {
              const content = e.target.value;
              setFieldValue('event_note', content);

              if (step)
                valueChanged(event_note, process, template, user, {
                  value: content,
                });
            }}
          />
        </div>
      )}

      <Modal
        isOpen={isOpenModal}
        toggle={toggle}
        className="SeasonModal"
        title={'Add Seasons'}
        showClose={true}
      >
        <div className={classes.modal}>
          <SetupSeasons
            step={7}
            setStep={() => {
              toggle();
            }}
            modal={true}
            newSeasonName={newSeasonName}
            onCreate={async () => {
              toggle();
              setWarningSeason(true);
              await dispatch(userInfo());
            }}
          />
        </div>
      </Modal>
      {open && (
        <SeacondaryDialog
          footer={false}
          paper={{ width: 1000, maxHeight: 800, borderRadius: '20px' }}
          headerNode={headerNode({ handleClose })}
          open={open}
          closeDialog={handleClose}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="form-container ">
              <FormikProvider value={formik}>
                <FieldArray name="rooms">
                  {({ push }) => {
                    return (
                      <FormGroup className="p-3">
                        {formik.values.rooms.map((room, index) => (
                          <Row key={index} className={'mb-2'}>
                            <Col xl="4">
                              <TextInput
                                label={
                                  <InputLabel>
                                    Room Name <span>*</span>
                                  </InputLabel>
                                }
                                type="text"
                                value={room.name}
                                name={`rooms[${index}].name`}
                                error={get(
                                  formik,
                                  `errors.rooms[${index}].name`,
                                  ''
                                )}
                                placeholder="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="inner-input mt-2"
                              />
                            </Col>
                            <Col xl="4">
                              <Select
                                isDisabled={true}
                                label={
                                  <InputLabel>
                                    Location <span>*</span>
                                  </InputLabel>
                                }
                                value={locationsOptions.find(
                                  (item) =>
                                    item.value ===
                                    formik.values.rooms[index].location
                                )}
                                onChange={noop}
                                options={locationsOptions}
                                text=""
                              />
                            </Col>
                          </Row>
                        ))}
                        <Row className={'mt-1'}>
                          <Col xl="12">
                            <Button
                              variant={'outlined'}
                              color={'primary'}
                              width="160"
                              onClick={() =>
                                push({
                                  name: '',
                                  location: get(values, 'location.id', ''),
                                })
                              }
                            >
                              + Add Another Room
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    );
                  }}
                </FieldArray>
              </FormikProvider>
              <Box
                display={'flex'}
                justifyContent="flex-end !important"
                className="footer"
                gap={1}
              >
                <DefaultButtonLink
                  variant="link"
                  className="cancel"
                  onClick={() => handleClose()}
                >
                  Cancel
                </DefaultButtonLink>
                <PrimaryButton
                  variant="contained"
                  width="160"
                  loading={formik.isSubmitting}
                  type="submit"
                  className="next-button"
                >
                  Create
                </PrimaryButton>
              </Box>
            </div>
          </form>
        </SeacondaryDialog>
      )}
    </div>
  );
};
export default injectIntl(EventDetail);
