import React from "react";
// import Page from "components/Page";
import Spinner from "components/Spinner";
// import PrivilegedComponent from "components/PrivilegedComponent";
import Tabs from "components/Process/tabsIndex";


// import { PRIVILEGES } from "utils/constants";

const AddProcessPage = props => {
  const { ui_hint, process } = props;
  // let isUpdate = props.process.data.fields.key;

  return (
    // <PrivilegedComponent
    //   requires={privileges => {
    //     if (isUpdate) return privileges.indexOf(PRIVILEGES.EDIT_PPROCESSES) > -1;
    //     else return privileges.indexOf(PRIVILEGES.ADD_PPROCESSES) > -1;
    //   }}>
    //   {({ isValid }) => {
    //     if (isValid) {
    // return (
    <>
      {props.process && props.template && (
        <Tabs
          process={props.process}
          template={props.template}
          match_process_id={props.match_process_id}
          ui_hint={ui_hint}
        />
      )}
      <Spinner isLoading={process.loading && !process.saving && !process.spotSwitching} />
    </>
    //       );
    //     } else {
    //       return <Redirect to="/processs" />;
    //     }
    //   }}
    // </PrivilegedComponent>
  );
};

export default AddProcessPage;
