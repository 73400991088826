import { compose } from "recompose";
import get from "lodash/get";
import { connect } from "react-redux";
import PrivilegedComponent from "./view";

export default compose(
  connect(state => ({
    privileges: get(state, "auth.user.privileges") || [],
    is_god_admin: get(state, "auth.user.is_god_admin", 0)
  }))
)(PrivilegedComponent);
