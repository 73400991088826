import View from './view';
import { compose, withState, withHandlers } from 'recompose';

export default compose(
  withState('timer', 'setTimer', 0),
  withState('isShowVolume', 'setIsShowVolume', false),
  withState('currentBubble', 'setCurrentBubble', null),
  withHandlers({
    toggleSetVolume: ({volume, setVolume}) => async() => {
      if(volume === 0){
        setVolume(1);
      }else{
        setVolume(0);
      }
    }
  })
)(View);