import React from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/lib/fa";
import styled from "styled-components";
// margin-left: ${({ isExpand }) => (isExpand ? `-15px` : `-19px`)};
const ButtonCircle = styled.button`
  margin-top: 100px;
  position: fixed;
  width: 35px;
  height: 35px;
  border-radius: 100px;
  border: none;
  font-weight: bold;
  color: #8165f3;
	background-color: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 10px #aaa;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:active {
    border-color: none;
  }
  &:focus {
    outline: none;
  }
`;



const ContainerCircle = styled.div`
    width: 35px;
    background-color: transparent;
    margin: 0px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      width: 1px;
      background-color: rgba(0,0,0,0.125);
      bottom: 0px;
      left: 50%;
      margin-left: -1px;
      z-index: 0;
    }
`;

const Button = styled.button`
  margin-top: 100px;
  position: absolute;
  width: 40px;
  height: 40px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border: none;
  font-weight: bold;
  color: #8165f3;
	background-color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:active {
    border-color: none;
  }
  &:focus {
    outline: none;
  }
  &:before{
    content: "";
    box-shadow: none;
    display: block;
    height: 40px;
    position: absolute;
    left: 15px;
    width: 5px;
    top: 0;
    z-index: 2;
    background-color: #fff;
  }
  &:after{
    content: "";
    box-shadow: 6px 4px 9px -1px #aaa;
    display: block;
    height: 40px;
    position: absolute;
    right: 0;
    width: 20px;
    top: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
`;
// width: ${({ isExpand }) => (isExpand ? `0px` : `1px`)};
const Container = styled.div`
    width: 40px;
    background-color: transparent;
    margin: 0px;
    position: relative;
    margin-left: -35px;
`;
const View = ({ sizeIcon, isExpand, onToggle, className, activeTab }) => {
  return activeTab === 'participants' ?
    <ContainerCircle isExpand={isExpand} className={className}>
      <ButtonCircle isExpand={isExpand} onClick={onToggle}>
        {isExpand ? (
          <FaAngleLeft size={sizeIcon} className="expand-icon" />
        ) : (
            <FaAngleRight size={sizeIcon} className="expand-icon" />
          )}
      </ButtonCircle>
    </ContainerCircle>
    :
    <Container isExpand={isExpand} className={className}>
      <Button isExpand={isExpand} onClick={onToggle}>
        {isExpand ? (
          <FaAngleLeft size={sizeIcon} className="expand-icon" />
        ) : (
            <FaAngleRight size={sizeIcon} className="expand-icon" />
          )}
      </Button>
    </Container>
}

View.defaultProps = {
  sizeIcon: 30
};
export default View;
