import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Form,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { get } from "lodash";
import Avatar from "components/Avatar";
import Textarea from 'react-textarea-autosize';
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import bn from "utils/bemnames";
import Spinner from "components/Spinner";
const bem = bn.create("send-mail-dialog");
class SendMailDialog extends React.Component{

    render(){
        const {
            subject,
            to,
            cc,
            process_id,
            isOpenDialog,
            toggleOpenDialog,
            onFormSubmit,
            isLoading,
            color,
            user,
            userInfo,
            process
        } = this.props;
        const nickName = get(process, "data.fields.contract_client_nickname.value") ? get(process, "data.fields.contract_client_nickname.value") : get(process, "data.fields.client_name.value", "");
        const clientName = get(process, "data.fields.client_name.value", "");
        const orderTitle = get(process, "data.fields.order_title.value", "");
        const deadline = get(process, "data.fields.order_start_date.value") ? moment(get(process, "data.fields.order_start_date.value")).format("MMMM DD") : "";
        const message = `The radio commercial for the ${clientName} ${orderTitle} is ready!.Please click on the link below to review, comment and either approve or request changes.`;
        const studios = get(process, "data.fields.course_studios.value", []).map(item => item);
        const roles_objects = get(user, 'roles', {})
        const roles = Object.keys(roles_objects).map((key, index) => {return {name: roles_objects[key], id:parseInt(key)}}) 
        const rolesName = roles.map(role => role.name).join(',');
        const studiosName = get(userInfo, "studios", []).map(studio => studio.current_studio).join(',');
        return (
            <div className={bem.b()}>
                <Button
                    color={color || "primary"}
                    onClick={toggleOpenDialog}
                    outline
                    className={classnames('btn btn-primary btn-pull-left', bem.e('btn-send-mail'))}
                >
                    <FormattedMessage
                        id="send mail dialog > send to client"
                    />
                </Button>
                <Modal isOpen={isOpenDialog} toggle={toggleOpenDialog} className={bem.b()}>
                    <ModalHeader toggle={toggleOpenDialog} className={bem.e('header')} />
                    <ModalBody className={bem.e('body')}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                subject: subject || "",
                                to: to || "",
                                cc: cc || "",
                                message: message || "",
                                link: get(process, "data.fields.email_link.value", "#") || '',
                                process_id: process_id || "",
                            }}
                            validationSchema={Yup.object().shape({
                                subject: Yup.string()
                                .required(this.props.intl.formatMessage({id: "validate > subject is required"})),
                                message: Yup.string()
                                .required(this.props.intl.formatMessage({id: "validate > message is required"})),
                                to: Yup.string()
                                .required(
                                    this.props.intl.formatMessage({id: "validate > to is required"})
                                ).email(this.props.intl.formatMessage({id: "validate > invalid email"}))
                            })}
                            onSubmit={onFormSubmit}>
                            {formProps => {
                                const errors = formProps.errors;
                                const touched = formProps.touched;
                                const values = formProps.values;
                                return (
                                <Form onSubmit={formProps.handleSubmit}>
                                    <div className={bem.e('header-information')}>
                                        <div className={bem.e('form-control')}>
                                            <label htmlFor="client-to" className={bem.e('label')}>
                                                <FormattedMessage
                                                    id="send mail dialog > to"
                                                />:
                                            </label>
                                            <input
                                                type="email"
                                                name="to"
                                                id="client-to"
                                                value={values.to}
                                                className={classnames(bem.e('input'), {
                                                    [bem.e('error')]: touched.to && errors.to
                                                })}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                            />
                                        </div>
                                        <div className={bem.e('form-control')}>
                                            <label htmlFor="client-cc" className={bem.e('label')}>
                                                <FormattedMessage
                                                    id="send mail dialog > cc"
                                                />:
                                            </label>
                                            <input
                                                type="email"
                                                name="cc"
                                                id="client-cc"
                                                value={values.cc}
                                                className={classnames(bem.e('input'), {
                                                    [bem.e('error')]: touched.cc && errors.cc
                                                })}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                            />
                                        </div>
                                        <div className={bem.e('form-control')}>
                                            <label htmlFor="client-subject" className={bem.e('label')}>
                                                <FormattedMessage
                                                    id="send mail dialog > subject"
                                                />:
                                            </label>
                                            <input
                                                type="text"
                                                name="subject"
                                                id="client-subject"
                                                value={values.subject}
                                                className={classnames(bem.e('input'), {
                                                    [bem.e('error')]: touched.subject && errors.subject
                                                })}
                                                onChange={formProps.handleChange}
                                                onBlur={formProps.handleBlur}
                                            />
                                        </div>
                                    </div>
                                    <div className={bem.e('email-editor')}>
                                        <p className={bem.e('welcome')}>Hello {nickName},</p>
                                        <Textarea
                                            name="message"
                                            id="client-message"
                                            value={values.message}
                                            className={classnames(bem.e('message'), {
                                                [bem.e('error')]: touched.message && errors.message
                                            })}
                                            placeholder={"Message"}
                                            onChange={formProps.handleChange}
                                            onBlur={formProps.handleBlur}
                                            maxRows={10}
                                        />
                                        <div className={bem.e('box-action')}>
                                            <h2 className={bem.e('box-action-title')}>
                                                {`${orderTitle}: ${get(process,"data.fields.order_length.value")} SECOND SPOT`}
                                            </h2>
                                            <h3 className={bem.e('box-sub-title')}>{orderTitle}</h3>
                                            <div className={bem.e('box-action-sub-title')}>
                                                <strong><FormattedMessage id="send mail dialog > studios" />:</strong>
                                                <span>{studios.join(',')}</span>
                                            </div>
                                            
                                            <span className={bem.e('deadline')}>
                                                <FormattedMessage id="send mail dialog > deadline" />: {deadline}
                                            </span>
                                            <a href={get(process, "data.fields.email_link.value", "#")} className={classnames("btn btn-blue", bem.e('email-button'))}><FormattedMessage id="send mail dialog > review spot" /></a>
                                        </div>
                                    </div>
                                    <div className={bem.e('section-user')}>
                                        <div className={bem.e('user')}>
                                            <div className={bem.e('user-avatar')}>
                                                <Avatar src={get(user, "avatar_url", "")} size={60} />
                                            </div>
                                            <div className={bem.e('user-info')}>
                                                <span>{get(user, "name", "")}</span>
                                                <span>{rolesName}</span>
                                                <span>{studiosName}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={bem.e('buttons')}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            disabled={isLoading}
                                            outline
                                            className={bem.e('button-send')}
                                        >
                                            <FormattedMessage
                                                id="send mail dialog > submit"
                                            />
                                        </Button>
                                    </div>
                                    <input 
                                        type="hidden"
                                        name="process_id"
                                        value={values.process_id}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur} 
                                    />
                                </Form>
                                );
                            }}
                        </Formik>
                    </ModalBody>
                </Modal>
                <Spinner isLoading={isLoading} />
            </div>
        )
    }
}
SendMailDialog.defaultProps = {
    subject: "",
    message: "",
    to: "",
    cc: "",
}
export default SendMailDialog;