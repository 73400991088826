import React, { memo, useState, useRef, useEffect } from 'react';
import { Avatar } from '@mui/material';
import { getProfileLetter } from 'utils/helpers';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

const StyledAvatar = ({ url, name = '', style = {}, ...props }) => {
  const avatarRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (avatarRef.current) {
      observer.observe(avatarRef.current);
    }

    return () => {
      if (avatarRef.current) {
        observer.unobserve(avatarRef.current);
      }
    };
  }, []);

  const defaultStyle = isVisible
    ? {
        opacity: 1,
        transition: ' opacity 0.5s ease-in-out',
      }
    : {
        opacity: 0,
        transition: 'opacity 0.5s ease-in-out',
      };
  return url ? (
    <Avatar
      {...props}
      ref={avatarRef}
      className={'avatar-image'}
      alt={name}
      src={isVisible ? url : ''}
      style={{ ...defaultStyle, ...style }}
    />
  ) : (
    <Box className="avatar-letter">{getProfileLetter(name)}</Box>
  );
};

StyledAvatar.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
};
export default memo(StyledAvatar);
