import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'studios',
    'users',
    'confirm',
    'onboard',
    'members',
    'theme',
    'parent'
  ],
};

const emptyMiddleWare = () => (next) => (action) => {
  const result = next(action);
  return result;
};

let loggerMiddleWare = emptyMiddleWare;

if (process.env.NODE_ENV === 'development') {
  loggerMiddleWare = createLogger({
    collapsed: true,
  });
}

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  applyMiddleware(thunk, loggerMiddleWare)
);
const persistor = persistStore(store);

export default store;
export { persistor };
