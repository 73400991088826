import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import bn from 'utils/bemnames';
import { FormattedMessage } from 'react-intl';
import checkedIcon from '../../assets/img/icons/checked.png';
import get from 'lodash/get';
import Checkbox from '../Checkbox';
import { useSelector, useDispatch } from 'react-redux';
import PrimaryButton from '../StyledUI/Buttons';
const bem = bn.create('confirmation-modal');

const ConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const autoConfirm = useSelector(({ confirm }) =>
    get(confirm, 'autoConfirm', false)
  );

  useEffect(() => {
    if (props.showCheckBox && props.isOpen && autoConfirm === true) {
      props.onConfirm();
    }
  }, [props.isOpen]);

  const [isChecked, setChecked] = useState(autoConfirm);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.onToggle}
      centered
      contentClassName={props.height && 'user-content'}
      className={classnames(props.className, bem.b(), [
        bem.e(get(props, 'state', '')),
      ])}
    >
      <ModalHeader toggle={props.onToggle} className={bem.e('header')}>
        {props.state === 'success' && (
          <div className={bem.e('checked-icon')}>
            <img src={checkedIcon} alt="" />
          </div>
        )}
        {props.title}
      </ModalHeader>
      {!!props.description && (
        <ModalBody className={bem.e('body')}>
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
        </ModalBody>
      )}
      {!!props.children && (
        <ModalBody className={bem.e('body')}>{props.children}</ModalBody>
      )}
      {props.showCheckBox ? (
        <div className="checkbox-wrap">
          <Checkbox
            onChange={() => setChecked(!isChecked)}
            checked={isChecked}
            value="confirm"
            name="confirm"
          />
          <p
            onClick={() => setChecked(!isChecked)}
            style={{ cursor: 'pointer' }}
          >
            {props.content}
          </p>
        </div>
      ) : null}
      <ModalFooter className={bem.e('footer')}>
        {props.swipeButton ? (
          <>
            <Button
              className={classnames('btn-block', bem.e('cancel-button'))}
              onClick={props.onCancel}
            >
              {props.cancelTitle}
            </Button>{' '}
            {props.onConfirm && (
              <Button
                className={classnames(
                  'btn-outline-blue  btn-blue btn-block',
                  bem.e('confirm-button')
                )}
                onClick={() => {
                  props.onConfirm();

                  if (isChecked) dispatch({ type: 'SET_NO_ASK_CONFIRM' });
                }}
              >
                {props.confirmTitle}
              </Button>
            )}
          </>
        ) : (
          <>
            {props.cancelTitle !== '' && (
              <Button
                className={classnames(
                  'btn-outline-blue  btn-blue btn-block',
                  bem.e('confirm-button')
                )}
                onClick={props.onCancel}
              >
                {props.cancelTitle}
              </Button>
            )}

            {props.onConfirm && (
              <PrimaryButton
                loading={props.loader}
                variant="contained"
                onClick={() => {
                  props.onConfirm();

                  if (isChecked) dispatch({ type: 'SET_NO_ASK_CONFIRM' });
                }}
              >
                {props.confirmTitle}
              </PrimaryButton>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.prototype = {
  onToggle: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onChecked: PropTypes.func,
  confirmTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
  loader: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  onToggle: () => {},
  // onConfirm: () => {},
  onCancel: () => {},
  onChecked: () => {},
  confirmTitle: <FormattedMessage id="confirm modal > button yes" />,
  cancelTitle: <FormattedMessage id="confirm modal > button no" />,
  title: <FormattedMessage id="confirm modal > title are you ready" />,
  content: <FormattedMessage id="confirm checkbox > content" />,
  description: '',
  loader: false,
  isChecked: false,
  showCheckBox: false,
};

export default ConfirmationModal;
