import React, { Component } from "react";
import { find } from "lodash";

/* 	Below method will find all @ recursively replace with user name
  *	Convert string into React DOM until all @ replaced with name
  */

const FormattedTaggedMessage = ({ users, text }) => {
  let finalStr = text;
  try{
    let arr = text.match(/(@\[.*?\]\(.*?\))/g);
    if (arr) {
      let nameArr = [];
      for (let index = 0; index < arr.length; index++) {
        const item = arr[index]; // @[Sarju](1)
        const endSquareBracketPos = item.indexOf("]");
        const userId = item
          .substr(endSquareBracketPos + 1)
          .replace("(", "")
          .replace(")", "");
        const userIdInt = Number(userId);
        const user = find(users, { id: userIdInt }) || {};
        nameArr.push(user.name);
        // here we will replace all @[<name>](<id>) with @,
        // then convertStringToDOM will recursively find @ and convert it into DOM
        finalStr = finalStr.replace(item, "@");
      }
      if (nameArr.length > 0) {
        finalStr = convertStringToDOM(finalStr, nameArr, null);
      }
    }
  } catch(e){
    finalStr=''
  }
  return finalStr;
};

const convertStringToDOM = (str, arr, dom) => {
  if (arr.length <= 0) {
    return str;
  }
  const pos = str.indexOf("@");
  if (pos <= -1) {
    return str;
  }
  const item = arr[0];
  arr.splice(0, 1);
  return (
    <React.Fragment>
      <span>{str.substr(0, pos)}</span>
      <span className="user-tag">
        {item}
      </span>
      <span>{convertStringToDOM(str.substr(pos + 1), arr)}</span>
    </React.Fragment>
  );
};

export default FormattedTaggedMessage