import React from 'react';
import { differenceWith, find, forOwn, get, isArray, isEqual } from 'lodash';
import messages_en from '../locale/en.json';
import messages_es from '../locale/es.json';
import axios from 'axios';
import { DateFormat, DateTimeFormat, MobileAppInfo } from './config';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import ToastManager from 'components/ToastManager';
import {
  CLASSES_STATUS,
  PAGINATION_PER_PAGE,
  PAGINATION_START,
  ROLES_OBJECT,
  SYSTEM_CUSTOM_DATE_FORMAT,
} from './constants';
import isEmpty from 'lodash/isEmpty';
import { NameAvatar } from '../components/UsersList/styles';
import noop from 'lodash/noop';
import jwt_decode from 'jwt-decode';


const localeData = {
  en: messages_en,
  es: messages_es,
};

export const combineStudioPivileges = (studio = {}) => {
  return Object.values(studio.user_privileges || {}).concat(
    Object.values(studio.studio_privileges || {})
  );
};

export function convertTimeFormat(timeStr) {
  const pattern = /(\d{1,2}):?(\d{2})?\s?([APM]+)/gi;
  return timeStr.replace(pattern, (match, p1, p2, p3) => {
    let hour = parseInt(p1, 10);
    let formattedTime = `${hour}`;
    
    if (formattedTime !== '0' && formattedTime.startsWith('0')) {
      formattedTime = formattedTime.substring(1);
    }

    if (p2) {
      if (p2 === '00') {
        formattedTime += ` ${p3}`; 
      } else {
        formattedTime += `:${p2} ${p3}`; 
      }
    } else {
      formattedTime += ` ${p3}`; 
    }

    return formattedTime.trim(); 
  }).replace(/-/g, ' - ');
}

export const combineStudioPivilegesWithID = (studio = {}, auth) => {
  const privileges = [];
  const authStudio = find(
    get(auth, 'info.studios', []),
    (item) => item.user_studio_id === studio.studio_id
  );
  for (let key in authStudio.user_privileges || {}) {
    const priv = authStudio.user_privileges[key];
    if (priv.indexOf('_servers') > -1) continue;
    if (priv.indexOf('_studios') > -1) continue;
    privileges.push({
      id: key,
      name: priv,
    });
  }
  return privileges;
};

export const combineStudioUserPrivilegesID = (studio = {}) => {
  const privileges = [];
  for (let key in studio.user_privileges || {}) {
    const priv = studio.user_privileges[key];
    privileges.push({
      id: key,
      name: priv,
    });
  }
  return privileges;
};
export const combinePivilegesOfRolesAndCurrentPrivileges = (
  newRoles,
  currentPrivs,
  currentRoles
) => {
  const roleWasRemoved = differenceWith(currentRoles, newRoles, isEqual);
  let newPrivs = [];

  (newRoles || []).map((role) => {
    if (!isArray(role.privileges)) {
      forOwn(role.privileges, (priv, id) => {
        newPrivs.push({
          label: priv,
          value: id,
        });
      });
    }
    return role;
  });
  const seen = new Set();
  const filteredArr = newPrivs.filter((el) => {
    const duplicate = seen.has(el.value);
    seen.add(el.value);
    return !duplicate;
  });
  return filteredArr;

  // when removing a role, remove all it's privileges(unless included in another role that is still selected)
  /* eslint-disable */
  if (roleWasRemoved.length > 0) {
    let privsWillRemove = [];
    (roleWasRemoved || []).map((role) => {
      if (!isArray(role.privileges)) {
        forOwn(role.privileges, (priv, id) => {
          privsWillRemove.push({
            label: priv,
            value: id,
          });
        });
      }
      return role;
    });

    if (privsWillRemove.length > 0) {
      const filteredPriv = [];
      (newRoles || []).map((role) => {
        if (!isArray(role.privileges)) {
          forOwn(role.privileges, (priv, id) => {
            filteredPriv.push({
              label: priv,
              value: id,
            });
          });
        }
      });
      var obj = filteredPriv.reduce(
        (data, values) => Object.assign(data, { [values.label]: values.value }),
        {}
      );
      const privsStillSelected = Object.keys(obj).map((s) => ({
        label: s,
        value: obj[s],
      }));
      return privsStillSelected;
    }
  }
};

// Split locales with a region code
export const languageWithoutRegionCode = (locale) =>
  locale ? locale.toLowerCase().split(/[_-]+/)[0] : 'en';

// Try full locale, try locale without region code, fallback to 'en'
export const getTranslates = (locale) => {
  const messages =
    localeData[languageWithoutRegionCode(locale)] ||
    localeData[locale] ||
    localeData.en;
  return messages;
};

export const countdown = (endDate) => {
  let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;
  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
    millisec: 0,
    isNegative: false,
  };
  // clear countdown when date is reached
  if (diff <= 0) {
    diff = -1 * diff;
    timeLeft.isNegative = true;
  }
  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = diff;
  return timeLeft;
};

export const addLeadingZeros = (value) => {
  value = String(value);
  while (value.length < 2) {
    value = '0' + value;
  }
  return value;
};
export const calculateDurationMp3 = (file) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    const url = URL.createObjectURL(file);
    audio.addEventListener('canplaythrough', function (e) {
      const duration = e.currentTarget.duration;
      URL.revokeObjectURL(url);
      resolve(duration);
    });
    audio.addEventListener('error', function failed(e) {
      reject(e);
    });
    audio.src = url;
    audio.load();
  });
};

const mimeTypesMp3 = [
  'audio/mpeg',
  'application/zip',
  'audio/mp3',
  'application/octet-stream',
];
export const isMp3 = (mime) => {
  return mimeTypesMp3.includes(mime);
};

export const isImage = (mime = '') => {
  return mime.indexOf('image/') > -1;
};

export const toHHMMSS = (secs) => {
  secs = Math.round(secs);
  var sec_num = parseInt(secs, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return (hours !== '00' ? hours + ':' : '') + minutes + ':' + seconds;
};
export const formatDisplayDate = (date) => {
  if (!date) return null;
  return moment(date).format(DateFormat);
};
export const formatDisplayDateTime = (date) => {
  if (!date) return null;
  return moment(date).format(DateTimeFormat);
};
export const getSortUserName = (name) => {
  var matches = name.match(/\b(\w)/g);
  return matches.join('');
};
/*
 *   Below method will find all @ recursively replace with user name
 */

export const resolveTaggedUsers = (text, users) => {
  let finalStr = text;
  let arr = text.match(/(@\[.*?\]\(.*?\))/g);
  if (arr) {
    for (let index = 0; index < arr.length; index++) {
      const item = arr[index]; // @[Sarju](1)
      const endSquareBracketPos = item.indexOf(']');
      const userId = item
        .substr(endSquareBracketPos + 1)
        .replace('(', '')
        .replace(')', '');
      const userIdInt = Number(userId);
      const user = find(users, { id: userIdInt }) || {};
      finalStr = finalStr.replace(item, user.name);
    }
  }
  return finalStr;
};

export const reviewStatus = (status, inviteStatus, hidetooltip) => {
  if (inviteStatus === 0) {
    return (
      <>
        <Tooltip
          placement="left"
          trigger={hidetooltip ? [] : ['click', 'hover']}
          overlay={
            <span className="rc-slider-tooltip-message active-hover-tooltip">
              {
                'Users with the “Pending” status have been invited to join this studio, but have not accepted their invitation yet.'
              }
            </span>
          }
          // onVisibleChange={(status) => console.log()}
        >
          <div className="status-box">
            <span className="statusIcon">
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.875 3C17.4963 3 18 2.49633 18 1.875V1.125C18 0.503672 17.4963 0 16.875 0H1.125C0.503672 0 0 0.503672 0 1.125V1.875C0 2.49633 0.503672 3 1.125 3C1.125 7.26398 3.51638 10.8625 6.78947 12C3.51638 13.1375 1.125 16.736 1.125 21C0.503672 21 0 21.5037 0 22.125V22.875C0 23.4963 0.503672 24 1.125 24H16.875C17.4963 24 18 23.4963 18 22.875V22.125C18 21.5037 17.4963 21 16.875 21C16.875 16.736 14.4836 13.1375 11.2105 12C14.4836 10.8625 16.875 7.26398 16.875 3ZM9 9.75C6.29123 9.75 4.125 6.63197 4.125 3H13.875C13.875 6.65367 11.6948 9.75 9 9.75Z"
                  fill="#F5B722"
                />
              </svg>{' '}
            </span>
            <span>Pending</span>
          </div>
        </Tooltip>
      </>
    );
  } else if (inviteStatus === 3) {
    return (
      <>
        <Tooltip
          placement="left"
          trigger={['click', 'hover']}
          overlay={
            <span className="rc-slider-tooltip-message active-hover-tooltip">
              Users with the “Added” status have not been invited to join
              SuitePeach yet. You will be able to invite users later on in the
              Studio Setup process.
            </span>
          }
        >
          <div className="status-box">
            <span className="statusIcon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="11.5"
                  fill="#EA9277"
                  stroke="#EA9277"
                />
                <path
                  d="M9.74496 17.5125C9.85496 17.6775 10.02 17.7325 10.24 17.7325C10.4325 17.7325 10.5975 17.6775 10.735 17.5125L18.8475 9.42754C18.9575 9.31754 19.04 9.15254 19.04 8.93254C19.04 8.74004 18.9575 8.57504 18.8475 8.43754L17.83 7.44754C17.6925 7.31004 17.5275 7.22754 17.335 7.22754C17.1425 7.22754 16.9775 7.31004 16.84 7.44754L10.24 14.0475L7.15996 10.9675C6.99496 10.83 6.82996 10.7475 6.63746 10.7475C6.44496 10.7475 6.27996 10.83 6.16996 10.9675L5.15246 11.9575C5.01496 12.095 4.95996 12.26 4.95996 12.4525C4.95996 12.6725 5.01496 12.8375 5.15246 12.9475L9.74496 17.5125Z"
                  fill="white"
                />
              </svg>
            </span>
            Added
          </div>
        </Tooltip>
      </>
    );
  } else if (status === 1) {
    return (
      <>
        <Tooltip
          placement="left"
          trigger={hidetooltip ? [] : ['click', 'hover']}
          overlay={
            <span className="rc-slider-tooltip-message active-hover-tooltip">
              {
                'Users with the “Active” status have accepted their invitation to join this studio.'
              }
            </span>
          }
          onVisibleChange={noop}
        >
          <div className="status-box">
            <span className="statusIcon">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="11.5"
                  fill="#7BDBC4"
                  stroke="#7BDBC4"
                />
                <path
                  d="M9.74496 17.5125C9.85496 17.6775 10.02 17.7325 10.24 17.7325C10.4325 17.7325 10.5975 17.6775 10.735 17.5125L18.8475 9.42746C18.9575 9.31746 19.04 9.15246 19.04 8.93246C19.04 8.73996 18.9575 8.57496 18.8475 8.43746L17.83 7.44746C17.6925 7.30996 17.5275 7.22746 17.335 7.22746C17.1425 7.22746 16.9775 7.30996 16.84 7.44746L10.24 14.0475L7.15996 10.9675C6.99496 10.83 6.82996 10.7475 6.63746 10.7475C6.44496 10.7475 6.27996 10.83 6.16996 10.9675L5.15246 11.9575C5.01496 12.095 4.95996 12.26 4.95996 12.4525C4.95996 12.6725 5.01496 12.8375 5.15246 12.9475L9.74496 17.5125Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Active</span>
          </div>
        </Tooltip>
      </>
    );
  } else {
    return (
      <div className="status-box">
        <span className="statusIcon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 0C5.37097 0 0 5.37097 0 12C0 18.629 5.37097 24 12 24C18.629 24 24 18.629 24 12C24 5.37097 18.629 0 12 0ZM17.8839 15.15C18.1113 15.3774 18.1113 15.7452 17.8839 15.9726L15.9677 17.8839C15.7403 18.1113 15.3726 18.1113 15.1452 17.8839L12 14.7097L8.85 17.8839C8.62258 18.1113 8.25484 18.1113 8.02742 17.8839L6.11613 15.9677C5.88871 15.7403 5.88871 15.3726 6.11613 15.1452L9.29032 12L6.11613 8.85C5.88871 8.62258 5.88871 8.25484 6.11613 8.02742L8.03226 6.11129C8.25968 5.88387 8.62742 5.88387 8.85484 6.11129L12 9.29032L15.15 6.11613C15.3774 5.88871 15.7452 5.88871 15.9726 6.11613L17.8887 8.03226C18.1161 8.25968 18.1161 8.62742 17.8887 8.85484L14.7097 12L17.8839 15.15Z"
              fill="#F55B5B"
            />
          </svg>{' '}
        </span>
        <span>Inactive</span>
      </div>
    );
  }
};

export const reviewStatusText = (status, inviteStatus) => {
  if (inviteStatus === 0) {
    return 'Pending';
  } else if (inviteStatus === 3) {
    return 'Added';
  } else if (status === 1) {
    return 'Active';
  } else {
    return 'Inactive';
  }
};

export const getProfileLetter = (name) => {
  if (!name) return '?';
  const separateName = name.split(' ');
  let letters = [];
  separateName.map((x, index) => {
    if (index < 2) letters.push(x.charAt(0));
    return true;
  });
  return letters.join('').toUpperCase();
};

export const getWeeklyTime = (data = {}) => {
  const result = [];
  Object.keys(data).map((x) => {
    const event = data[x];
    if (!event) return false;

    const time = `${moment(get(event, 'from')).format('h:mmA')} - ${moment(
      get(event, 'to')
    ).format('h:mmA')}`;
    let day = get(event, 'shortValue');

    const sameTimeDayIndex = result.findIndex((y) => y.time === time);

    if (sameTimeDayIndex > -1) {
      let tempDay = result[sameTimeDayIndex].day;
      tempDay += `, ${day}`;
      result.splice(sameTimeDayIndex, 1, {
        day: tempDay,
        time,
      });
    } else {
      result.push({
        day,
        time,
      });
    }
  });
  return result;
};

export const getCustomTime = (data = {}) => {
  const result = [];
  Object.keys(data).map((x) => {
    const event = data[x];
    if (!event) return false;

    const time = `${moment(get(event, 'from')).format('h:mmA')} - ${moment(
      get(event, 'to')
    ).format('h:mmA')}`;
    let day = moment(get(event, 'from')).format(SYSTEM_CUSTOM_DATE_FORMAT);

    const sameTimeDayIndex = result.findIndex((y) => y.time === time);

    if (sameTimeDayIndex > -1) {
      let tempDay = result[sameTimeDayIndex].day;
      tempDay += `, ${day}`;
      result.splice(sameTimeDayIndex, 1, {
        day: tempDay,
        time,
      });
    } else {
      result.push({
        day,
        time,
      });
    }
  });
  return result;
};

/**
 * @param data {any}
 * @description return status
 * @return {string} draft || active || inactive
 * */
export const getClassStatus = (data) => {
  const status =
    get(data, 'course_details_status', CLASSES_STATUS.DRAFT) ||
    CLASSES_STATUS.DRAFT;
  const endDate = get(data, 'end_date', null) || null;
  if (status === CLASSES_STATUS.DRAFT || !endDate) return null;
  const expiryDate = moment(endDate).format('X');
  const currentDate = moment().format('X');
  if (expiryDate < currentDate || status === CLASSES_STATUS.DELETED)
    return CLASSES_STATUS.INACTIVE;
  const price = get(data, 'tuition.tuitionRates.0.rate');
  if (!price) return CLASSES_STATUS.INACTIVE;
  return CLASSES_STATUS.ACTIVE;
};

export const createMessage = (payload) => {
  const { sendTo, user, from, text } = payload;
  const team = sendTo.type === 'user' ? [sendTo.id] : [];
  const courses = sendTo.type === 'course' ? [sendTo.id] : [];
  const studios = sendTo.type === 'studio' ? [sendTo.id] : [];

  return {
    m_type: 'chat',
    from: from
      ? from
      : {
          id: user.id,
          name: user.name,
          avatar_url: user.avatar_url,
        },
    tagged: text ? getTaggedUserIds(text) : [],
    studios,
    courses,
    team,
    chat: text,
    sendTo,
    medias: payload.medias ? [...payload.medias] : [],
  };
};

export const getTaggedUserIds = (text) => {
  let arr = text.match(/(@\[.*?\]\(.*?\))/g);
  let idArr = [];
  if (arr) {
    for (let index = 0; index < arr.length; index++) {
      const item = arr[index]; // @[Sarju](1)
      const endSquareBracketPos = item.indexOf(']');
      const userId = item
        .substr(endSquareBracketPos + 1)
        .replace('(', '')
        .replace(')', '');
      const userIdInt = Number(userId);
      idArr.push(userIdInt);
    }
  }
  return idArr;
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const getPlayStoreUrl = () =>
  `market://details?id=${MobileAppInfo.androidPackageName}`;
export const getAppStoreUrl = () =>
  `itms-apps://itunes.apple.com/app/${MobileAppInfo.iOSAppId}`;

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const getDisplayDate = (
  date,
  format,
  existinFormat = 'YYYY-MM-DD HH:mm:ss'
) => {
  return date ? moment(date, existinFormat).format(format) : '';
};

export const minutesOfDay = (date) => {
  return moment(date).minutes() + moment(date).hours() * 60;
};

export const showIframeToast = (message) => {
  ToastManager.show({
    message: message || '',
    level: 'success',
  });
};

/**
 * @param page {string}
 * @param data {array}
 * @returns array for classes
 */

export const getDataSourceWithPagintion = (
  data = [],
  currentPage = PAGINATION_START,
  perPage = PAGINATION_PER_PAGE
) => {
  return data.slice((currentPage - 1) * perPage, currentPage * perPage);
};

export const getDataSourceWithPagintionPayment = (
  data = [],
  currentPage = PAGINATION_START,
  perPage = PAGINATION_PER_PAGE,
  filter = ''
) => {
  const list = data.filter((i) => {
    if (filter === '') {
      return true;
    }
    if (filter === '-1') {
      return true;
    }
    return i.status === filter;
  });

  return {
    list:
      list.length < perPage
        ? list
        : list.slice((currentPage - 1) * perPage, currentPage * perPage),
    filteredLength: list.length,
  };
};

export const capitalize = (s) => (s = s.charAt(0).toUpperCase() + s.slice(1));

export const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

/**
 * @param no {string}
 * @return {string}
 * */
export const getCleanPhoneNo = (no) =>
  typeof no === 'string' ? no.replace(/^\+[0-9]/, '').replace(/\D/g, '') : no;

/**
 * @param s {string}
 * @description retrieve the text from the html string
 * @return {string}
 * */
export const extractTextContent = (s) => {
  const span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

/**
 * @param date {Date}
 * @description convert date string to epoch time
 * @return {number}
 * */
export const epoch = (date = new Date()) => {
  return Date.parse(date);
};

/**
 * @param dateFrom {Date}
 * @param dateTo {Date}
 * @param dateCheck {Date}
 * @description return boolean if dateCheck is between dateFrom and dateTo
 * @return {boolean}
 * */

// date.parse support MM/DD/YYYY format
export const isDateBetweenTwoDates = (dateFrom, dateTo, dateCheck) => {
  const defaultFormat = 'MM/DD/YYYY';
  const fDate = Date.parse(moment(dateFrom).format(defaultFormat));
  const lDate = Date.parse(moment(dateTo).format(defaultFormat));
  const cDate = Date.parse(moment(dateCheck).format(defaultFormat));

  return cDate >= fDate && cDate <= lDate;
};

export const today = new Date();

export const isStudentOrParent = (roleId) =>
  [
    ROLES_OBJECT.Student.id,
    ROLES_OBJECT['Parent/Guardian'].id,
    ROLES_OBJECT['Parent/Guardian (Secondary)'].id,
  ].includes(roleId);

export const isInstructor = (roleId) =>
  [ROLES_OBJECT.Instructor.id].includes(roleId);

export const isStaff = (roleId) => [ROLES_OBJECT.Staff.id].includes(roleId);
export const isMobile = window.matchMedia(
  'only screen and (max-width: 480px)'
).matches;

export const isParent = (roleId) =>
  [
    ROLES_OBJECT['Parent/Guardian'].id,
    ROLES_OBJECT['Parent/Guardian (Secondary)'].id,
  ].includes(roleId);

export const isAdminOrOwner = (roleId) =>
  [ROLES_OBJECT['Studio Admin'].id, ROLES_OBJECT['Studio Owner'].id].includes(
    roleId
  );

export const isAttendanceAllowed = (roleId) =>
  [
    ROLES_OBJECT['Studio Admin'].id,
    ROLES_OBJECT['Studio Owner'].id,
    ROLES_OBJECT['Instructor'].id,
  ].includes(roleId);

export const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const pxToRem = (value) => {
  return `${value / 16}rem`;
};

export const setSession = (result) => {
  const expiresAt = result.expiresIn
    ? JSON.stringify(result.expiresIn * 1000 + new Date().getTime())
    : JSON.stringify(result.expires_in * 1000 + new Date().getTime());
  setSessionStorage(result, expiresAt);
};
export const setSessionStorage = (result, expiresAt) => {
  sessionStorage.setItem(
    'refresh_token',
    result.refreshToken ? result.refreshToken : result.refresh_token
  );
  sessionStorage.setItem('expires_at', expiresAt);
};

export const isAuthenticated = () => {
  const date = sessionStorage.getItem('expires_at');
  const refreshToken = sessionStorage.getItem('refresh_token');
  // uncomment this when refresh token is available
  // if (date && refreshToken) {
  //   const expiresAt = JSON.parse(date);
  //   if (!refreshToken || new Date().getTime() > expiresAt) {
  //     removeSessionStorage();
  //     return false;
  //   }
  //   return true;
  // }
  if (date) {
    const expiresAt = JSON.parse(date);
    if (new Date().getTime() > expiresAt) {
      removeSessionStorage();
      return false;
    }
    return true;
  }
  return false;
};

export const removeSessionStorage = () => {
  sessionStorage.removeItem('refresh_token');
  sessionStorage.removeItem('expires_at');
};

export const getUserWithRefreshToken = async () => {
  const response = await axios.post(
    `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
    {
      grant_type: 'refresh_token',
      client_id: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
      refresh_token: sessionStorage.getItem('refresh_token'),
      client_secret: `${process.env.REACT_APP_AUTH0_CLIENT_SECRET}`,
    },
    { headers: { 'Content-Type': 'application/json' } }
  );
  return response.data;
};

export const isValidDate = (date) =>
  date &&
  Object.prototype.toString.call(date) === '[object Date]' &&
  !isNaN(date);

/**
 * @param value {String}
 * @description return clean number Remove any localized formatting like commas
 * @return {Number}
 * */
export const toNumber = (value = '') => {
  // Remove any localized formatting like commas

  if (isEmpty(value)) {
    return value;
  }
  const cleanedString = value.replace(/,/g, '');

  // Parse the cleaned string to a number
  return parseFloat(cleanedString);
};

// Regular expression for a basic email validation
export const isEmail = (st) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(st);

export const nameAvatar = (name) => {
  if (!name) return null;
  let nameArr = name.split(' ');
  let firstName = get(nameArr, '[0]') || '';
  let secondName = get(nameArr, '[1]') || '';
  let firstLetter = get(firstName, '[0]') || '';
  let secondLetter = get(secondName, '[0]') || '';
  return <NameAvatar>{`${firstLetter}${secondLetter}`}</NameAvatar>;
};

export const combineDateWithTime = ({
  dateIndex = '2023-07-11',
  desiredTime = '07:00 PM',
}) => {
  const givenDate = new Date(dateIndex); // Example: 2023-07-11
  // Split the time components
  const [hours, minutes] = desiredTime.split(':');
  let hour = parseInt(hours, 10);
  const isPM = desiredTime.toUpperCase().includes('PM');

  // Adjust the hour component if needed
  if (isPM && hour !== 12) {
    hour += 12;
  } else if (!isPM && hour === 12) {
    hour = 0;
  }
  givenDate.setHours(hour);
  givenDate.setMinutes(parseInt(minutes, 10));
  givenDate.setSeconds(0);
  givenDate.setMilliseconds(0);
  return givenDate;
};

export const uploadPlugin = (editor) => {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
};

/**
 * @param cls {Object}
 * @description return active class on basis of end date , active status and course details status
 * @return {Boolean}
 * */
export const isActiveClass = (cls = {}) =>
  getClassStatus(cls) === CLASSES_STATUS.ACTIVE;

/**
 * @param token {String}
 * @description return is token active or not
 * @return {Boolean}
 * */
export const isTokenExpired = (token) => {
  try {
    const decodedToken = jwt_decode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true; // Token is considered expired if decoding fails
  }
};
