import Api from "../../utils/api";
import { PUSHER } from "../../utils/constants";
import ToastManager from "../../components/ToastManager";
import get from "lodash/get";
import {
  SET_MESSAGES,
  SET_HEADER_NOTIFICATIONS,
  HEADER_NOTIFICATIONS_SEEN,
  REMOVE_HEADER_NOTIFICATIONS
} from "../types/messages";
import { resolveTaggedUsers } from '../../utils/helpers';
import { UPDATE_PROCESSES_INCOMING, UPDATE_PROCESS_INCOMING } from "../types/processes";
import { userInfo } from "./auth";

export const sendMessage = async (payload) => {
  try {

    // const { status } = await Api.doCall(PUSHER(), "PUT", payload);
    // const { status } = await Api.doCall(PUSHER() + '?payload=' + JSON.stringify(payload), "GET");
    await Api.doCall(PUSHER() + '?payload=' + JSON.stringify(payload), "GET");
    return true;
    // if (status === 200) {
    //   return true;
    // } else {
    //   return false;
    // }
  } catch (error) {
    return false;
  }
};

export const markMessageAsRead = (messageId) => async dispatch => {
  try {
    dispatch({
      type: REMOVE_HEADER_NOTIFICATIONS,
      payload: { m_id: messageId }
    });
    const { status } = await Api.doCall(PUSHER() + '/' + messageId, "DELETE");
    if (status === 200) {

      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const headerNotificationsSeen = (payload) => async dispatch => {
  dispatch({
    type: HEADER_NOTIFICATIONS_SEEN,
    payload
  });
}

let showNotification = (auth, title, message, avatar) => {
  if (auth.notify) {
    const options = {
      body: message,
      icon: avatar,
      vibrate: [50, 100, 150]
    }

    const notification = new Notification(title, options);
    setTimeout(() => {
      notification.close();
    }, 6000);
    // click on notification
    const anchor = "notification";
    const onClickNotification = () => async (anchor) => {
    }

    notification.onclick = () => onClickNotification(anchor);
  }
  else {
    ToastManager.show({
      title,
      message,
      level: "info"
    });
  }


}

var last_message_id = 0
export const incomingMessage = (payload, auth, messages, processes) => async dispatch => {
  payload.dismissable = (payload.message && payload.message.needs_confirmation) && payload.message.needs_confirmation.indexOf(auth.user.id) > -1

  if (last_message_id === payload.m_id || messages.find(m => m.m_id === payload.m_id)) return
  last_message_id = payload.m_id
  let add_notification = false;
  // refresh process.  
  let chat_from_me = false

  const name = get(auth, 'user.name');

  if (name && payload.message.m_type === 'chat') {
    if (
      payload.message.chat.indexOf(`@[${auth.user.name}](${auth.user.id})`) > -1
    ) {
      add_notification = true;
      const title = payload.message.from.name + ' mentioned you';
      const message =
        'in order ' +
        payload.message.order_title +
        ': \r\n' +
        resolveTaggedUsers(payload.message.chat, auth.info.users);
      showNotification(auth, title, message, payload.message.from.avatar_url);
    }
    if (payload.message.from.id === auth.user.id) {
      chat_from_me = true;
    }
  }

  if(!chat_from_me && get(payload, "message.delta") && get(process, "key") === get(payload, "message.process_key")){
    let fields = {}
    Object.keys(get(payload, "message.delta")).map(key => fields[key] = { value: get(payload, `message.delta.${key}`) })
    dispatch({
      type: UPDATE_PROCESS_INCOMING,
      payload: {
        data: fields
      }
    });
  }

  if (payload.message.m_type === 'proc') {
    // reload user info
    dispatch(userInfo());

    if (payload.message.next_users && payload.message.next_users.indexOf(auth.user.id) > -1) {
      // me now

      // disabled for testing
      if (payload.message.from.id !== auth.user.id) {
        add_notification = true
        const title = 'Order updated'
        const message = 'Order ' + payload.message.order_title + (payload.message.to_step ? (', now in status \'' + payload.message.to_step.replace(/_/, ' ', 'gi')) + '\'' : '')
        showNotification(auth, title, message, payload.message.from.avatar_url)
      }
    }
    if (payload.message.still_required_users && payload.message.still_required_users.indexOf(auth.user.id) > -1) {
      // I'm soon
      add_notification = true
    }
  }
  if(payload.m_id.indexOf(' ')>-1){
    payload.m_id=payload.m_id.split(' ')[0]
  }
  if (add_notification) {
    dispatch({
      type: SET_HEADER_NOTIFICATIONS,
      payload: { notifications: [payload] }
    });
  }
  dispatch({
    type: SET_MESSAGES,
    payload: { messages: [payload] }
  });

  // update processes
  if (payload.message.m_type === 'proc') {
    dispatch({
      type: UPDATE_PROCESSES_INCOMING,
      payload
    });
  }
}


