import React from 'react';
import classes from './MainContentReview.module.scss';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import CustomToolbar from './CustomToolar';
import CustomHeader from './CustomHeader';
import CustomEvent from './CustomEvent';
import get from 'lodash/get';
const localizer = momentLocalizer(moment);

const MainContentReview = (props) => {
  const events = get(props, 'events', []);

  return (
    <div className={classes.root}>
      <Calendar
        localizer={localizer}
        // events={[
        //   {
        //     title: 'Dance 1',
        //     allDay: false,
        //     start: new Date('2020-09-08 05:00:00'),
        //     end: new Date('2020-09-08 07:00:00'),
        //     room: '101',
        //   }
        // ]}
        events={events}
        defaultView="week"
        components={{
          toolbar: (e) => (
            <CustomToolbar {...e} title="Review Scheduled Class" />
          ),
          week: {
            header: CustomHeader,
          },
          event: CustomEvent,
        }}
      />
    </div>
  );
};

export default MainContentReview;
