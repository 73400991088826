// const availableColors = [
//   'blue',
//   'indigo',
//   'purple',
//   'pink',
//   'red',
//   'orange',
//   'yellow',
//   'green',
//   'teal',
//   'cyan',
//   'white',
//   'gray',
//   'gray-dark',
//   'primary',
//   'secondary',
//   'success',
//   'info',
//   'warning',
//   'danger',
//   'light',
//   'dark',
// ];

export const getColor = (availableColor = 'primary') => {
  if (typeof window === 'undefined') {
    return null;
  }

  const color = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(`--${availableColor}`);

  return color;
};

export const getThemeColors = () => [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
];


const primaryColor = '#51BCDA';
const secondaryColor = '#EF8157';
const tertiaryColor = '#00A398';

export const COLORS = {
  white: '#FFFFFF',
  navy: '#1B243D',
  skyBlue: primaryColor,
  violet: secondaryColor,
  orange: secondaryColor,
  green: tertiaryColor,
  heavyMetel: '#2B2C29',
  charcoal: '#535556',
  lime: '#71E954',
  deepGray: '#252422',
  medGray: '#515659',
  chipGray: '#e7e9ec',
  black: '#000000',
  textBlack: '#2C2C2C',
  lightGrayPrimary: '#DDDDDD',
  Gray81: '#CFCFCF',
  lightGray: '#82878C',
  lightActiveColor: '#3F4143',
  lightGray2: '#CFD3DA',
  lightGray3: '#F4F6F8',
  lightGray4: '#C4C4C4',
  lightGray5: '#FAFBFB',
  lightGray6: '#636C72',
  darkGray1: '#343741',
  hawkesBlue: '#DDE1E8',
  lightGrayishBlue: '#98A2B3',
  paleBlue: '#D3DAE6',
  paleBlue1: '#DBDFE4',
  darkGrayishBlue: '#2B2C2E',
  gray2: '#66615B',
  gray: '#ccc',
  gray63: '#a1a1a1',
  blackRussian: '#1A1C21',
  successGreen: '#14C512',
  lightBlue: '#60D9FF',
  lighterBlue: '#EAFAFF',
  hotViolet: '#FF5AEF',
  brightViolet: '#BD00FF',
  violet15: '#F0E0F6',
  darkBlue: '#0C74D3',
  hoverBlue: '#0b79ad',
  lightGreen: '#DCFFC1',
  backgroundPeach: '#FFECE0',
  heartFillRed: '#F7643B',
  checkBoxFilled: '#F7C0AB',
  transparent: 'transparent',
  tourTitle: '#403D39',
  tourInfo: '#828282',
  facebookBlue: '#1877F2',
  skyBlueDisabled: 'rgba(17, 173, 248, 0.35)',

  background: '#FFFFFF',
  lightGray2RBA: 'rgba(207, 211, 218, 0.1)',
  selectedCellBackground: 'rgba(17, 173, 248, 0.05)',
  lightGrayWithOpacity: 'rgba(130,135,140, 0.5)',
  hoverButtonGrey: 'rgba(0, 0, 0, 0.04)',

  logoGradient: 'linear-gradient(180deg, #71E954 12.26%, #11ADF8 45.36%, #9730C2 87.03%)',
  navyBlackGradient: 'linear-gradient(180deg, #000000 0%, #1B243D 98.5%)',
  navyGradient: 'linear-gradient(180deg, #1B243D 0%, #344E76 98.5%);',
  loginGradient: 'linear-gradient(140.97deg, rgba(234, 115, 79, 0.75) 14.74%, rgba(255, 168, 116, 0.82) 84.14%);',
  selectBoxShadow: '0px 1px 3px rgba(30, 41, 67, 0.2);',
  focusBoxShadow: `0 0 3px 1px ${primaryColor}`,
  errorBorderShadow: `0 0 3px 1px ${secondaryColor}`,
  filterBoxShadow: '-2px 0px 13px rgba(0, 0, 0, 0.15);',
  Nobel: '#9A9A9A',
  NobelHex: '#A2A2A2',
  LightGrey: '#D6D6D6',
};