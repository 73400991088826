import * as Types from '../types/studios';
import get from 'lodash/get';

const initialState = {
  studios: {
    // This object contains all studios list
    // this is mainly required in studios listing page
    // where we show all studios list and loading icon if studios are loading
    data: [],
    error: null,
    loading: null,
    isLast: false,
  },
  step: 1,
  studio: {
    // This object contains particular studio object with id mapping
    // this is mainly required in studio details page
    // where we show particular studio detail and loading icon if studio details is loading
    // below is example object
    // "<id>": {
    //   data: null,
    //   error: null,
    //   loading: null
    // }
  },
  addStudio: {
    // This object will be useful to show loading icon and status when user want to create new studio
    is_added: false,
    data: null,
    error: null,
    loading: false,
  },
  updateStudio: {
    // This object will be useful to show loading icon and status when user want to update studio
    data: null,
    error: null,
    loading: false,
  },
  deleteStudio: {
    // This object will be useful to show loading icon and status when user want to delete studio
    is_deleted: false,
    data: null,
    error: null,
    loading: false,
  },
  seasons: {
    is_creating: false,
    data: [],
    error: null,
    loading: false,
  },
  waivers: {
    is_creating: false,
    data: [],
    error: null,
    loading: false,
  },
  studioCatalog: {
    info: {},
    classes: [],
    loading: false,
    error: null,
    passes: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    // studios Actions
    case Types.FETCH_USER_STUDIO_REQUEST: {
      return {
        ...state,
        userStudios: { data: [], error: null, loading: true },
      };
    }
    case Types.FETCH_USER_STUDIO_SUCCESS: {
      return {
        ...state,
        userStudios: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_USER_STUDIOS_ERROR: {
      return {
        ...state,
        userStudios: {
          data: null,
          error: action.payload,
          loading: false,
        },
      };
    }

    case Types.FETCH_STUDIOS_REQUEST: {
      let obj = { error: null, loading: true };
      if (!action.isScroll) {
        obj.data = [];
      }
      return {
        ...state,
        studios: { ...state.studios, ...obj },
      };
    }
    case Types.FETCH_STUDIOS_SUCCESS: {
      return {
        ...state,
        studios: {
          data: action.payload.data,
          error: null,
          loading: false,
          isLast: !!(
            action.payload.data &&
            action.payload.data.data &&
            action.payload.data.data.length === 0
          ),
        },
      };
    }
    case Types.FETCH_STUDIOS_ONSCROLL: {
      let studiosList = state.studios;
      studiosList.data.data = [
        ...studiosList.data.data,
        ...action.payload.data.data,
      ];
      studiosList.error = null;
      studiosList.loading = false;
      // studiosList.data.data = [...new Set([...studiosList.data.data])]
      let isLast = false;
      if (action.payload.data.data.length === 0) {
        isLast = true;
      }
      studiosList.isLast = isLast;
      return {
        ...state,
        studios: { ...studiosList },
      };
    }
    case Types.FETCH_STUDIOS_ERROR: {
      return {
        ...state,
        studios: {
          data: null,
          error: action.payload,
          loading: false,
          isLast: true,
        },
      };
    }

    // Particular Studio Actions
    case Types.FETCH_STUDIO_REQUEST: {
      const existingStudios = get(state, 'studio');
      const { id } = action.payload;
      return {
        ...state,
        studio: {
          ...existingStudios,
          [id]: {
            ...(existingStudios[id] || {}),
            loading: true,
            error: null,
          },
        },
      };
    }
    case Types.FETCH_STUDIO_SUCCESS: {
      const existingStudios = get(state, 'studio');
      const { id, data } = action.payload;
      return {
        ...state,
        studio: {
          ...existingStudios,
          [id]: {
            loading: false,
            error: null,
            data,
          },
        },
        seasons: {
          is_creating: false,
          data: { seasons: data.data.seasons },
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_STUDIO_ERROR: {
      const existingStudios = get(state, 'studio');
      const { id, error } = action.payload;
      return {
        ...state,
        studio: {
          ...existingStudios,
          [id]: {
            loading: false,
            error,
          },
        },
      };
    }

    // Add Studio Actions
    case Types.ADD_STUDIO_REQUEST: {
      return {
        ...state,
        addStudio: {
          is_added: false,
          loading: true,
          error: null,
        },
      };
    }
    case Types.ADD_STUDIO_SUCCESS: {
      let newStudios = get(state, 'studios.data.data')
        ? get(state, 'studios.data.data').concat([action.payload])
        : [action.payload];
      return {
        ...state,
        studios: {
          ...get(state, 'studios'),
          data: {
            ...get(state, 'studios.data'),
            data: newStudios,
          },
        },
        addStudio: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.ADD_STUDIO_ERROR: {
      return {
        ...state,
        addStudio: {
          is_added: false,
          loading: false,
          error: action.payload,
        },
      };
    }

    // Update Studio Actions
    case Types.UPDATE_STUDIO_REQUEST: {
      return {
        ...state,
        updateStudio: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_STUDIO_SUCCESS: {
      let studiosDetail = state.studios;
      let studioDetail = state.studio;
      let studioId = get(action, 'payload.data.id', null) || null;
      let studiosList = get(studiosDetail, 'data.data') || null;
      let updatedStudiosList = studiosList;
      let updatePayload = get(action, 'payload', null) || null;
      if (studioId && updatePayload) {
        studioDetail[studioId].data.data = updatePayload.data;
      }
      if (studiosList) {
        updatedStudiosList = studiosList.filter((studio) => {
          if (studio.id === action.payload.id) {
            studio.name = action.payload.name;
            studio.website_url = action.payload.website_url;
            studio.overview = action.payload.overview;
          }
          return studio;
        });
      }
      if (state.step === 1 || state.step === 2) {
        return {
          ...state,
          studios: {
            ...get(state, 'studios'),
            data: {
              ...get(state, 'studios.data'),
              data: updatedStudiosList,
            },
          },
          studio: studioDetail,
          updateStudio: {
            data: action.payload,
            loading: false,
            error: null,
          },
          step: 3,
        };
      }
      return {
        ...state,
        updateStudio: {
          data: action.payload,
          loading: false,
          error: null,
        },
      };
    }
    case Types.UPDATE_STUDIO_ERROR: {
      return {
        ...state,
        updateStudio: {
          loading: false,
          // error: action.payload,
        },
      };
    }

    // DELETE Studio Actions
    case Types.DELETE_STUDIO_REQUEST: {
      return {
        ...state,
        deleteStudio: {
          is_deleted: false,
          loading: true,
          error: null,
        },
      };
    }

    case Types.DELETE_STUDIO_SUCCESS: {
      return {
        ...state,
        studios: {
          ...state.studios,
          data: {
            ...state.studios.data,
            data: state.studios.data.data.filter(
              (item) => item.id !== action.payload
            ),
          },
        },
        deleteStudio: {
          is_deleted: true,
          loading: false,
          error: null,
        },
      };
    }
    case Types.DELETE_STUDIO_ERROR: {
      return {
        ...state,
        deleteStudio: {
          is_deleted: false,
          loading: false,
          error: action.payload,
        },
      };
    }
    case Types.INVITE_STUDIO_SUCCESS: {
      let studiosDetail = state.studios;
      studiosDetail.data.data = [
        ...studiosDetail.data.data,
        action.studio.data,
      ];
      return {
        ...state,
        studios: studiosDetail,
      };
    }
    case Types.INVITE_STUDIO_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case Types.CHANGE_STUDIO_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }
    case Types.DELETE_STUDIO_LOCATION_SUCCESS: {
      let studiosDetail = state.studios;
      let getUserStudio = state.studio;
      let deleteStudioId = get(action, 'payload.data.data.id', null) || null;
      let getUpdatedLocation =
        get(action, 'payload.data.data.locations', []) || [];
      if (deleteStudioId) {
        getUserStudio[deleteStudioId].data.data.locations = getUpdatedLocation;
      }
      let studiosList = get(studiosDetail, 'data.data') || null;
      let updatedStudiosList = studiosList;
      if (studiosList) {
        updatedStudiosList = studiosList.filter((studio) => {
          if (studio.id === deleteStudioId) {
            studio.locations = getUpdatedLocation;
          }
          return studio;
        });
      }
      return {
        ...state,
        studios: {
          ...get(state, 'studios'),
          data: {
            ...get(state, 'studios.data'),
            data: updatedStudiosList,
          },
        },
        studio: getUserStudio,
      };
    }
    case Types.ADD_STUDIO_LOCATION_SUCCESS: {
      let studiosDetail = state.studios;
      let studioDetail = state.studio;
      let studiosList = get(studiosDetail, 'data.data') || null;
      let studioLocations =
        get(action, 'payload.data.data.locations', []) || [];
      let studioId = get(action, 'payload.data.data.id', null) || null;
      let updatedStudiosList = studiosList;
      // let studioLocData = get(studioDetail,[studioId]+'data.data.locations','');
      if (studioId) {
        studioDetail[studioId].data.data.locations = studioLocations;
      }
      if (studiosList) {
        updatedStudiosList = studiosList.filter((studio) => {
          if (studio.id === studioId) {
            studio.locations = studioLocations;
          }
          return studio;
        });
      }
      return {
        ...state,
        studios: {
          ...get(state, 'studios'),
          data: {
            ...get(state, 'studios.data'),
            data: updatedStudiosList,
          },
        },
        studio: studioDetail,
      };
    }

    // Create studio season

    case Types.CREATE_STUDIO_SEASON_REQUEST: {
      return {
        ...state,
        seasons: {
          is_creating: true,
          // data: [],
          error: null,
          loading: false,
        },
      };
    }
    case Types.CREATE_STUDIO_SEASON_SUCCESS: {
      return {
        ...state,
        seasons: {
          is_creating: false,
          data: action.payload.data.data,
          error: null,
          loading: false,
        },
      };
    }
    case Types.CREATE_STUDIO_SEASON_ERROR: {
      return {
        ...state,
        seasons: {
          is_creating: false,
          error: null,
          loading: false,
        },
      };
    }

    case Types.GET_STUDIO_SEASON_REQUEST: {
      return {
        ...state,
        seasons: {
          is_creating: true,
          // data: [],
          error: null,
          loading: false,
        },
      };
    }
    case Types.GET_STUDIO_SEASON_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_STUDIO_SEASON_ERROR: {
      return {
        ...state,
        seasons: {
          is_creating: false,
          error: null,
          loading: false,
        },
      };
    }

    // studios Actions
    case Types.FETCH_STUDIO_CATALOG_REQUEST: {
      return {
        ...state,
        studioCatalog: {
          info: {},
          classes: [],
          error: null,
          loading: true,
        },
      };
    }
    case Types.FETCH_STUDIO_CATALOG_SUCCESS: {
      const { classes, studio } = action.payload;
      return {
        ...state,
        studioCatalog: {
          info: studio,
          classes,
          error: null,
          loading: false,
        },
      };
    }
    case Types.FETCH_STUDIO_CATALOG_ERROR: {
      return {
        ...state,
        studioCatalog: {
          info: {},
          classes: [],
          error: action.payload,
          loading: false,
        },
      };
    }
    // Create studio waiver

    case Types.CREATE_STUDIO_WAIVER_REQUEST: {
      return {
        ...state,
        waivers: {
          is_creating: true,
          // data: [],
          error: null,
          loading: false,
        },
      };
    }
    case Types.CREATE_STUDIO_WAIVER_SUCCESS: {
      return {
        ...state,
        waivers: {
          is_creating: false,
          data: action.payload.data.data.waivers,
          error: null,
          loading: false,
        },
      };
    }
    case Types.CREATE_STUDIO_WAIVER_ERROR: {
      return {
        ...state,
        waivers: {
          is_creating: false,
          error: null,
          loading: false,
        },
      };
    }
    case Types.STUDIO_CLASS_PASSES:
      return {
        ...state,
        studioCatalog: {
          ...state.studioCatalog,
          passes: action.payload,
        },
      };

    default:
      return state;
  }
}
