import {
  AUTH_USER,
  AUTH_ERROR,
  LOGOUT_USER,
  USER_INFO_SUCCESS,
  USER_INFO_COURSES,
  USER_INFO_COURSES_UPDATE,
  TOKEN_USER,
  ID_TOKEN_USER,
  SET_NOTIFICATION,
  USER_UPDATE_SUCCESS,
  SET_LANGUAGE,
  REQUEST_AUTH_USER,
  USER_CART,
  STOP_AUTH_LOADER,
  START_AUTH_LOADER,
  USER_INFO_PUNCHPASS,
  SET_SELECTED_USER,
  REQUEST_USER_INFO_COURSES,
} from '../types/auth';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import {
  combineStudioPivileges,
  getTranslates,
  removeSessionStorage,
} from '../../utils/helpers';

const initialState = {
  user: {
    avatar: '',
    studios: [{ user_privileges: {}, studio_privileges: {} }],
    privileges: [],
  },
  selectedUser: null,
  user_defaults: {
    name: '',
    avatar_url: '',
    studios: [
      {
        studio_key: 'ventive',
        user_studio_id: 1,
        last_login: '',
        user_privileges: {
          studio_privileges: [
            'create_studio',
            'edit_studio',
            'view_studio',
            'delete_studio',
            'add_servers',
            'edit_servers',
            'view_servers',
            'delete_servers',
            'add_users',
            'edit_users',
            'view_users',
            'delete_users',
            'assign_privileges',
            'revoke_privileges',
            'view_templates',
            'edit_templates',
            'delete_templates',
          ],
        },
        studio_privileges: [
          'create_studio',
          'edit_studio',
          'view_studio',
          'delete_studio',
          'add_servers',
          'edit_servers',
          'view_servers',
          'delete_servers',
          'add_users',
          'edit_users',
          'view_users',
          'delete_users',
          'assign_privileges',
          'view_templates',
          'edit_templates',
          'delete_templates',
        ],
      },
    ],
    current_studio: 'ventive',
  },
  token: '',
  IDToken: '',
  messages: [],
  authenticated: false,
  seacondaryLoading: false,
  tertiaryLoading: false,
  loading: false,
  cart: { type: '', classesInSchedule: [] },
  notify: localStorage.getItem('notify') === 'true',
};

const combinePivileges = (payload) => {
  let combined = [];
  payload.studios &&
    payload.studios.map(function (studio) {
      combined = uniq(combined.concat(combineStudioPivileges(studio)) || []);
      return true;
    });
  return combined;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      localStorage.setItem('notify', action.payload);
      return {
        ...state,
        notify: action.payload,
      };
    case USER_INFO_SUCCESS:
      var addedroles = [];
      var roles = {};
      const { payload } = action;
      payload.studios.map((us) =>
        Object.keys(us.user_roles).map((urk) => {
          var ur = us.user_roles[urk];
          if (addedroles.indexOf(ur) === -1) {
            addedroles.push(ur);
            roles[urk] = us.user_roles[urk];
          }
          return true;
        })
      );

      return {
        ...state,
        info: { ...state.info, ...payload },
        user: {
          ...state.user,
          roles,
          privileges: combinePivileges(payload), // state.user)
        },
      };
    case REQUEST_USER_INFO_COURSES:
      return {
        ...state,
        seacondaryLoading: true,
      };
    case USER_INFO_COURSES:
      return {
        ...state,
        seacondaryLoading: false,
        info: { ...state.info, ...action.payload },
      };
    case USER_INFO_COURSES_UPDATE:
      return {
        ...state,
        seacondaryLoading: false,
        info: {
          ...state.info,
          courses: action.payload
        },
      };

    case USER_INFO_PUNCHPASS:
      return {
        ...state,
        user: {
          ...state.user,
          punchpasses: action.payload.data,
        },
        info: {
          ...state.info,
          punchpasses: action.payload.data,
        },
      };
    case TOKEN_USER: {
      const { token } = action.payload;
      return {
        ...state,
        token,
      };
    }
    case ID_TOKEN_USER: {
      const { IDToken } = action.payload;
      return {
        ...state,
        IDToken,
      };
    }
    case AUTH_USER: {
      const {
        token,
        user,
        user: { studios },
        ...rest
      } = action.payload;

      var addedroles = [];
      var roles = {};

      const current_studio = studios.find(
        (item) => item.studio_id === action.payload.current_studio_id
      );
      action.payload &&
        action.payload.info !== undefined &&
        action.payload.info.studios !== undefined &&
        action.payload.info.studios.map((us) => {
          if (us.user_roles) {
            Object.keys(us.user_roles).map((urk) => {
              var ur = us.user_roles[urk];
              if (addedroles.indexOf(ur) === -1) {
                addedroles.push(ur);
                roles[urk] = us.user_roles[urk];
              }
              return true;
            });
          }
          return true;
        });
      return {
        ...state,
        authenticated: true,
        error: null,
        token,
        notify: !!+get(user, 'notify'),
        loading: false,
        user: {
          ...state.user,
          ...user,
          roles,
          studios,
          current_studio: get(current_studio, 'studio_key'),
          privileges: combinePivileges(user),
        },
        cart: { type: '', classesInSchedule: [] },
        ...rest,
      };
    }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...get(action, 'payload.user'),
          notify: !!+get(action, 'payload.user.notify'),
          privileges: get(state, 'user.privileges'),
          studios: get(state, 'user.studios'),
        },
      };
    case SET_LANGUAGE:
      const translates = getTranslates(action.payload);
      return {
        ...state,
        info: {
          ...state.info,
          translates,
        },
      };
    case LOGOUT_USER:
      removeSessionStorage();
      localStorage.removeItem('repeatAlertAudition');
      return initialState;
    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        tertiaryLoading: false,
        seacondaryLoading: false,
      };
    case REQUEST_AUTH_USER:
      return { ...state, loading: true };
    case STOP_AUTH_LOADER:
      return { ...state, tertiaryLoading: false, loading: false };
    case START_AUTH_LOADER:
      return { ...state, tertiaryLoading: true };
    case SET_SELECTED_USER:
      return { ...state, selectedUser: action.payload };
    case USER_CART:
      return {
        ...state,
        cart: {
          type: action.payload.type,
          classesInSchedule: action.payload.classesInSchedule,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
