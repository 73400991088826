const initialState = {
  mode: 'light', // light - dark
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_THEME_MODE': {
      return {
        ...state,
        mode: action.payload,
      };
    }
    default:
      return state;
  }
}
