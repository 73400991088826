import * as Types from '../types/alert';

const initialState = {
  config: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.SET_ALERT_MESSAGE: {
      return {
        ...state,
        config: { ...action.payload },
      };
    }
    default:
      return state;
  }
}
