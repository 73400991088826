import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import classes from './Modal.module.scss';

import { Modal as BaseModal } from 'reactstrap';
import closeIcon from 'assets/img/icons/close.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { UncontrolledTooltip } from 'reactstrap';

const Modal = forwardRef((props, ref) => {
  const {
    title,
    isOpen,
    toggle,
    children,
    className,
    showClose,
    header,
    confirmClose,
    ...rest
  } = props;

  const [confirmOpen, setConfirmOpen] = useState(false);

  const toggleConfirm = () => setConfirmOpen(!confirmOpen);

  useImperativeHandle(ref, () => ({
    toggleConfirm() {
      setConfirmOpen(!confirmOpen);
    },
  }));

  return (
    <BaseModal
      className={cs('modal-dialog-centered', classes.root, className)}
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >
      {title || showClose ? (
        <div className={cs('modal-header', classes.modalHeader)}>
          {title && (
            <h5 className={cs('modal-title flex-grow-1', classes.modalTitle)}>
              {title}
            </h5>
          )}

          {header}

          {showClose && (
            <>
              <button
                aria-label="Close"
                className={cs('close', classes.closeButton)}
                data-dismiss="modal"
                type="button"
                onClick={confirmClose ? toggleConfirm : toggle}
                id={`close-button-modal`}
              >
                <img src={closeIcon} alt="close" />
              </button>
              <UncontrolledTooltip
                hideArrow={true}
                delay={{ show: 0, hide: 0 }}
                placement="bottom"
                target={`close-button-modal`}
              >
                Close
              </UncontrolledTooltip>
            </>
          )}
        </div>
      ) : (
        ''
      )}

      {children}

      <ConfirmationModal
        isOpen={confirmOpen}
        title="Discard unsaved changes?"
        onConfirm={() => {
          toggle();
          setConfirmOpen(false);
        }}
        onToggle={toggleConfirm}
        onCancel={toggleConfirm}
        showCheckBox={false}
        confirmTitle="Yes, Discard"
        cancelTitle="No, Cancel"
      ></ConfirmationModal>
    </BaseModal>
  );
});

const ModalBody = (props) => {
  const { className, children, ...rest } = props;

  return (
    <div className={cs('modal-body', className)} {...rest}>
      {children}
    </div>
  );
};

const ModalFooter = (props) => {
  const { className, children, ...rest } = props;

  return (
    <div className={cs('modal-footer', className)} {...rest}>
      {children}
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  header: '',
};

export { ModalBody, ModalFooter };
export default Modal;
