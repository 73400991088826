import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import get from 'lodash/get';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import checkedIcon from '../../assets/img/icons/checked.png';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import bn from 'utils/bemnames';
const bem = bn.create('textinput');

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: '#ea734f',
    borderRadius: '5px',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#ea9277',
      borderRadius: '20px',
      fontWeight: 'bold',
      lineHeight: '20px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
    borderRadius: '20px',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: 'white',
    ':hover': {
      backgroundColor: '#ea9277',
      borderRadius: '20px',
      color: 'white',
    },
  }),
};

class Dropdown extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string.isRequired,
    labelProps: PropTypes.object,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    closeMenuOnSelect: PropTypes.bool,
    outline: PropTypes.bool,
    dropDownIcon: PropTypes.bool,
  };

  static defaultProps = {
    labelProps: {},
    allOption: {
      label: 'Select All',
      value: '*',
    },
    onChange: () => {},
    allowSelectAll: false,
    name: '',
    closeMenuOnSelect: true,
  };

  renderSelectComponent() {
    /* eslint-disable */
    const {
      label,
      error,
      name,
      labelProps,
      allowSelectAll,
      allOption,
      outline,
      dropDownIcon,
      noPadding,
      ...rest
    } = this.props;
    const IconOption = (props) => (
      <components.Option {...props}>
        <img src={checkedIcon} alt="" />
        {props.data.label}
      </components.Option>
    );
    /* eslint-enable */
    if (rest.allowNew) {
      return (
        <CreatableSelect
          {...rest}
          isClearable
          options={rest.options}
          onChange={(selected, meta) => {
            return rest.onChange(selected);
          }}
          styles={colourStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: '#ea734f',
            },
          })}
          components={
            dropDownIcon !== undefined &&
            !dropDownIcon && {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }
          }
        />
      );
    }
    if (rest.isMulti && allowSelectAll) {
      if (get(rest, 'value.length') === get(rest, 'options.length')) {
        return (
          <Select
            {...rest}
            value={[allOption]}
            onChange={(selected) => rest.onChange(selected.slice(1))}
            className={classnames('react-select-container', {
              outline: outline === true,
              error: error !== undefined,
              noPadding: noPadding,
            })}
            classNamePrefix="react-select"
            components={
              dropDownIcon !== undefined &&
              !dropDownIcon && {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }
            }
            noOptionsMessage={() => (
              <>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM14.9032 12.625C15.0927 12.8145 15.0927 13.121 14.9032 13.3105L13.3065 14.9032C13.1169 15.0927 12.8105 15.0927 12.621 14.9032L10 12.2581L7.375 14.9032C7.18548 15.0927 6.87903 15.0927 6.68952 14.9032L5.09677 13.3065C4.90726 13.1169 4.90726 12.8105 5.09677 12.621L7.74194 10L5.09677 7.375C4.90726 7.18548 4.90726 6.87903 5.09677 6.68952L6.69355 5.09274C6.88306 4.90323 7.18952 4.90323 7.37903 5.09274L10 7.74194L12.625 5.09677C12.8145 4.90726 13.121 4.90726 13.3105 5.09677L14.9073 6.69355C15.0968 6.88306 15.0968 7.18952 14.9073 7.37903L12.2581 10L14.9032 12.625Z"
                    fill="#9C9CA3"
                  />
                </svg>
                <span
                  style={{
                    alignItems: 'center',
                    width: '117px',
                    height: '20px',
                    position: 'absolute',
                  }}
                >
                  <FormattedMessage id="validate > No Results Found" />
                </span>
              </>
            )}
          />
        );
      }

      return (
        <Select
          {...rest}
          components={
            dropDownIcon !== undefined &&
            !dropDownIcon && {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }
          }
          noOptionsMessage={() => (
            <>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM14.9032 12.625C15.0927 12.8145 15.0927 13.121 14.9032 13.3105L13.3065 14.9032C13.1169 15.0927 12.8105 15.0927 12.621 14.9032L10 12.2581L7.375 14.9032C7.18548 15.0927 6.87903 15.0927 6.68952 14.9032L5.09677 13.3065C4.90726 13.1169 4.90726 12.8105 5.09677 12.621L7.74194 10L5.09677 7.375C4.90726 7.18548 4.90726 6.87903 5.09677 6.68952L6.69355 5.09274C6.88306 4.90323 7.18952 4.90323 7.37903 5.09274L10 7.74194L12.625 5.09677C12.8145 4.90726 13.121 4.90726 13.3105 5.09677L14.9073 6.69355C15.0968 6.88306 15.0968 7.18952 14.9073 7.37903L12.2581 10L14.9032 12.625Z"
                  fill="#9C9CA3"
                />
              </svg>
              <span
                style={{
                  alignItems: 'center',
                  width: '117px',
                  height: '20px',
                  position: 'absolute',
                }}
              >
                <FormattedMessage id="validate > No Results Found" />
              </span>
            </>
          )}
          options={[allOption, ...rest.options]}
          className={classnames('react-select-container', {
            outline: outline === true,
            error: error !== undefined,
          })}
          classNamePrefix="react-select"
          onChange={(selected) => {
            if (
              selected.length > 0 &&
              selected[selected.length - 1].value === allOption.value
            ) {
              return rest.onChange(rest.options);
            }
            return rest.onChange(selected);
          }}
        />
      );
    }

    return (
      <Select
        name={name}
        components={
          dropDownIcon !== undefined &&
          !dropDownIcon && {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }
        }
        className={classnames(
          'react-select-container',
          { outline: outline === true, error: error !== undefined },
          rest.isError
        )}
        classNamePrefix={
          rest.isDisabled
            ? 'react-select react-select-disabled'
            : 'react-select'
        }
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#fff',
          },
        })}
        noOptionsMessage={() => (
          <NoMessage>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM14.9032 12.625C15.0927 12.8145 15.0927 13.121 14.9032 13.3105L13.3065 14.9032C13.1169 15.0927 12.8105 15.0927 12.621 14.9032L10 12.2581L7.375 14.9032C7.18548 15.0927 6.87903 15.0927 6.68952 14.9032L5.09677 13.3065C4.90726 13.1169 4.90726 12.8105 5.09677 12.621L7.74194 10L5.09677 7.375C4.90726 7.18548 4.90726 6.87903 5.09677 6.68952L6.69355 5.09274C6.88306 4.90323 7.18952 4.90323 7.37903 5.09274L10 7.74194L12.625 5.09677C12.8145 4.90726 13.121 4.90726 13.3105 5.09677L14.9073 6.69355C15.0968 6.88306 15.0968 7.18952 14.9073 7.37903L12.2581 10L14.9032 12.625Z"
                fill="#9C9CA3"
              />
            </svg>
            <span>
              <FormattedMessage id="validate > No Results Found" />
            </span>
          </NoMessage>
        )}
        {...rest}
      />
    );
  }

  render() {
    const { label, error, name, labelProps, isDisabled } = this.props;

    return (
      <div className={bem.b()}>
        {!!label && (
          <Label
            className={classnames(!!error && 'invalid', {
              'react-select-label-disable': isDisabled === true,
            })}
            disabled={true}
            for={name}
            {...labelProps}
          >
            {label}
          </Label>
        )}
        {this.renderSelectComponent()}
        {!!error && (
          <span style={{ fontSize: '12px' }} className="text-danger">
            {error}
          </span>
        )}
      </div>
    );
  }
}

export default Dropdown;

const NoMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 8px;
    color: #9c9ca3;
  }
  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #515254;
  }
`;
