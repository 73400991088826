import * as Const from 'utils/constants';
import Api from 'utils/api';
import get from 'lodash/get';
import store from './../../store';

export const getFeeds = async (page = 1) => {
  const rs = await Api.doCall(`${Const.GET_FEED()}?page=${page}`, 'GET');

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const addFeed = async (data) => {
  const rs = await Api.doCall(Const.GET_FEED(), 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const deleteFeed = async (id) => {
  let rs = await Api.doCall(Const.FEED_BY_ID(id), 'DELETE');

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const updateFeed = async (id, data) => {
  const rs = await Api.doCall(Const.FEED_BY_ID(id), 'PUT', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getEmails = async ({
  page = 1,
  pageSize = 10,
  search = '',
  type = '',
  studio = '',
  user = '',
  starred,
}) => {
  const rs = await Api.doMessangerCall(
    `${Const.GET_MESSAGE()}?page=${page}&pageSize=${pageSize}&type=${type}&search=${search}&starred=${starred}&studio=${studio}&user=${user}`,
    'GET'
  );

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getGroups = async () => {
  const rs = await Api.doCall(`${Const.GET_GROUPS()}`, 'GET');

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const addGroups = async (page = 1) => {
  const rs = await Api.doCall(`${Const.GET_FEED()}?page=${page}`, 'POST');

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const addCustomGroups = async (data) => {
  const rs = await Api.doCall(`${Const.GET_GROUPS()}`, 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const editCustomGroups = async (id, data) => {
  const rs = await Api.doCall(`${Const.EDIT_GROUPS(id)}`, 'PUT', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getDrafts = async ({
  page = 1,
  pageSize = 10,
  search = '',
  type = 'draft',
}) => {
  const rs = await Api.doMessangerCall(
    `${Const.GET_MESSAGE()}?page=${page}&pageSize=${pageSize}&type=${type}&search=${search}`,
    'GET'
  );
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getEmailById = async (id) => {
  const rs = await Api.doMessangerCall(Const.GET_MESSAGE_BY_ID(id), 'GET');
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const sendMessage = async (id, data) => {
  const rs = await Api.doMessangerCall(
    Const.SEND_MESSAGE_BY_ID(id),
    'PUT',
    data
  );
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const uploadFile = async (data) => {
  const rs = await Api.doCall(Const.FILE_UPLOADS(), 'POST', data);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const deleteEmailById = async (id) => {
  const rs = await Api.doMessangerCall(
    Const.DELETE_MESSAGE_BY_ID(id),
    'DELETE'
  );
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const editEmailById = async (id, data) => {
  const rs = await Api.doMessangerCall(
    Const.EDIT_MESSAGE_BY_ID(id),
    'PUT',
    data
  );
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const editStaredById = async (id, data) => {
  const rs = await Api.doMessangerCall(
    Const.EDIT_STARED_MESSAGE_BY_ID(id),
    'PUT',
    data
  );
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getRenderEmail = async (data) => {
  const rs = await Api.doCall(Const.EMAIL_RENDER(), 'POST', data);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const deleteDraftById = async (id) => {
  const rs = await Api.doCall(Const.FEED_BY_ID(id), 'DELETE');

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getDraftById = async (id) => {
  const rs = await Api.doCall(Const.FEED_BY_ID(id), 'GET');

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const createMessage = async (data) => {
  const rs = await Api.doMessangerCall(Const.CREATE_MESSAGE(), 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const createInvoice = async (data) => {
  const rs = await Api.doCall(Const.CREATE_INVOICE(), 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getPresignedUrl = async (data) => {
  const rs = await Api.doCall(Const.GET_PRESIGNED(), 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getChats = async () => {
  let url = Const.GET_CHATS();
  const auth = store.getState().auth;
  if (auth.selectedUser) {
    url = `${url}?id=${auth.selectedUser}`;
  }
  const rs = await Api.doCall(url, 'GET');

  if (get(rs, 'status') === 200) {
    return rs;
  }

  return [];
};

export const getNewChatUsers = async (id) => {
  const rs = await Api.doCall(Const.GET_CHAT_USERS(id), 'GET');

  if (get(rs, 'status') === 200) {
    return rs;
  }

  return [];
};

export const sendChatMessage = async (data) => {
  const rs = await Api.doCall(Const.SEND_CHAT_MESSAGE(), 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }

  return [];
};

export const createNewChat = async (data) => {
  const rs = await Api.doCall(Const.NEW_CHAT(), 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }

  return [];
};

export const sendChatReadReceipt = async (id) => {
  const rs = await Api.doCall(Const.CHAT_RECEIPT_BY_ID(id), 'PUT', {});

  if (get(rs, 'status') === 200) {
    return rs;
  }
};
