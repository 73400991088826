import React, { useState } from 'react';
import classes from './ClassInformation.module.scss';
import Icon from 'components/Icons';
import { Container, Row, Col, Button, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import TextInput from 'components/TextInput';
import cs from 'classnames';
import isEmpty from 'lodash/isEmpty';
import history from 'components/History';
import urls from '../../../../../utils/urls';

const ClassInformation = (props) => {
  const { values, touched, errors, setFieldValue } = props;
  const { step, process, template, user, valueChanged, setStep, stepForm } =
    props;

  const isEdit = document.location.pathname.split('/')[2] === 'edit';
  const urlParams = new URLSearchParams(window.location.search);
  const isClone = urlParams.get('action');

  let form_step = step.fields.find((f) => f.field === 'form_step');
  let class_name = step.fields.find((f) => f.field === 'class_name');
  let class_capacity = step.fields.find((f) => f.field === 'class_capacity');
  let class_level = step.fields.find((f) => f.field === 'class_level');

  const t = props.intl.formatMessage;
  const [clickSubmit, setClickSubmit] = useState(false);

  if (stepForm > 1)
    return (
      <Container className={classes.root}>
        <Row>
          <Col>
            <div className={'justify-content-between cr-list'}>
              <div className={'d-flex align-items-center'}>
                <Icon
                  icon="DoneSmall"
                  className="cr-icons success"
                  width="28"
                  height="28"
                />
                <div style={{ marginLeft: 3 }}>
                  <FormattedMessage id="classes > form > Class Information > title" />
                </div>
              </div>
              <div
                className={'text-edit can-click'}
                onClick={() => {
                  valueChanged(form_step, process, template, user, {
                    value: 1,
                  });
                  setStep(1);
                }}
              >
                Edit
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container className={classes.root}>
      <Row>
        <Col>
          <div className={'cr-list justify-content-start'}>
            <h4 className={'space-cadet m-0 p-0 d-flex align-items-center '}>
              {isEdit && !isClone && (
                <Icon
                  icon="DoneSmall"
                  className="cr-icons success"
                  width="28"
                  height="28"
                />
              )}

              <div style={{ marginLeft: 3 }}>
                <FormattedMessage id="classes > form > Class Information > title" />
              </div>
            </h4>
          </div>
          <div className={'cr-list justify-content-between h-auto p-4'}>
            <Row style={{ width: '100%' }}>
              <Col md={8}>
                <div className={'content flex-column'}>
                  <p>
                    <FormattedMessage id="classes > form > Class Information > subtitle" />
                  </p>

                  <Row>
                    <Col lg={9}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="classes > form > Class Name" />
                          }
                          type="text"
                          name="class_name"
                          placeholder={t({
                            id: 'classes > form > Placeholder Class Name',
                          })}
                          value={values.class_name}
                          error={touched.class_name && errors.class_name}
                          required={true}
                          onChange={(e) => {
                            let value = e.target.value;
                            const checkData = value.replace(/\s/g, '').trim();
                            const isValid = checkData !== '';
                            if (isValid === true) {
                              valueChanged(
                                class_name,
                                process,
                                template,
                                user,
                                {
                                  value,
                                }
                              );

                              valueChanged(form_step, process, template, user, {
                                value: 1,
                              });

                              props.handleChange(e);
                            } else {
                              setFieldValue('class_name', '');
                            }
                          }}
                          onBlur={props.handleBlur}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3}>
                      <FormGroup className={classes.classCapacity}>
                        <TextInput
                          label={
                            <FormattedMessage id="classes > form > Class Capacity" />
                          }
                          className={classes.inputCapacity}
                          type="text"
                          name="class_capacity"
                          placeholder={t({
                            id: 'classes > form > Placeholder Class Capacity',
                          })}
                          value={values.class_capacity}
                          error={
                            touched.class_capacity && errors.class_capacity
                          }
                          onChange={(e) => {
                            let value = e.target.value;

                            if (/^[0-9]*$/.test(value) || value === '') {
                              if (value !== '') value = parseInt(value);
                              valueChanged(
                                class_capacity,
                                process,
                                template,
                                user,
                                {
                                  value,
                                }
                              );
                              props.handleChange(e);
                            }
                          }}
                          onBlur={props.handleBlur}
                          rightComponent={
                            <span className={classes.appendText}>students</span>
                          }
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={9}>
                      <FormGroup>
                        <TextInput
                          label={
                            <FormattedMessage id="classes > form > Class Level" />
                          }
                          type="text"
                          name="class_level"
                          placeholder={t({
                            id: 'classes > form > Placeholder Class Level',
                          })}
                          value={values.class_level}
                          error={touched.class_level && errors.class_level}
                          onChange={(e) => {
                            const value = e.target.value;
                            valueChanged(class_level, process, template, user, {
                              value,
                            });
                            props.handleChange(e);
                          }}
                          onBlur={props.handleBlur}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className={'cr-list justify-content-end'}>
            <Button
              className={cs('btn-link', classes.skipButton, 'mr-4')}
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>

            <Button
              color="primary "
              className={cs(classes.save, {
                'btn-grey': clickSubmit === true && !isEmpty(errors),
              })}
              disabled={clickSubmit === true && !isEmpty(errors)}
              onClick={async (e) => {
                props.submitForm();
                setClickSubmit(true);

                const valid = await props.schema.isValidSync(values);
                if (valid)
                  props.buttonClicked(
                    step,
                    {
                      ...step.fields.find((f) => f.key === 'save_information'),
                      draft: false,
                      edit: isEdit,
                    },
                    process,
                    template,
                    user,
                    e,
                    () => {
                      if (isEdit) {
                        history.push(urls.CLASSES());
                      } else {
                        setStep(2);
                        valueChanged(form_step, process, template, user, {
                          value: 2,
                        });
                      }
                    }
                  );
              }}
            >
              <FormattedMessage id="classes > form > save & continue" />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

ClassInformation.propTypes = {};
ClassInformation.defaultProps = {};

export default injectIntl(ClassInformation);
