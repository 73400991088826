import * as React from 'react';
import PropsType from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import PlayerUI from 'components/PlayerUI';

const Container = styled.div`
  margin-bottom: 10px
`
const WrapperPlayer = styled.div`
  margin-bottom: 10px
`
class Player extends React.Component {
  onSeekMouseUp = (value) => {
    this.player.seekTo(parseFloat(value));
    setTimeout(() => {
      this.props.setPlaying(true);
    }, 5);

  }
  render(){
    const {
      url,
      playing,
      setPlaying,
      setPlayedSeconds,
      setDuration,
      volume,
      setVolume,
      playedSeconds,
      duration,
      width,
      height,
      isBubble,
      process,
    } = this.props;
    return (
      <Container>
        <WrapperPlayer>
          <ReactPlayer
            ref={ref => {
              this.player = ref;
              window.player = ref;
            }}
            className="player-hidden"
            url={url}
            playing={playing}
            controls={true}
            volume={volume}
            height={height}
            width={width}
            config={{ file: { 
              attributes: {
                controlsList: 'nodownload'
              }
            }}}
            onProgress={({playedSeconds}) => {
              window.playerPlayedSeconds = playedSeconds;
              setPlayedSeconds(playedSeconds);
            }}
            onDuration={setDuration}
            onEnded={() => { setPlaying(false) }}
          />
          <PlayerUI
            playedSeconds={playedSeconds}
            duration={duration}
            width={width}
            height={height}
            volume={volume}
            setVolume={setVolume}
            playing={playing}
            onClickPlaying={setPlaying}
            setPlayedSeconds={setPlayedSeconds}
            onSeekMouseUp={this.onSeekMouseUp}
            isBubble={isBubble}
            process={process}
          />
        </WrapperPlayer>
      </Container>
    );
  }
}

Player.propsType = {
  url: PropsType.string.isRequired,
  width: PropsType.any,
  height: PropsType.any,
  isBubble: PropsType.bool
}
Player.defaultProps = {
  isBubble: false
}
export default Player;