import React from "react";
import classnames from "classnames";
import { MdFormatBold, MdFormatItalic, MdFormatUnderlined, MdStrikethroughS } from "react-icons/lib/md";
export const DEFAULT_FONT_SIZE = 16;
export const DEFAULT_STEP_FONT_SIZE = 1;

const StyleIcon = ({style, active, onToggle}) => {
  let className = 'RichEditor-styleButton';
  let defaultClass = "RichEditor-styleButton";
  if (active) {
    className += ' RichEditor-activeButton';
  }
  const onMouseDown = (e) => {
    e.preventDefault();
    onToggle(style);
  };
  const size = 24;
  switch (style) {
    case "BOLD":
      return <MdFormatBold size={size} className={className} onMouseDown={onMouseDown} />  
    case "ITALIC":
      return <MdFormatItalic size={size} className={className} onMouseDown={onMouseDown} />  
    case "UNDERLINE":
      return <MdFormatUnderlined size={size} className={className} onMouseDown={onMouseDown} />  
    case "INCREMENT_FONTSIZE":
      return <span className={classnames(defaultClass, "RichEditor-increment-icon RichEditor-icon-text")} onMouseDown={onMouseDown}>A</span>
    case "DECREMENT_FONTSIZE":
      return <span className={classnames(defaultClass, "RichEditor-decrement-icon RichEditor-icon-text")} onMouseDown={onMouseDown}>A</span>
    default:
      break;
  }
  return <MdStrikethroughS size={size} className={className} onMouseDown={onMouseDown} />  
}
// Custom overrides for "code" style.
export const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

export const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

const INLINE_STYLES = [
  {style: 'BOLD'},
  {style: 'ITALIC'},
  {style: 'UNDERLINE'},
  {style: 'INCREMENT_FONTSIZE'},
  {style: 'DECREMENT_FONTSIZE'}
];

export const InlineStyleControls = ({editorState, onToggle}) => {
  let currentStyle = editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls RichEditor-inline-controls">
      {INLINE_STYLES.map((type, i) =>
        <StyleIcon
          key={i}
          active={currentStyle.has(type.style)}
          onToggle={onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};