import React, { useRef, useState } from 'react';
import classes from './Select.module.scss';

import BaseSelect, { components } from 'react-select';
import errorIcon from 'assets/img/icons/error.svg';
import dropdownIcon from 'assets/img/icons/dropdownn_indicator.svg';

import cs from 'classnames';

const Select = ({
  className,
  text,
  error,
  label,
  onCreate,
  onInputChange,
  onFocus,
  onBlur,
  placeholder,
  placeholderFocus,
  ...rest
}) => {
  const [keyword, setKeyword] = useState('');
  const [focus, setFocus] = useState(false);
  const ref = useRef();

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropdownIcon} alt="down" />
      </components.DropdownIndicator>
    );
  };

  return (
    <div
      className={cs(classes.root, className, {
        [classes.hasError]: error && error !== true,
        [classes.highLight]: error === true,
      })}
    >
      {label}

      <BaseSelect
        ref={ref}
        components={{ DropdownIndicator }}
        onInputChange={(value) => {
          setKeyword(value);
          onInputChange(value);
        }}
        placeholder={
          focus
            ? placeholderFocus
              ? placeholderFocus
              : text
              ? `Type to Search ${text.replace(/(^\w|\s\w)/g, (m) =>
                  m.toUpperCase()
                )}`
              : placeholder
            : placeholder
        }
        onFocus={() => {
          setFocus(true);
          onFocus();
        }}
        onBlur={onBlur}
        noOptionsMessage={() => (
          <div className={classes.noData}>
            No {text} data.
            {onCreate !== null && (
              <>
                <br />
                <button
                  onClick={async () => {
                    await onCreate(keyword);
                    ref.current.blur();
                  }}
                  type="button"
                >
                  Create New {text}
                </button>
              </>
            )}
          </div>
        )}
        {...rest}
      />

      {error && error !== true && (
        <>
          <div className={classes.errorIcon}>
            <img src={errorIcon} alt="error" />
          </div>
          <div className={classes.errorMessage}>{error}</div>
        </>
      )}
    </div>
  );
};

Select.defaultProps = {
  className: null,
  classNamePrefix: 'react-base-select',
  onCreate: null,
  onInputChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

export default Select;
