import {
  SET_CHECKLIST_REQUEST,
  SET_CHECKLIST_SUCCESS,
  SET_CHECKLIST_ERROR,
  // CLOSE_TOOLTIP_CREATE_STUDIO,
  // CLOSE_WELCOME_MODAL,
  // CLOSE_TOOLTIP_USER_CHECKLIST,
} from '../types/onboard';

const initialState = {
  data: null,
  error: null,
  loading: false,
  openTooltipCreateStudio: true,
  openTooltipAutoSaving: true,
  setupStudioCompleted: {},
  openTooltipViewProfile: true,
  openTooltipAddFamily: true,
  openTooltipManagedProfile: true,
  openTooltipCreateClass: true,
  openTooltipUserChecklist: true,
  welcomeModalShown: false,
  addedFamily: false,
  openTooltipCreatePerformance: true,
  openTooltipDragDropPerformance: true,
  openTooltipPreviewLive: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CHECKLIST_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SET_CHECKLIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    }
    case SET_CHECKLIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    // case CLOSE_WELCOME_MODAL: {
    //   return {
    //     ...state,
    //     welcomeModalShown: true,
    //   };
    // }
    // case CLOSE_TOOLTIP_CREATE_STUDIO: {
    //   return {
    //     ...state,
    //     openTooltipCreateStudio: false,
    //   };
    // }
    // case CLOSE_TOOLTIP_USER_CHECKLIST: {
    //   return {
    //     ...state,
    //     openTooltipUserChecklist: false,
    //   };
    // }
    // case 'CLOSE_TOOLTIP_AUTOSAVING': {
    //   return {
    //     ...state,
    //     openTooltipAutoSaving: false,
    //   };
    // }

    case 'SETUP_STUDIO_COMPLETED': {
      return {
        ...state,
        setupStudioCompleted: {
          ...state.setupStudioCompleted,
          [action.payload]: true,
        },
      };
    }

    // case 'CLOSE_TOOLTIP_VIEW_PROFILE': {
    //   return {
    //     ...state,
    //     openTooltipViewProfile: false,
    //   };
    // }

    // case 'CLOSE_TOOLTIP_ADD_FAMILY': {
    //   return {
    //     ...state,
    //     openTooltipAddFamily: false,
    //   };
    // }

    // case 'CLOSE_TOOLTIP_MANAGE_PROFILE': {
    //   return {
    //     ...state,
    //     openTooltipManagedProfile: false,
    //   };
    // }

    // case 'CLOSE_TOOLTIP_CREATE_CLASS': {
    //   return {
    //     ...state,
    //     openTooltipCreateClass: false,
    //   };
    // }

    // case 'CLOSE_TOOLTIP_CREATE_PERFORMANCE': {
    //   return {
    //     ...state,
    //     openTooltipCreatePerformance: false,
    //   };
    // }

    // case 'CLOSE_TOOLTIP_PREVIEW_LIVE': {
    //   return {
    //     ...state,
    //     openTooltipPreviewLive: false,
    //   };
    // }
    // case 'CLOSE_TOOLTIP_DRAGDROP_PERFORMANCE': {
    //   return {
    //     ...state,
    //     openTooltipDragDropPerformance: false,
    //   };
    // }

    // case 'ADDED_FAMILY': {
    //   return {
    //     ...state,
    //     addedFamily: true,
    //   };
    // }

    case 'SET_GUIDE_STATUS': {
      return {
        ...state,
        [action.key]: action.payload,
      };
    }

    default:
      return state;
  }
}
