import React, { useState } from 'react';
import cs from 'classnames';
import get from 'lodash/get';

import classes from 'pages/PreviewLiveMode/PreviewLiveMode.module.scss';

const DisplayPerformance = (props) => {
  const { courses, item } = props;
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const performance = courses.find((p) => p.id === item.class_id);

  const media = get(
    performance,
    'media_selected.meta.filePath',
    '/img/default_media.png'
  );

  const name = get(item, 'performance_song', null) || performance.class_name;

  const participants = get(item, 'participants', []);

  var minutes = 0;
  var seconds = 0;
  if (parseInt(item.performance_length) > 0) {
    minutes = Math.floor(item.performance_length / 60);
    seconds = item.performance_length - minutes * 60;
  }

  if (parseInt(seconds) < 10) seconds = `0${seconds}`;

  return (
    <div className={classes.performance}>
      <div className={cs(classes.content, classes.viewer)}>
        <div>
          <div
            className={cs(classes.namePerformance, {
              [classes.active]: open,
            })}
            onClick={() => toggle()}
          >
            <img className={classes.avatar} src={media} alt="" /> {name}
          </div>

          {open && (
            <ul>
              {participants.map((p, index) => {
                const nameP = get(p, 'participant.name');
                return <li key={index}> {nameP} </li>;
              })}
            </ul>
          )}
        </div>
        <span>
          {minutes}:{seconds}
        </span>
      </div>
    </div>
  );
};

export default React.memo(DisplayPerformance);
