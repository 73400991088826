import {
  SET_WALLET_AUTH_ERROR,
  SET_WALLET_AUTH_REQUEST,
  SET_WALLET_AUTH_SUCCESS,
} from '../types/wallet';

const initialState = {
  access_token: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_WALLET_AUTH_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SET_WALLET_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
      };
    }
    case SET_WALLET_AUTH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
