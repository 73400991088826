import React from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cs from 'classnames';
import classes from './TimePicker.module.scss';

const TimePicker = (props, ref) => {
  const { error } = props;
  return (
    <div className={classes.root}>
      <div className={cs({ [classes.hasError]: error === true })}>
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          showPopperArrow={false}
          timeIntervals={15}
          dateFormat={['h:mm a', 'ha']}
          onChange={(data) => {
            console.log(data);
            props.onChange(data);
          }}
          ref={ref}
          {...props}
        />
      </div>
    </div>
  );
};

TimePicker.defaultProps = {
  error: false,
  className: '',
};

export default React.forwardRef(TimePicker);
