import {
  INIT_NEW_PRIZE, PRIZE_VALUE_CHANGED, 
  // SAVE_FORM_VALUES_REQUEST, SAVE_FORM_VALUES_SUCCESS, SAVE_FORM_VALUES_ERROR,
  FETCH_PRIZE_REQUEST, FETCH_PRIZE_SUCCESS, SET_ACTIVE_TAB,
  DELETE_PRIZE_REQUEST, DELETE_PRIZE_SUCCESS, DELETE_PRIZE_ERROR,
  FETCH_PRIZES_REQUEST, FETCH_PRIZES_SUCCESS, FETCH_PRIZES_ERROR,
  FORGET_PRIZES, SET_TAB_TO_EDIT, RESET_TAB,
  ADD_PRIZE_REQUEST,ADD_PRIZE_SUCCESS,ADD_PRIZE_ERROR,
  UPDATE_PRIZE_REQUEST, UPDATE_PRIZE_SUCCESS, UPDATE_PRIZE_ERROR
} from "../types/products";
import get from 'lodash/get';
import { LOGOUT_USER } from "../types/auth";
import { pageSize } from "utils/config";
import * as Types from "../types/products";

const initialState = {
  prizes: {
    data: [],
    error: null,
    loading: false
  },
  prize: {
    data: {},
    error: null,
    loading: null
  },
    addPrizes: {
    is_added: false,
    data: null,
    error: null,
    loading: false
  },
   updatePrizes: {
    data: null,
    error: null,
    loading: false
  },
  deleteprize: {
    data: { },
    error: null,
    loading: false
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PRIZES_REQUEST: {
      return {
        ...state,
        prizes: {
          ...state.prizes,
            ['filter_'+action.payload.filter]:{
              data: state.prizes['filter_'+action.payload.filter] ? state.prizes['filter_'+action.payload.filter].data : null,
              pages: state.prizes['filter_'+action.payload.filter] ? state.prizes['filter_'+action.payload.filter].pages : null,
              page: state.prizes['filter_'+action.payload.filter] ? state.prizes['filter_'+action.payload.filter].page : 1,
              rpp: state.prizes['filter_'+action.payload.filter] ? state.prizes['filter_'+action.payload.filter].rpp : pageSize,
              error: null,
              loading: true
            }
          }
      };
    }
    case FETCH_PRIZES_SUCCESS: {
      return {
        ...state,
        prize: {
          ...get(state, "prize"),
          lastEditTab: [],
          activeTab: null
        },
        prizes: {
          ...state.prizes,
          ['filter_'+action.payload.filter]:{
            ...action.payload,
            error: null,
            loading: false
          }
        }
      };
    }
    case FETCH_PRIZES_ERROR: {
      return {
        ...state,
        prizes: {
          ...state.prizes,
          ['filter_'+action.payload.filter]:{
              data: [],
              error: action.error,
              loading: false
            }
          }
      };
    }

    case FORGET_PRIZES:{
      let new_prizes={...state.prizes}
      Object.keys(new_prizes).map(k=>{if (k.indexOf('filter_')===0) new_prizes[k].is_dirty=true; return true})
      return {
        ...state,
        prizes: new_prizes
      };
    }

    case INIT_NEW_PRIZE:
    return {
      ...state,
      prize: action.payload
    }

    // case PRIZE_VALUE_CHANGED:
    //   let field = {}
    //   field = { ...state.prize.data.fields[action.payload.item.field] }

    //   let history = { time: new Date(), user: action.payload.user.name, value: action.payload.value }
    //   if (!field || field.history === undefined) {
    //     field = {
    //       history: [history]
    //     }
    //   }
    //   else if (field.is_dirty) {
    //     field.history[field.history.length - 1].time = new Date()
    //     field.history[field.history.length - 1].value = action.payload.value
    //   }
    //   else field.history.push(history)
    //   field.is_dirty = true
    //   field.value = action.payload.value
    //   return {
    //     ...state,
    //     prize: {
    //       ...state.prize,
    //       data: {
    //         fields: {
    //           ...state.prize.data.fields,
    //           [action.payload.item.field]: {
    //             ...state.prize.data.fields[action.payload.item.field],
    //             ...field
    //           }
    //         },
    //         loaded_fields: {
    //           ...state.prize.data.loaded_fields
    //         }
    //       }
    //     }
    //   }

     // Add Prize Actions
    case Types.ADD_PRIZE_REQUEST: {
      return {
        ...state,
        addPrizes: {
          is_added: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_PRIZE_SUCCESS: {
      return {
        ...state,
        prize: {
          ...get(state, "prize"),
          data: {
            ...get(state, "prize.data"),
            data: get(state, "prize.data.data"),
          }
        },
        addPrizes: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_PRIZE_ERROR: {
      return {
        ...state,
        addPrizes: {
          is_added: false,
          loading: false,
          error: action.payload
        }
      };
    }



    // Update Prizes Actions
    case Types.UPDATE_PRIZE_REQUEST: {
      return {
        ...state,
        updatePrizes: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_PRIZE_SUCCESS: {
      return {
        ...state,
        updatePrizes: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.UPDATE_PRIZE_ERROR: {
      return {
        ...state,
        updatePrizes: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // case SET_ACTIVE_TAB:{
    //   return {
    //     ...state,
    //     prize: {
    //       ...state.prize,
    //       activeTab: action.payload,
    //     }
    //   };
    // }
    // case SET_TAB_TO_EDIT:{
    //   return {
    //     ...state,
    //     prize: {
    //       ...state.prize,
    //       activeTab: action.payload,
    //       lastEditTab: uniq(concat(get(state, "prize.lastEditTab", []), [action.payload])),
    //     }
    //   }
    // }
    // case RESET_TAB: {
    //   return {
    //     ...state,
    //     prize: {
    //       ...state.prize,
    //       activeTab: null,
    //       lastEditTab: [],
    //     }
    //   }
    // }


    // Particular Prize Actions
    case Types.FETCH_PRIZE_REQUEST: {
      const existingPrize = get(state, "prize");
      const { id } = action.payload;
      return {
        ...state,
        prize: {
          ...existingPrize,
            ...(existingPrize[id] || {}),
            loading: true,
            error: null
          }
      };
    }
    case Types.FETCH_PRIZE_SUCCESS: {
      const existingPrize = get(state, "prize");
      const { id, data } = action.payload;
      return {
        ...state,
        prize: {
          ...existingPrize,
            loading: false,
            error: null,
            data
        }
      };
    }
    case Types.FETCH_PRIZE_ERROR: {
      const existingPrize = get(state, "prize");
      const { id, error } = action.payload;
      return {
        ...state,
        prize: {
          ...existingPrize,
            loading: false,
            error
        }
      };
    }

  // delete prizes Actions
  case Types.DELETE_PRIZE_REQUEST: {
      return {
        ...state,
        updatePrizes: {
          is_updated: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_PRIZE_SUCCESS: {
      return {
        ...state,
        updatePrizes: {
          is_updated: true,
          data: action.payload,
          loading: false,
          error: null
        },
      };
    }
    case Types.DELETE_PRIZE_ERROR: {
      return {
        ...state,
        updatePrizes: {
          is_updated: false,
          loading: false,
          error: action.payload
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }

}
