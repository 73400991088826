import NotificationsPusher from "./view";
import { connect } from "react-redux";
import { compose } from "recompose";
import { sendMessage } from "store/actions/messages";

export default compose(
  connect(
    (state, props) => ({
      auth: state.auth,
      user: state.auth.user,
      messages: state.messages.messages,
      ...props
    }),
    {
      sendMessage
    }
  ),
)(NotificationsPusher);
