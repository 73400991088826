import Api from '../../utils/api';
import * as Const from '../../utils/constants';
import get from 'lodash/get';

const handleResponse = (dispatch, rs) => {
  const data = get(rs, 'data', []);

  data.map(item => {
    dispatch({ type: 'SET_GUIDE_STATUS', key : item.feature, payload : item.status === 1 ? true : false });
    return true;
  })
}

export const addGuide = (key, value) => async (dispatch) => {
  
  const data = {
    feature : key,
    status : value
  }
  
  try {
    let response = await Api.doCall(
      Const.GUIDE(),
      'POST',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        // dispatch({ type: Types.SET_CHECKLIST_SUCCESS, payload: response.data });
        handleResponse(dispatch, response.data);
      }
    }
  } catch (error) {
    return Promise.reject(error.message);
  }
};

export const getGuide = () => async (dispatch) => {
  try {
    
    let response = await Api.doCall(Const.GUIDE(), 'GET');
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {

        handleResponse(dispatch, response.data);

        // return dispatch({
        //   type: Types.SET_CHECKLIST_SUCCESS,
        //   payload: response.data,
        // });
      }
    }
  } catch (error) {
    return Promise.reject(error.message);
  }
};

