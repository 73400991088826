import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, find, findIndex, isEqual } from 'lodash';
import { Row, Col, Button } from 'reactstrap';
import CheckBox from 'components/Checkbox';
import Avatar from 'components/Avatar';
import moment from 'moment';

class Attendance extends Component {
  state = {
    currentSelectedDate: moment().format(),
    currentAttendanceIndex: 0,
  };

  componentDidMount() {
    const attendance =
      get(this.props.process, 'data.fields.attendance.value', []) || [];
    this.updateCurrentData(attendance);
  }

  componentDidUpdate(prevProps) {
    const oldProcess = prevProps.process;
    const attendance =
      get(this.props.process, 'data.fields.attendance.value', []) || [];
    const oldAttendance =
      get(oldProcess, 'data.fields.attendance.value', []) || [];
    if (!isEqual(attendance, oldAttendance)) {
      this.updateCurrentData(attendance);
    }
  }

  updateCurrentData = (attendance) => {
    let currentDate = moment().format();
    let currentAttendanceIndex = 0;
    if (attendance.length > 0) {
      currentAttendanceIndex = attendance.length - 1;
      currentDate = attendance[attendance.length - 1].created_ts;
    }
    this.setState({
      currentAttendanceIndex,
      currentSelectedDate: currentDate,
    });
  };

  getFieldRefs = () => {
    const { template } = this.props;
    const step = template.steps.attendance;
    const attendance = step.fields.find((f) => f.field === 'attendance');
    return {
      attendanceField: attendance,
    };
  };

  onAddNewAttendance = () => {
    const { attendanceField } = this.getFieldRefs();
    const { process, template, user } = this.props;
    let selectedAttendance =
      get(process, 'data.fields.attendance.value', []) || [];
    selectedAttendance.push({
      created_ts: moment().format(),
      users: [],
    });
    const newAttendance = selectedAttendance[selectedAttendance.length - 1];
    this.setState({
      currentSelectedDate: newAttendance.created_ts,
      currentAttendanceIndex: selectedAttendance.length - 1,
    });
    this.props.valueChanged(attendanceField, process, template, user, {
      value: selectedAttendance,
    });
  };

  onChange = (selectedUser, checked) => {
    const { attendanceField } = this.getFieldRefs();
    const { process, template, user } = this.props;
    const { currentAttendanceIndex, currentSelectedDate } = this.state;
    let selectedAttendance =
      get(process, 'data.fields.attendance.value', []) || [];
    let currentAttendance = selectedAttendance[currentAttendanceIndex] || {};
    let currentAttendancePos = currentAttendanceIndex;
    if (currentAttendance) {
      let users = get(currentAttendance, 'users', []) || [];
      let userPos = findIndex(users, { id: selectedUser.id });
      if (userPos > -1) {
        // if user is already checked , then remove it from array
        users.splice(userPos, 1);
      } else {
        users.push(selectedUser);
      }
      currentAttendance.users = users;
      selectedAttendance[currentAttendancePos] = currentAttendance;
    } else {
      currentAttendance = {
        created_ts: currentSelectedDate,
        users: [
          {
            id: selectedUser.id,
            name: selectedUser.name,
            avatar_url: selectedUser.avatar_url,
          },
        ],
      };
      selectedAttendance.push(currentAttendance);
    }
    this.props.valueChanged(attendanceField, process, template, user, {
      value: selectedAttendance,
    });
  };

  onPreviousAttendanceClick = () => {
    const { currentAttendanceIndex } = this.state;
    let attendances =
      get(this.props.process, 'data.fields.attendance.value', []) || [];
    let nextIndex;
    if (currentAttendanceIndex > 0) {
      nextIndex = currentAttendanceIndex - 1;
    } else if (currentAttendanceIndex === 0) {
      nextIndex = 0;
    }
    const attendance = attendances[nextIndex];
    this.setState({
      currentAttendanceIndex: nextIndex,
      currentSelectedDate: attendance['created_ts'],
    });
  };

  onNextAttendanceClick = () => {
    const { currentAttendanceIndex } = this.state;
    let attendances =
      get(this.props.process, 'data.fields.attendance.value', []) || [];
    let nextIndex;
    if (currentAttendanceIndex < attendances.length - 1) {
      nextIndex = currentAttendanceIndex + 1;
    } else if (currentAttendanceIndex === attendances.length - 1) {
      nextIndex = attendances.length - 1;
    }
    const attendance = attendances[nextIndex];
    this.setState({
      currentAttendanceIndex: nextIndex,
      currentSelectedDate: attendance['created_ts'],
    });
  };

  renderAddNewAttendanceButton = () => {
    if (this.props.mode === 'edit') {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginBottom: 10 }}
        >
          <Button
            color="primary"
            style={{ borderRadius: 30 }}
            onClick={this.onAddNewAttendance}
          >
            Start New Attendance
          </Button>
        </div>
      );
    }
    return null;
  };

  render() {
    const { userList, process, roles, mode } = this.props;
    console.log({ userList });
    const { currentAttendanceIndex } = this.state;
    const isEditMode = mode === 'edit';
    const courseStudios =
      get(process, 'data.fields.course_studios.value', []) || [];
    const selectedAttendance =
      get(process, 'data.fields.attendance.value', []) || [];
    const currentAttendance = selectedAttendance[currentAttendanceIndex] || {};
    let userOptions = [];
    userList.forEach((user) => {
      user.studios.forEach((studio) => {
        const userRoles = Object.values(studio.user_roles);
        if (
          courseStudios.includes(studio.current_studio) &&
          userRoles.find((userRole) => roles.includes(userRole))
        ) {
          userOptions.push(user);
        }
      });
    });
    return (
      <div style={{ marginTop: 30, marginLeft: 30 }}>
        {this.renderAddNewAttendanceButton()}
        {userOptions.map((user) => {
          const selectedUsers = get(currentAttendance, 'users', []) || [];
          const isUserSelected = find(selectedUsers, { id: user.id });
          return (
            <Row key={user.id}>
              <Col>
                <div className="d-flex align-items-center">
                  <div style={{ marginRight: 10 }}>
                    <CheckBox
                      checked={Boolean(isUserSelected)}
                      onChange={(checked) => {
                        this.onChange(user, checked);
                      }}
                      disabled={!isEditMode}
                    />
                  </div>
                  <div>
                    <Avatar
                      src={user.avatar_url}
                      size={50}
                      style={{ marginRight: '10px' }}
                    />
                  </div>
                  <div>{user.name}</div>
                </div>
              </Col>
            </Row>
          );
        })}
        {selectedAttendance.length > 1 && (
          <Row>
            <Col>
              <Button
                color="primary"
                style={{ borderRadius: 30 }}
                onClick={this.onPreviousAttendanceClick}
                disabled={currentAttendanceIndex === 0}
              >
                Previous Attendance
              </Button>
            </Col>
            <Col>
              <div className="d-flex align-items-center justify-content-center">
                {currentAttendanceIndex + 1} / {selectedAttendance.length}
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-end flex-column">
                <Button
                  color="primary"
                  style={{ borderRadius: 30 }}
                  className={'btn-pull-right'}
                  onClick={this.onNextAttendanceClick}
                  disabled={
                    currentAttendanceIndex === selectedAttendance.length - 1
                  }
                >
                  Next Attendance
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

Attendance.propTypes = {
  mode: PropTypes.string,
  valueChanged: PropTypes.func,
};

Attendance.defaultProps = {
  mode: 'edit',
  valueChanged: () => {},
};

export default Attendance;
