import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";
import { withRouter } from "react-router-dom";
import { setActiveTab, setActiveEditTab} from "store/actions/processes";
import Tabs from "./tabsView";

const Enhanced = compose(
  connect(
    (state, props) => ({
      user: state.auth.user,
      process: props.process,
      template: props.template,
      messages: state.messages.messages
    }),
    {
      setActiveTab,
      setActiveEditTab
    }
  ),
  withState("isCollapse", "setIsCollapse", false),
  withHandlers({
    onSetCollapse: ({ setIsCollapse }) => async isCollapse => {
      setIsCollapse(isCollapse);
    }
  })
)(Tabs);
export default withRouter(Enhanced);
