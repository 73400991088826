import { Component } from 'react';
import Pusher from 'pusher-js';
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { incomingMessage, sendMessage } from "store/actions/messages";

class Listener extends Component {
    render() {
        return null
    }
}

export default compose(
    connect(
        (state, props) => {
            return {
                process: state.processes.process,
                processes: state.processes,
                auth: state.auth,
                messages: state.messages.messages,
                studios: state.auth.user && state.auth.user.studios
            };
        },
        {
            sendMessage,
            incomingMessage
        }
    ),
    withHandlers({
        onInComingMessage: ({incomingMessage, auth, messages, processes}) => (message) => {
            incomingMessage(message, auth, messages, processes);
        }
    }),
    lifecycle({
        componentWillUpdate(nextProps) {
            const props = nextProps
            if (!props.auth.info || !props.auth.info.studios || props.auth.info.studios.length === 0 || !props.auth.info.courses_ids || !props.auth.info.key || this.inited) return
            const pusher = new Pusher('6629c1933e27c63f419f', {
                cluster: 'mt1',
                encrypted: true
            });
            props.auth.info.studios.map(s => {
                if(s.key){ 
                    const channel = pusher.subscribe(s.key);
                     channel.bind('global', (data) => {
                         this.props.onInComingMessage(data);
                     });
                     this.inited=true
                }
                return true
             })
             props.auth.info.courses_ids.map(s => {
                const channel = pusher.subscribe(s);
                    channel.bind('global', (data) => {
                        this.props.onInComingMessage(data);
                    });
                    this.inited=true
                return true
             })
             if(this.inited){
                const channel = pusher.subscribe(props.auth.info.key);
                channel.bind('global', (data) => {
                    this.props.onInComingMessage(data);
                });
             }
          }
    })
)(Listener);