import React, { useEffect, useState } from 'react';
import classes from './ClassSchedule.module.scss';
import { Button, Col, Container, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import cs from 'classnames';
import get from 'lodash/get';
import moment from 'moment';

import calendarIcon from 'assets/img/icons/calendar.svg';
import DateTime from './DateTime';
import DateTimeNoRecurring from './DateTimeNoRecurring';
import history from 'components/History';
import Icon from 'components/Icons';
import EventDetail from './EventDetail';
import EventGuests from './EventGuests';

import ToastManager from 'components/ToastManager';
import { MdCheckCircle } from 'react-icons/lib/md';

import { createMessage } from 'utils/helpers';
import { sendMessage } from 'store/actions/messages';
import ReviewCalendar from './ReviewCalendar';
import { renderRecurringToggle } from '../../../../../utils/constants';
import { classType } from 'pages/SetupSchedule/util';
import urls from '../../../../../utils/urls';

const ClassSchedule = (props) => {
  const { values, stepForm, setStep } = props;
  const { step, process, template, user, valueChanged, initialValues } = props;
  const [loading, setLoading] = useState(false);
  const isEdit = document.location.pathname.split('/')[2] === 'edit';
  const urlParams = new URLSearchParams(window.location.search);
  const isClone = urlParams.get('action');
  const cloneStep = urlParams.get('step');

  const { setFieldValue } = props;
  const fields = get(step, 'fields', []) || [];
  let start_date = null;
  let end_date = null;
  let recurring_event = null;

  if (step) {
    start_date = fields.find((f) => f.field === 'start_date');
    end_date = fields.find((f) => f.field === 'end_date');
    recurring_event = fields.find((f) => f.field === 'recurring_event');
  }
  useEffect(() => {
    if (stepForm === 2) {
      [
        'start_date',
        'end_date',
        'recurring_event',
        'recurring_type',
        'recurring_repeat_on',
        'recurring_number',
        'event_note',
        'event_guests',
        'recurring_repeat_on_monthly',
      ].map((item) => {
        valueChanged({ field: item }, process, template, user, {
          value: values[item],
        });
        return true;
      });
    }
  }, [stepForm]);

  const t = props.intl.formatMessage;

  const isAdd = document.location.pathname.split('/')[2] === 'add';

  const recurringEvent = get(
    process,
    'data.fields.recurring_event.value',
    true
  );

  const classStatus = get(
    process,
    `data.fields.course_details_status.value`,
    ''
  );

  let form_step = step.fields.find((f) => f.field === 'form_step');

  let validateDate = true;
  let validateTime = true;
  let isValid = true;

  // validate date
  const secondStartDate = moment(
    values.start_date,
    'YYYY-MM-DD 00:00:00'
  ).format('x');
  const secondEndDate = moment(values.end_date, 'YYYY-MM-DD 00:00:00').format(
    'x'
  );

  if (secondStartDate > secondEndDate) {
    validateDate = false;
  }

  // validate time

  const date = parseInt(moment().format('D'));
  const month = parseInt(moment().format('M'));
  const year = parseInt(moment().format('YYYY'));

  const startTime = moment(values.start_date, 'YYYY-MM-DD HH:mm:ss')
    .set({ year: year, month: month, date: date })
    .format('x');

  const endTime = moment(values.end_date, 'YYYY-MM-DD HH:mm:ss')
    .set({ year: year, month: month, date: date })
    .format('x');

  if (startTime >= endTime) {
    validateTime = false;
  }

  Object.keys(values.recurring_repeat_on).map((key) => {
    if (!get(values.recurring_repeat_on, `${key}.id`)) return true;

    const day = get(values.recurring_repeat_on, key);

    if (moment(day.to).format('X') - moment(day.from).format('X') <= 0) {
      isValid = false;
    }
    return true;
  });

  if (values.recurring_number === 0 || values.recurring_number === '') {
    isValid = false;
  }

  const validateData = () => {
    if (validateDate === false || validateTime === false || isValid === false) {
      ToastManager.show({
        autoDismiss: 3,
        level: 'warning',
        message: 'Resolve the error before continuing.',
      });

      return false;
    }

    return true;
  };

  const className = get(values, 'class_name', '');
  const classTypeValue = get(values, 'classType.value', '');

  const onSaveChange = (e) => {
    setLoading(true);
    props.buttonClicked(
      step,
      {
        ...step.fields.find((f) => f.key === 'save_schedule_now'),
        draft: false,
      },
      process,
      template,
      user,
      e,
      async () => {
        const system =
          get(process, 'data.fields.from.value.system', false) || false;

        if (system === true) {
          const payload = {
            sendTo: {
              type: 'course',
              id: get(process, 'data.fields.key.value'),
              name: get(process, 'data.fields.class_name.value'),
            },
            from: get(process, 'data.fields.from.value'),
            text: get(process, 'data.fields.from.value.chat'),
          };
          const message = createMessage(payload);
          await sendMessage(message);
        }

        ToastManager.show({
          title: <MdCheckCircle />,
          message: 'Class scheduled successfully.',
          autoDismiss: 2,
          level: 'success',
        });
        history.push(urls.CLASSES());
        setLoading(false);
      }
    );
  };

  const onSaveDraft = (e) => {
    props.buttonClicked(
      step,
      {
        ...step.fields.find((f) => f.key === 'save_as_draft'),
        draft: false,
      },
      process,
      template,
      user,
      e,
      () => {
        ToastManager.show({
          title: <MdCheckCircle />,
          message: 'Class successfully saved as a draft.',
          autoDismiss: 2,
          level: 'success',
        });
        history.push(urls.CLASSES());
      }
    );
  };

  if (stepForm === 1 && isAdd) return '';
  if (stepForm === 1 && !isAdd)
    return (
      <Container className={classes.root}>
        <Row>
          <Col>
            <div className={'justify-content-between cr-list'}>
              <div className={'d-flex align-items-center'}>
                {classStatus === 'active' && (
                  <Icon
                    icon="DoneSmall"
                    className="cr-icons success"
                    width="28"
                    height="28"
                  />
                )}
                <div style={{ marginLeft: 3 }}>
                  <FormattedMessage id="classes > form > Schedule" />
                  {` "${className}"`}
                </div>
              </div>
              <div
                className={'text-edit can-click'}
                onClick={() => {
                  valueChanged(form_step, process, template, user, {
                    value: 2,
                  });
                  setStep(2);
                }}
              >
                Edit
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container className={classes.root}>
      <Row>
        <Col>
          <div className={'cr-list justify-content-between'}>
            <div className="d-flex align-items-center">
              {classStatus === 'active' && isEdit && !isClone && (
                <Icon
                  icon="DoneSmall"
                  className="cr-icons success mr-2"
                  width="28"
                  height="28"
                />
              )}

              <h4 className={'space-cadet m-0 p-0'}>
                {t({ id: 'classes > form > Schedule' })} "
                {get(values, 'class_name', '')}"
              </h4>
            </div>

            {(isAdd || (isClone && cloneStep === '2')) && (
              <Button
                className={cs('btn-link', classes.skipButton)}
                onClick={(e) => {
                  history.push(urls.CLASSES());
                }}
              >
                <FormattedMessage id="classes > form > Skip For Now" />
              </Button>
            )}
          </div>
          <div className={'cr-list justify-content-between h-auto p-4'}>
            <div className={cs('content flex-column', classes.content)}>
              <Row>
                <Col md={12}>
                  <h4 className={classes.titleArea}>
                    <FormattedMessage id="classes > form > Date & Time" />
                  </h4>
                </Col>
              </Row>
              {renderRecurringToggle({
                values,
                classes,
                setFieldValue,
                valueChanged,
                recurring_event,
                user,
                template,
                process,
                step,
                isAdd,
                start_date,
                end_date,
                initialValues,
              })}
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-baseline">
                    <img
                      src={calendarIcon}
                      alt="Calendar"
                      className={cs('mr-2 mt-2')}
                    />

                    {recurringEvent ? (
                      <DateTime
                        {...props}
                        validateDate={validateDate}
                        validateTime={validateTime}
                      />
                    ) : (
                      <DateTimeNoRecurring
                        {...props}
                        validateDate={validateDate}
                        validateTime={validateTime}
                      />
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <h4 className={classes.titleArea}>
                    <FormattedMessage id="classes > form > Event Details" />
                  </h4>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <EventDetail {...props} from="schedule" />
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <h4 className={classes.titleArea}>
                    <FormattedMessage id="classes > form > Guests" />
                  </h4>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <EventGuests {...props} />
                </Col>
              </Row>
            </div>
          </div>
          <div
            className={'cr-list d-flex flex-wrap p-2'}
            style={{ height: 'initial' }}
          >
            <div className="flex-grow-1">
              <ReviewCalendar
                validateData={validateData}
                {...props}
                classStatus={classStatus}
                loading={loading}
                onSaveDraft={onSaveDraft}
                onSaveChange={onSaveChange}
              />
            </div>

            {classStatus === 'draft' && (
              <Button
                className={cs('btn-link', classes.skipButton, 'mr-4')}
                onClick={async (e) => {
                  if (validateData() === false) return false;
                  onSaveDraft(e);
                }}
              >
                <FormattedMessage id="classes > form > Save as Draft" />
              </Button>
            )}

            <div className={classes.scheduleButton}>
              <Button
                color="primary "
                block={true}
                disabled={loading}
                onClick={(e) => {
                  if (validateData() === false) return false;
                  onSaveChange(e);
                }}
              >
                {loading ? (
                  'Saving...'
                ) : classStatus === 'draft' ? (
                  <FormattedMessage id="classes > form > Schedule Now" />
                ) : (
                  <FormattedMessage id="classes > form > Save Changes" />
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default injectIntl(ClassSchedule);
