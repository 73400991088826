import * as React from "react";
import PropTypes from "prop-types";
import { MentionsInput, Mention } from "react-mentions";
import { FaSmileO } from "react-icons/lib/fa";
import { IoAndroidSend } from "react-icons/lib/io";
import { Picker } from "emoji-mart";
import mentionInputStyle, { mentionStyle } from "./mentionStyles";
import bn from "utils/bemnames";
import cx from "classnames";
import { getSortUserName } from "utils/helpers"
import ToolTip from "react-portal-tooltip";
import { injectIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
const bem = bn.create("chatbox");

class ChatInput extends React.Component {
  _calculateCursorPositionAndSplitString = () => {
    const { inputValue } = this.props;
    const textarea = this.inputRef;
    const mentions = inputValue.mentions;
    const cursorPosition = textarea.selectionEnd;
    let offset = 0;
    mentions.map(mention => {
      if (mention.plainTextIndex < cursorPosition) {
        offset += mention.id.length + 5; // here 5 is due to @[]() character in mention string
        return mention;
      }
      return mention;
    });
    const splitIndex = cursorPosition + offset;
    const start = inputValue.value.substring(0, splitIndex);
    const end = inputValue.value.substring(splitIndex);
    return { start, end, splitIndex, cursorPosition };
  };

  _onSelectEmoji = emoji => {
    const { inputValue, setInputValue } = this.props;
    const textarea = this.inputRef;
    const {
      start,
      end,
      cursorPosition
    } = this._calculateCursorPositionAndSplitString();
    const text = start + emoji.native + end;
    setInputValue({ ...inputValue, value: text });
    setTimeout(() => {
      textarea.focus();
      textarea.selectionEnd = cursorPosition + emoji.native.length;
    });
  };

  renderEmojiPicker() {
    const { toggleEmojiPicker, isEmojiPickerOpen } = this.props;
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          if (isEmojiPickerOpen) {
            toggleEmojiPicker();
          }
        }}>
        <div className={bem.e("picker")}>
          <Picker title="" emoji="" onSelect={this._onSelectEmoji} />
        </div>
      </OutsideClickHandler>
    );
  }

  render() {
    const {
      toggleEmojiPicker,
      isEmojiPickerOpen,
      setInputValue,
      inputValue,
      users,
      submitOnEnter
    } = this.props;

    let toolTipStyle = {
      style: {
        padding: 0,
        transition: "none",
        zIndex: 1000
      },
      arrowStyle: {
        borderColor: false
      }
    };
    return (
      <React.Fragment>
        <div className={bem.e("input")}>
          <div
            className={bem.e("input__button")}
            data-emoji
            style={{ backgroundColor: '#f8f9fa',color: '#c2d4e0',border: '1px solid #e8e9e9'}} 
            ref={ref => (this.emojiButton = ref)}
            onClick={toggleEmojiPicker}>
            <FaSmileO size={20} />
          </div>
          <div className={bem.e("input__field")}>
            <MentionsInput
              inputRef={ref => (this.inputRef = ref)}
              placeholder={this.props.placeholder || this.props.intl.formatMessage({id: "chat system > placeholder comment input"})}
              value={inputValue.value}
              style={mentionInputStyle}
              className={bem.e("mentionInput")}
              onKeyDown={e => {
                if (submitOnEnter) {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                    this.props.onSend();
                    return false;
                  }
                }
              }}
              onChange={(event, newValue, newPlainTextValue, mentions) => {
                setInputValue({
                  value: newValue,
                  plainValue: newPlainTextValue,
                  mentions
                });
              }}>
              <Mention
                trigger="@"
                data={users.map(user => ({
                  id: user.id,
                  name: user.name,
                  display:user.name,
                  avatar: user.avatar_url
                }))}
                renderSuggestion={(entry) => {
                  return (
                  <div className={bem.e('mention__list')}>
                    {entry.avatar ?
                      <img src={entry.avatar} width="30px" height="30px" alt={entry.display} className={bem.e('mention__avatar')} />
                    : <div className={cx("message-avatar-text-noti")}>
                    {getSortUserName(entry.display)}
                  </div>}
                    
                    <div className={bem.e('mention__name')}>{entry.display}</div>
                    </div>)
                    }}
                style={mentionStyle}
                className={bem.e("mention")}
                appendSpaceOnAdd
              />
            </MentionsInput>
          </div>
          <div className={bem.e("input__button")}>
            <IoAndroidSend size={20} onClick={this.props.onSend} />
          </div>
        </div>
        {this.emojiButton && <ToolTip
          position="top"
          active={isEmojiPickerOpen}
          parent={this.emojiButton}
          style={toolTipStyle}>
          {this.renderEmojiPicker()}
        </ToolTip>}
      </React.Fragment>
    );
  }
}

ChatInput.propTypes = {
  setInputValue: PropTypes.func,
  inputValue: PropTypes.object,
  toggleEmojiPicker: PropTypes.func,
  users: PropTypes.array,
  onSend: PropTypes.func,
  submitOnEnter: PropTypes.bool
};

export default injectIntl(ChatInput);
