import styled from "styled-components";

const Form = styled.div`
`;
const SectionTitle = styled.h6`
  border-bottom: 0.5px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding: 20px 25px;
  font-size: 20px;
  font-weight: 600;
  color: #324856;
`;
const SectionFields = styled.div`
  padding: 10px;
`;
const ShadowBox = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 4px 10px #aaa;
  margin-bottom: 30px;
`;

const Asterisk = styled.span`
  color: red;
  margin-left: 1px;
`;

const DropArea = styled.div`
  width: 100%;
  height: 100px;
  border: 2px dotted #444;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const SpotBar = styled.div`
  background: #eceded;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #1d51b7;
  margin-bottom: 20px;
`;
export {
  Form,
  SectionTitle,
  SectionFields,
  ShadowBox,
  Asterisk,
  DropArea,
  SpotBar
};