export const INIT_NEW_PRIZE = "INIT_NEW_PRIZE"
export const PRIZE_VALUE_CHANGED = "PRIZE_VALUE_CHANGED"
export const SAVE_FORM_VALUES_REQUEST = "SAVE_FORM_VALUES_REQUEST"
export const SAVE_FORM_VALUES_SUCCESS = "SAVE_FORM_VALUES_SUCCESS"
export const SAVE_FORM_VALUES_ERROR = "SAVE_FORM_VALUES_ERROR"

export const FETCH_PRIZE_REQUEST = "FETCH_PRIZE_REQUEST";
export const FETCH_PRIZE_SUCCESS = "FETCH_PRIZE_SUCCESS"
export const FETCH_PRIZE_ERROR = "FETCH_PRIZE_ERROR"

export const DELETE_PRIZE_REQUEST = "DELETE_PRIZE_REQUEST";
export const DELETE_PRIZE_SUCCESS = "DELETE_PRIZE_SUCCESS"
export const DELETE_PRIZE_ERROR = "DELETE_PRIZE_ERROR"

export const UPDATE_PRIZE_REQUEST = "UPDATE_PRIZE_REQUEST";
export const UPDATE_PRIZE_SUCCESS = "UPDATE_PRIZE_SUCCESS"
export const UPDATE_PRIZE_ERROR = "UPDATE_PRIZE_ERROR"

export const FETCH_PRIZES_REQUEST = "FETCH_PRIZES_REQUEST";
export const FETCH_PRIZES_SUCCESS = "FETCH_PRIZES_SUCCESS"
export const FETCH_PRIZES_ERROR = "FETCH_PRIZES_ERROR"

export const ADD_PRIZE_REQUEST = "ADD_PRIZE_REQUEST";
export const ADD_PRIZE_SUCCESS = "ADD_PRIZE_SUCCESS"
export const ADD_PRIZE_ERROR = "ADD_PRIZE_ERROR"

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
export const SET_TAB_TO_EDIT = "SET_TAB_TO_EDIT"
export const RESET_TAB = "RESET_TAB";

export const FORGET_PRIZES = "FORGET_PRIZES"
