import UploadFile from "./view";
import { connect } from "react-redux";
import axios from "axios";
import {
  compose,
  withHandlers,
  withState,
  withPropsOnChange,
  lifecycle
} from "recompose";
import { uploadFile, deleteFile } from "store/actions/uploads";
import { download, getFileObjectFromURL } from "./helper";

export default compose(
  withState("isShowFormUpload", "setIsShowFormUpload", ({ url }) => !url),
  withState("isLoading", "setIsLoading", false),
  withState("file", "setFile", ({ url }) => getFileObjectFromURL(url)),
  withState("error", "setError", null),
  lifecycle({
    componentWillUpdate(nextProps) {
      //need update states which relate to url props
      //according to this: https://stackoverflow.com/questions/46898253/recompose-withstate-how-to-update-on-props-changes
      if (this.props.url !== nextProps.url) {
        const url = nextProps.url;
        this.props.setIsShowFormUpload(!url);
        this.props.setFile(getFileObjectFromURL(url));
      }
    }
  }),
  connect(
    state => state,
    {
      uploadFile,
      deleteFile
    }
  ),
  withHandlers({
    onFormSubmit: ({
      uploadFile,
      setIsLoading,
      setIsShowFormUpload,
      setFile,
      setError,
      onUploaded
    }) => async values => {
      setIsLoading(true);
      const response = await uploadFile(values);
      if (response) {
        setFile(response);
        setIsShowFormUpload(false);
        onUploaded(response); // callback
      } else {
        setError(`Can't upload`);
      }
      setIsLoading(false);
    },
    onDeleteFile: ({
      deleteFile,
      setIsLoading,
      setError,
      setIsShowFormUpload,
      setFile,
      onDeleted
    }) => async file => {
      setIsLoading(true);
      const response = await deleteFile(file);
      if (response) {
        setIsShowFormUpload(true);
        setFile(null);
        onDeleted(file); // callback
      } else {
        setError(`Can't delete`);
      }
      setIsLoading(false);
    },
    onDownloadFile: ({ setError }) => async ({ path }) => {
      axios({
        url: path,
        method: "GET",
        responseType: "blob"
      })
        .then(response => {
          const { data } = response;
          if (data) {
            const fileName = path.replace(/^.*[\\/]/, "");
            download(data, fileName);
          } else {
            setError(`Can't download`);
          }
        })
        .catch(e => {
          const { message } = e;
          setError(message);
        });
    }
  }),
  withPropsOnChange(["file", "isShowFormUpload"], ({ setError }) => {
    setError(null);
  }),
  withPropsOnChange(["isShowFormUpload"], ({ isShowFormUpload, setFile }) => {
    if (isShowFormUpload) {
      setFile(null);
    }
  })
)(UploadFile);
