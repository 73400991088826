import React from 'react';
import { SvgIcon } from '@mui/material';

const WaitingIcon = (props) => (
  <SvgIcon
    fontSize="small"
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1667_113493)">
        <path
          d="M16.875 3C17.4963 3 18 2.49633 18 1.875V1.125C18 0.503672 17.4963 0 16.875 0H1.125C0.503672 0 0 0.503672 0 1.125V1.875C0 2.49633 0.503672 3 1.125 3C1.125 7.26398 3.51637 10.8625 6.78947 12C3.51637 13.1375 1.125 16.736 1.125 21C0.503672 21 0 21.5037 0 22.125V22.875C0 23.4963 0.503672 24 1.125 24H16.875C17.4963 24 18 23.4963 18 22.875V22.125C18 21.5037 17.4963 21 16.875 21C16.875 16.736 14.4836 13.1375 11.2105 12C14.4836 10.8625 16.875 7.26398 16.875 3ZM9 9.75C6.29123 9.75 4.125 6.63197 4.125 3H13.875C13.875 6.65367 11.6948 9.75 9 9.75Z"
          fill="#F5B722"
        />
      </g>
      <defs>
        <clipPath id="clip0_1667_113493">
          <rect width="18" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);

export default WaitingIcon;
