import moment from "moment";
import { get } from "lodash";
import momentDuration from "moment-duration-format"; // eslint-disable-line no-unused-vars
const formatSeconds = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const formatted = duration.format("mm:ss");
    if(formatted < 60) return `0:${formatted}`;
    return formatted
}
const getBubbleMessages = (process, isBubble) => {
    const messages = get(process, "data.fields.messages.value", []).filter(message => {
        if(
            get(message, "_source.m_type") === 'chat'
            && get(message, "_source.include_timestamp", false)
            && isBubble
        ){
            return message;
        }
        return false;
    });
    return messages;
}
export {
    formatSeconds,
    getBubbleMessages,
}
