import * as React from 'react';
import { compose, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import { get } from "lodash";
import { sendMail } from "store/actions/mail";
import ToastManager from "components/ToastManager";
import { MdImportantDevices } from "react-icons/lib/md";
import { formatDisplayDate } from "utils/helpers";
import moment from "moment";
import { injectIntl  } from 'react-intl';
import View from "./view";
const generateEmailHtml = (process, user, userInfo, intl, data) => {
    const nickName = get(process, "data.fields.contract_client_nickname.value") ? get(process, "data.fields.contract_client_nickname.value") : get(process, "data.fields.client_name.value", "");
    const orderTitle = get(process, "data.fields.order_title.value", "");
    const deadline = get(process, "data.fields.order_start_date.value") ? moment(get(process, "data.fields.order_start_date.value")).format("MMMM DD") : "";
    const studios = get(process, "data.fields.course_studios.value", []).map(item => item);
    const studiosName = get(userInfo, "studios", []).map(studio => studio.current_studio).join(',');
    return `
    <div
        style="
            padding: 1rem 2rem 0 2rem;
            background: #f7fbfd;
            font-family: 'Muli', sans-serif;
            color: #657894;
            -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        "
    >
        <div
            style="
                max-width: 500px;
                flex-direction: column;
                margin: 0 auto;
            "
        >
            <p style="
                color: #657894;
                font-weight: 400;
                margin-bottom: 0 
            ">Hello ${nickName},</p>
            <p
                style="
                font-family: 'Muli', sans-serif;
                color: #657894;
                -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                width: 100%;
                border: none;
                resize: none;
                text-align: left;
                text-align: left;
                min-height: 75px;
                white-space: pre-wrap;
                "
            >${get(data, "message")}</p>
            <div
                style="
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
                    border-radius: 6px;
                    padding: 30px 10px;
                    justify-items: center;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    margin: 20px 0;
                    background-color: #fff;
                    border: 1px solid #ced4da;
                "
            >
                <h2
                    style="
                        text-transform: uppercase;
                        color: #657894;
                        font-size: 16px;
                        margin-bottom: 0;
                    "
                >
                    ${`${orderTitle}: ${get(process,"data.fields.order_length.value")} SECOND SPOT`}
                </h2>
                <p
                    style="
                        font-size: 25px;
                        font-weight: 600;
                        margin-bottom: 20px;
                        color: #212529;
                        margin-top: 10px;
                    "
                >
                    ${orderTitle}
                </p>
                <div style="text-transform: uppercase;">
                    <strong
                        style="
                            color: #212529;
                        "
                    >
                    ${intl.formatMessage({id: "send mail dialog > studios"})}:
                    </strong>
                    <span style="
                        margin-left: 5px;
                    ">
                        ${studios.join(',')}
                    </span>
                </div>
                ${
                    deadline
                    ? (
                        `
                            <div
                                style="
                                    font-weight: 600;
                                    margin-bottom: 15px;
                                    text-transform: uppercase;
                                    color: #212529;
                                "
                            >
                                ${intl.formatMessage({id: "send mail dialog > deadline"})}: ${deadline}
                            </div> 
                        `
                    ) : ``
                }
                <a href="${get(process, "data.fields.email_link.value", "#")}"
                    style="
                        border-radius: 30px;
                        min-width: 145px;
                        text-transform: uppercase;
                        color: #fff;
                        background: #324856;
                        border-color: #324856;
                        display: inline-block;
                        font-weight: 400;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        border: 1px solid transparent;
                        padding: 0.375rem 0.75rem;
                        font-size: 1rem;
                        line-height: 1.5;
                        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        cursor: pointer;
                        text-decoration: none;
                    "
                >
                ${intl.formatMessage({id: "send mail dialog > review spot"})}
                </a>
            </div>
            <div
                style="
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row;
                    align-items: center;
                "
            >
                <div
                    style="
                        background-color: white;
                        border-radius: 100px;
                        overflow: hidden;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;
                        box-shadow: rgba(0, 0, 0, 0.34) 0px 3px 10px;
                    "
                >
                    <img src="${get(user, "avatar_url", "")}"
                        style="
                            border-radius: 50% !important;
                            width: 60px;
                            height: 60px;
                            border: 1px solid #ced4da;
                            padding: 5px
                        "
                    />
                </div>
                <div
                    style="
                        justify-content: flex-start;
                        flex-direction: column;
                        margin-left: 10px;
                        font-size: 13px;
                        margin-top: 0;
                        width: calc(100% - 80px);
                    "
                >
                    <span style="display:block">${get(user, "name", "")}</span>
                    <span style="display:block; text-transform: capitalize;">${studiosName}</span>
                </div>
            </div>
        </div>
    </div>
    `
}
export default injectIntl(compose(
    connect(
        (state, props) => {
            return{
                subject: get(props, "mail_subject"),  
                to: get(props, "process.data.loaded_fields.contract_client_email.value"),
                message: get(props, "process.data.loaded_fields.email_link.value"),
                process_id: get(props, "process.data.loaded_fields.key.value"),
                user: get(state, "auth.user"),
                userInfo: get(state, "auth.info"),
            }
        },
        {
            sendMail
        }
    ),
    withState('isLoading', 'setIsLoading', false),
    withState('isOpenDialog', 'setIsOpenDialog', false),
    withHandlers({
        toggleOpenDialog: ({isOpenDialog, setIsOpenDialog}) => async() => setIsOpenDialog(!isOpenDialog),
        onFormSubmit: ({ sendMail, setIsLoading, setIsOpenDialog, process, user, userInfo, intl }) => async(data) => {
            setIsLoading(true);
            const {status, message} = await sendMail({
                ...data,
                message: generateEmailHtml(process, user, userInfo, intl, data)
            });
            setIsLoading(false);
            ToastManager.show({
                title: <MdImportantDevices />,
                message,
                autoDismiss: 1.5,
                level: status ? 'success' : 'error'
            });
            if(status){
                setIsOpenDialog(false);
            }
        },
    })
)(View));