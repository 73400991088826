import React from 'react';
import { useSelector } from 'react-redux';
import classes from './EventGuests.module.scss';
import guestIcon from 'assets/img/icons/guest.svg';
import guestIconNotFound from 'assets/img/icons/guest_circle.svg';
import deleteIcon from 'assets/img/icons/delete.svg';

import cs from 'classnames';

import get from 'lodash/get';
import debounce from 'lodash/debounce';
import DropdownAsync from 'components/DropdownAsync';
import GuestOption from './GuestOption';
const EVENT_TYPES = {
  EVENT: {
    value: 'event',
  },
};
const EventGuests = (props) => {
  const users = useSelector(({ auth }) => get(auth, 'info.users', []) || []);

  const courses = useSelector(
    ({ auth }) => get(auth, 'info.courses', []) || []
  );

  const coursesId = useSelector(
    ({ auth }) => get(auth, 'info.courses_ids', []) || []
  );

  const classesOnGoing = [];
  // TODO: need to revist with dev team
  // const classesOnGoing = courses.map((item, index) => {
  //   const id = get(coursesId, index, '');
  //   if (!id) return false;
  //
  //   const name = get(item, 'class_name', '');
  //   const role = {
  //     name: 'Class',
  //   };
  //   return {
  //     id: 0,
  //     name,
  //     role,
  //     avatar_url: null,
  //     classId: id,
  //     className: name,
  //   };
  // });
  const classType = get(props, 'classType', {
    label: 'Curriculum',
    value: 'curriculum',
  }) || { label: 'Curriculum', value: 'curriculum' }; // event value for ad hoc event

  const userId = useSelector(({ auth }) => get(auth, 'user.id', null));

  const { values, step, process, template, user, valueChanged, setFieldValue, classType : ct } =
    props;
  let event_guests = null;

  if (step) event_guests = step.fields.find((f) => f.field === 'event_guests');

  const loadOptions = debounce((keyword, callback) => {
    let data = users.filter((user) => {
      const isSelected = values.event_guests.find(
        (selected) => selected.id === user.id
      );

      if (isSelected) return false;

      if (user.id === userId) return false;

      if (keyword) {
        let name = get(user, 'name', '');
        let role = get(user, 'role.name', '');
        const text = keyword.toLowerCase();
        if (name) name = name.toLowerCase();
        if (role) role = role.toLowerCase();

        if (name && name.indexOf(text) > -1) return true;
        if (role && role.indexOf(text) > -1) return true;

        return false;
      }

      return true;
    });

    if (classType === EVENT_TYPES.EVENT.value) {
      const rsClasses = classesOnGoing.filter((user) => {
        const isSelected = values.event_guests.find(
          (selected) => selected.classId === user.classId
        );

        if (isSelected) return false;

        if (keyword) {
          let name = get(user, 'name', '');
          let role = get(user, 'role.name', '');
          const text = keyword.toLowerCase();
          if (name) name = name.toLowerCase();
          if (role) role = role.toLowerCase();

          if (name && name.indexOf(text) > -1) return true;
          if (role && role.indexOf(text) > -1) return true;

          return false;
        }

        return true;
      });

      data = [...rsClasses, ...data];
    }

    callback(data);
  }, 200);

  return (
    <div className={classes.root}>
      <div className="d-flex align-item-center flex-wrap">
        {ct !== 'event'  && (<img
          src={guestIcon}
          alt="location"
          className={cs('mr-2 mt-2', classes.icon)}SP-2036
        />)}
        <div className={ct !== 'event' ? cs(classes.guests, 'ml-3') : cs(classes.event_guests, 'ml-3') }>
          {ct !== 'event'  && (<DropdownAsync
            key={
              'guests-' +
              JSON.stringify(values.event_guests.map((item) => item.id)) +
              '-' +
              JSON.stringify(users.map((item) => item.id))
            }
            label={null}
            placeholder="Add Students"
            name="event_guests"
            loadOptions={loadOptions}
            filterOption={() => true}
            getOptionValue={(option) => option.name}
            getOptionLabel={(option) => <GuestOption {...option} />}
            onChange={(selectedOption) => {
              const isSelected = values.event_guests.find((item) =>
                item.id === 0 ? false : item.id === selectedOption.id
              );
              let arr = [...values.event_guests];

              if (!isSelected) {
                arr.push(selectedOption);
              }
              setFieldValue('event_guests', arr);

              if (step)
                valueChanged(event_guests, process, template, user, {
                  value: arr,
                });
            }}
            noOptionsMessage={() => {
              return (
                <div className={classes.notFound}>
                  <img src={guestIconNotFound} alt="not found" /> No results
                  found.
                </div>
              );
            }}
            value={null}
            menuPlacement="auto"
          />)}

          {values.event_guests.length > 0 ? (
            <div
              className={'text-attandees can-click'}
              onClick={() => {
                let arr = [];
                setFieldValue('event_guests', arr);

                if (step)
                  valueChanged(event_guests, process, template, user, {
                    value: arr,
                  });
              }}
            >
              Remove All  {ct === 'event' ? 'Attendees' : 'Students'}
            </div>
          ) : null}

          <div className="mt-4" style={{ minHeight: '120px' }}>
            {values.event_guests.map((data, index) => (
              <div
                className="mt-2 d-flex align-items-center justify-content-between"
                key={data.id}
              >
                <GuestOption {...data} />
                <img
                  src={deleteIcon}
                  alt="delete"
                  className="ml-2"
                  onClick={() => {
                    let arr = [...values.event_guests];
                    arr.splice(index, 1);
                    setFieldValue('event_guests', arr);

                    if (step)
                      valueChanged(event_guests, process, template, user, {
                        value: arr,
                      });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default EventGuests;
