import React from 'react';
import classes from './ClassSchedule.module.scss';
import { Container } from 'reactstrap';
import Dropdown from 'components/Dropdown';
import get from 'lodash/get';
import moment from 'moment';
import WeeklyRecurring from './WeeklyRecurring';
import MonthlyRecurring from './MonthlyRecurring';
import cs from 'classnames';
import Alert from 'components/Alert';
import InputNumber from 'components/FormFields/InputNumber';
import DateTimeWeekly from './DateTimeWeekly';
import DateTimeMonthly from './DateTimeMonthly';

const convertFormatDate = 'YYYY-MM-DD HH:mm:ss';

const DateTime = (props) => {
  const { values, touched, errors, setFieldValue, validateDate, validateTime } = props;
  const { step, process, template, user, valueChanged } = props;

  const isAdd = document.location.pathname.split('/')[2] === 'add';

  const recurringType = get(values, 'recurring_type.value');

  let end_date = null;
  let recurring_type = null;
  let recurring_number = null;

  if (step) {
    end_date = step.fields.find((f) => f.field === 'end_date');
    recurring_type = step.fields.find((f) => f.field === 'recurring_type');
    recurring_number = step.fields.find((f) => f.field === 'recurring_number');
  }
  

  const displayConfigRecurring = () => {
    switch (recurringType) {
      case 'Weekly':
        return <WeeklyRecurring {...props} />;

      case 'Monthly':
        return <MonthlyRecurring {...props} />;

      default:
        break;
    }
  };

  return (
    <Container className={classes.root}>
      {values.recurring_type.value === 'Weekly' && (
        <DateTimeWeekly
          {...props}
          validateDate={validateDate}
          validateTime={validateTime}
        />
      )}

      {values.recurring_type.value !== 'Weekly' && (
        <DateTimeMonthly
          {...props}
          validateDate={validateDate}
          validateTime={validateTime}
        />
      )}

      <div className={classes.recurring}>
        <div className={classes.recurringType}>
          <Dropdown
            label={null}
            placeholder={null}
            outline={true}
            name="recurring_type"
            error={touched.recurring_type && errors.recurring_type}
            onChange={(selectedOption) => {
              setFieldValue('recurring_type', selectedOption);
              if (step)
                valueChanged(recurring_type, process, template, user, {
                  value: selectedOption,
                });

              if (isAdd === true) {
                const unit = selectedOption.unit;
                if (unit === 'month' || unit === 'week') {
                  const endtDate = moment(values.start_date, convertFormatDate)
                    .add(1, 'hour')
                    .add(1, unit)
                    .format('YYYY-MM-DD HH:mm:ss');

                  setFieldValue('end_date', endtDate);
                  if (step)
                    valueChanged(end_date, process, template, user, {
                      value: endtDate,
                    });
                }
              }
            }}
            value={values.recurring_type}
            options={[
              {
                value: 'Daily',
                label: 'Daily',
                unit: 'day',
              },
              {
                value: 'Weekly',
                label: 'Weekly',
                unit: 'week',
              },
              {
                value: 'Monthly',
                label: 'Monthly',
                unit: 'month',
              },
            ]}
          />
        </div>
        <span className="ml-2 mr-2">Repeat every</span>
        <InputNumber
          value={values.recurring_number}
          className={classes.recurringNumber}
          error={
            values.recurring_number === 0 || values.recurring_number === ''
              ? true
              : false
          }
          onChange={(number) => {
            let value = number;

            if (value !== '' && value < 0) value = Math.abs(value);

            setFieldValue('recurring_number', value);
            if (step)
              valueChanged(recurring_number, process, template, user, {
                value: value,
              });
          }}
        />
        <span className="ml-2 mr-2">
          {get(values.recurring_type, 'unit')}
          {parseInt(values.recurring_number) > 1 ? 's' : ''}.
        </span>
        {(values.recurring_number === 0 || values.recurring_number === '') && (
          <div className={cs(classes.alert, 'ml-2')}>
            <Alert className="warning" content="Invalid number of intervals." />
          </div>
        )}
      </div>

      {displayConfigRecurring()}
    </Container>
  );
};

export default DateTime;
