import React from 'react';

const ICONS = {
  none: '',
  Calendar: (props) => {
    return (
      <path d="M14.2222 1.8H13.3333V0H11.5556V1.8H4.44444V0H2.66667V1.8H1.77778C0.791111 1.8 0.00888888 2.61 0.00888888 3.6L0 16.2C0 17.19 0.791111 18 1.77778 18H14.2222C15.2 18 16 17.19 16 16.2V3.6C16 2.61 15.2 1.8 14.2222 1.8ZM14.2222 16.2H1.77778V6.3H14.2222V16.2Z" />
    );
  },
  SettingGear: (props) => {
    return (
      <path d="M17.6407 10.98C17.6818 10.66 17.7127 10.34 17.7127 10C17.7127 9.66 17.6818 9.34 17.6407 9.02L19.8099 7.37C20.0052 7.22 20.0566 6.95 19.9333 6.73L17.8772 3.27C17.7538 3.05 17.4762 2.97 17.25 3.05L14.6902 4.05C14.1556 3.65 13.5799 3.32 12.9528 3.07L12.5621 0.42C12.5313 0.18 12.3154 0 12.0584 0H7.94616C7.68915 0 7.47326 0.18 7.44242 0.42L7.05176 3.07C6.42464 3.32 5.84894 3.66 5.31435 4.05L2.7545 3.05C2.51804 2.96 2.25075 3.05 2.12738 3.27L0.0712765 6.73C-0.0623704 6.95 -0.00068705 7.22 0.194643 7.37L2.36384 9.02C2.32271 9.34 2.29187 9.67 2.29187 10C2.29187 10.33 2.32271 10.66 2.36384 10.98L0.194643 12.63C-0.00068705 12.78 -0.0520898 13.05 0.0712765 13.27L2.12738 16.73C2.25075 16.95 2.52832 17.03 2.7545 16.95L5.31435 15.95C5.84894 16.35 6.42464 16.68 7.05176 16.93L7.44242 19.58C7.47326 19.82 7.68915 20 7.94616 20H12.0584C12.3154 20 12.5313 19.82 12.5621 19.58L12.9528 16.93C13.5799 16.68 14.1556 16.34 14.6902 15.95L17.25 16.95C17.4865 17.04 17.7538 16.95 17.8772 16.73L19.9333 13.27C20.0566 13.05 20.0052 12.78 19.8099 12.63L17.6407 10.98ZM10.0023 13.5C8.01813 13.5 6.40408 11.93 6.40408 10C6.40408 8.07 8.01813 6.5 10.0023 6.5C11.9864 6.5 13.6005 8.07 13.6005 10C13.6005 11.93 11.9864 13.5 10.0023 13.5Z" />
    );
  },
  Graduated: (props) => {
    return (
      <path d="M19.4651 3.86733L10.8459 6.30509C10.2936 6.45385 9.70639 6.45385 9.15417 6.30509L2.88746 4.53268C2.61984 4.86033 2.54315 5.19297 2.51502 5.63082C2.80559 5.79118 3.00002 6.08308 3.00002 6.41666C3.00002 6.75904 2.79512 7.05736 2.49174 7.21477L2.9979 10.741C3.01758 10.8781 2.90102 11 2.75002 11H1.25002C1.09915 11 0.982461 10.8782 1.00215 10.7409L1.5083 7.21474C1.20493 7.05736 1.00002 6.75904 1.00002 6.41666C1.00002 6.07194 1.20771 5.77182 1.51455 5.61527C1.54415 5.10104 1.63874 4.66334 1.8943 4.25181L0.534868 3.86733C-0.180007 3.67251 -0.176569 2.74323 0.534868 2.54933L9.15414 0.111568C9.70636 -0.0371896 10.2936 -0.0371896 10.8458 0.111568L19.4651 2.54933C20.18 2.74415 20.1766 3.67342 19.4651 3.86733ZM11.1407 7.18099L11.1343 7.18283L11.1278 7.18457C10.3915 7.38291 9.60854 7.38291 8.87227 7.18457L8.8658 7.18283L8.85933 7.18099L4.14327 5.84695L3.75002 8.25C3.75002 9.26251 6.54824 10.0833 10 10.0833C13.4518 10.0833 16.25 9.26251 16.25 8.25L15.8568 5.84695L11.1407 7.18099Z" />
    );
  },
  Plus: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.5 0C8.32843 0 9 0.223858 9 0.5V14.5C9 14.7761 8.32843 15 7.5 15C6.67157 15 6 14.7761 6 14.5V0.5C6 0.223858 6.67157 0 7.5 0Z"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 7.5C0 6.67157 0.223858 6 0.5 6H14.5C14.7761 6 15 6.67157 15 7.5C15 8.32843 14.7761 9 14.5 9H0.5C0.223858 9 0 8.32843 0 7.5Z"
        />
      </>
    );
  },
  Check: (props) => {
    return (
      <>
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          fill="#EA734F"
          stroke="#EA734F"
        />
        <path
          d="M9.74493 17.5125C9.85493 17.6775 10.0199 17.7325 10.2399 17.7325C10.4324 17.7325 10.5974 17.6775 10.7349 17.5125L18.8474 9.42748C18.9574 9.31748 19.0399 9.15248 19.0399 8.93248C19.0399 8.73998 18.9574 8.57498 18.8474 8.43748L17.8299 7.44748C17.6924 7.30998 17.5274 7.22748 17.3349 7.22748C17.1424 7.22748 16.9774 7.30998 16.8399 7.44748L10.2399 14.0475L7.15993 10.9675C6.99493 10.83 6.82993 10.7475 6.63743 10.7475C6.44493 10.7475 6.27993 10.83 6.16993 10.9675L5.15243 11.9575C5.01493 12.095 4.95993 12.26 4.95993 12.4525C4.95993 12.6725 5.01493 12.8375 5.15243 12.9475L9.74493 17.5125Z"
          fill="white"
        />
      </>
    );
  },
  Circle: (props) => {
    return (
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        stroke={props.color}
      />
    );
  },
  Door: (props) => {
    return (
      <>
        <path
          d="M1 15H3.18724V1H13.0054V15H15"
          stroke="#EA9277"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <line
          x1="1"
          y1="15"
          x2="15"
          y2="15"
          stroke="#EA9277"
          stroke-width="2"
        />
        <circle cx="6" cy="8.5" r="1" fill="#EA9277" />
      </>
    );
  },
  AlignLeft: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 4.72729C0 4.07459 0.44938 3.54547 1.00372 3.54547H10.9963C11.5506 3.54547 12 4.07459 12 4.72729C12 5.37999 11.5506 5.90911 10.9963 5.90911H1.00372C0.44938 5.90911 0 5.37999 0 4.72729Z"
          fill={props.fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 1.18182C0 0.529118 0.453765 0 1.01351 0H13.9865C14.5462 0 15 0.529118 15 1.18182C15 1.83452 14.5462 2.36364 13.9865 2.36364H1.01351C0.453765 2.36364 0 1.83452 0 1.18182Z"
          fill={props.fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 8.2727C0 7.62 0.453765 7.09088 1.01351 7.09088H13.9865C14.5462 7.09088 15 7.62 15 8.2727C15 8.9254 14.5462 9.45452 13.9865 9.45452H1.01351C0.453765 9.45452 0 8.9254 0 8.2727Z"
          fill={props.fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 11.8182C0 11.1655 0.44938 10.6364 1.00372 10.6364H10.9963C11.5506 10.6364 12 11.1655 12 11.8182C12 12.4709 11.5506 13 10.9963 13H1.00372C0.44938 13 0 12.4709 0 11.8182Z"
          fill={props.fill}
        />
      </>
    );
  },
  BulletPoint: (props) => {
    return (
      <>
        <path
          d="M15.4062 0H1.59375C0.713535 0 0 0.7675 0 1.71429V14.2857C0 15.2325 0.713535 16 1.59375 16H15.4062C16.2865 16 17 15.2325 17 14.2857V1.71429C17 0.7675 16.2865 0 15.4062 0ZM15.207 14.2857H1.79297C1.74013 14.2857 1.68946 14.2631 1.6521 14.223C1.61474 14.1828 1.59375 14.1283 1.59375 14.0714V1.92857C1.59375 1.87174 1.61474 1.81724 1.6521 1.77705C1.68946 1.73686 1.74013 1.71429 1.79297 1.71429H15.207C15.2599 1.71429 15.3105 1.73686 15.3479 1.77705C15.3853 1.81724 15.4062 1.87174 15.4062 1.92857V14.0714C15.4062 14.1283 15.3853 14.1828 15.3479 14.223C15.3105 14.2631 15.2599 14.2857 15.207 14.2857ZM13.8125 11V11.8571C13.8125 12.0938 13.6341 12.2857 13.4141 12.2857H6.77344C6.5534 12.2857 6.375 12.0938 6.375 11.8571V11C6.375 10.7633 6.5534 10.5714 6.77344 10.5714H13.4141C13.6341 10.5714 13.8125 10.7633 13.8125 11ZM13.8125 7.57143V8.42857C13.8125 8.66525 13.6341 8.85714 13.4141 8.85714H6.77344C6.5534 8.85714 6.375 8.66525 6.375 8.42857V7.57143C6.375 7.33475 6.5534 7.14286 6.77344 7.14286H13.4141C13.6341 7.14286 13.8125 7.33475 13.8125 7.57143ZM13.8125 4.14286V5C13.8125 5.23668 13.6341 5.42857 13.4141 5.42857H6.77344C6.5534 5.42857 6.375 5.23668 6.375 5V4.14286C6.375 3.90618 6.5534 3.71429 6.77344 3.71429H13.4141C13.6341 3.71429 13.8125 3.90618 13.8125 4.14286ZM5.44531 4.57143C5.44531 5.2815 4.91014 5.85714 4.25 5.85714C3.58986 5.85714 3.05469 5.2815 3.05469 4.57143C3.05469 3.86136 3.58986 3.28571 4.25 3.28571C4.91014 3.28571 5.44531 3.86136 5.44531 4.57143ZM5.44531 8C5.44531 8.71007 4.91014 9.28572 4.25 9.28572C3.58986 9.28572 3.05469 8.71007 3.05469 8C3.05469 7.28993 3.58986 6.71429 4.25 6.71429C4.91014 6.71429 5.44531 7.28993 5.44531 8ZM5.44531 11.4286C5.44531 12.1386 4.91014 12.7143 4.25 12.7143C3.58986 12.7143 3.05469 12.1386 3.05469 11.4286C3.05469 10.7185 3.58986 10.1429 4.25 10.1429C4.91014 10.1429 5.44531 10.7185 5.44531 11.4286Z"
          fill={props.fill}
        />
      </>
    );
  },
  BellAlarm: (props) => {
    return (
      <>
        <path
          d="M17.0921 12.9273C16.4095 12.2716 15.7041 11.5936 15.7041 8.39281C15.7041 5.14961 13.2549 2.45242 10.0603 1.9568C10.2075 1.74888 10.2861 1.50237 10.2857 1.25C10.2857 0.559648 9.71008 0 9.00001 0C8.28993 0 7.71429 0.559648 7.71429 1.25C7.71393 1.50238 7.79253 1.7489 7.93966 1.95684C4.74514 2.45246 2.29593 5.14965 2.29593 8.39285C2.29593 11.5934 1.59076 12.2714 0.908044 12.9271C-1.02438 14.7829 0.390785 17.5 2.68872 17.5H6.42858C6.42858 18.8807 7.57986 20 9.00001 20C10.4202 20 11.5714 18.8807 11.5714 17.5H15.3113C17.6091 17.5 19.0244 14.7819 17.0921 12.9273ZM9.00001 18.4375C8.46829 18.4375 8.03572 18.017 8.03572 17.5H9.96429C9.96429 18.017 9.53173 18.4375 9.00001 18.4375ZM15.3117 15.625H2.68796C2.01448 15.625 1.67807 14.8285 2.15221 14.3675C3.30072 13.2509 4.2245 12.1908 4.2245 8.39285C4.2245 5.83277 6.36679 3.75 9.00001 3.75C11.6332 3.75 13.7755 5.83277 13.7755 8.39285C13.7755 12.2073 14.7085 13.259 15.8474 14.3675C16.3237 14.8305 15.9824 15.625 15.3117 15.625Z"
          fill={props.fill}
        />
      </>
    );
  },
  Warning: (props) => {
    return (
      <>
        <path
          d="M23.7299 18.3339C24.499 19.667 23.5336 21.3333 21.9975 21.3333H2.00226C0.46322 21.3333 -0.497696 19.6644 0.269887 18.3339L10.2676 0.999375C11.0371 -0.334333 12.9643 -0.331916 13.7324 0.999375L23.7299 18.3339ZM12 14.75C10.9415 14.75 10.0833 15.6081 10.0833 16.6667C10.0833 17.7252 10.9415 18.5833 12 18.5833C13.0585 18.5833 13.9167 17.7252 13.9167 16.6667C13.9167 15.6081 13.0585 14.75 12 14.75ZM10.1803 7.86058L10.4894 13.5272C10.5038 13.7924 10.7231 14 10.9886 14H13.0114C13.2769 14 13.4962 13.7924 13.5106 13.5272L13.8197 7.86058C13.8353 7.57417 13.6073 7.33333 13.3205 7.33333H10.6795C10.3927 7.33333 10.1647 7.57417 10.1803 7.86058Z"
          fill="#EA734F"
        />
      </>
    );
  },
  Home: (props) => {
    return (
      <>
        <path
          d="M16.9441 10.0203V15.1422C16.9441 15.6176 16.5726 16 16.1107 16H12.0825C11.8533 16 11.6658 15.807 11.6658 15.5711V11.568C11.6658 11.3321 11.4783 11.1391 11.2491 11.1391H8.74884C8.51965 11.1391 8.33213 11.3321 8.33213 11.568V15.5711C8.33213 15.807 8.14461 16 7.91542 16H3.88723C3.42538 16 3.05381 15.6176 3.05381 15.1422V10.0203C3.05381 9.89166 3.10937 9.77013 3.2066 9.68793L9.73505 4.15503C9.88784 4.02636 10.1101 4.02636 10.2664 4.15503L16.7948 9.68793C16.8886 9.77013 16.9441 9.89166 16.9441 10.0203ZM19.8472 7.84363L16.9441 5.38099V0.430694C16.9441 0.194795 16.7566 0.00178716 16.5274 0.00178716H14.5828C14.3536 0.00178716 14.1661 0.194795 14.1661 0.430694V3.02558L11.0581 0.391377C10.4435 -0.130459 9.55448 -0.130459 8.93983 0.391377L0.150735 7.84363C-0.026367 7.99374 -0.050675 8.26539 0.0951733 8.44767L0.980681 9.55568C1.12653 9.73796 1.39044 9.76298 1.56755 9.61287L9.73505 2.6896C9.88784 2.56093 10.1101 2.56093 10.2664 2.6896L18.4339 9.61287C18.611 9.76298 18.8749 9.73796 19.0207 9.55568L19.9062 8.44767C20.0521 8.26181 20.0243 7.99374 19.8472 7.84363Z"
          fill={props.fill}
        />
      </>
    );
  },
  ShoppingCart: (props) => {
    return (
      <>
        <path
          d="M18.3375 10.0047L19.9789 3.09848C20.0974 2.59984 19.7011 2.125 19.1663 2.125H5.52806L5.20979 0.637168C5.13049 0.266322 4.78924 0 4.39337 0H0.833334C0.37309 0 0 0.356768 0 0.796875V1.32812C0 1.76823 0.37309 2.125 0.833334 2.125H3.25983L5.69899 13.5281C5.11545 13.849 4.72222 14.4507 4.72222 15.1406C4.72222 16.1675 5.59278 17 6.66667 17C7.74056 17 8.61111 16.1675 8.61111 15.1406C8.61111 14.6202 8.38726 14.15 8.02695 13.8125H15.3064C14.9461 14.15 14.7222 14.6202 14.7222 15.1406C14.7222 16.1675 15.5928 17 16.6667 17C17.7406 17 18.6111 16.1675 18.6111 15.1406C18.6111 14.4044 18.1636 13.7683 17.5146 13.467L17.7062 12.661C17.8247 12.1623 17.4283 11.6875 16.8936 11.6875H7.57351L7.34625 10.625H17.5249C17.914 10.625 18.2513 10.3675 18.3375 10.0047Z"
          fill={props.fill}
        />
      </>
    );
  },
  Number: (props) => {
    return (
      <>
        <rect width="16" height="16" rx="8" fill={props.color} />
        <text x="6" y="12" fill="white">
          {props.value}
        </text>
      </>
    );
  },
  InfoFill: (props) => {
    return (
      <>
        <rect width="20" height="20" rx="10" fill={props.fill} />
        <path
          d="M8.69565 4.7496C8.69565 4.37535 8.81559 4.07266 9.05547 3.84151C9.29535 3.59935 9.61019 3.47827 10 3.47827C10.3898 3.47827 10.7046 3.59935 10.9445 3.84151C11.1844 4.07266 11.3043 4.37535 11.3043 4.7496C11.3043 5.11283 11.1844 5.41553 10.9445 5.65769C10.7046 5.88884 10.3898 6.00441 10 6.00441C9.61019 6.00441 9.29535 5.88884 9.05547 5.65769C8.81559 5.41553 8.69565 5.11283 8.69565 4.7496ZM11.0495 7.3748V16.5217H8.93553V7.3748H11.0495Z"
          fill="white"
        />
      </>
    );
  },
  ClockTime: (props) => {
    return (
      <path
        d="M10 0C4.47581 0 0 4.47581 0 10C0 15.5242 4.47581 20 10 20C15.5242 20 20 15.5242 20 10C20 4.47581 15.5242 0 10 0ZM10 18.0645C5.54435 18.0645 1.93548 14.4556 1.93548 10C1.93548 5.54435 5.54435 1.93548 10 1.93548C14.4556 1.93548 18.0645 5.54435 18.0645 10C18.0645 14.4556 14.4556 18.0645 10 18.0645ZM12.4919 13.8548L9.06855 11.3669C8.94355 11.2742 8.87097 11.129 8.87097 10.9758V4.35484C8.87097 4.08871 9.08871 3.87097 9.35484 3.87097H10.6452C10.9113 3.87097 11.129 4.08871 11.129 4.35484V10.0685L13.8226 12.0282C14.0403 12.1855 14.0847 12.4879 13.9274 12.7056L13.1694 13.75C13.0121 13.9637 12.7097 14.0121 12.4919 13.8548Z"
        fill="#EA9277"
      />
    );
  },
  LevelBanner: (props) => {
    return (
      <>
        <path
          d="M1 1H15V15.382L8 18.882L1 15.382V1Z"
          stroke="#EA9277"
          stroke-width="2"
        />
      </>
    );
  },
  MapPin: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 2C7.15083 2 5.37273 2.75107 4.05815 4.09552C2.74283 5.44073 2 7.26992 2 9.18182C2 11.9701 3.77507 14.6834 5.73362 16.7977C6.69377 17.8342 7.65716 18.683 8.38148 19.2729C8.61647 19.4643 8.82547 19.6277 9 19.7608C9.17453 19.6277 9.38353 19.4643 9.61852 19.2729C10.3428 18.683 11.3062 17.8342 12.2664 16.7977C14.2249 14.6834 16 11.9701 16 9.18182C16 7.26992 15.2572 5.44073 13.9418 4.09552C12.6273 2.75107 10.8492 2 9 2ZM9 21C8.43666 21.8262 8.43613 21.8259 8.43613 21.8259L8.43344 21.824L8.42732 21.8198L8.40654 21.8055C8.38896 21.7932 8.364 21.7758 8.33214 21.7532C8.26843 21.7081 8.17711 21.6425 8.06212 21.5576C7.83222 21.3878 7.50713 21.1402 7.11852 20.8237C6.34284 20.192 5.30623 19.2794 4.26638 18.1568C2.22493 15.953 0 12.7572 0 9.18182C5.96046e-08 6.75381 0.942878 4.42084 2.62814 2.69728C4.31415 0.972956 6.60571 0 9 0C11.3943 0 13.6859 0.972956 15.3719 2.69728C17.0571 4.42084 18 6.75381 18 9.18182C18 12.7572 15.7751 15.953 13.7336 18.1568C12.6938 19.2794 11.6572 20.192 10.8815 20.8237C10.4929 21.1402 10.1678 21.3878 9.93788 21.5576C9.82289 21.6425 9.73157 21.7081 9.66786 21.7532C9.636 21.7758 9.61104 21.7932 9.59346 21.8055L9.57268 21.8198L9.56656 21.824L9.56458 21.8254C9.56458 21.8254 9.56334 21.8262 9 21ZM9 21L9.56334 21.8262C9.22351 22.0579 8.77596 22.0576 8.43613 21.8259L9 21Z"
          fill="#EA9277"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 7.45453C8.10059 7.45453 7.33333 8.20655 7.33333 9.1818C7.33333 10.1571 8.10059 10.9091 9 10.9091C9.89941 10.9091 10.6667 10.1571 10.6667 9.1818C10.6667 8.20655 9.89941 7.45453 9 7.45453ZM5.33333 9.1818C5.33333 7.14459 6.95389 5.45453 9 5.45453C11.0461 5.45453 12.6667 7.14459 12.6667 9.1818C12.6667 11.219 11.0461 12.9091 9 12.9091C6.95389 12.9091 5.33333 11.219 5.33333 9.1818Z"
          fill="#EA9277"
        />
      </>
    );
  },
  ChartLineGraph: (props) => {
    return (
      <>
        <path
          d="M27.3438 17.5C27.7047 17.5 28 17.7953 28 18.1562V20.3438C28 20.7047 27.7047 21 27.3438 21H0.65625C0.295312 21 0 20.7047 0 20.3438V0.65625C0 0.295312 0.295312 0 0.65625 0H2.84375C3.20469 0 3.5 0.295312 3.5 0.65625V17.5H27.3438ZM24.9375 1.75H18.8125C17.6422 1.75 17.057 3.16641 17.8828 3.99219L19.682 5.79141L15.7445 9.77812L12.7039 6.7375C12.4469 6.48047 12.0367 6.48047 11.7797 6.7375L5.27187 13.1797C5.01484 13.4312 5.00937 13.8469 5.26094 14.1039L6.81953 15.7117C7.07656 15.9742 7.49766 15.9797 7.75469 15.7172L12.2445 11.2273L15.2797 14.2625C15.5367 14.5195 15.9523 14.5195 16.2094 14.2625L22.1812 8.29063L24.0078 10.1172C24.8336 10.943 26.25 10.3578 26.25 9.1875V3.0625C26.25 2.33516 25.6648 1.75 24.9375 1.75Z"
          fill={props.fill}
        />
      </>
    );
  },
  LightBuldIdea: (props) => {
    return (
      <>
        <path
          d="M12.3636 20.8984V22.2656C12.3636 22.7758 12.041 23.2097 11.5909 23.3706V23.8281C11.5909 24.4753 11.072 25 10.4318 25H6.56818C5.92803 25 5.40909 24.4753 5.40909 23.8281V23.3706C4.95898 23.2097 4.63636 22.7758 4.63636 22.2656V20.8984C4.63636 20.5749 4.89585 20.3125 5.21591 20.3125H11.7841C12.1041 20.3125 12.3636 20.5749 12.3636 20.8984ZM5.45222 18.75C4.97376 18.75 4.54301 18.4532 4.37156 18.0016C2.49673 13.0635 0 13.5611 0 8.59375C0 3.8478 3.80592 0 8.5 0C13.1941 0 17 3.8478 17 8.59375C17 13.5611 14.5033 13.0635 12.6285 18.0016C12.457 18.4532 12.0262 18.75 11.5478 18.75H5.45222ZM4.63636 8.59375C4.63636 6.43984 6.36959 4.6875 8.5 4.6875C8.92679 4.6875 9.27273 4.3377 9.27273 3.90625C9.27273 3.4748 8.92679 3.125 8.5 3.125C5.51742 3.125 3.09091 5.57827 3.09091 8.59375C3.09091 9.0252 3.4369 9.375 3.86364 9.375C4.29037 9.375 4.63636 9.0252 4.63636 8.59375Z"
          fill={props.fill}
        />
      </>
    );
  },
  QuestionMark: (props) => {
    return (
      <>
        <path
          d="M6.01606 0C3.29423 0 1.53129 1.08584 0.147202 3.02235C-0.103876 3.37364 -0.0264694 3.85528 0.323772 4.11387L1.79478 5.19991C2.1485 5.46105 2.65178 5.39999 2.92871 5.06218C3.78288 4.02023 4.41649 3.42032 5.75072 3.42032C6.79978 3.42032 8.09735 4.07771 8.09735 5.06822C8.09735 5.81702 7.46251 6.20158 6.42669 6.76703C5.21876 7.42644 3.62029 8.24709 3.62029 10.3V10.625C3.62029 11.0651 3.9867 11.4219 4.43869 11.4219H6.90996C7.36195 11.4219 7.72836 11.0651 7.72836 10.625V10.4333C7.72836 9.01023 12 8.95096 12 5.1C12 2.19991 8.9105 0 6.01606 0ZM5.67434 12.4C4.37186 12.4 3.3122 13.4318 3.3122 14.7C3.3122 15.9682 4.37186 17 5.67434 17C6.97683 17 8.03648 15.9682 8.03648 14.7C8.03648 13.4318 6.97683 12.4 5.67434 12.4Z"
          fill={props.color}
        />
      </>
    );
  },
  QuestionMarkFill: (props) => {
    return (
      <>
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          fill="#515254"
          stroke="#515254"
        />
        <path
          d="M12.0107 6C10.1962 6 9.02086 6.76648 8.09813 8.13342C7.93075 8.38139 7.98235 8.72137 8.21585 8.90391L9.19652 9.67052C9.43233 9.85486 9.76785 9.81176 9.95247 9.5733C10.5219 8.83781 10.9443 8.41434 11.8338 8.41434C12.5332 8.41434 13.3982 8.87838 13.3982 9.57757C13.3982 10.1061 12.975 10.3776 12.2845 10.7767C11.4792 11.2422 10.4135 11.8215 10.4135 13.2706V13.5C10.4135 13.8107 10.6578 14.0625 10.9591 14.0625H12.6066C12.908 14.0625 13.1522 13.8107 13.1522 13.5V13.3647C13.1522 12.3602 16 12.3183 16 9.6C16 7.55288 13.9403 6 12.0107 6ZM11.7829 14.7529C10.9146 14.7529 10.2081 15.4813 10.2081 16.3765C10.2081 17.2717 10.9146 18 11.7829 18C12.6512 18 13.3577 17.2717 13.3577 16.3765C13.3577 15.4812 12.6512 14.7529 11.7829 14.7529Z"
          fill="white"
        />
      </>
    );
  },
  UserProfileAvatar: (props) => {
    return (
      <>
        <path
          d="M8.5 0C11.434 0 13.8125 2.37847 13.8125 5.3125C13.8125 8.24653 11.434 10.625 8.5 10.625C5.56597 10.625 3.1875 8.24653 3.1875 5.3125C3.1875 2.37847 5.56597 0 8.5 0ZM14.5856 11.0839L12.2178 10.492C9.73008 12.2813 6.71473 11.882 4.78225 10.492L2.41443 11.0839C0.995463 11.4386 0 12.7136 0 14.1762V15.4062C0 16.2865 0.713535 17 1.59375 17H15.4062C16.2865 17 17 16.2865 17 15.4062V14.1762C17 12.7136 16.0045 11.4386 14.5856 11.0839Z"
          fill={props.fill}
        />
      </>
    );
  },
  LockSecurity: (props) => {
    return (
      <>
        <path
          d="M12.5 7H11.75V4.75C11.75 2.13125 9.61875 0 7 0C4.38125 0 2.25 2.13125 2.25 4.75V7H1.5C0.671875 7 0 7.67188 0 8.5V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V8.5C14 7.67188 13.3281 7 12.5 7ZM9.25 7H4.75V4.75C4.75 3.50938 5.75938 2.5 7 2.5C8.24063 2.5 9.25 3.50938 9.25 4.75V7Z"
          fill={props.fill}
        />
      </>
    );
  },
  DollarSignMoney: (props) => {
    return (
      <>
        <path
          d="M3.22265 5.62378C3.22265 4.84892 4.01261 4.34821 5.23516 4.34821C6.2155 4.34821 7.22019 4.75538 8.03983 5.28896C8.25552 5.42938 8.55814 5.36712 8.68451 5.15558L9.54842 3.70962C9.64559 3.54699 9.60696 3.34468 9.45397 3.2222C8.56331 2.50909 7.28483 2.0903 6.10538 1.96692V0.398438C6.10538 0.1784 5.90829 0 5.66521 0H4.19796C3.95488 0 3.75779 0.1784 3.75779 0.398438V2.05302C1.60728 2.49429 0.18875 3.90963 0.18875 5.66017C0.18875 9.76185 6.96614 9.33941 6.96614 11.2852C6.96614 11.9434 6.36783 12.6518 5.05417 12.6518C3.92619 12.6518 2.74576 12.1246 1.83181 11.4603C1.63703 11.3187 1.35195 11.3502 1.2024 11.5313L0.0876933 12.8816C-0.0453492 13.0428 -0.0251012 13.2686 0.135452 13.4078C1.0176 14.1721 2.30877 14.7775 3.75775 15.0006V16.6016C3.75775 16.8216 3.95484 17 4.19792 17H5.66517C5.90826 17 6.10534 16.8216 6.10534 16.6016V15.0265C8.51603 14.6873 10 13.0613 10 11.1578C10 7.18376 3.22265 7.71438 3.22265 5.62378Z"
          fill={props.fill}
        />
      </>
    );
  },
  UserProfileAvatars: (props) => {
    return (
      <>
        <path
          d="M10 0C11.8122 0 13.2812 1.46906 13.2812 3.28125C13.2812 5.09344 11.8122 6.5625 10 6.5625C8.18781 6.5625 6.71875 5.09344 6.71875 3.28125C6.71875 1.46906 8.18781 0 10 0ZM13.5457 6.79269L12.2963 6.48031C10.7598 7.58547 8.89734 7.33884 7.70375 6.48031L6.45431 6.79269C5.45266 7.04309 4.75 7.94303 4.75 8.9755V10.875C4.75 11.4963 5.25369 12 5.875 12H14.125C14.7463 12 15.25 11.4963 15.25 10.875V8.9755C15.25 7.94303 14.5473 7.04309 13.5457 6.79269ZM16.5 7.375C17.7081 7.375 18.6875 6.39563 18.6875 5.1875C18.6875 3.97937 17.7081 3 16.5 3C15.2919 3 14.3125 3.97937 14.3125 5.1875C14.3125 6.39563 15.2919 7.375 16.5 7.375ZM3.5 7.375C4.70813 7.375 5.6875 6.39563 5.6875 5.1875C5.6875 3.97937 4.70813 3 3.5 3C2.29187 3 1.3125 3.97937 1.3125 5.1875C1.3125 6.39563 2.29187 7.375 3.5 7.375ZM4.25 10.875V8.9755C4.25 8.45828 4.39353 7.96913 4.64697 7.55053C3.70984 8.01053 2.66772 7.82266 1.96916 7.32022L1.13619 7.52847C0.468438 7.69538 0 8.29537 0 8.98366V10.25C0 10.6642 0.335781 11 0.75 11H4.25528C4.25193 10.9584 4.25017 10.9167 4.25 10.875ZM18.8638 7.52844L18.0308 7.32019C17.1582 7.94788 16.128 7.92122 15.35 7.54575C15.6053 7.96541 15.75 8.45628 15.75 8.9755V10.875C15.75 10.9171 15.7479 10.9587 15.7447 11H19.25C19.6642 11 20 10.6642 20 10.25V8.98366C20 8.29537 19.5316 7.69537 18.8638 7.52844Z"
          fill={props.fill}
        />
      </>
    );
  },
  BellAlarmNotification: (props) => {
    return (
      <>
        <path
          d="M13.5589 11.4393C12.8636 10.744 12 9.87869 12 6.5C12 4.01894 10.1928 1.96034 7.82303 1.56781C7.93852 1.40101 8.00027 1.20289 8 1C8 0.447719 7.55228 0 7 0C6.44772 0 6 0.447719 6 1C6 1.21094 6.06547 1.4065 6.17697 1.56781C3.80722 1.96034 2 4.01894 2 6.5C2 9.87869 1.13615 10.744 0.44084 11.4393C-0.501316 12.3815 0.166403 14 1.5015 14H5C5 15.1046 5.89544 16 7 16C8.10456 16 9 15.1046 9 14H12.4982C13.8307 14 14.5029 12.3834 13.5589 11.4393ZM7 14.75C7.0663 14.75 7.12989 14.7763 7.17678 14.8232C7.22366 14.8701 7.25 14.9337 7.25 15C7.25 15.0663 7.22366 15.1299 7.17678 15.1768C7.12989 15.2237 7.0663 15.25 7 15.25C6.31075 15.25 5.75 14.6892 5.75 14H6.25C6.25 14.4136 6.58644 14.75 7 14.75Z"
          fill={props.fill}
        />
      </>
    );
  },
  Logout: (props) => {
    return (
      <>
        <path
          d="M19.7232 8.70777L13.0562 15.7066C12.4609 16.3315 11.4291 15.8941 11.4291 14.9984V10.9991H6.03205C5.50424 10.9991 5.07962 10.5533 5.07962 9.99923V5.99989C5.07962 5.44582 5.50424 5.00006 6.03205 5.00006H11.4291V1.00072C11.4291 0.109201 12.457 -0.332392 13.0562 0.292504L19.7232 7.29134C20.0923 7.68294 20.0923 8.31617 19.7232 8.70777ZM7.61943 15.4983V13.8319C7.61943 13.557 7.40513 13.332 7.14321 13.332H3.80971C3.1073 13.332 2.53981 12.7363 2.53981 11.9989V4.00022C2.53981 3.26284 3.1073 2.66711 3.80971 2.66711H7.14321C7.40513 2.66711 7.61943 2.44215 7.61943 2.16719V0.500803C7.61943 0.225849 7.40513 0.000886047 7.14321 0.000886047H3.80971C1.70643 0.000886047 0 1.79226 0 4.00022V11.9989C0 14.2069 1.70643 15.9982 3.80971 15.9982H7.14321C7.40513 15.9982 7.61943 15.7733 7.61943 15.4983Z"
          fill={props.fill}
        />
      </>
    );
  },
  Info: (props) => {
    return (
      <>
        <path
          d="M0.729167 14.0857H1.45833V9.28927H0.729167C0.326448 9.28927 0 8.99197 0 8.62521V7.03906C0 6.6723 0.326448 6.375 0.729167 6.375H4.8125C5.21522 6.375 5.54167 6.6723 5.54167 7.03906V14.0857H6.27083C6.67355 14.0857 7 14.383 7 14.7498V16.3359C7 16.7027 6.67355 17 6.27083 17H0.729167C0.326448 17 0 16.7027 0 16.3359V14.7498C0 14.383 0.326448 14.0857 0.729167 14.0857ZM3.5 0C2.05023 0 0.875 1.0703 0.875 2.39062C0.875 3.71095 2.05023 4.78125 3.5 4.78125C4.94977 4.78125 6.125 3.71095 6.125 2.39062C6.125 1.0703 4.94973 0 3.5 0Z"
          fill={props.fill}
        />
      </>
    );
  },
  EmailEnvelopeClosed: (props) => {
    return (
      <>
        <path
          d="M20.6021 5.28333C20.7621 5.15417 21 5.275 21 5.47917V14C21 15.1042 20.1182 16 19.0312 16H1.96875C0.881836 16 0 15.1042 0 14V5.48333C0 5.275 0.233789 5.15833 0.397852 5.2875C1.3166 6.0125 2.53477 6.93333 6.71836 10.0208C7.58379 10.6625 9.04395 12.0125 10.5 12.0042C11.9643 12.0167 13.4531 10.6375 14.2857 10.0208C18.4693 6.93333 19.6834 6.00833 20.6021 5.28333ZM10.5 10.6667C11.4516 10.6833 12.8215 9.45 13.5105 8.94167C18.9533 4.92917 19.3676 4.57917 20.6227 3.57917C20.8605 3.39167 21 3.1 21 2.79167V2C21 0.895833 20.1182 0 19.0312 0H1.96875C0.881836 0 0 0.895833 0 2V2.79167C0 3.1 0.139453 3.3875 0.377344 3.57917C1.63242 4.575 2.04668 4.92917 7.48945 8.94167C8.17852 9.45 9.54844 10.6833 10.5 10.6667Z"
          fill={props.fill}
        />
      </>
    );
  },
  UserProfilePlus: (props) => {
    return (
      <>
        <path
          d="M8.75 0C11.7703 0 14.2188 2.37848 14.2188 5.3125C14.2188 8.24652 11.7703 10.625 8.75 10.625C5.72969 10.625 3.28125 8.24652 3.28125 5.3125C3.28125 2.37848 5.72969 0 8.75 0ZM15.0146 11.0839L12.5771 10.4919C10.0163 12.2812 6.91223 11.8819 4.92289 10.4919L2.48543 11.0839C1.02473 11.4386 0 12.7136 0 14.1762V15.4062C0 16.2865 0.734531 17 1.64062 17H15.8594C16.7655 17 17.5 16.2865 17.5 15.4062V14.1762C17.5 12.7136 16.4753 11.4386 15.0146 11.0839ZM24.5312 7.31945H21.8403V4.70536C21.8403 4.45389 21.6304 4.25 21.3715 4.25H19.8785C19.6196 4.25 19.4097 4.45389 19.4097 4.70536L19.4096 7.31937L16.7188 7.31945C16.4599 7.31945 16.25 7.52334 16.25 7.77481V9.22519C16.25 9.47666 16.4599 9.68055 16.7188 9.68055L19.4096 9.68063L19.4097 12.2946C19.4097 12.5461 19.6196 12.75 19.8785 12.75H21.3715C21.6304 12.75 21.8403 12.5461 21.8403 12.2946L21.8404 9.68063L24.5312 9.68055C24.7901 9.68055 25 9.47666 25 9.22519V7.77481C25 7.52334 24.7901 7.31945 24.5312 7.31945Z"
          fill={props.fill}
        />
      </>
    );
  },
  NumberLarge: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
          fill={props.fill}
        />
        <text x="10" y="20.5" fill="white">
          {props.value}
        </text>
      </>
    );
  },
  ImageMedia: (props) => {
    return (
      <>
        <path
          d="M16.6667 14.5714V15.1786C16.6667 16.1845 15.9205 17 15 17H1.66667C0.746181 17 0 16.1845 0 15.1786V5.46429C0 4.45833 0.746181 3.64286 1.66667 3.64286H2.22222V11.5357C2.22222 13.2096 3.46833 14.5714 5 14.5714H16.6667ZM20 11.5357V1.82143C20 0.815469 19.2538 0 18.3333 0H5C4.07951 0 3.33333 0.815469 3.33333 1.82143V11.5357C3.33333 12.5417 4.07951 13.3571 5 13.3571H18.3333C19.2538 13.3571 20 12.5417 20 11.5357ZM8.88889 3.64286C8.88889 4.64882 8.14271 5.46429 7.22222 5.46429C6.30174 5.46429 5.55556 4.64882 5.55556 3.64286C5.55556 2.6369 6.30174 1.82143 7.22222 1.82143C8.14271 1.82143 8.88889 2.6369 8.88889 3.64286ZM5.55556 9.10714L7.48316 7.00055C7.64587 6.82273 7.90969 6.82273 8.07243 7.00055L9.44444 8.5L14.1498 3.35769C14.3125 3.17987 14.5764 3.17987 14.7391 3.35769L17.7778 6.67857V10.9286H5.55556V9.10714Z"
          fill={props.fill}
        />
      </>
    );
  },
  PlayTriangle: (props) => {
    return (
      <>
        <path
          d="M17.0523 8.38513L2.90901 0.25655C1.75987 -0.403579 0 0.23702 0 1.86977V18.123C0 19.5878 1.63531 20.4706 2.90901 19.7362L17.0523 11.6116C18.3139 10.8889 18.3179 9.10775 17.0523 8.38513Z"
          fill="#EA734F"
        />
      </>
    );
  },
  EditCreateNew: (props) => {
    return (
      <>
        <g clip-path="url(#clip0)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 1.05882C0 0.474051 0.460883 0 1.02941 0H11.3235C11.8921 0 12.3529 0.474051 12.3529 1.05882C12.3529 1.6436 11.8921 2.11765 11.3235 2.11765H2.05882V15.8824H15.4412V10.5882C15.4412 10.0035 15.9021 9.52941 16.4706 9.52941C17.0391 9.52941 17.5 10.0035 17.5 10.5882V16.9412C17.5 17.5259 17.0391 18 16.4706 18H1.02941C0.460883 18 0 17.5259 0 16.9412V1.05882Z"
            fill={props.fill}
          />
          <path
            d="M19.4545 4.82821L17.9584 6.02602C17.8059 6.14814 17.5865 6.12003 17.4678 5.96314L14.6638 2.25796C14.5451 2.10107 14.5724 1.87549 14.725 1.75337L16.221 0.555569C16.8279 0.0696922 17.7052 0.182131 18.1804 0.806708L19.6986 2.81285C20.1739 3.43743 20.0646 4.33974 19.4545 4.82821ZM13.4398 2.78229L4.91766 9.60535L3.86899 13.1332C3.72633 13.6093 4.08793 14.0836 4.57125 14.0612L8.15398 13.8784L16.6761 7.0553C16.8287 6.93319 16.856 6.70761 16.7373 6.55072L13.9333 2.84554C13.8117 2.68828 13.5923 2.66017 13.4398 2.78229ZM7.95642 9.31663C7.81748 9.13304 7.84913 8.87185 8.02762 8.72894L13.0254 4.7276C13.2039 4.5847 13.4578 4.61725 13.5967 4.80084C13.7357 4.98443 13.704 5.24562 13.5256 5.38853L8.52779 9.38987C8.3493 9.53277 8.09536 9.50022 7.95642 9.31663ZM6.61226 11.6793L7.9974 11.8569L7.86687 12.9343L5.96496 13.0311L5.17933 11.993L5.73736 10.1203L6.78487 10.2546L6.61226 11.6793Z"
            fill={props.fill}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path d="M0 0H20V18H0V0Z" fill="white" />
          </clipPath>
        </defs>
      </>
    );
  },
  Feed: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 3C0 1.34315 1.34315 0 3 0H18C19.6569 0 21 1.34315 21 3V21C21 22.6569 19.6569 24 18 24H3C1.34315 24 0 22.6569 0 21V3ZM9 9C9 8.44772 9.44771 8 10 8L18 8C18.5523 8 19 8.44771 19 9C19 9.55228 18.5523 10 18 10L10 10C9.44771 10 9 9.55229 9 9ZM10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44771 7 10 7L18 7C18.5523 7 19 6.55229 19 6C19 5.44772 18.5523 5 18 5L10 5ZM2 7.5C2 6.11929 3.11929 5 4.5 5C5.88071 5 7 6.11929 7 7.5C7 8.88071 5.88071 10 4.5 10C3.11929 10 2 8.88071 2 7.5ZM10 17C9.44771 17 9 17.4477 9 18C9 18.5523 9.44771 19 10 19L18 19C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17L10 17ZM9 15C9 14.4477 9.44772 14 10 14L18 14C18.5523 14 19 14.4477 19 15C19 15.5523 18.5523 16 18 16L10 16C9.44771 16 9 15.5523 9 15ZM4.5 14C3.11929 14 2 15.1193 2 16.5C2 17.8807 3.11929 19 4.5 19C5.88071 19 7 17.8807 7 16.5C7 15.1193 5.88071 14 4.5 14Z"
          fill="white"
        />
      </>
    );
  },
  UploadDragDrop: (props) => {
    return (
      <>
        <path
          d="M36.375 11.25C35.0625 4.875 29.4375 0 22.5 0C17.0625 0 12.375 3 10.125 7.5C4.3125 8.25 0 12.9375 0 18.75C0 24.9375 5.0625 30 11.25 30H35.625C40.875 30 45 25.875 45 20.625C45 15.75 41.0625 11.625 36.375 11.25ZM26.25 16.875V24.375H18.75V16.875H13.125L22.5 7.5L31.875 16.875H26.25Z"
          fill={props.color}
        />
      </>
    );
  },
  NumberMedium: (props) => {
    return (
      <>
        <rect width="24" height="24" rx="12" fill={props.color} />
        <text x="10" y="16" fill="white">
          {props.value}
        </text>
      </>
    );
  },
  EyeHidden: (props) => {
    return (
      <>
        <path
          d="M4.97732 6.89036L5.54292 7.70848C5.36245 7.72571 5.18128 7.73435 5.00001 7.73439C2.91997 7.73439 1.0943 6.61029 0.11539 4.92837C0.0398431 4.79857 0 4.65066 0 4.5C0 4.34934 0.0398431 4.20143 0.11539 4.07163C0.532456 3.35504 1.10346 2.73964 1.78247 2.26922L2.77339 3.70252C2.6864 3.95192 2.6389 4.22029 2.6389 4.50001C2.6389 5.81373 3.68291 6.87802 4.97732 6.89036ZM9.88462 4.92837C9.33485 5.87297 8.51749 6.64154 7.53784 7.13399L7.53803 7.13426L8.25799 8.17488C8.38994 8.36578 8.3441 8.62875 8.15556 8.76235L7.92796 8.92371C7.73942 9.05731 7.47969 9.0109 7.34775 8.82L1.74202 0.825116C1.61008 0.634217 1.65591 0.371248 1.84445 0.237654L2.07206 0.0762861C2.2606 -0.057308 2.52032 -0.0109016 2.65226 0.179997L3.53834 1.45647C4.00487 1.332 4.49489 1.26563 5.00001 1.26563C7.08004 1.26563 8.90572 2.38973 9.88462 4.07165C9.96016 4.20144 10 4.34935 10 4.50001C10 4.65066 9.96016 4.79857 9.88462 4.92837ZM7.36112 4.50001C7.36112 3.17859 6.3049 2.10938 5.00001 2.10938C4.68857 2.10938 4.3914 2.17043 4.1193 2.28115L4.45435 2.75678C4.90346 2.61315 5.40997 2.64845 5.85018 2.88704H5.84966C5.43907 2.88704 5.10626 3.22401 5.10626 3.63973C5.10626 4.05503 5.43867 4.39243 5.84966 4.39243C6.26025 4.39243 6.59306 4.05546 6.59306 3.63973V3.63921C6.91358 4.24548 6.87626 5.00879 6.44983 5.5895V5.58968L6.78515 6.06536C7.14403 5.64611 7.36112 5.09897 7.36112 4.50001ZM4.54886 6.27061L3.20285 4.3237C3.11581 5.24457 3.71178 6.05258 4.54886 6.27061Z"
          fill={props.fill}
        />
      </>
    );
  },
  EyeShow: (props) => {
    return (
      <>
        <path d="M10.8731 3.03646C9.79628 1.21642 7.78803 0 5.5 0C3.21101 0 1.20321 1.21728 0.126929 3.03646C0.0438273 3.17692 0 3.33698 0 3.50001C0 3.66304 0.0438273 3.8231 0.126929 3.96356C1.20372 5.78358 3.21197 7 5.5 7C7.78899 7 9.79679 5.78272 10.8731 3.96354C10.9562 3.82309 11 3.66303 11 3.5C11 3.33697 10.9562 3.17691 10.8731 3.03646ZM5.5 6.08696C4.06462 6.08696 2.90278 4.92992 2.90278 3.5C2.90278 2.07029 4.06439 0.913043 5.5 0.913043C6.93538 0.913043 8.09722 2.07006 8.09722 3.5C8.09722 4.92971 6.93561 6.08696 5.5 6.08696ZM7.48611 3.5C7.48611 4.59257 6.5969 5.47826 5.5 5.47826C4.4031 5.47826 3.51389 4.59257 3.51389 3.5C3.51389 3.16316 3.59851 2.84605 3.74757 2.56845L3.74755 2.56906C3.74755 3.01894 4.1137 3.38363 4.56536 3.38363C5.01703 3.38363 5.38318 3.01892 5.38318 2.56906C5.38318 2.11917 5.01703 1.75447 4.56536 1.75447L4.56475 1.75449C4.84346 1.60602 5.16183 1.52174 5.5 1.52174C6.5969 1.52174 7.48611 2.40743 7.48611 3.5Z" />
      </>
    );
  },
  Unpin: (props) => {
    return (
      <>
        <path d="M3.13442 11.0487L3.58049 15.4375L2.0417 15.4375C1.55845 15.4375 1.1667 15.8362 1.1667 16.3281L1.1667 18.1094C1.1667 18.6013 1.55845 19 2.0417 19L11.9583 19C12.4416 19 12.8333 18.6013 12.8333 18.1094L12.8333 16.3281C12.8333 15.8362 12.4416 15.4375 11.9583 15.4375L10.4195 15.4375L10.8656 11.0487C12.6341 10.2118 14 8.71103 14 6.82814C14 6.33625 13.6083 5.93751 13.125 5.93751L8.16668 5.93751L8.16668 2.07789C8.16668 2.0318 8.15615 1.98634 8.13587 1.94511L7.26088 0.163859C7.15365 -0.054418 6.84657 -0.0548261 6.73912 0.163859L5.86412 1.94511C5.84388 1.98634 5.83333 2.03179 5.83332 2.07789L5.83332 5.93752L0.874999 5.93752C0.391745 5.93752 1.75392e-06 6.33626 1.79692e-06 6.82814C3.82026e-05 8.72751 1.38479 10.2207 3.13442 11.0487Z" />
      </>
    );
  },
  Pin: (props) => {
    return (
      <>
        <path d="M7.83877 10.029L5.05084 13.4478L3.96274 12.3597C3.62103 12.018 3.06208 12.0229 2.71426 12.3707L1.45473 13.6303C1.10691 13.9781 1.10197 14.537 1.44368 14.8787L8.4558 21.8909C8.79751 22.2326 9.35647 22.2276 9.70429 21.8798L10.9638 20.6203C11.3116 20.2725 11.3166 19.7135 10.9749 19.3718L9.88678 18.2837L13.3056 15.4958C15.1479 16.1545 17.1749 16.0591 18.5063 14.7277C18.8541 14.3799 18.859 13.821 18.5173 13.4792L15.0113 9.97318L17.7404 7.24401C17.773 7.21142 17.7977 7.17183 17.8125 7.12834L18.4534 5.25009C18.5319 5.01993 18.315 4.80249 18.0844 4.88116L16.2062 5.52197C16.1627 5.53681 16.1231 5.56149 16.0905 5.59408L13.3613 8.32325L9.85527 4.81719C9.51356 4.47547 8.9546 4.48042 8.60679 4.82824C7.26376 6.17132 7.1871 8.20632 7.83877 10.029Z" />
      </>
    );
  },
  NavCalendar: (props) => {
    return (
      <>
        <path
          d="M0.5625 9H20.4375C20.7469 9 21 9.25313 21 9.5625V21.75C21 22.9922 19.9922 24 18.75 24H2.25C1.00781 24 0 22.9922 0 21.75V9.5625C0 9.25313 0.253125 9 0.5625 9ZM21 6.9375V5.25C21 4.00781 19.9922 3 18.75 3H16.5V0.5625C16.5 0.253125 16.2469 0 15.9375 0H14.0625C13.7531 0 13.5 0.253125 13.5 0.5625V3H7.5V0.5625C7.5 0.253125 7.24687 0 6.9375 0H5.0625C4.75313 0 4.5 0.253125 4.5 0.5625V3H2.25C1.00781 3 0 4.00781 0 5.25V6.9375C0 7.24687 0.253125 7.5 0.5625 7.5H20.4375C20.7469 7.5 21 7.24687 21 6.9375Z"
          fill="white"
        />
      </>
    );
  },
  Messaging: (props) => {
    return (
      <>
        <path
          d="M21.6 4.80005H20.4V14.4C20.4 15.06 19.86 15.6 19.2 15.6H4.8V16.8001C4.8 18.1201 5.88 19.2001 7.2 19.2001H19.2L24 24V7.20005C24 5.88005 22.92 4.80005 21.6 4.80005Z"
          fill="white"
        />
        <path
          d="M18 10.8V2.4C18 1.08 16.92 0 15.6 0H2.4C1.08 0 0 1.08 0 2.4V18L4.8 13.2H15.6C16.92 13.2 18 12.12 18 10.8Z"
          fill="white"
        />
      </>
    );
  },
  DeleteArchive: (props) => {
    return (
      <>
        <path
          d="M0 4.59375V3.0625C0 2.33516 0.573214 1.75 1.28571 1.75H7.28571L7.78929 0.727344C8.00357 0.278906 8.44821 0 8.93571 0H15.0589C15.5464 0 15.9911 0.278906 16.2107 0.727344L16.7143 1.75H22.7143C23.4268 1.75 24 2.33516 24 3.0625V4.59375C24 4.95469 23.7107 5.25 23.3571 5.25H0.642857C0.289286 5.25 0 4.95469 0 4.59375ZM22.2857 7.65625V25.375C22.2857 26.8242 21.1339 28 19.7143 28H4.28571C2.86607 28 1.71429 26.8242 1.71429 25.375V7.65625C1.71429 7.29531 2.00357 7 2.35714 7H21.6429C21.9964 7 22.2857 7.29531 22.2857 7.65625ZM7.71429 11.375C7.71429 10.8938 7.32857 10.5 6.85714 10.5C6.38571 10.5 6 10.8938 6 11.375V23.625C6 24.1063 6.38571 24.5 6.85714 24.5C7.32857 24.5 7.71429 24.1063 7.71429 23.625V11.375ZM12.8571 11.375C12.8571 10.8938 12.4714 10.5 12 10.5C11.5286 10.5 11.1429 10.8938 11.1429 11.375V23.625C11.1429 24.1063 11.5286 24.5 12 24.5C12.4714 24.5 12.8571 24.1063 12.8571 23.625V11.375ZM18 11.375C18 10.8938 17.6143 10.5 17.1429 10.5C16.6714 10.5 16.2857 10.8938 16.2857 11.375V23.625C16.2857 24.1063 16.6714 24.5 17.1429 24.5C17.6143 24.5 18 24.1063 18 23.625V11.375Z"
          fill="white"
        />
      </>
    );
  },
  Facebook: (props) => {
    return (
      <>
        <path
          d="M19 1.10268V18.8929C19 19.5045 18.5292 19.9955 17.9525 19.9955H13.1092V12.2545H15.5775L15.9464 9.23661H13.1049V7.30804C13.1049 6.43304 13.3339 5.83929 14.5257 5.83929H16.044V3.13839C15.781 3.10268 14.8819 3.01786 13.8301 3.01786C11.6417 3.01786 10.1404 4.42411 10.1404 7.00893V9.23661H7.66362V12.2545H10.1404V20H1.04754C0.470759 20 0 19.5045 0 18.8973V1.10268C0 0.495536 0.470759 0 1.04754 0H17.9482C18.5292 0 19 0.495536 19 1.10268Z"
          fill="white"
        />
      </>
    );
  },
  Camera: (props) => {
    return (
      <>
        <path
          d="M26 6V21.4286C26 22.8482 24.9082 24 23.5625 24H2.4375C1.0918 24 0 22.8482 0 21.4286V6C0 4.58036 1.0918 3.42857 2.4375 3.42857H6.90625L7.53086 1.66607C7.88633 0.664286 8.79531 0 9.81094 0H16.184C17.1996 0 18.1086 0.664286 18.4641 1.66607L19.0938 3.42857H23.5625C24.9082 3.42857 26 4.58036 26 6ZM19.0938 13.7143C19.0938 10.1679 16.3617 7.28571 13 7.28571C9.63828 7.28571 6.90625 10.1679 6.90625 13.7143C6.90625 17.2607 9.63828 20.1429 13 20.1429C16.3617 20.1429 19.0938 17.2607 19.0938 13.7143ZM17.4688 13.7143C17.4688 16.3125 15.4629 18.4286 13 18.4286C10.5371 18.4286 8.53125 16.3125 8.53125 13.7143C8.53125 11.1161 10.5371 9 13 9C15.4629 9 17.4688 11.1161 17.4688 13.7143Z"
          fill="white"
        />
      </>
    );
  },
  EditPencil: (props) => {
    return (
      <>
        <path
          d="M19.4492 5.54807L17.6483 7.34893C17.4647 7.53253 17.1678 7.53253 16.9842 7.34893L12.6481 3.01282C12.4645 2.82921 12.4645 2.53233 12.6481 2.34873L14.449 0.547873C15.1795 -0.182624 16.367 -0.182624 17.1014 0.547873L19.4492 2.89562C20.1836 3.62612 20.1836 4.81367 19.4492 5.54807ZM11.1012 3.89566L0.842863 14.1539L0.0147001 18.9002C-0.0985864 19.5408 0.460033 20.0955 1.10069 19.9861L5.847 19.1541L16.1053 8.89586C16.2889 8.71226 16.2889 8.41537 16.1053 8.23177L11.7691 3.89566C11.5816 3.71206 11.2848 3.71206 11.1012 3.89566ZM4.84695 13.2749C4.6321 13.0601 4.6321 12.7163 4.84695 12.5015L10.8629 6.48561C11.0777 6.27076 11.4215 6.27076 11.6363 6.48561C11.8512 6.70046 11.8512 7.04423 11.6363 7.25908L5.62043 13.2749C5.40557 13.4898 5.06181 13.4898 4.84695 13.2749ZM3.43673 16.5602H5.31182V17.9783L2.79217 18.4197L1.57727 17.2048L2.0187 14.6852H3.43673V16.5602Z"
          fill="white"
        />
      </>
    );
  },
  ExclamationMark: (props) => {
    return (
      <path
        d="M2 6.75C2 7.43925 1.5514 8 1 8C0.4486 8 0 7.43925 0 6.75C0 6.06075 0.4486 5.5 1 5.5C1.5514 5.5 2 6.06075 2 6.75ZM0.11575 0.393734L0.28575 4.64373C0.293738 4.84331 0.425513 5 0.585375 5H1.41463C1.57449 5 1.70626 4.84331 1.71425 4.64373L1.88425 0.393734C1.89281 0.179531 1.7562 0 1.58463 0H0.415375C0.2438 0 0.107188 0.179531 0.11575 0.393734Z"
        fill="white"
      />
    );
  },
  Search: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.1554 15.0935H16.0659L15.6871 14.7175C17.025 13.1574 17.8388 11.1349 17.8388 8.91887C17.8388 3.99291 13.8456 0 8.91938 0C3.99314 0 0 3.99291 0 8.91887C0 13.8448 3.99314 17.8377 8.91938 17.8377C11.1355 17.8377 13.1568 17.0254 14.717 15.689L15.0957 16.0649V17.1517L21.954 24L24 21.9541L17.1554 15.0935ZM8.91938 15.0935C5.50806 15.0935 2.74443 12.33 2.74443 8.91887C2.74443 5.50912 5.50806 2.74427 8.91938 2.74427C12.3293 2.74427 15.0943 5.50912 15.0943 8.91887C15.0943 12.33 12.3293 15.0935 8.91938 15.0935Z"
          fill="white"
        />
      </>
    );
  },
  SpinnerLoading: (props) => {
    return (
      <>
        <path
          d="M11.875 1.875C11.875 2.91055 11.0355 3.75 10 3.75C8.96445 3.75 8.125 2.91055 8.125 1.875C8.125 0.839453 8.96445 0 10 0C11.0355 0 11.875 0.839453 11.875 1.875ZM10 16.25C8.96445 16.25 8.125 17.0895 8.125 18.125C8.125 19.1605 8.96445 20 10 20C11.0355 20 11.875 19.1605 11.875 18.125C11.875 17.0895 11.0355 16.25 10 16.25ZM18.125 8.125C17.0895 8.125 16.25 8.96445 16.25 10C16.25 11.0355 17.0895 11.875 18.125 11.875C19.1605 11.875 20 11.0355 20 10C20 8.96445 19.1605 8.125 18.125 8.125ZM3.75 10C3.75 8.96445 2.91055 8.125 1.875 8.125C0.839453 8.125 0 8.96445 0 10C0 11.0355 0.839453 11.875 1.875 11.875C2.91055 11.875 3.75 11.0355 3.75 10ZM4.25477 13.8702C3.21922 13.8702 2.37977 14.7097 2.37977 15.7452C2.37977 16.7808 3.21922 17.6202 4.25477 17.6202C5.29031 17.6202 6.12977 16.7808 6.12977 15.7452C6.12977 14.7097 5.29027 13.8702 4.25477 13.8702ZM15.7452 13.8702C14.7097 13.8702 13.8702 14.7097 13.8702 15.7452C13.8702 16.7808 14.7097 17.6202 15.7452 17.6202C16.7808 17.6202 17.6202 16.7808 17.6202 15.7452C17.6202 14.7097 16.7808 13.8702 15.7452 13.8702ZM4.25477 2.37977C3.21922 2.37977 2.37977 3.21922 2.37977 4.25477C2.37977 5.29031 3.21922 6.12977 4.25477 6.12977C5.29031 6.12977 6.12977 5.29031 6.12977 4.25477C6.12977 3.21922 5.29027 2.37977 4.25477 2.37977Z"
          fill="white"
        />
      </>
    );
  },
  VideoCamera: (props) => {
    return (
      <>
        <g filter="url(#filter0_d)">
          <path
            d="M22.3333 3H21.9126C21.4705 3 21.0466 3.17121 20.7341 3.47596L17.3333 6.79167V4.625C17.3333 3.72753 16.5872 3 15.6667 3H5.66667C4.74618 3 4 3.72753 4 4.625V14.375C4 15.2725 4.74618 16 5.66667 16H15.6667C16.5872 16 17.3333 15.2725 17.3333 14.375V12.2083L20.7341 15.524C21.0466 15.8288 21.4705 16 21.9126 16H22.3333C23.2538 16 24 15.2725 24 14.375V4.625C24 3.72753 23.2538 3 22.3333 3Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0.5"
            width="28"
            height="21"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1.5" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.137255 0 0 0 0 0.141176 0 0 0 0 0.160784 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </>
    );
  },
  FlipCamera: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M30 25C30 26.6562 28.7402 28 27.1875 28H2.8125C1.25977 28 0 26.6562 0 25V7C0 5.34375 1.25977 4 2.8125 4H7.96875L8.68945 1.94375C9.09961 0.775 10.1484 0 11.3203 0H18.6738C19.8457 0 20.8945 0.775 21.3047 1.94375L22.0312 4H27.1875C28.7402 4 30 5.34375 30 7V25ZM15.5723 10.6129C16.8739 10.6117 18.1009 11.1024 19.0463 11.9974L17.7837 13.274C17.3264 13.7364 17.6503 14.5268 18.2969 14.5268H22.3511C22.752 14.5268 23.0769 14.1983 23.0769 13.793V9.69372C23.0769 9.0399 22.2951 8.71247 21.8379 9.17478L20.7578 10.2669C19.4113 8.96581 17.5864 8.16663 15.5769 8.16663C11.912 8.16663 8.86118 10.8246 8.20684 14.338C8.16489 14.5633 8.33715 14.7715 8.56391 14.7715H10.2969C10.4641 14.7715 10.6086 14.6557 10.6492 14.4917C11.2076 12.2389 13.2298 10.6149 15.5723 10.6129ZM12.8569 16.9731H8.80273C8.40187 16.9731 8.07692 17.3016 8.07692 17.7069V21.8062C8.07692 22.46 8.8587 22.7874 9.31593 22.3251L10.396 21.233C11.7425 22.5341 13.5674 23.3333 15.5769 23.3333C19.2418 23.3333 22.2927 20.6753 22.947 17.162C22.989 16.9367 22.8167 16.7285 22.59 16.7285H20.857C20.6898 16.7285 20.5453 16.8443 20.5046 17.0083C19.946 19.2621 17.9229 20.8849 15.5816 20.8871C14.28 20.8883 13.053 20.3976 12.1076 19.5025L13.3702 18.2259C13.8274 17.7635 13.5036 16.9731 12.8569 16.9731Z"
          fill="white"
        />
      </>
    );
  },
  FlashOn: (props) => {
    return (
      <>
        <g filter="url(#filter0_d)">
          <path d="M4 3V14H7V23L14 11H10L13 3H4Z" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0.5"
            width="18"
            height="28"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1.5" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.137255 0 0 0 0 0.141176 0 0 0 0 0.160784 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </>
    );
  },
  FlashOff: (props) => {
    return (
      <>
        <g filter="url(#filter0_d)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 3H9V4.61L15.13 10.74L19 3ZM19 11H15.39L17.67 13.28L19 11ZM4 5.27L5.41 3.86L21.14 19.59L19.73 21L15.58 16.85L12 23V14H9V10.27L4 5.27Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d"
            x="0"
            y="0.5"
            width="25.14"
            height="28"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="1.5" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.137255 0 0 0 0 0.141176 0 0 0 0 0.160784 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </>
    );
  },
  CheckSmall: (props) => {
    return (
      <>
        <path
          d="M4.75781 10.7696C4.86719 10.9424 5.03125 11 5.25 11C5.44141 11 5.60547 10.9424 5.74219 10.7696L13.8086 2.30366C13.918 2.18848 14 2.01571 14 1.78534C14 1.58377 13.918 1.41099 13.8086 1.26702L12.7969 0.230366C12.6602 0.0863874 12.4961 0 12.3047 0C12.1133 0 11.9492 0.0863874 11.8125 0.230366L5.25 7.14136L2.1875 3.91623C2.02344 3.77225 1.85938 3.68586 1.66797 3.68586C1.47656 3.68586 1.3125 3.77225 1.20312 3.91623L0.191406 4.95288C0.0546875 5.09686 0 5.26963 0 5.4712C0 5.70157 0.0546875 5.87435 0.191406 5.98953L4.75781 10.7696Z"
          fill="white"
        />
      </>
    );
  },
  Send: (props) => {
    return (
      <path
        d="M13.7744 4.70835L0.418804 0.0316803C0.373559 0.0064757 0.320599 -0.00414794 0.268169 0.00146313C0.215738 0.0070742 0.166778 0.0286052 0.128911 0.0627043C0.055004 0.127251 0.00922337 0.214722 0.000260851 0.30851V3.60103C-0.000555929 3.80478 0.0717325 4.00299 0.205739 4.16444C0.339745 4.32589 0.527853 4.4414 0.74043 4.49278L3.05614 5.01621L0.74043 5.53964C0.527762 5.57973 0.337075 5.68792 0.201755 5.84528C0.0664358 6.00264 -0.00489193 6.19913 0.000260851 6.40036V9.69209C0.00922337 9.78587 0.055004 9.87335 0.128911 9.93789C0.187312 9.97178 0.253344 9.99274 0.321887 9.99914C0.339525 10.0012 0.357442 9.9995 0.374249 9.99412C0.391056 9.98874 0.406302 9.97985 0.418804 9.96812L13.7744 5.26201C13.8388 5.24399 13.8953 5.20746 13.9359 5.15771C13.9764 5.10795 13.9989 5.04754 14 4.98518C13.9942 4.92406 13.97 4.86568 13.9301 4.81672C13.8902 4.76777 13.8363 4.73021 13.7744 4.70835Z"
        fill="white"
      />
    );
  },
  Close: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5607 0.43934C12.1464 1.02513 12.1464 1.97487 11.5607 2.56066L2.56066 11.5607C1.97487 12.1464 1.02513 12.1464 0.43934 11.5607C-0.146447 10.9749 -0.146447 10.0251 0.43934 9.43934L9.43934 0.43934C10.0251 -0.146447 10.9749 -0.146447 11.5607 0.43934Z"
          fill={props.color}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.43934 0.43934C1.02513 -0.146447 1.97487 -0.146447 2.56066 0.43934L11.5607 9.43934C12.1464 10.0251 12.1464 10.9749 11.5607 11.5607C10.9749 12.1464 10.0251 12.1464 9.43934 11.5607L0.43934 2.56066C-0.146447 1.97487 -0.146447 1.02513 0.43934 0.43934Z"
          fill={props.color}
        />
      </>
    );
  },
  ChevronArrow: (props) => {
    return (
      <>
        <path
          d="M0.212138 6.45579L6.07895 0.2253C6.3618 -0.0750999 6.82064 -0.0750999 7.10349 0.2253L7.78782 0.952094C8.07037 1.25217 8.07067 1.7382 7.78903 2.03892L3.13935 6.99984L7.78873 11.9611C8.07067 12.2618 8.07007 12.7478 7.78752 13.0479L7.10319 13.7747C6.82034 14.0751 6.3615 14.0751 6.07865 13.7747L0.212138 7.54389C-0.0707126 7.24349 -0.0707126 6.75619 0.212138 6.45579Z"
          fill={props.color}
        />
      </>
    );
  },
  SyncRefresh: (props) => {
    return (
      <>
        <path
          d="M8.04419 2.77839C7.35088 2.12921 6.45115 1.77335 5.49663 1.77419C3.77881 1.7757 2.29581 2.95354 1.88634 4.5874C1.85654 4.70634 1.75057 4.79032 1.62796 4.79032H0.357123C0.190837 4.79032 0.0645141 4.63936 0.0952742 4.47594C0.575127 1.92775 2.81239 0 5.5 0C6.97364 0 8.3119 0.579629 9.29932 1.52326L10.0914 0.73119C10.4267 0.395889 11 0.633365 11 1.10756V4.08065C11 4.37461 10.7617 4.6129 10.4677 4.6129H7.49466C7.02046 4.6129 6.78299 4.03959 7.11829 3.70427L8.04419 2.77839ZM0.532258 6.3871H3.50534C3.97954 6.3871 4.21701 6.96041 3.88171 7.29573L2.95581 8.22163C3.64912 8.87083 4.54892 9.22669 5.50346 9.22583C7.22039 9.22428 8.70399 8.04723 9.11366 6.41265C9.14346 6.29371 9.24942 6.20972 9.37204 6.20972H10.6429C10.8092 6.20972 10.9355 6.36068 10.9047 6.52411C10.4249 9.07225 8.18761 11 5.5 11C4.02635 11 2.6881 10.4204 1.70068 9.47674L0.908609 10.2688C0.573308 10.6041 0 10.3666 0 9.89244V6.91935C0 6.62539 0.238296 6.3871 0.532258 6.3871Z"
          fill="#515254"
        />
      </>
    );
  },
  DropdownArrow: (props) => {
    return (
      <>
        <path
          d="M0.299321 1.78783L5.05599 6.62178C5.50287 7.07592 6.28777 7.12851 6.80913 6.73925C6.85747 6.70316 6.90255 6.66389 6.94399 6.62178L11.7007 1.78783C12.1475 1.3337 12.0872 0.649989 11.5658 0.260729C11.3405 0.0924811 11.0535 0 10.7567 0L1.24332 0C0.556653 0 0 0.484883 0 1.08302C0 1.34155 0.10617 1.59155 0.299321 1.78783Z"
          fill="#515254"
        />
      </>
    );
  },
  MoreOptions: (props) => {
    return (
      <>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
          fill="#515254"
        />
      </>
    );
  },
  Added: (props) => {
    return (
      <>
        <path
          d="M23.7299 18.3339C24.499 19.667 23.5336 21.3333 21.9975 21.3333H2.00226C0.46322 21.3333 -0.497696 19.6644 0.269887 18.3339L10.2676 0.999375C11.0371 -0.334333 12.9643 -0.331916 13.7324 0.999375L23.7299 18.3339ZM12 14.75C10.9415 14.75 10.0833 15.6081 10.0833 16.6667C10.0833 17.7252 10.9415 18.5833 12 18.5833C13.0585 18.5833 13.9167 17.7252 13.9167 16.6667C13.9167 15.6081 13.0585 14.75 12 14.75ZM10.1803 7.86058L10.4894 13.5272C10.5038 13.7924 10.7231 14 10.9886 14H13.0114C13.2769 14 13.4962 13.7924 13.5106 13.5272L13.8197 7.86058C13.8353 7.57417 13.6073 7.33333 13.3205 7.33333H10.6795C10.3927 7.33333 10.1647 7.57417 10.1803 7.86058Z"
          fill="#F5B722"
        />
        <path
          d="M23.7299 18.3339C24.499 19.667 23.5336 21.3333 21.9975 21.3333H2.00226C0.46322 21.3333 -0.497696 19.6644 0.269887 18.3339L10.2676 0.999375C11.0371 -0.334333 12.9643 -0.331916 13.7324 0.999375L23.7299 18.3339ZM12 14.75C10.9415 14.75 10.0833 15.6081 10.0833 16.6667C10.0833 17.7252 10.9415 18.5833 12 18.5833C13.0585 18.5833 13.9167 17.7252 13.9167 16.6667C13.9167 15.6081 13.0585 14.75 12 14.75ZM10.1803 7.86058L10.4894 13.5272C10.5038 13.7924 10.7231 14 10.9886 14H13.0114C13.2769 14 13.4962 13.7924 13.5106 13.5272L13.8197 7.86058C13.8353 7.57417 13.6073 7.33333 13.3205 7.33333H10.6795C10.3927 7.33333 10.1647 7.57417 10.1803 7.86058Z"
          fill="#F5B722"
        />
      </>
    );
  },

  Pending: (props) => {
    return (
      <>
        <path
          d="M16.9173 3C17.5402 3 18.0451 2.49633 18.0451 1.875V1.125C18.0451 0.503672 17.5402 0 16.9173 0H1.12782C0.504934 0 0 0.503672 0 1.125V1.875C0 2.49633 0.504934 3 1.12782 3C1.12782 7.26398 3.52519 10.8625 6.80648 12C3.52519 13.1375 1.12782 16.736 1.12782 21C0.504934 21 0 21.5037 0 22.125V22.875C0 23.4963 0.504934 24 1.12782 24H16.9173C17.5402 24 18.0451 23.4963 18.0451 22.875V22.125C18.0451 21.5037 17.5402 21 16.9173 21C16.9173 16.736 14.5199 13.1375 11.2386 12C14.5199 10.8625 16.9173 7.26398 16.9173 3ZM9.02256 9.75C6.307 9.75 4.13534 6.63197 4.13534 3H13.9098C13.9098 6.65367 11.7242 9.75 9.02256 9.75Z"
          fill="#F5B722"
        />
      </>
    );
  },
  Active: (props) => {
    return (
      <>
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          fill="#00DB6F"
          stroke="#00DB6F"
        />
        <path
          d="M9.7449 17.5125C9.8549 17.6775 10.0199 17.7325 10.2399 17.7325C10.4324 17.7325 10.5974 17.6775 10.7349 17.5125L18.8474 9.42748C18.9574 9.31748 19.0399 9.15248 19.0399 8.93248C19.0399 8.73998 18.9574 8.57498 18.8474 8.43748L17.8299 7.44748C17.6924 7.30998 17.5274 7.22748 17.3349 7.22748C17.1424 7.22748 16.9774 7.30998 16.8399 7.44748L10.2399 14.0475L7.1599 10.9675C6.9949 10.83 6.8299 10.7475 6.6374 10.7475C6.4449 10.7475 6.2799 10.83 6.1699 10.9675L5.1524 11.9575C5.0149 12.095 4.9599 12.26 4.9599 12.4525C4.9599 12.6725 5.0149 12.8375 5.1524 12.9475L9.7449 17.5125Z"
          fill="white"
        />
      </>
    );
  },
  Decline: (props) => {
    return (
      <>
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          fill="#F55B5B"
          stroke="#F55B5B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.6339 7.36612C17.122 7.85427 17.122 8.64573 16.6339 9.13388L9.13388 16.6339C8.64573 17.122 7.85427 17.122 7.36612 16.6339C6.87796 16.1457 6.87796 15.3543 7.36612 14.8661L14.8661 7.36612C15.3543 6.87796 16.1457 6.87796 16.6339 7.36612Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.36612 7.36612C7.85427 6.87796 8.64573 6.87796 9.13388 7.36612L16.6339 14.8661C17.122 15.3543 17.122 16.1457 16.6339 16.6339C16.1457 17.122 15.3543 17.122 14.8661 16.6339L7.36612 9.13388C6.87796 8.64573 6.87796 7.85427 7.36612 7.36612Z"
          fill="white"
        />
      </>
    );
  },
  Done: (props) => {
    return (
      <>
        <circle r="65" transform="matrix(-1 0 0 1 65 65)" fill="#7BDBC4" />
        <path
          d="M54.4297 88.9738C54.9453 89.7435 55.7188 90 56.75 90C57.6523 90 58.4258 89.7435 59.0703 88.9738L97.0977 51.2618C97.6133 50.7487 98 49.9791 98 48.9529C98 48.055 97.6133 47.2853 97.0977 46.644L92.3281 42.0262C91.6836 41.3848 90.9102 41 90.0078 41C89.1055 41 88.332 41.3848 87.6875 42.0262L56.75 72.8115L42.3125 58.445C41.5391 57.8037 40.7656 57.4188 39.8633 57.4188C38.9609 57.4188 38.1875 57.8037 37.6719 58.445L32.9023 63.0628C32.2578 63.7042 32 64.4738 32 65.3717C32 66.3979 32.2578 67.1675 32.9023 67.6806L54.4297 88.9738Z"
          fill="white"
        />
      </>
    );
  },
  DoneSkipped:  (props) => {
    return (
      <>
        <svg
          width="130"
          height="130"
          viewBox="0 0 130 130"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle r="65" transform="matrix(-1 0 0 1 65 65)" fill="#9C9CA3" />
          <path
            d="M54.4297 88.9738C54.9453 89.7435 55.7188 90 56.75 90C57.6523 90 58.4258 89.7435 59.0703 88.9738L97.0977 51.2618C97.6133 50.7487 98 49.9791 98 48.9529C98 48.055 97.6133 47.2853 97.0977 46.644L92.3281 42.0262C91.6836 41.3848 90.9102 41 90.0078 41C89.1055 41 88.332 41.3848 87.6875 42.0262L56.75 72.8115L42.3125 58.445C41.5391 57.8037 40.7656 57.4188 39.8633 57.4188C38.9609 57.4188 38.1875 57.8037 37.6719 58.445L32.9023 63.0628C32.2578 63.7042 32 64.4738 32 65.3717C32 66.3979 32.2578 67.1675 32.9023 67.6806L54.4297 88.9738Z"
            fill="white"
          />
        </svg>
      </>
    );
  },
  DoneSmall: (props) => {
    return (
      <>
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="27"
            height="27"
            rx="13.5"
            fill="#7BDBC4"
            stroke="#7BDBC4"
          />
          <path
            d="M11.3691 20.4313C11.4974 20.6238 11.6899 20.688 11.9466 20.688C12.1711 20.688 12.3636 20.6238 12.5241 20.4313L21.9886 10.9988C22.117 10.8705 22.2132 10.678 22.2132 10.4213C22.2132 10.1967 22.117 10.0042 21.9886 9.8438L20.8016 8.6888C20.6411 8.52838 20.4486 8.43213 20.2241 8.43213C19.9995 8.43213 19.807 8.52838 19.6466 8.6888L11.9466 16.3888L8.35323 12.7955C8.16073 12.635 7.96823 12.5388 7.74364 12.5388C7.51906 12.5388 7.32656 12.635 7.19823 12.7955L6.01114 13.9505C5.85073 14.1109 5.78656 14.3034 5.78656 14.528C5.78656 14.7846 5.85073 14.9771 6.01114 15.1055L11.3691 20.4313Z"
            fill="white"
          />
        </svg>
      </>
    );
  },
  HomeSetup: (props) => {
    return (
      <>
        <circle r="65" transform="matrix(-1 0 0 1 65 65)" fill="#FCF7F4" />
        <path
          d="M88.61 71.8186V89.1049C88.61 90.7093 87.3467 92 85.7764 92H72.0805C71.3013 92 70.6637 91.3486 70.6637 90.5524V77.0419C70.6637 76.2457 70.0262 75.5943 69.2469 75.5943H60.746C59.9668 75.5943 59.3292 76.2457 59.3292 77.0419V90.5524C59.3292 91.3486 58.6917 92 57.9124 92H44.2166C42.6463 92 41.383 90.7093 41.383 89.1049V71.8186C41.383 71.3843 41.5719 70.9742 41.9025 70.6968L64.0992 52.0232C64.6187 51.589 65.3743 51.589 65.9056 52.0232L88.1023 70.6968C88.4211 70.9742 88.61 71.3843 88.61 71.8186ZM98.4805 64.4722L88.61 56.1608V39.4536C88.61 38.6574 87.9724 38.006 87.1932 38.006H80.5814C79.8022 38.006 79.1646 38.6574 79.1646 39.4536V48.2113L68.5975 39.3209C66.5077 37.5597 63.4852 37.5597 61.3954 39.3209L31.5125 64.4722C30.9104 64.9789 30.8277 65.8957 31.3236 66.5109L34.3343 70.2504C34.8302 70.8656 35.7275 70.9501 36.3297 70.4434L64.0992 47.0774C64.6187 46.6431 65.3743 46.6431 65.9056 47.0774L93.6751 70.4434C94.2773 70.9501 95.1746 70.8656 95.6705 70.2504L98.6812 66.5109C99.1771 65.8836 99.0826 64.9789 98.4805 64.4722Z"
          fill="#EA9277"
        />
      </>
    );
  },
  NoUsers: (props) => {
    return (
      <>
        <rect
          x="1.5"
          y="1.5"
          width="37"
          height="37"
          rx="18.5"
          fill="#FCF7F4"
          stroke="#EA9277"
          stroke-width="3"
        />
        <path
          d="M20 14C21.8122 14 23.2812 15.4691 23.2812 17.2812C23.2812 19.0934 21.8122 20.5625 20 20.5625C18.1878 20.5625 16.7188 19.0934 16.7188 17.2812C16.7188 15.4691 18.1878 14 20 14ZM23.5457 20.7927L22.2963 20.4803C20.7598 21.5855 18.8973 21.3388 17.7038 20.4803L16.4543 20.7927C15.4527 21.0431 14.75 21.943 14.75 22.9755V24.875C14.75 25.4963 15.2537 26 15.875 26H24.125C24.7463 26 25.25 25.4963 25.25 24.875V22.9755C25.25 21.943 24.5473 21.0431 23.5457 20.7927ZM26.5 21.375C27.7081 21.375 28.6875 20.3956 28.6875 19.1875C28.6875 17.9794 27.7081 17 26.5 17C25.2919 17 24.3125 17.9794 24.3125 19.1875C24.3125 20.3956 25.2919 21.375 26.5 21.375ZM13.5 21.375C14.7081 21.375 15.6875 20.3956 15.6875 19.1875C15.6875 17.9794 14.7081 17 13.5 17C12.2919 17 11.3125 17.9794 11.3125 19.1875C11.3125 20.3956 12.2919 21.375 13.5 21.375ZM14.25 24.875V22.9755C14.25 22.4583 14.3935 21.9691 14.647 21.5505C13.7098 22.0105 12.6677 21.8227 11.9692 21.3202L11.1362 21.5285C10.4684 21.6954 10 22.2954 10 22.9837V24.25C10 24.6642 10.3358 25 10.75 25H14.2553C14.2519 24.9584 14.2502 24.9167 14.25 24.875ZM28.8638 21.5284L28.0308 21.3202C27.1582 21.9479 26.128 21.9212 25.35 21.5457C25.6053 21.9654 25.75 22.4563 25.75 22.9755V24.875C25.75 24.9171 25.7479 24.9587 25.7447 25H29.25C29.6642 25 30 24.6642 30 24.25V22.9837C30 22.2954 29.5316 21.6954 28.8638 21.5284Z"
          fill="#EA9277"
        />
      </>
    );
  },
  WarningSmall: (props) => {
    return (
      <>
        <path
          d="M17.7974 13.7504C18.3742 14.7502 17.6502 16 16.4981 16H1.5017C0.347415 16 -0.373272 14.7483 0.202415 13.7504L7.70073 0.749531C8.27782 -0.25075 9.72322 -0.248937 10.2993 0.749531L17.7974 13.7504ZM9.00001 11.0625C8.2061 11.0625 7.56251 11.7061 7.56251 12.5C7.56251 13.2939 8.2061 13.9375 9.00001 13.9375C9.79391 13.9375 10.4375 13.2939 10.4375 12.5C10.4375 11.7061 9.79391 11.0625 9.00001 11.0625ZM7.63523 5.89544L7.86704 10.1454C7.87788 10.3443 8.04232 10.5 8.24147 10.5H9.75854C9.95769 10.5 10.1221 10.3443 10.133 10.1454L10.3648 5.89544C10.3765 5.68063 10.2055 5.5 9.99035 5.5H8.00963C7.79451 5.5 7.62351 5.68063 7.63523 5.89544Z"
          fill="#F55B5B"
        />
      </>
    );
  },
  InfoCircle: (props) => {
    return (
      <>
        <rect
          x="0.5"
          y="0.5"
          width="29"
          height="29"
          rx="14.5"
          stroke="#EA9277"
        />
        <path
          d="M15.0161 6C12.2942 6 10.5313 7.14971 9.1472 9.20013C8.89612 9.57209 8.97353 10.0821 9.32377 10.3559L10.7948 11.5058C11.1485 11.7823 11.6518 11.7176 11.9287 11.36C12.7829 10.2567 13.4165 9.62152 14.7507 9.62152C15.7998 9.62152 17.0974 10.3176 17.0974 11.3664C17.0974 12.1592 16.4625 12.5664 15.4267 13.1651C14.2188 13.8633 12.6203 14.7322 12.6203 16.9059V17.25C12.6203 17.716 12.9867 18.0938 13.4387 18.0938H15.91C16.362 18.0938 16.7284 17.716 16.7284 17.25V17.047C16.7284 15.5402 21 15.4775 21 11.4C21 8.32931 17.9105 6 15.0161 6ZM14.6743 19.1294C13.3719 19.1294 12.3122 20.2219 12.3122 21.5647C12.3122 22.9075 13.3719 24 14.6743 24C15.9768 24 17.0365 22.9075 17.0365 21.5647C17.0365 20.2219 15.9768 19.1294 14.6743 19.1294Z"
          fill="#EA9277"
        />
      </>
    );
  },
  InfoCircle2: (props) => {
    return (
      <>
        <path
          d="M10 0C4.47754 0 0 4.47915 0 10C0 15.5241 4.47754 20 10 20C15.5225 20 20 15.5241 20 10C20 4.47915 15.5225 0 10 0ZM10 4.43548C10.9353 4.43548 11.6935 5.19371 11.6935 6.12903C11.6935 7.06435 10.9353 7.82258 10 7.82258C9.06468 7.82258 8.30645 7.06435 8.30645 6.12903C8.30645 5.19371 9.06468 4.43548 10 4.43548ZM12.2581 14.6774C12.2581 14.9446 12.0414 15.1613 11.7742 15.1613H8.22581C7.95859 15.1613 7.74194 14.9446 7.74194 14.6774V13.7097C7.74194 13.4425 7.95859 13.2258 8.22581 13.2258H8.70968V10.6452H8.22581C7.95859 10.6452 7.74194 10.4285 7.74194 10.1613V9.19355C7.74194 8.92633 7.95859 8.70968 8.22581 8.70968H10.8065C11.0737 8.70968 11.2903 8.92633 11.2903 9.19355V13.2258H11.7742C12.0414 13.2258 12.2581 13.4425 12.2581 13.7097V14.6774Z"
          fill="#EA9277"
        />
      </>
    );
  },
  InfoCircleSmall: (props) => {
    return (
      <>
        <path
          d="M5 0C2.23877 0 0 2.23958 0 5C0 7.76204 2.23877 10 5 10C7.76123 10 10 7.76204 10 5C10 2.23958 7.76123 0 5 0ZM5 2.21774C5.46766 2.21774 5.84677 2.59685 5.84677 3.06452C5.84677 3.53218 5.46766 3.91129 5 3.91129C4.53234 3.91129 4.15323 3.53218 4.15323 3.06452C4.15323 2.59685 4.53234 2.21774 5 2.21774ZM6.12903 7.33871C6.12903 7.47232 6.02071 7.58065 5.8871 7.58065H4.1129C3.97929 7.58065 3.87097 7.47232 3.87097 7.33871V6.85484C3.87097 6.72123 3.97929 6.6129 4.1129 6.6129H4.35484V5.32258H4.1129C3.97929 5.32258 3.87097 5.21425 3.87097 5.08065V4.59677C3.87097 4.46317 3.97929 4.35484 4.1129 4.35484H5.40323C5.53683 4.35484 5.64516 4.46317 5.64516 4.59677V6.6129H5.8871C6.02071 6.6129 6.12903 6.72123 6.12903 6.85484V7.33871Z"
          fill="#EA9277"
        />
      </>
    );
  },
  DoneSmall2: (props) => {
    return (
      <>
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="11.5"
          fill="#7BDBC4"
          stroke="#7BDBC4"
        />
        <path
          d="M9.74496 17.5125C9.85496 17.6775 10.02 17.7325 10.24 17.7325C10.4325 17.7325 10.5975 17.6775 10.735 17.5125L18.8475 9.42746C18.9575 9.31746 19.04 9.15246 19.04 8.93246C19.04 8.73996 18.9575 8.57496 18.8475 8.43746L17.83 7.44746C17.6925 7.30996 17.5275 7.22746 17.335 7.22746C17.1425 7.22746 16.9775 7.30996 16.84 7.44746L10.24 14.0475L7.15996 10.9675C6.99496 10.83 6.82996 10.7475 6.63746 10.7475C6.44496 10.7475 6.27996 10.83 6.16996 10.9675L5.15246 11.9575C5.01496 12.095 4.95996 12.26 4.95996 12.4525C4.95996 12.6725 5.01496 12.8375 5.15246 12.9475L9.74496 17.5125Z"
          fill="white"
        />
      </>
    );
  },
  Invite: (props) => {
    return (
      <>
        <path
          d="M96.7496 56.85C97.2523 56.4625 98 56.825 98 57.4375V83C98 86.3125 95.2285 89 91.8125 89H38.1875C34.7715 89 32 86.3125 32 83V57.45C32 56.825 32.7348 56.475 33.2504 56.8625C36.1379 59.0375 39.9664 61.8 53.1148 71.0625C55.8348 72.9875 60.4238 77.0375 65 77.0125C69.602 77.05 74.2812 72.9125 76.898 71.0625C90.0465 61.8 93.8621 59.025 96.7496 56.85ZM65 73C67.9906 73.05 72.2961 69.35 74.4617 67.825C91.5676 55.7875 92.8695 54.7375 96.8141 51.7375C97.5617 51.175 98 50.3 98 49.375V47C98 43.6875 95.2285 41 91.8125 41H38.1875C34.7715 41 32 43.6875 32 47V49.375C32 50.3 32.4383 51.1625 33.1859 51.7375C37.1305 54.725 38.4324 55.7875 55.5383 67.825C57.7039 69.35 62.0094 73.05 65 73Z"
          fill={props.fill}
        />
      </>
    );
  },
  Users: (props) => {
    return (
      <>
        <path
          d="M35 0C41.3427 0 46.4844 5.14172 46.4844 11.4844C46.4844 17.827 41.3427 22.9688 35 22.9688C28.6573 22.9688 23.5156 17.827 23.5156 11.4844C23.5156 5.14172 28.6573 0 35 0ZM47.41 23.7744L43.037 22.6811C37.6592 26.5491 31.1407 25.686 26.9631 22.6811L22.5901 23.7744C19.0843 24.6508 16.625 27.8006 16.625 31.4142V38.0625C16.625 40.2371 18.3879 42 20.5625 42H49.4375C51.6121 42 53.375 40.2371 53.375 38.0625V31.4142C53.375 27.8006 50.9157 24.6508 47.41 23.7744ZM57.75 25.8125C61.9784 25.8125 65.4062 22.3847 65.4062 18.1562C65.4062 13.9278 61.9784 10.5 57.75 10.5C53.5216 10.5 50.0938 13.9278 50.0938 18.1562C50.0938 22.3847 53.5216 25.8125 57.75 25.8125ZM12.25 25.8125C16.4784 25.8125 19.9062 22.3847 19.9062 18.1562C19.9062 13.9278 16.4784 10.5 12.25 10.5C8.02156 10.5 4.59375 13.9278 4.59375 18.1562C4.59375 22.3847 8.02156 25.8125 12.25 25.8125ZM14.875 38.0625V31.4142C14.875 29.604 15.3774 27.8919 16.2644 26.4269C12.9845 28.0369 9.33702 27.3793 6.89205 25.6208L3.97666 26.3496C1.63953 26.9338 0 29.0338 0 31.4428V35.875C0 37.3248 1.17523 38.5 2.625 38.5H14.8935C14.8818 38.3545 14.8756 38.2085 14.875 38.0625ZM66.0233 26.3495L63.108 25.6207C60.0537 27.8176 56.4481 27.7243 53.7251 26.4101C54.6185 27.8789 55.125 29.597 55.125 31.4142V38.0625C55.125 38.2098 55.1176 38.3554 55.1065 38.5H67.375C68.8248 38.5 70 37.3248 70 35.875V31.4428C70 29.0338 68.3605 26.9338 66.0233 26.3495Z"
          fill={props.fill}
        />
      </>
    );
  },
  /*
  info: props => {
    return (
      <></>
    )
  }, 
  */
};

const Icon = props => {
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    path: {
      fill: props.color,
    },
  };
  const Ico = (ICONS[props.icon]) ? ICONS[props.icon] : ICONS[props.none];
  const fill = (props.type != 'circle') ? props.color : 'none';
  return (
    <svg
      fill={fill}
      className={props.className}
      style={styles.svg}
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox={`0 0 ${props.width} ${props.height}`}
    >
      <Ico {...props} fill={fill} />
    </svg>
  );
};

Icon.defaultProps = {
  icon: 'none',
  width: 20,
  height: 20,
  className: 'cr-icons',
  color: 'currentColor',
  type: 'fill'
};

export default Icon;