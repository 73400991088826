import React from "react";
import {Editor} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { InlineStyleControls, styleMap, getBlockStyle } from "./config";
import { customStyleFn } from "./index";
const RichEditor = (props) => {
  let {
    editorState,
    setEditorState,
    toggleInlineStyle,
    handleKeyCommand,
    onTab,
  } = props
  let className = props.readOnly ? '' : 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }
  return (
    <div className={props.readOnly ? '' : 'RichEditor-root'}>
      {
        !props.readOnly &&
          <div className="RichEditor-toolbar">
            <InlineStyleControls
              editorState={editorState}
              onToggle={toggleInlineStyle}
            />
          </div>
      }
      <div className={className}>
        <Editor
          blockStyleFn={getBlockStyle}
          customStyleFn={customStyleFn}
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
          onTab={onTab}
          placeholder=""
          spellCheck={true}
          {...props}
        />
      </div>
    </div>
  );
}

export default RichEditor;