import React from 'react';
import Alert from 'components/Alert';
import Toast from './view';
import {
  NOTIFICATION_AUTO_DISMISS,
  NOTIFICATION_POSITION,
} from '../../utils/constants';
import { Box } from '@mui/system';

class Manager {
  currentToast;

  registerToast = (ref) => {
    this.currentToast = ref;
  };

  unregisterToast = () => {
    this.currentToast = null;
  };

  show = ({ autoDismiss = NOTIFICATION_AUTO_DISMISS, ...options }) => {
    if (this.currentToast) {
      const autoDismissConfig =
        !options.autoDismiss ||
        options.autoDismiss === true ||
        isNaN(options.autoDismiss)
          ? NOTIFICATION_AUTO_DISMISS
          : parseFloat(options.autoDismiss);
      this.currentToast.show({
        autoDismiss: autoDismissConfig,
        level: options.level,
        position: options.position || NOTIFICATION_POSITION.TOP_RIGHT,
        children: (
          <Alert
            className={options.level}
            content={
              <Box width={'100%'} className="d-flex align-items-center">
                {options.message}
              </Box>
            }
            isOpen={true}
          />
        ),
      });
    }
  };

  hide = (notif) => {
    if (this.currentToast) {
      this.currentToast.hide(notif);
    }
  };
}
const ToastManager = new Manager();

export default ToastManager;
export { Toast };
