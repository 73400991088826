export const FETCH_STUDIOS_REQUEST = 'FETCH_STUDIOS_REQUEST';
export const FETCH_STUDIOS_SUCCESS = 'FETCH_STUDIOS_SUCCESS';
export const FETCH_STUDIOS_ERROR = 'FETCH_STUDIOS_ERROR';

export const FETCH_STUDIO_REQUEST = 'FETCH_STUDIO_REQUEST';
export const FETCH_STUDIO_SUCCESS = 'FETCH_STUDIO_SUCCESS';
export const FETCH_STUDIO_ERROR = 'FETCH_STUDIO_ERROR';

export const ADD_STUDIO_REQUEST = 'ADD_STUDIO_REQUEST';
export const ADD_STUDIO_SUCCESS = 'ADD_STUDIO_SUCCESS';
export const ADD_STUDIO_ERROR = 'ADD_STUDIO_ERROR';

export const UPDATE_STUDIO_REQUEST = 'UPDATE_STUDIO_REQUEST';
export const UPDATE_STUDIO_SUCCESS = 'UPDATE_STUDIO_SUCCESS';
export const UPDATE_STUDIO_ERROR = 'UPDATE_STUDIO_ERROR';

export const DELETE_STUDIO_REQUEST = 'DELETE_STUDIO_REQUEST';
export const DELETE_STUDIO_SUCCESS = 'DELETE_STUDIO_SUCCESS';
export const DELETE_STUDIO_ERROR = 'DELETE_STUDIO_ERROR';

export const FETCH_USER_STUDIO_REQUEST = 'FETCH_USER_STUDIOS_REQUEST';
export const FETCH_USER_STUDIO_SUCCESS = 'FETCH_USER_STUDIOS_SUCCESS';
export const FETCH_USER_STUDIOS_ERROR = 'FETCH_USER_STUDIOS_ERROR';

export const INVITE_STUDIO_REQUEST = 'INVITE_STUDIO_REQUEST';
export const INVITE_STUDIO_SUCCESS = 'INVITE_STUDIO_SUCCESS';
export const INVITE_STUDIO_ERROR = 'INVITE_STUDIO_ERROR';

export const FETCH_STUDIOS_ONSCROLL = 'FETCH_STUDIOS_ONSCROLL';
export const CHANGE_STUDIO_STEP = 'CHANGE_STUDIO_STEP';

export const ADD_STUDIO_LOCATION_REQUEST = 'ADD_STUDIO_LOCATION_REQUEST';
export const ADD_STUDIO_LOCATION_SUCCESS = 'ADD_STUDIO_LOCATION_SUCCESS';
export const ADD_STUDIO_LOCATION_ERROR = 'ADD_STUDIO_LOCATION_ERROR';

export const DELETE_STUDIO_LOCATION_REQUEST = 'DELETE_STUDIO_LOCATION_REQUEST';
export const DELETE_STUDIO_LOCATION_SUCCESS = 'DELETE_STUDIO_LOCATION_SUCCESS';
export const DELETE_STUDIO_LOCATION_ERROR = 'DELETE_STUDIO_LOCATION_ERROR';

export const GET_STUDIO_SEASON_REQUEST = 'GET_STUDIO_SEASON_REQUEST';
export const GET_STUDIO_SEASON_SUCCESS = 'GET_STUDIO_SEASON_SUCCESS';
export const GET_STUDIO_SEASON_ERROR = 'GET_STUDIO_SEASON_ERROR';

export const CREATE_STUDIO_SEASON_REQUEST = 'CREATE_STUDIO_SEASON_REQUEST';
export const CREATE_STUDIO_SEASON_SUCCESS = 'CREATE_STUDIO_SEASON_SUCCESS';
export const CREATE_STUDIO_SEASON_ERROR = 'CREATE_STUDIO_SEASON_ERROR';

export const FETCH_STUDIO_CATALOG_REQUEST = 'FETCH_STUDIO_CATALOG_REQUEST';
export const FETCH_STUDIO_CATALOG_SUCCESS = 'FETCH_STUDIO_CATALOG_SUCCESS';
export const FETCH_STUDIO_CATALOG_ERROR = 'FETCH_STUDIO_CATALOG_ERROR';

export const DELETE_STUDIO_SEASON_REQUEST = 'DELETE_STUDIO_SEASON_REQUEST';
export const DELETE_STUDIO_SEASON_SUCCESS = 'DELETE_STUDIO_SEASON_SUCCESS';
export const DELETE_STUDIO_SEASON_ERROR = 'DELETE_STUDIO_SEASON_ERROR';

export const STUDIO_USER_REGISTER_REQUEST = 'STUDIO_USER_REGISTER_REQUEST';
export const STUDIO_USER_REGISTER_SUCCESS = 'STUDIO_USER_REGISTER_SUCCESS';
export const STUDIO_USER_REGISTER_ERROR = 'STUDIO_USER_REGISTER_ERROR';

export const CREATE_STUDIO_WAIVER_REQUEST = 'CREATE_STUDIO_WAIVER_REQUEST';
export const CREATE_STUDIO_WAIVER_SUCCESS = 'CREATE_STUDIO_WAIVER_SUCCESS';
export const CREATE_STUDIO_WAIVER_ERROR = 'CREATE_STUDIO_WAIVER_ERROR';
export const STUDIO_CLASS_PASSES = 'STUDIO_CLASS_PASSES';

export const DELETE_CLASS_PASS = 'DELETE_CLASS_PASS';
