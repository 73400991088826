import React from 'react';
import classes from 'components/ProcessesList/Classes/components/ClassSchedule/EventGuests/EventGuests.module.scss';
import get from 'lodash/get';
import { getProfileLetter } from 'utils/helpers';
const GuestOption = (props) => {
  const { name, role, avatar_url } = props;

  const roleName = get(role, 'name', '');

  const displayAvatar = () => {
    if (avatar_url)
      return <img src={avatar_url} className={classes.avatar} alt={name} />;

    return <span className={classes.avatar}>{getProfileLetter(name)}</span>;
  };

  return (
    <div className={classes.guestOption}>
      {displayAvatar()}
      <div>
        <div className={classes.name}>{name}</div>
        {roleName && <div className={classes.role}>{roleName}</div>}
      </div>
    </div>
  );
};

export default GuestOption;
