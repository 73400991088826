import styled from 'styled-components';
import Box from '@mui/material/Box';
export const displayAvatar = styled.div`
  width: 40px;
  heigth: 40px;
`;
export const UserListWrap = styled.div`
  // margin-top: 40px;
  min-height: calc(100vh - 350px);
  .add-user {
    width: 165px;
    height: 40px;
    margin: 0;
    span {
      margin-right: 5px;
    }
  }
  .infinite-scroll-component.scroll-box-Wrap {
    overflow: unset !important;
    .wrapcol-box {
      margin-bottom: 0 !important;
    }
  }
`;
export const SearchInput = styled.div`
  width: 258px;
  margin-left: auto;
  height: 40px;
  position: relative;
  button {
    background: #22294e;
    opacity: 0.9 !important;
    width: 40px;
    height: 40px;
    color: #fff;
    padding: 0px !important;
    border-radius: 0px !important;
    margin: 0px !important;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  input {
    margin-left: -5px !important;
    background-color: #fff !important;
    padding: 0px 17px !important;
    border: none !important;
    font-weight: bold;
    font-family: 'Muli';
    color: #141414;
    font-size: 14px;
    line-height: 20px;
    &::placeholder {
      color: #9c9ca3 !important;
    }
    &:focus {
      // border: 1px solid #ea734f !important;
    }
  }
  .active {
    // background: #ea734f;
  }

  @media (max-width: 768.98px) {
    width: 100%;
  }
`;
export const TopWrap = styled.div`
  padding: 8px 25px;
  background: rgb(253, 249, 247);
  position: sticky;
  left: 0;
  top: 0;
  height: 72px;
  width: 100%;
  z-index: 1;
  .col-sm-2,
  .col {
    margin-bottom: 0px !important;
  }
  .cr-textinput div {
    margin-bottom: 0px !important;
  }
  .row {
    align-items: center;
  }
`;

export const TableWrap = styled.div`
  background: #ffffff;

  .ReactTable {
    border: none;
    position: relative;
  }
  .ReactTable .rt-table {
    overflow: unset;
  }
  .ReactTable .rt-thead.-header {
    box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
    margin-bottom: 3px;
    position: sticky;
    top: 72px;
    left: 0;
    background-color: #fff;
    z-index: 1;
  }
  .rt-resizable-header-content {
    text-align: left;
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .rt-th {
    // position: sticky !important;
    // top: 50px;
    // left: 0;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #515254;
      vertical-align: middle;
      text-align: left;
      font-family: Poppins;
    }
  }
  .rt-resizable-header {
    padding: 4.5px 24px !important;
    border: none !important;
    overflow: unset !important;
  }
  .rt-tbody .rt-td {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    padding: 16px 24px;
    border: none !important;
    font-family: Muli !important;
    svg {
      // margin-right: 11px;
      ${'' /* width: 24px; */}
      height: 24px;
    }
  }
  .btn-group .btn-primary {
    background-color: transparent;
    color: #9c9ca3 !important;
    padding: 0px !important;
    margin: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    margin-right: 6px;
    &:hover {
      color: #ea734f !important;
      background-color: #faf1ef;
      opacity: 1;
    }
    &:active {
      color: #ea734f !important;
      background-color: #faf1ef !important;
      opacity: 1;
    }
    &:focus {
      color: #ea734f !important;
      background-color: #faf1ef !important;
      opacity: 1;
    }
    svg {
      font-size: 18px;
      margin-right: 0px;
      width: 20px;
      height: 20px;
    }
  }
  .btn-group .btn-primary.delete {
    &:hover {
      color: #f55b5b !important;
      background-color: #fbecec;
      opacity: 1;
    }
  }
  .btn-group {
    width: 100%;
  }
  .cr-checkbox [type='checkbox']:not(:checked) + label:before {
    padding: 7px;
    margin: 0 auto;
    border: 1px solid #9c9ca3;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .cr-checkbox label {
    margin: 0px;
  }
  .rounded-circle {
    margin-right: 10px;
    border: 1px solid #22294e;
  }
  .rt-resizable-header-content .cr-checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
    justify-content: flex-end;
    width: 100%;
  }
  .rt-resizable-header-content .cr-checkbox label {
    display: block;
    text-align: center;
  }
  .cr-page .row .col,
  .cr-page .row > [class^='col-'] {
    margin-bottom: 0 !important;
  }
  .user-main-Wrap .rt-tr-group .rt-tr {
    &:hover {
      background: #f8e6df;
    }
  }
`;
export const NoRecord = styled.div`
  h3 {
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #22294e;
  }
  svg {
    margin-bottom: 20px;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  p {
    margin: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #ea734f;
    margin-left: 8px;
  }
`;

export const InitialLoader = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 3rem;
  p {
    margin: 0px;
    font-weight: normal;
    font-size: 24px !important;
    line-height: 32px !importatn;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #ea734f;
    margin-left: 8px;
  }
`;

export const NameAvatar = styled.span`
  background: #ffffff;
  border: 1px solid #22294e;
  box-sizing: border-box;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #22294e;
  font-family: Poppins;
  text-transform: uppercase;
  margin-right: 8px;
`;

export const DisplayName = styled(Box)`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  p.dispaly-nametext {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    margin-bottom: 0;
    word-break: break-word;
    white-space: pre-wrap;
  }
  .displayAvatar {
    margin-right: 8px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .dispaly-role {
    font-family: Mulish;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    margin-bottom: 0;
  }
`;

export const BannerButton = styled.button`
  background-color: ${(p) => (p.exists ? '#fff' : '#FCF7F4')};
  color: #ea734f;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  min-height: 40px;
  min-width: 200px;
  border: ${(p) => (p.exists ? '1px solid #EA734F' : 'none')};
  border-radius: 5px;
  margin-top: 0px;
  transition: all 0.5s;
  &:hover {
    background-color: ${(p) => (p.active ? '#F5B399' : '#ea734f')};
    color: #fff !important;
  }
  &.active {
    background-color: #ea734f;
    color: #fff;
    :hover {
      background-color: #f5b399;
      border-color: #f5b399;
      color: #fff;
    }
  }
  &:focus {
    outline: none;
  }
  padding-left: 20px;
  padding-right: 20px;
  visibility: ${(p) => (p.disable ? 'hidden' : 'visible')};
  @media (max-width: 500.98px) {
    min-width: initial;
    font-size: 14px;
  }
`;
