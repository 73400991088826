export const FETCH_SERVERS_REQUEST = "FETCH_SERVERS_REQUEST";
export const FETCH_SERVERS_SUCCESS = "FETCH_SERVERS_SUCCESS";
export const FETCH_SERVERS_ERROR = "FETCH_SERVERS_ERROR";

export const FETCH_SERVER_REQUEST = "FETCH_SERVER_REQUEST";
export const FETCH_SERVER_SUCCESS = "FETCH_SERVER_SUCCESS";
export const FETCH_SERVER_ERROR = "FETCH_SERVER_ERROR";

export const ADD_SERVER_REQUEST = "ADD_SERVER_REQUEST";
export const ADD_SERVER_SUCCESS = "ADD_SERVER_SUCCESS";
export const ADD_SERVER_ERROR = "ADD_SERVER_ERROR";

export const UPDATE_SERVER_REQUEST = "UPDATE_SERVER_REQUEST";
export const UPDATE_SERVER_SUCCESS = "UPDATE_SERVER_SUCCESS";
export const UPDATE_SERVER_ERROR = "UPDATE_SERVER_ERROR";

export const DELETE_SERVER_REQUEST = "DELETE_SERVER_REQUEST";
export const DELETE_SERVER_SUCCESS = "DELETE_SERVER_SUCCESS";
export const DELETE_SERVER_ERROR = "DELETE_SERVER_ERROR";

export const CHANGE_STUDIO_STEP = "CHANGE_STUDIO_STEP";
