import * as React from "react";
import history from "components/History";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import Avatar from "../Avatar";
import { FaUser, FaCheckCircle, FaEllipsisH, FaCog } from "react-icons/lib/fa";
import classnames from "classnames";
import { FormattedMessage } from "react-intl"
import bn from "utils/bemnames";
const bem = bn.create("select-team");

class SelectTeam extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  static propTypes = {
    mode: PropTypes.string
  };

  static defaultProps = {
    mode: "edit"
  };

  roleClicked = (role) => {
    if (this.state.selectedRole === role)
      this.setState({ selectedRole: null })
    else
      this.setState({ selectedRole: role })
  }

  userInfo = (user) => {
    const action_items = user.info.availability.action_items;
    const upcoming_items = user.info.availability.upcoming_items;
    return (
      <div className={bem.e('user-tasks')}>
        <div className={classnames(bem.e('user-task'), bem.e('user-task-current'))}>
          <label><FormattedMessage id="select team > current tasks" />:</label>
          <span>{action_items}</span>
        </div>
        <div className={classnames(bem.e('user-task'), bem.e('user-task-assigned'))}>
          <label><FormattedMessage id="select team > assigned tasks" />:</label>
          <span>{upcoming_items}</span>
        </div>
        <div className={bem.e('user-action')}>
          <FaEllipsisH color={`#798186`} size={20} />
        </div>
      </div>
    )
  }

  toggleUserRole = (user, role) => {
    let { step, template, process } = this.props
    let item = step.fields.find(f => f.field === role.field)
    if (process.data.fields[role.field]
      && parseInt(process.data.fields[role.field].value.id) === user.info.id
    ) {
      this.props.valueChanged(item, process, template
        , user
        , { value: { id: 0, name: '', role: 0, avatar_url: '' } })
    }
    else {
      let candidate_for=[]
      let role_id=0
      user.candidate_for.map(c=>{c.roles.map(r=>{if(candidate_for.indexOf(r)===-1 && role.roles.indexOf(r)>-1)candidate_for.push(r);return true});return true })
      role_id=candidate_for[0]
      this.props.valueChanged(item, process, template
        , user
        , { value: { id: user.info.id, name: user.info.name, role: role_id, avatar_url: user.info.avatar_url } })
    }

  }
  renderForm() {
    let { process, template, user, step, validationState } = this.props
    let info = this.props.info
    let userInfo = this.userInfo.bind(this)
    let toggleUserRole = this.toggleUserRole.bind(this)

    let roles = [];

    let role_candidates = {};
    roles.map(role => {
      if (!this.state.selectedRole || this.state.selectedRole === role) {
        role_candidates[role.field] = this.props.getRoleCandidates(role, process, template, user)
      }
      return true
    })
    let all_candidates = []
    Object.keys(role_candidates).map(role_key => {
      let candidates = role_candidates[role_key]
      candidates.map(candidate => {
        if (!all_candidates.find(c => c.info.id === candidate.info.id)) all_candidates.push({ ...candidate, candidate_for: [] })
        all_candidates.find(c => c.info.id === candidate.info.id).candidate_for.push(roles.find(r => r.field === role_key))
        return true
      })
      return true
    })

    Object.keys(role_candidates).map(role_key => {
      let candidates = role_candidates[role_key]
      if(candidates.length===1 && !(process.data.fields[role_key] && process.data.fields[role_key].value && process.data.fields[role_key].value.id !== 0)){
        // role was not assigned and there is just one candidate
        let user = all_candidates.find(c => c.info.id === candidates[0].info.id)
        let role=user.candidate_for.find(role=>role.field===role_key)
        toggleUserRole(user, role)
        process.data.fields[role_key]={is_dirty: true,value : { id: user.info.id, name: user.info.name, role: user.info.role }}
      }
      return true
    })

    let roles_row = (
      <div className={bem.e('roles')}>
        {roles.map((role,ind) => {
          let assigned = (process.data.fields[role.field] && process.data.fields[role.field].value && process.data.fields[role.field].value.id !== 0)
          let avatar_url= !assigned ? '' : info.users.find(u => u.id === parseInt(process.data.fields[role.field].value.id)) 
            ? info.users.find(u => u.id === parseInt(process.data.fields[role.field].value.id)).avatar_url
            : process.data.fields[role.field].value.avatar_url ? process.data.fields[role.field].value.avatar_url : ''
          return <div
            key={role.field+'_'+ind}
            onClick={() => this.roleClicked(role)}
            className={classnames(bem.e('role'), {
              [bem.e('role-selected')]: this.state.selectedRole === role
            })}
          >
            {assigned ? (
              <div className={bem.e('role-info')}>
                <div className={bem.e('assigned-user-info')}>
                  {avatar_url ? <Avatar src={avatar_url} size={20} className={bem.e('assigned-user-avatar')}/>
                  : <FaUser size={30} color="white" />}
                  <div className={bem.e('checked')}>
                    <FaCheckCircle color="#10BA37" size={20} />
                  </div>
                </div>
                <div className={bem.e("role-left")}>
                  <span className={bem.e('assigned-user-name')}>{process.data.fields[role.field].value.name}</span>
                  <span className={classnames(bem.e('role-title'), bem.e('assigned'))}>{role.title}</span>
                </div>
              </div>
            ): (
              <div className={bem.e('role-info')}>
                <div className={bem.e('role-image')}>
                  <FaUser size={30} color="white" />
                </div>
                <span className={bem.e('role-title')}>{role.title}</span>
              </div>
            )}
            <div className="alert-danger">{validationState[role.field] && validationState[role.field].validation_error}</div>
          </div>
        }
        )}
      </div>
    )
    let roles_filter = (
      <div className={bem.e('filters')}>
        {roles.map((role,ind) => (
          <div
            key={role.field+'_'+ind}
            onClick={() => this.roleClicked(role)}
            className={classnames(bem.e('filter-role'), {
              [bem.e('filter-selected')]: this.state.selectedRole === role
            })}
          >
            {role.title}
          </div>
        )
        )}
      </div>
    )
    let users_row = (
      <div className={bem.e('container-users')}>
        {all_candidates.map((user,ind) => {
          if (this.state.selectedRole
            && !user.candidate_for.find(r => r.field === this.state.selectedRole.field)) return null
          //|| role_candidates[role.field]
          return (
            <div key={'user_' + ind} className={bem.e('user-row')}>

              <div className={bem.e('user-info')}>
                <Avatar src={user.info.avatar_url} size={40} className={bem.e('user-avatar')} />
                <span className={bem.e('user-name')}>{user.info.name}</span>
              </div>

              <div className={bem.e('user-roles')}>
                {user.candidate_for.map(role => {
                  return (
                    <div
                      className={classnames(bem.e('user-role-button'),{
                        [bem.e('user-role-assigned')]: process.data.fields[role.field] && parseInt(process.data.fields[role.field].value.id) === user.info.id,
                        // [bem.e('user-role-disabled')]: true <== add condition for show disabled style
                      })}
                      key={'user_role_' + user.info.id + '_' + role.field}
                      onClick={() => toggleUserRole(user, role)}
                    >
                      <span className={bem.e('user-role-title')}>{role.title}</span>
                    </div>
                  )
                })}
              </div>

              {userInfo(user)}
            </div>
          )
        }
        )}
      </div>
    )


    return (
      <div className={bem.b()}>
        <Row>
          {roles_row}
        </Row>
        {this.props.mode === "edit" &&
          (
            <div>
              <Row className={bem.e('filter-row')}>
                {roles_filter}
                <div className={bem.e('settings')} onClick={() => history.push('/users')}>
                  <FormattedMessage id="select team > manage team" />
                  <FaCog size={30} />
                </div>
              </Row>
              <Row>
                {users_row}
              </Row>
            </div>
          )
        }
      </div>
    )
  }

  render() {
    return this.renderForm();
  }
}

export default SelectTeam;
