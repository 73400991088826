import { FormattedMessage } from 'react-intl';
import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
/* eslint-disable  */
export const default_templates = [
  {
    _index: 'templates',
    _type: 'template',
    _id: 'course',
    _score: 1,
    _source: {
      key: 'course',
      templates_name: 'Course',
      templates_description: 'Create, ciew, edit, and track a course',
      no_tabs: true,
      steps: {
        course_details: {
          process_step_index: 1,
          key: 'course_details',
          caption_color: '#CC1EE6',
          title: 'Course details',
          step_roles: [3, 4],
          step_viewers_roles: [3, 4],
          step_editors_roles: [3, 4],
          description: null,
          priviliges: [],
          status: 'initial',
          disabled_until_completed_steps: [],
          // "step_container": "none",
          // "no_edit_button":true,
          fields: [
            {
              component: 'class_details',
            },
            {
              field: 'form_step',
            },
            {
              field: 'class_name',
            },
            {
              field: 'class_capacity',
            },
            {
              field: 'class_level',
            },
            {
              field: 'start_date',
            },
            {
              field: 'end_date',
            },
            {
              field: 'recurring_event',
            },
            {
              field: 'recurring_type',
            },
            {
              field: 'recurring_number',
            },
            {
              field: 'recurring_repeat_on',
            },
            {
              field: 'recurring_repeat_on_monthly',
            },
            {
              field: 'location',
            },
            {
              field: 'room',
            },
            {
              field: 'event_note',
            },
            {
              field: 'event_guests',
            },
            {
              key: 'save_information',
              text: <FormattedMessage id="process > button submit" />,
              draft: false,
              on_click: {
                validate: true,
                save: true,
              },
              ignored_fields: [
                'start_date',
                'end_date',
                'start_date_ts',
                'end_date_ts',
                'recurring_event',
                'recurring_type',
                'recurring_repeat_on',
                'recurring_number',
                'event_note',
                'event_guests',
                'recurring_repeat_on_monthly',
              ],
              fields: [
                {
                  key: 'course_details_status',
                  value: (_user, _template, process, _step, _item) => {
                    const status =
                      get(
                        process,
                        'data.loaded_fields.course_details_status.value',
                        'draft'
                      ) || 'draft';

                    return status === 'completed' ? 'draft' : status;
                  },
                },
                {
                  key: 'is_schedule',
                  value: (_user, _template, _process, _step, item) => {
                    const isSchedule =
                      get(
                        process,
                        'data.loaded_fields.is_schedule.value',
                        false
                      ) || false;
                    return isSchedule;
                  },
                },
              ],
            },
            {
              key: 'save_as_draft',
              text: <FormattedMessage id="process > button submit" />,
              draft: false,
              on_click: {
                validate: true,
                save: true,
              },
              fields: [
                {
                  key: 'course_details_status',
                  value: 'draft',
                },
                {
                  key: 'is_schedule',
                  value: true,
                },
                {
                  key: 'start_date_ts',
                  value: (_user, _template, process, _step, _item) => {
                    const date = get(process, 'data.fields.start_date.value');
                    if (!date) return '';
                    const convertTS = parseInt(moment(date).format('X'));
                    return convertTS;
                  },
                },
                {
                  key: 'end_date_ts',
                  value: (_user, _template, process, _step, _item) => {
                    const date = get(process, 'data.fields.end_date.value');
                    if (!date) return '';
                    const convertTS = parseInt(moment(date).format('X'));
                    return convertTS;
                  },
                },
              ],
            },
            {
              key: 'save_schedule_now',
              text: <FormattedMessage id="process > button submit" />,
              draft: false,
              on_click: {
                validate: true,
                save: true,
              },
              fields: [
                {
                  key: 'course_details_status',
                  value: 'active',
                },
                {
                  key: 'is_schedule',
                  value: true,
                },
                {
                  key: 'start_date_ts',
                  value: (_user, _template, process, _step, _item) => {
                    const date = get(process, 'data.fields.start_date.value');
                    if (!date) return '';
                    const convertTS = parseInt(moment(date).format('X'));
                    return convertTS;
                  },
                },
                {
                  key: 'end_date_ts',
                  value: (_user, _template, process, _step, _item) => {
                    const date = get(process, 'data.fields.end_date.value');
                    if (!date) return '';
                    const convertTS = parseInt(moment(date).format('X'));
                    return convertTS;
                  },
                },
                {
                  key: 'from',
                  value: (_user, _template, process, _step, item) => {
                    let from = get(process, 'data.loaded_fields.from.value');

                    const guestsInDB =
                      get(
                        process,
                        'data.loaded_fields.event_guests.value',
                        []
                      ) || [];

                    const guestsInClient =
                      get(process, 'data.fields.event_guests.value', []) || [];

                    let newGuest =
                      guestsInClient.filter((guest) => {
                        const guestExist = guestsInDB.find(
                          (item) => item.id === guest.id
                        );
                        if (!guestExist) return true;
                        return false;
                      }) || [];

                    if (newGuest.length > 0) {
                      let message = '';

                      if (newGuest.length > 1) {
                        message =
                          newGuest.map((item) => item.name).join(', ') +
                          ` have been added to the chat.`;
                      } else {
                        message =
                          newGuest.map((item) => item.name).join(', ') +
                          ` has been added to the chat.`;
                      }

                      from.system = true;
                      from.chat = message;
                    }

                    return from;
                  },
                },
              ],
            },
          ],
        },
      },
      active: true,
    },
  },
  {
    _index: 'templates',
    _type: 'template',
    _id: 'course_old',
    _score: 1,
    _source: {
      key: 'course_old',
      templates_name: 'Course_old',
      templates_description: 'Create, ciew, edit, and track a course',
      steps: {
        course_details: {
          process_step_index: 1,
          key: 'course_details',
          caption_color: '#CC1EE6',
          title: 'Course details',
          step_roles: [3, 4],
          step_viewers_roles: [3, 4],
          step_editors_roles: [3, 4],
          description: null,
          priviliges: [],
          status: 'initial',
          disabled_until_completed_steps: [],
          // "step_container": "none",
          // "no_edit_button":true,
          fields: [
            {
              field: 'course_name',
              title: 'Course Name',
              mandatory: true,
              component: 'text',
            },
            {
              field: 'is_team',
              label: 'Team',
              mandatory: true,
              component: 'checkbox',
            },
            {
              field: 'course_studios',
              title: 'Course Studios',
              component: 'select_studios',
              mandatory: true,
              validation: function (
                step,
                item,
                process,
                template,
                user,
                value
              ) {
                return value.length > 0
                  ? true
                  : 'At least one studio has to be selected';
              },
            },
            {
              field: 'course_type',
              title: 'Type',
              mandatory: true,
              component: 'select',
              validation: function (
                step,
                item,
                process,
                template,
                user,
                value
              ) {
                return value ? true : 'Please select a course type';
              },
              props: {
                options: [
                  { label: 'Balet', value: 'Balet' },
                  { label: 'Jazz', value: 'Jazz' },
                ],
              },
            },
            {
              field: 'course_description',
              component: 'text_timer',
              hide_open_in_new_window: true,
              props: {
                title: 'Description',
                noTimer: true,
              },
              mandatory: false,
            },
            {
              field: 'role_intructor',
              component: 'select_user',
              title: 'instructor',
              roles: [3],
              validation: function (
                step,
                item,
                process,
                template,
                user,
                value
              ) {
                return value != '' && value.id
                  ? true
                  : 'Please select an instructor';
              },
            },
            {
              field: 'role_intructor_subtitute_1',
              component: 'select_user',
              title: 'Instructor first subtitute',
              roles: [3],
            },
            {
              field: 'role_intructor_subtitute_2',
              component: 'select_user',
              title: 'Instructor second subtitute',
              roles: [3],
            },
            {
              text: <FormattedMessage id="process > button submit" />,
              component: 'button',
              container: 'bottom_buttons',
              confirmation_dialog_props: {
                description: (
                  <FormattedMessage id="process > description to submit this course" />
                ),
                confirmTitle: <FormattedMessage id="process > button yes" />,
                cancelTitle: <FormattedMessage id="process > button no" />,
              },
              success_dialog_props: {
                title: <FormattedMessage id="process > title submitted" />,
                state: 'success',
              },
              props: { color: 'primary' },
              on_click: {
                validate: true,
                save: true,
              },
              fields: [
                {
                  key: 'course_details_status',
                  value: 'submitted',
                },
                {
                  key: 'process_step',
                  value: 'participants',
                },
              ],
            },
          ],
        },
        participants: {
          process_step_index: 2,
          key: 'participants',
          caption_color: '#6803CC',
          title: 'participants',
          description: '',
          step_roles: [3, 4],
          step_viewers_roles: [3, 4],
          step_editors_roles: [3, 4],
          status: 'initial',
          // "step_container": "none",
          // "className": "tab-assign-tem",
          fields: [
            {
              component: 'participants',
            },
            {
              field: 'participants',
              title: <FormattedMessage id="process > field participants" />,
              roles: [
                'Parent',
                'Student',
                'Instructor',
                'Studio Admin',
                'Studio Owner',
                'Fan',
                'Account Manager',
              ],
            },
            {
              key: 'save_button',
              component: 'button',
              container: 'bottom_buttons',
              text: <FormattedMessage id="process > button save" />,
              props: { color: 'primary' },
              confirmation_dialog_props: {
                description: (
                  <FormattedMessage id="process > description to submit these assignments" />
                ),
                confirmTitle: <FormattedMessage id="process > button yes" />,
                cancelTitle: <FormattedMessage id="process > button no" />,
              },
              success_dialog_props: {
                title: <FormattedMessage id="process > title submitted" />,
                state: 'success',
              },
              class: null,
              on_click: {
                save: true,
              },
              fields: [
                {
                  key: 'participants_status',
                  value: 'submitted',
                },
                {
                  key: 'process_step',
                  value: 'attendance',
                },
              ],
            },
          ],
        },
        attendance: {
          process_step_index: 3,
          key: 'attendance',
          title: 'Attendance',
          caption_color: '#CC1EE6',
          description: '',
          step_roles: [10],
          step_viewers_roles: [10, 4, 7, 2],
          step_editors_roles: [10, 4],
          status: 'initial',
          fields: [
            {
              component: 'attendance',
            },
            {
              field: 'attendance',
              title: <FormattedMessage id="process > field attendance" />,
              roles: [9],
            },
            {
              text: <FormattedMessage id="process > button submit" />,
              component: 'button',
              container: 'bottom_buttons',
              confirmation_dialog_props: {
                description: (
                  <FormattedMessage id="process > description to submit this course" />
                ),
                confirmTitle: <FormattedMessage id="process > button yes" />,
                cancelTitle: <FormattedMessage id="process > button no" />,
              },
              success_dialog_props: {
                title: <FormattedMessage id="process > title submitted" />,
                state: 'success',
              },
              props: { color: 'primary' },
              on_click: {
                validate: true,
                save: true,
              },
              fields: [
                {
                  key: 'attendance_status',
                  value: 'submitted',
                },
                {
                  key: 'process_step',
                  value: 'schedule',
                },
              ],
            },
          ],
        },
        schedule: {
          process_step_index: 4,
          key: 'events',
          title: 'schedule',
          caption_color: '#CC1EE6',
          description: '',
          step_roles: [1, 2, 3],
          step_viewers_roles: [1, 2, 3],
          step_editors_roles: [1, 2, 3],
          status: 'initial',
          fields: [
            {
              component: 'label',
              value: 'Events',
              props: { className: 'form-label' },
            },
            {
              field: 'script',
              component: 'text_timer',
              hide_open_in_new_window: true,
              props: {
                readOnly: true,
                className: 'form-div',
                isOrderLength: true,
              },
            },
          ],
        },
        log: {
          process_step_index: 10,
          key: 'log',
          title: <FormattedMessage id="process > title log" />,
          no_edit_button: true,
          description: (
            <FormattedMessage id="process > description activity log" />
          ),
          step_roles: [],
          step_viewers_roles: [],
          step_editors_roles: [],
          status: 'initial',
          fields: [
            {
              component: 'log',
            },
          ],
        },
      },
      active: true,
    },
  },
];
