import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DisplayName, InitialLoader, Loader, UserListWrap } from './styles';
import history from '../../components/History';
import StyledAvatar from '../../components/StyledUI/StyledAvatar';
import { Box, Button, CircularProgress } from '@mui/material';
import { formatPhoneNumber } from '../../utils/helpers';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Col, Row } from 'reactstrap';
import AddIcon from '@mui/icons-material/Add';
import { get, groupBy, omit } from 'lodash';
import {
  PrimaryButton,
  PrimaryButtonLink,
} from '../../components/StyledUI/Buttons';
import PrintIcon from '@mui/icons-material/Print';
import ExportIcon from '../../components/SvgIcons/ExportIcon';
import { Pagination } from '@mui/lab';
import Collapse from '@mui/material/Collapse';
import Materialtable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditPencil from '../../components/SvgIcons/EditPencil';
import { useFormik } from 'formik';
import TextInput from '../../components/FormFields/InputText';
import { errorSvg } from '../Profile/components/PaymentHistory/util';
import moment from 'moment';
import { ValidationError } from '../../components/AddUserForm/styles';
import Select from '../../components/FormFields/Select';
import {
  addRoom,
  addStaff,
  deleteRoom,
  deleteStaff,
  updateRoom,
  updateStaff,
} from '../../store/actions/users';
import qs from 'query-string';
import * as Yup from 'yup';
import ToastManager from '../../components/ToastManager';
import { downloadCSV } from '../Feed/util';
import ReactToPrint from 'react-to-print';
import Typography from '@mui/material/Typography';
import ClockIcon from 'components/SvgIcons/ClockIcon';
import { convertTimeFormat } from 'utils/helpers.js'
import { useSelector } from 'react-redux';

const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
const SEACONDARY_DATE_FORMAT = 'MM/DD/YYYY';
export const TABS_OPTIONS = {
  STAFF: 'staff',
  ROOM: 'rooms',
};

export const TABS_OPTIONS_SEACONDARY = {
  AVAILABLE: {
    name: 'Available',
    route: 'available',
  },
  UNAVAILABLE: {
    name: 'Unavailable',
    route: 'unavailable',
  },
};

export const LoadingImg = (width, height) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.875 1.875C11.875 2.91055 11.0355 3.75 10 3.75C8.96445 3.75 8.125 2.91055 8.125 1.875C8.125 0.839453 8.96445 0 10 0C11.0355 0 11.875 0.839453 11.875 1.875ZM10 16.25C8.96445 16.25 8.125 17.0895 8.125 18.125C8.125 19.1605 8.96445 20 10 20C11.0355 20 11.875 19.1605 11.875 18.125C11.875 17.0895 11.0355 16.25 10 16.25ZM18.125 8.125C17.0895 8.125 16.25 8.96445 16.25 10C16.25 11.0355 17.0895 11.875 18.125 11.875C19.1605 11.875 20 11.0355 20 10C20 8.96445 19.1605 8.125 18.125 8.125ZM3.75 10C3.75 8.96445 2.91055 8.125 1.875 8.125C0.839453 8.125 0 8.96445 0 10C0 11.0355 0.839453 11.875 1.875 11.875C2.91055 11.875 3.75 11.0355 3.75 10ZM4.25477 13.8702C3.21922 13.8702 2.37977 14.7097 2.37977 15.7452C2.37977 16.7808 3.21922 17.6202 4.25477 17.6202C5.29031 17.6202 6.12977 16.7808 6.12977 15.7452C6.12977 14.7097 5.29027 13.8702 4.25477 13.8702ZM15.7452 13.8702C14.7097 13.8702 13.8702 14.7097 13.8702 15.7452C13.8702 16.7808 14.7097 17.6202 15.7452 17.6202C16.7808 17.6202 17.6202 16.7808 17.6202 15.7452C17.6202 14.7097 16.7808 13.8702 15.7452 13.8702ZM4.25477 2.37977C3.21922 2.37977 2.37977 3.21922 2.37977 4.25477C2.37977 5.29031 3.21922 6.12977 4.25477 6.12977C5.29031 6.12977 6.12977 5.29031 6.12977 4.25477C6.12977 3.21922 5.29027 2.37977 4.25477 2.37977Z"
      fill="#ea734f"
    />
  </svg>
);

export const columns = ({ gotoUserDetail, params, seadondaryLoader }) => [
  {
    Header: <FormattedMessage id="user > name" />,
    accessor: 'name',
    style: {
      backgroundColor: '#FCFCFC',
    },
    headerStyle: {
      width: 100,
      height: 72,
      justifyContent: 'center',
    },
    Cell: ({ original: user }) => {
      return (
        <DisplayName
          onClick={(e) => {
            history.push(
              `/studio-available/staff/${user.id}?name=${get(user, 'name', '')}`
            );
          }}
        >
          <StyledAvatar url={user.avatar_url} name={user.name} />
          <Box className="dispaly-nametext">{user.name}</Box>
        </DisplayName>
      );
    },
  },

  {
    Header: <span>Email</span>,
    accessor: 'email',
    minWidth: 100,
    style: {
      backgroundColor: '#FCFCFC',
    },
    headerStyle: {
      width: 50,
      height: 72,
      justifyContent: 'center',
    },
  },
  {
    Header: <span>Contact</span>,
    accessor: 'phone',
    minWidth: 50,
    style: {
      backgroundColor: '#FCFCFC',
    },
    headerStyle: {
      width: 50,
      height: 72,
      justifyContent: 'center',
    },
    Cell: ({ original: user }) => {
      return <div>{formatPhoneNumber(user.phone)}</div>;
    },
  },
  {
    Header: '',
    accessor: 'action',
    minWidth: 80,
    style: {
      backgroundColor: '#FCFCFC',
      justifyContent: 'end',
    },
    headerStyle: {
      width: 80,
      height: 72,
      justifyContent: 'center',
    },
    Cell: ({ original: user }) => {
      return (
        <PrimaryButton
          onClick={() => {
            history.push(
              `/studio-available/staff/${user.id}?name=${get(user, 'name', '')}`
            );
          }}
          color="primary"
          variant={'contained'}
          aria-label="add an alarm"
        >
          View Schedule
        </PrimaryButton>
      );
    },
  },
];

export const roomColumns = ({ seadondaryLoader }) => [
  {
    Header: <span>Room</span>,
    accessor: 'room_name',
    style: {
      backgroundColor: '#FCFCFC',
    },
    headerStyle: {
      width: 100,
      height: 72,
      justifyContent: 'center',
    },
    Cell: ({ original: user }) => (
      <Typography
        variant={'paragraphBold'}
        onClick={(e) => {
          history.push(
            `/studio-available/rooms/${user.id}?room_name=${get(
              user,
              'room_name',
              ''
            )}`
          );
        }}
      >
        {get(user, 'room_name', '')}
      </Typography>
    ),
  },
  {
    Header: <span>Location</span>,
    accessor: 'location.location_name',
    style: {
      backgroundColor: '#FCFCFC',
    },
    headerStyle: {
      width: 100,
      height: 72,
      justifyContent: 'center',
    },
    Cell: ({ original: user }) => get(user, 'location.location_name', ''),
  },
  // {
  //   Header: <span>Description</span>,
  //   accessor: 'description',
  //   minWidth: 100,
  //   style: {
  //     backgroundColor: '#FCFCFC',
  //   },
  //   headerStyle: {
  //     width: 50,
  //     height: 72,
  //     justifyContent: 'center',
  //   },
  //   Cell: ({ original: user }) => get(user, 'description', ''),
  // },
  {
    Header: '',
    accessor: 'action',
    minWidth: 80,
    style: {
      backgroundColor: '#FCFCFC',
      justifyContent: 'end',
    },
    headerStyle: {
      width: 80,
      height: 72,
      justifyContent: 'center',
    },
    Cell: ({ original: user }) => {
      return (
        <PrimaryButton
          onClick={() => {
            history.push(
              `/studio-available/rooms/${user.id}?room_name=${get(
                user,
                'room_name',
                ''
              )}`
            );
          }}
          variant="contained"
          aria-label="add an alarm"
        >
          View Schedule
        </PrimaryButton>
      );
    },
  },
];

export const fetchingRecords = ({ loading, data }) => {
  if ((loading === null || loading === true) && data.length === 0) {
    return (
      <InitialLoader>
        {LoadingImg(30, 30)}
        <p
          style={{
            marginLeft: '5px',
            fontSize: '14px',
            color: '#EA734F',
            lineHeight: '19px',
          }}
        >
          Loading users...
        </p>
      </InitialLoader>
    );
  }
  if (loading === false) {
    return null;
  }
  return (
    <Loader>
      {LoadingImg(20, 20)}
      {'  '}{' '}
      <p
        style={{
          marginLeft: '5px',
          fontSize: '14px',
          color: '#EA734F',
          lineHeight: '19px',
        }}
      >
        Loading More
      </p>
    </Loader>
  );
};

const isValidationError = ({ rest, action }) => {
  if (rest.errors && action && rest.errors[action]) {
    return (
      <span>
        {errorSvg('array')}
        {rest.errors[action]}
      </span>
    );
  } else {
    return false;
  }
};
const Schema = {
  description: Yup.string().nullable(),
  start_time: Yup.string().required('This field is required.'),
  end_time: Yup.string().required('This field is required.'),
  location_id: Yup.string().required('This field is required.'),
  start_date: Yup.date().required('This field is required.'),
  end_date: Yup.date()
    .required('End date is required')
    .min(Yup.ref('start_date'), "End date can't be before Start date"),
};

function generateHourlyLabels() {
  return Array.from({ length: 24 }, (_, i) => {
    const label = moment({ hour: i }).format('h A');
    return {
      label: label,
      value: label,
    };
  });
}

const RowForm = ({
  parentProps,
  status,
  item,
  setAvalableData,
  name,
  ...props
}) => {
  const userId = get(parentProps, 'match.params.id', '');
  const type = get(parentProps, 'type', 'staffs') || 'staffs';
  const [loading, setLoading] = useState(false);
  const studioLocations = useSelector(({ auth }) =>
    get(auth, 'info.current_studio.locations', [])
  ).map((i) => ({
    label: i.location_name,
    value: i.id,
  }));
  const [seadondaryLoader, setSeadondaryLoader] = useState(false);
  const validationSchema =
    type === TABS_OPTIONS.STAFF ? Schema : omit(Schema, ['location_id']);
  const formik = useFormik({
    initialValues: {
      ...item,
      start_date: moment(item.start_date).startOf('day'),
    },
    validationSchema: Yup.object().shape(validationSchema),
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const postData = {
          user_id: userId,
          location_id: values.location_id,
          day_of_week: values.day_of_week,
          start_time: values.start_time,
          end_time: values.end_time,
          start_date: moment(values.start_date).format(DEFAULT_DATE_FORMAT),
          end_date: values.end_date
            ? moment(values.end_date).format(DEFAULT_DATE_FORMAT)
            : null,
          available: status === TABS_OPTIONS_SEACONDARY.AVAILABLE.route,
          description: values.description,
        };
        let res = null;
        if (type === TABS_OPTIONS.STAFF) {
          if ('edit' in values) {
            await updateStaff({
              data: postData,
              id: values.id,
            });
            delete values.add;
            delete values.edit;

            res = {
              payload: {
                data: values,
              },
            };
          } else {
            res = await addStaff({ data: postData });
            ToastManager.show({
              message: 'New schedule added',
              autoDismiss: 2,
              level: 'success',
            });
          }
        } else {
          const body = {
            room_id: userId,
            day_of_week: values.day_of_week,
            start_time: values.start_time,
            end_time: values.end_time,
            start_date: moment(values.start_date).format(DEFAULT_DATE_FORMAT),
            end_date: values.end_date
              ? moment(values.end_date).format(DEFAULT_DATE_FORMAT)
              : null,
            available: status === TABS_OPTIONS_SEACONDARY.AVAILABLE.route,
            description: values.description,
          };
          if ('edit' in values) {
            await updateRoom({
              data: body,
              id: values.id,
            });
            delete values.add;
            delete values.edit;

            res = {
              payload: {
                data: values,
              },
            };
          } else {
            res = await addRoom({ data: body });
          }
        }
        setAvalableData((prev) => {
          prev[status][name] = get(prev, `[${status}][${name}]`, []).map(
            (i, index) => {
              if (values.id === i.id) {
                return get(res, 'payload.data', {});
              }
              return i;
            }
          );
          return { ...prev };
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
  });
  const isAdd = item.add;
  const options = generateHourlyLabels();
  return (
    <TableBody>
      <TableRow sx={{ verticalAlign: 'initial' }}>
        {type === TABS_OPTIONS.STAFF && (
          <TableCell component="th" scope="row">
            {isAdd ? (
              <Box display={'flex'} flexDirection={'column'}>
                <Select
                  label={''}
                  value={studioLocations.find(
                    (item) => item.value === formik.values.location_id
                  )}
                  onChange={(item) => {
                    const { value } = item;
                    formik.setFieldValue(`location_id`, value);
                  }}
                  options={studioLocations}
                  text=""
                />
                <Box height={'20'}>
                  <ValidationError>
                    {isValidationError({ rest: formik, action: 'location_id' })}
                  </ValidationError>
                </Box>
              </Box>
            ) : (
              get(
                studioLocations.find((i) => i.value === item.location_id),
                'label',
                ''
              )
            )}
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          {isAdd ? (
            <Box display={'flex'} flexDirection={'column'}>
              <Select
                label={''}
                value={options.find(
                  (item) => item.value === formik.values.start_time
                )}
                onChange={(item) => {
                  const { value } = item;
                  formik.setFieldValue(`start_time`, value);
                }}
                options={options}
                text=""
              />
              <Box height={'20'}>
                <ValidationError>
                  {isValidationError({ rest: formik, action: 'start_time' })}
                </ValidationError>
              </Box>
            </Box>
          ) : (
            convertTimeFormat(item.start_time)
          )}
        </TableCell>
        <TableCell>
          {isAdd ? (
            <Box display={'flex'} flexDirection={'column'}>
              <Select
                label={''}
                value={options.find(
                  (item) => item.value === formik.values.end_time
                )}
                onChange={(item) => {
                  const { value } = item;
                  formik.setFieldValue(`end_time`, value);
                }}
                options={options}
                text=""
              />
              <Box height={'20'}>
                <ValidationError>
                  {isValidationError({ rest: formik, action: 'end_time' })}
                </ValidationError>
              </Box>
            </Box>
          ) : (
            convertTimeFormat(item.end_time)
          )}
        </TableCell>
        <TableCell>
          {isAdd ? (
            <TextInput
              label={null}
              hasCalendarIcon
              type="date"
              name={`start_date`}
              calednderColor={'#EA9277'}
              error={get(formik, `errors.start_date`, false)}
              className="w-100"
              selected={moment(
                formik.values.start_date,
                SEACONDARY_DATE_FORMAT
              )}
              onChange={(date) => {
                formik.setFieldValue(`start_date`, moment(date).startOf('day'));
              }}
            />
          ) : (
            moment(item.start_date).format(SEACONDARY_DATE_FORMAT)
          )}
        </TableCell>
        <TableCell>
          {isAdd ? (
            <TextInput
              label={null}
              hasCalendarIcon
              type="date"
              name={`end_date`}
              calednderColor={'#EA9277'}
              error={get(formik, `errors.end_date`, false)}
              className="w-100"
              selected={moment(formik.values.end_date, SEACONDARY_DATE_FORMAT)}
              onChange={(date) => {
                formik.setFieldValue(`end_date`, moment(date).endOf('day'));
              }}
            />
          ) : item.end_date ? (
            moment(item.end_date).format(SEACONDARY_DATE_FORMAT)
          ) : (
            'Ongoing'
          )}
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isAdd ? (
              <TextInput
                label={''}
                type="text"
                name="description"
                value={formik.values.description}
                error={formik.touched.description && formik.errors.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="inner-input mt-2"
              />
            ) : (
              item.description
            )}
          </Box>
        </TableCell>
        <TableCell align="right">
          {isAdd ? (
            <Stack gap={1} direction={'row'} justifyContent={'flex-end'}>
              <PrimaryButton
                onClick={formik.handleSubmit}
                variant={'contained'}
                loading={loading}
              >
                {!item.edit ? 'Add' : 'Save'}
              </PrimaryButton>
              <Button
                sx={{
                  color: '#EA734F',
                  background: '#FCF7F4',
                }}
                onClick={() => {
                  if (item.edit) {
                    setAvalableData((prev) => {
                      prev[status][name] = get(
                        prev,
                        `[${status}][${name}]`,
                        []
                      ).map((i) => {
                        if (item.id === i.id) {
                          i.add = false;
                          i.edit = false;
                        }
                        return i;
                      });
                      return { ...prev };
                    });
                    return;
                  }
                  if (item.add) {
                    setAvalableData((prev) => {
                      prev[status][name] = get(
                        prev,
                        `[${status}][${name}]`,
                        []
                      ).filter((i) => item.id !== i.id);
                      return { ...prev };
                    });
                  }
                }}
                variant={'text'}
              >
                Cancel
              </Button>
            </Stack>
          ) : item.editable ? (
            <Stack gap={1} direction={'row'} justifyContent={'flex-end'}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={async () => {
                  setAvalableData((prev) => {
                    prev[status][name] = get(
                      prev,
                      `[${status}][${name}]`,
                      []
                    ).map((i) => {
                      if (item.id === i.id) {
                        i.add = true;
                        i.edit = true;
                      }
                      return i;
                    });
                    return { ...prev };
                  });
                }}
              >
                <EditPencil />
              </IconButton>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={async () => {
                  try {
                    setSeadondaryLoader(true);
                    if (type === TABS_OPTIONS.STAFF) {
                      await deleteStaff({ id: item.id });
                    } else {
                      await deleteRoom({ id: item.id });
                    }
                    setAvalableData((prev) => {
                      prev[status][name] = get(
                        prev,
                        `[${status}][${name}]`,
                        []
                      ).filter((i) => item.id !== i.id);
                      return { ...prev };
                    });
                    setSeadondaryLoader(false);

                    ToastManager.show({
                      message: 'Successfully Deleted!',
                      autoDismiss: 2,
                      level: 'success',
                    });
                  } catch (e) {
                    setSeadondaryLoader(false);
                  }
                }}
              >
                {seadondaryLoader ? (
                  <CircularProgress size={30} color="primary" />
                ) : (
                  <DeleteIcon />
                )}
              </IconButton>
            </Stack>
          ) : null}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

function RowChild({
  name,
  parentProps,
  setAvalableData,
  status,
  row,
  ...props
}) {
  const type = get(parentProps, 'type', 'staffs') || 'staffs';
  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
        }}
      >
        <TableCell component="th" scope="row">
          {get(weekDays, `${name}`, '')}
        </TableCell>
        <TableCell align="right" sx={{ textAlign: 'end' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setAvalableData((prev) => {
                get(prev, `[${status}][${name}]`, []).push({
                  id: Date.now(),
                  day_of_week: name,
                  start_time: '',
                  end_time: '',
                  start_date: new Date(),
                  location: '',
                  end_date: new Date(),
                  add: true,
                  description: '',
                });
                return { ...prev };
              });
            }}
          >
            <AddIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {row.length > 0 && (
        <TableRow>
          <TableCell style={{ padding: '0rem 0rem 0rem 4rem' }} colSpan={6}>
            <Collapse in={true} timeout="auto" unmountOnExit>
              <Box sx={{ padding: '0rem 0rem 0rem 4rem' }}>
                <Materialtable size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {type === TABS_OPTIONS.STAFF && (
                        <TableCell style={{ width: '220px' }}>
                          Location
                        </TableCell>
                      )}
                      <TableCell>Start Time</TableCell>
                      <TableCell>End Time</TableCell>
                      <TableCell>Start Date</TableCell>

                      <TableCell>End Date</TableCell>
                      <TableCell style={{ width: '10%' }}>
                        Description
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {row.map((item) => (
                    <RowForm
                      name={name}
                      key={item.id}
                      setAvalableData={setAvalableData}
                      item={item}
                      parentProps={parentProps}
                      status={status}
                      {...props}
                    />
                  ))}
                </Materialtable>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export const StaffAvaliblity = ({
  selectedStatus,
  params,
  loading,
  setAvalableData,
  rawData = {},
  available = {},
  unavailable = {},
  parentProps,
  ...props
}) => {
  const query = window.location.search;
  const parsedQuery = qs.parse(query);
  const status =
    get(parsedQuery, 'status') || TABS_OPTIONS_SEACONDARY.AVAILABLE.route;
  const dataHash =
    status === TABS_OPTIONS_SEACONDARY.AVAILABLE.route
      ? available
      : unavailable;
  return (
    <>
      <div className={'root'}>
        <div className="userWrap">
          <UserListWrap>
            <Row>
              <Col>
                <TableContainer
                  sx={{
                    position: 'relative',
                    overflow: 'unset',
                    marginBottom: 15,
                  }}
                  component={Paper}
                >
                  {loading && (
                    <Box
                      width={'100%'}
                      display={'flex'}
                      justifyContent="center"
                      alignItems={'center'}
                      padding="2rem"
                      height={'500px'}
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  )}
                  <Materialtable aria-label="collapsible table">
                    <TableBody>
                      {Object.keys(dataHash).map((key) => (
                        <RowChild
                          setAvalableData={setAvalableData}
                          key={key}
                          name={key}
                          parentProps={parentProps}
                          row={dataHash[key]}
                          status={status}
                          {...props}
                        />
                      ))}
                    </TableBody>
                  </Materialtable>
                </TableContainer>
              </Col>
            </Row>
          </UserListWrap>
        </div>
      </div>
    </>
  );
};

export const params = new URLSearchParams(window.location.search);

export const weekDays = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const downloadHandle = ({
  data,
  activeTab,
  columns = [
    'Class Name',
    'Description',
    'Bank Name',
    'Email',
    'Amount',
    'Payment Date',
    'status',
  ],
}) => {
  downloadCSV({
    filename: `${activeTab}.csv`,
    data,
    columns,
  });
};

export const PaginationFooter = ({
  data,
  page,
  setPage,
  total,
  colums = [],
  activeTab,
  exportData = [],
  componentRef,
}) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
      {data.length === 0 ? (
        <Box />
      ) : (
        <Box display={'flex'} gap={'1rem'} alignItems={'center'}>
          <ReactToPrint
            trigger={() => (
              <PrimaryButtonLink startIcon={<PrintIcon />}>
                Print
              </PrimaryButtonLink>
            )}
            content={() => componentRef.current}
          />

          <PrimaryButtonLink
            startIcon={<ExportIcon stroke={'#EA734F'} />}
            onClick={() => {
              downloadHandle({
                data: exportData,
                columns: colums,
                activeTab,
              });
            }}
          >
            Export
          </PrimaryButtonLink>
        </Box>
      )}
      <Pagination
        count={total}
        page={page}
        color="secondary"
        shape="rounded"
        size="small"
        sx={{
          '& .Mui-selected': {
            fontFamily: 'Mulish',
            fontWeight: 400,
            fontSize: '14px',
            color: '#FFFFFF !important',
          },
        }}
        onChange={(event, page) => {
          setPage(page);
        }}
      />
    </Stack>
  );
};

export const groupByWeek = (value) => {
  const output = groupBy(value, 'day_of_week');
  const othervalues = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
  };
  return { ...othervalues, ...output };
};
