import 'babel-polyfill';
import React, { lazy, Suspense } from 'react';
import 'react-table/react-table.css';
import 'react-chat-elements/dist/main.css';
import 'emoji-mart/css/emoji-mart.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import GAListener from './components/GAListener';
import { addLocaleData, IntlProvider } from 'react-intl';

import get from 'lodash/get';
import Listener from 'listener';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import QueryString from 'query-string';
import { compose, lifecycle } from 'recompose';
import { connect, Provider } from 'react-redux';
import componentQueries from 'react-component-queries';
import { Redirect, Router, Switch } from 'react-router-dom';

// page end
import ToastManager, { Toast } from './components/ToastManager';

import { setIsNotification, setLanguage, userInfo } from 'store/actions/auth';
import store from './store';
import './styles/reduction.css';
import history from 'components/History';
import URL from './utils/urls';
import { getTranslates, isAuthenticated } from 'utils/helpers';
import { language as defaultLangCode, languageList } from './utils/config';
import { getChecklist } from 'store/actions/onboard';
import { addGuide, getGuide } from 'store/actions/guide';
import {
  getAppStoreUrl,
  getMobileOperatingSystem,
  getPlayStoreUrl,
} from './utils/helpers';

import NotificationsBarIframe from 'pages/NotificationsBarIframe/NotificationsBarIframe';
import Spinner from './components/Spinner';
import { logoutUser } from './store/actions/auth';
import ProtectedRoutes from './components/ProtectedRoutes';

// pages

const SetupStudio = lazy(() => import('./pages/StudioDetails/SetupStudio'));
const WaiverContent = lazy(() => import('./pages/WaiverContent'));
const ComingSoon = lazy(() => import('./pages/ComingSoon'));
const Home = lazy(() => import('./pages/Home'));

const Insights = lazy(() => import('./pages/Insights'));
const FamilyOnboarding = lazy(() => import('./pages/FamilyOnboarding'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const LayoutRoute = lazy(() => import('./components/Layout/LayoutRoute'));
const MainLayout = lazy(() => import('./components/Layout/MainLayout'));

const EmptyLayout = lazy(() => import('./components/Layout/EmptyLayout'));
const AuthLayout = lazy(() => import('./components/Layout/AuthLayout'));
const PublicLayout = lazy(() => import('./components/Layout/PublicLayout'));
const CatelogLayout = lazy(() => import('./components/Layout/CatelogLayout'));
const IframeLayout = lazy(() => import('./components/Layout/IframeLayout'));

const PrivacyPolicy = lazy(() =>
  import('./pages/PrivacyAndTerms/PrivacyPolicy')
);
const Auth = lazy(() => import('./pages/Auth'));

const SeasonsDetail = lazy(() =>
  import('./components/ProcessesList/Seasons/SeasonsDetail')
);
const ClassDetails = lazy(() => import('./pages/ClassDetails'));
const SignWaivers = lazy(() => import('./pages/SignWaivers'));
const StudioCatalog = lazy(() => import('./pages/StudioCatalog'));
const Punchpasses = lazy(() => import('./pages/Punchpasses'));
addLocaleData([...en, ...es]);

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const isComingSoon =
  process.env.REACT_APP_IS_COMING_SOON === 'true' ? true : false;

class Routes extends React.Component {
  componentDidMount() {
    this.detectMobileDeepLink();
  }

  detectMobileDeepLink() {
    const queryParams = QueryString.parse(window.location.search);
    const mobileOS = getMobileOperatingSystem();
    const isMobile = mobileOS === 'Android' || mobileOS === 'iOS';
    if (!!queryParams.deeplink && isMobile) {
      // if its mobile and url contains "deeplink" params then we want to redirect user to playstore or app store
      if (mobileOS === 'Android') window.location.href = getPlayStoreUrl();
      if (mobileOS === 'iOS') window.location.href = getAppStoreUrl();
    }
  }

  render() {
    const baseProps = this.props;
    const auth = store.getState().auth;
    let token = auth.token;
    const locale =
      findIndex(
        languageList,
        (item) => item.value === get(this.props, 'auth.user.lang')
      ) !== -1
        ? get(this.props, 'auth.user.lang')
        : defaultLangCode;
    const messages = get(this.props, 'auth.info.translates')
      ? get(this.props, 'auth.info.translates')
      : getTranslates(locale);

    const isGodAdmin =
      get(this.props, 'auth.user.is_god_admin', 0) &&
      get(this.props, 'auth.info.current_studio_id', 0) === 1;
    const isCheckListLoading = get(this.props, 'onboard.loading', false);
    const isSessionAuthenticated = isAuthenticated();
    const authenticated =
      isSessionAuthenticated && get(baseProps, 'auth.authenticated');
    const allStepCompleted =
      get(this.props, 'auth.user') &&
      get(this.props, 'checklists', []).some(
        (item) => item.item_completed === 'all'
      );

    return (
      <IntlProvider locale={locale} messages={messages}>
        <Suspense fallback={<Spinner isLoading />}>
          <Router basename={getBasename()} history={history}>
            <GAListener>
              <Switch>
                <LayoutRoute
                  exact
                  path={URL.HOME({ path: true })}
                  layout={
                    isComingSoon || isCheckListLoading
                      ? EmptyLayout
                      : MainLayout
                  }
                  render={(props) => (
                    <Home
                      isComingSoon={isComingSoon}
                      isCheckListLoading={isCheckListLoading}
                      baseProps={baseProps}
                      isGodAdmin={isGodAdmin}
                      allStepCompleted={allStepCompleted}
                    />
                  )}
                />
                {isComingSoon && <Redirect to={URL.HOME({ path: true })} />}
                <LayoutRoute
                  exact
                  path={URL.COMING_SOON({ path: true })}
                  layout={EmptyLayout}
                  render={(props) => <ComingSoon />}
                />
                <LayoutRoute
                  exact
                  path={URL.WALL({ path: true })}
                  layout={MainLayout}
                  render={(props) => {
                    if (!baseProps.auth.authenticated && !token) {
                      return <Redirect to={URL.LOGIN({ path: true })} />;
                    }
                    return <Dashboard {...props} />;
                  }}
                  component={Dashboard}
                />
                <LayoutRoute
                  path={URL.INSIGHTS({ path: true })}
                  layout={MainLayout}
                  render={(props) => {
                    if (!baseProps.auth.authenticated && !token) {
                      return <Redirect to={URL.LOGIN({ path: true })} />;
                    } else if (!allStepCompleted) {
                      return <Redirect to={URL.SETUP_STUDIO({ path: true })} />;
                    }
                    return <Insights {...props} />;
                  }}
                />

                <LayoutRoute
                  exact
                  path={URL.LOGIN({ path: true })}
                  layout={AuthLayout}
                  render={(props) => <Auth type="signin" key={'signin'} />}
                />
                <LayoutRoute
                  exact
                  path={URL.CALLBACK({ path: true })}
                  layout={AuthLayout}
                  render={(props) => <Auth type="callback" key={'callback'} />}
                />
                <LayoutRoute
                  exact
                  path={URL.SIGN_UP({ path: true })}
                  layout={AuthLayout}
                  render={(props) => <Auth key={'signup'} />}
                />
                <LayoutRoute
                  exact
                  path={URL.FAMILY_ONBOARDING({ path: true })}
                  layout={AuthLayout}
                  render={(props) => <FamilyOnboarding {...props} />}
                />
                <LayoutRoute
                  exact
                  path={`/privacy-and-terms`}
                  layout={EmptyLayout}
                  render={(props) => (
                    <PrivacyPolicy hash={props.history.location.hash} />
                  )}
                />

                <LayoutRoute
                  exact
                  path={URL.FORGOT_PASSWORD({ path: true })}
                  layout={AuthLayout}
                  render={(props) => (
                    <Auth type="forgot-password" key={'forgot-password'} />
                  )}
                />
                <LayoutRoute
                  exact
                  path={URL.DELETE_USER({ path: true })}
                  layout={AuthLayout}
                  render={(props) => (
                    <Auth type="delete-user" key={'delete-user'} />
                  )}
                />
                <LayoutRoute
                  exact
                  path={URL.PASSWORD_RESET({ path: true })}
                  layout={AuthLayout}
                  render={(props) => (
                    <Auth type="password-reset" key={'password-reset'} />
                  )}
                />
                <LayoutRoute
                  exact
                  path={URL.INVITE_ACCEPT({ path: true })}
                  layout={AuthLayout}
                  render={(props) => <Auth key={'signup'} />}
                />

                <LayoutRoute
                  exact
                  path={URL.SEASONSDETAIL({ path: true })}
                  layout={MainLayout}
                  component={SeasonsDetail}
                />

                <LayoutRoute
                  exact
                  path={URL.CLASS_DETAILS({ path: true })}
                  layout={CatelogLayout}
                  component={ClassDetails}
                />
                <LayoutRoute
                  path={URL.STUDIO_CATALOG({ path: true })}
                  layout={CatelogLayout}
                  component={StudioCatalog}
                />
                <LayoutRoute
                  path={URL.PUNCHPASSES({ path: true })}
                  layout={CatelogLayout}
                  component={Punchpasses}
                />

                <LayoutRoute
                  exact
                  path={URL.SIGN_WAIVERS({ path: true })}
                  layout={PublicLayout}
                  component={SignWaivers}
                />

                <LayoutRoute
                  exact
                  path={URL.NOTIFICATION_BAR_IFRAME({ path: true })}
                  layout={IframeLayout}
                  component={NotificationsBarIframe}
                />

                <LayoutRoute
                  exact
                  path={URL.WAIVER({ path: true })}
                  layout={IframeLayout}
                  component={WaiverContent}
                />

                {authenticated && !allStepCompleted && !isGodAdmin ? (
                  <LayoutRoute
                    exact
                    path={URL.SETUP_STUDIO({ path: true })}
                    layout={MainLayout}
                    component={SetupStudio}
                  />
                ) : (
                  <ProtectedRoutes />
                )}
                <Redirect to={URL.HOME({ path: true })} />
              </Switch>
            </GAListener>
          </Router>
        </Suspense>
      </IntlProvider>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

const RoutesWithQuery = compose(
  connect(
    ({ auth, language, onboard }) => ({
      auth,
      language,
      checklists: get(onboard, 'data.data', []) || [],
      onboard,
    }),
    (dispatch) => ({
      setIsNotification: (status) => dispatch(setIsNotification(status)),
      userInfo: () => dispatch(userInfo()),
      setLanguage: () => dispatch(setLanguage()),
      getChecklist: () => dispatch(getChecklist()),
      getGuide: () => dispatch(getGuide()),
      addGuide: (data) => dispatch(addGuide(data)),
      logoutUser: () => dispatch(logoutUser()),
    })
  ),
  componentQueries(query),
  lifecycle({
    componentDidUpdate(prevProps) {
      const preAuthenticated = get(prevProps, 'auth.authenticated');
      //
      // if (!isAuthenticated() && !window.location.pathname.includes('login')) {
      //   // this.props.logoutUser();
      //   history.push(URL.LOGIN({ path: true }));
      //   return;
      // }
      const authenticated = get(this.props, 'auth.authenticated');
      if (preAuthenticated !== authenticated && authenticated === true) {
        // this.props.userInfo();
        this.props.getChecklist();
        this.props.getGuide();
      }
    },
    componentDidMount() {
      const isNotification = localStorage.getItem('notify');
      try {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              if (isNotification === null) {
                this.props.setIsNotification(true);
              }
            } else {
              if (!isNotification) {
                this.props.setIsNotification(false);
              }
            }
          })
          .catch((err) => {
            this.props.setIsNotification(false);
          });
      } catch (error) {
        if (error instanceof TypeError) {
          Notification.requestPermission(() => {
            if (isNotification === null) {
              this.props.setIsNotification(true);
            } else {
              this.props.setIsNotification(false);
            }
          });
        } else {
          this.props.setIsNotification(false);
        }
      }
      const authenticated = get(this.props, 'auth.authenticated');
      if (authenticated) {
        // this.props.userInfo();
        this.props.getChecklist();
        this.props.getGuide();
      }
      // refresh translates
      const locale =
        findIndex(
          languageList,
          (item) => item.value === get(this.props, 'auth.user.lang')
        ) !== -1
          ? get(this.props, 'auth.user.lang')
          : defaultLangCode;
      const messages = get(this.props, 'auth.info.translates');
      const staticMessages = getTranslates(locale);
      if (!isEqual(messages, staticMessages)) {
        this.props.setLanguage(locale);
      }
    },
  })
)(Routes);

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <React.Fragment>
          <Listener store={store} />
          <RoutesWithQuery />
          <Toast ref={(ref) => ToastManager.registerToast(ref)} />
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
