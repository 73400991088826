import * as Const from 'utils/constants';
import Api from 'utils/api';
import history from 'components/History';
import styled from 'styled-components';
import { matchSorter } from 'match-sorter';
import Papa from 'papaparse';
import URL from 'utils/urls';

export const options = ['Edit', 'Duplicate', 'Read Receipts', 'Delete'];

export const uploadAdapter = (loader) => {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        loader.file.then((file) => {
          formData.append('file_name', file);

          Api.doCall(Const.FILE_UPLOADS(), 'POST', formData)
            .then((item) => {
              resolve({
                default: item.data.path,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
};
export const uploadPlugin = (editor) => {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
};

export const goBack = (path = window.location.pathname) => {
  if (path.includes('drafts')) {
    history.push(`${URL.FEED()}/drafts`);
  } else {
    history.push(`${URL.FEED()}/email`);
  }
};

export const EllipseDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const filterOptions = (opt, { inputValue }) =>
  matchSorter(opt, inputValue, {
    keys: ['name'],
  });

export const intitialStateCreateMessage = {
  first: {
    data: [],
  },
  seacond: {
    data: null,
  },
  third: {
    data: null,
  },
  fourth: {
    data: null,
  },
  fifth: {
    data: null,
  },
  sixth: {
    data: null,
  },
  attachments: { data: [], uploading: '' },
  duplicate: false,
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const downloadCSV = (args) => {
  let filename = args.filename || 'export.csv';
  let columns = args.columns || null;

  let csv = Papa.unparse({ data: args.data, fields: columns });
  if (csv == null) return;

  var blob = new Blob([csv]);
  if (window.navigator.msSaveOrOpenBlob)
    // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    window.navigator.msSaveBlob(blob, args.filename);
  else {
    var a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
    a.download = filename;
    document.body.appendChild(a);
    a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
    document.body.removeChild(a);
  }
};
