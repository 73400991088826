export const DO_CLASSES_REGISTER_REQUEST = 'DO_CLASSES_REGISTER_REQUEST';
export const DO_CLASSES_REGISTER_SUCCESS = 'DO_CLASSES_REGISTER_SUCCESS';
export const DO_CLASSES_REGISTER_ERROR = 'DO_CLASSES_REGISTER_ERROR';

export const CLASS_CONTACT_US_REQUEST = 'CLASS_CONTACT_US_REQUEST';
export const CLASS_CONTACT_US_SUCCESS = 'CLASS_CONTACT_US_SUCCESS';
export const CLASS_CONTACT_US_ERROR = 'CLASS_CONTACT_US_ERROR';

export const GLOBAL_REQUEST = 'GLOBAL_REQUEST';
export const GLOBAL_SUCCESS = 'GLOBAL_SUCCESS';
export const GLOBAL_ERROR = 'GLOBAL_ERROR';