import * as Types from '../types/studios';
import { get, map, isArray } from 'lodash';
import {
  authError,
  loginWithFacebook,
  loginWithGoogle,
  userInfo,
} from '../actions/auth';
import history from '../../components/History';
import Api from '../../utils/api';
import * as Const from '../../utils/constants';
import URL from '../../utils/urls';
import queryString from 'query-string';
import { adminTabs } from '../../utils/config';
import ToastManager from '../../components/ToastManager';
import { WebAuth } from 'auth0-js';
import { noop } from 'lodash/util';
import { clearSocialStorage } from '../../utils/constants';
import moment from 'moment';
import qs from 'query-string';
import {USER_INFO_PUNCHPASS, USER_INFO_SUCCESS} from "../types/auth";

// export const getStudios = () => {
//   return async dispatch =>{
//     dispatch({ type: Types.FETCH_STUDIOS_REQUEST });
//     try {
//       let response = await Api.doCall(Const.GET_STUDIOS(),'GET');
//     if (response.status === 200 || response.status === 201) {
//             if (!response.data.error) {
//               dispatch(fetchStudiosSuccess(response.data));
//             }else{
//               let errorStr = response.data.error;
//               dispatch(fetchStudiosError({ data: errorStr }))
//             }
//           }
//     } catch (error) {
//       let errorStr = error.toString();
//        dispatch(fetchStudiosError({ data: errorStr }))
//     }

//         }
// }

export const getStudios = (state) => async (dispatch) => {
  let errorStr = '';
  try {
    const {
      pageSize = 25,
      page = 0,
      sorted = [],
      filtered = [],
      isScroll,
    } = state;
    dispatch({ type: Types.FETCH_STUDIOS_REQUEST, isScroll });
    const params = {
      page: page + 1,
      rpp: pageSize,
    };
    if (sorted.length > 0) {
      const sortedColumns = map(sorted, 'name').join(',');
      params.sort = `${sortedColumns} ${
        get(sorted, `${sorted.length - 1}.desc`) ? 'desc' : 'asc'
      }`;
    }
    const index = filtered.findIndex((item) => item.id === 'q');
    if (index !== -1) {
      params.q = filtered[index].value;
    }

    let response = await Api.doCall(
      `${Const.GET_STUDIOS()}?${queryString.stringify(params)}`,
      'GET'
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        if (!isScroll) {
          dispatch(fetchStudiosSuccess(response.data));
        } else {
          dispatch(fetchStudiosOnScroll(response.data));
        }
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchStudiosError({ data: errorStr }));
};

export const getStudio = (id) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.FETCH_STUDIO_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_STUDIO(id), 'GET');
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch(fetchStudioSuccess({ id, data: response.data }));
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch(fetchStudioError({ id, error: errorStr }));
};

export const addStudio = (data) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.ADD_STUDIO_REQUEST });
    let response = await Api.doCall(
      Const.GET_STUDIOS(),
      'POST',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.ADD_STUDIO_SUCCESS, payload: response.data });
        // if(data.step!==1){
        history.push(URL.ADMINS(), {
          activeTab: adminTabs.TAB_STUDIOS,
        });
        // }
        dispatch(userInfo());
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    dispatch({ type: Types.ADD_STUDIO_ERROR, payload: errorStr });
    return Promise.reject(error.message);
  }
};

export const updateStudio = (id, data, prevPage) => async (dispatch) => {
  let errorStr = '';
  data.background_images = data.logo;
  try {
    dispatch({ type: Types.UPDATE_STUDIO_REQUEST });
    let response = await Api.doCall(
      Const.UPDATE_STUDIO(id),
      'PUT',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        data.id = id;
        dispatch({ type: Types.UPDATE_STUDIO_SUCCESS, payload: data });
        dispatch(userInfo());

        if (prevPage === 'onboarding') {
          // do nothing
        } else if (
          prevPage === 'settings' &&
          data.step !== 1 &&
          data.step !== 2
        ) {
          dispatch(changeStudioStep(3));
        } else if (prevPage && data.step !== 1 && data.step !== 2) {
          history.push(URL.ON_BOARDING());
        } else if (prevPage === undefined) {
          history.push(URL.ADMINS(), {
            activeTab: adminTabs.TAB_STUDIOS,
            editStudioSuccess: true,
          });
        }
        return dispatch({
          type: Types.UPDATE_STUDIO_SUCCESS,
          payload: response.data,
        });
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
    ToastManager.show({
      message: error.message,
      autoDismiss: 3,
      level: 'warning',
    });
    return Promise.reject(error.message);
  }
  return dispatch({ type: Types.UPDATE_STUDIO_ERROR, payload: errorStr });
};

export const deleteStudio = (id) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.DELETE_STUDIO_REQUEST });
    let response = await Api.doCall(Const.DELETE_STUDIO(id), 'DELETE');
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.DELETE_STUDIO_SUCCESS, payload: id });
        dispatch(userInfo());
        history.push(URL.ADMINS(), {
          activeTab: adminTabs.TAB_STUDIOS,
        });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.toString();
  }
  dispatch({ type: Types.DELETE_STUDIO_ERROR, payload: errorStr });
};

export function fetchStudiosSuccess(payload) {
  return {
    type: Types.FETCH_STUDIOS_SUCCESS,
    payload,
  };
}

export function fetchStudiosOnScroll(payload) {
  return {
    type: Types.FETCH_STUDIOS_ONSCROLL,
    payload,
  };
}

export function fetchStudiosError(payload) {
  return {
    type: Types.FETCH_STUDIOS_ERROR,
    payload,
  };
}

export function fetchStudioSuccess(payload) {
  return {
    type: Types.FETCH_STUDIO_SUCCESS,
    payload,
  };
}

export function fetchStudioError(payload) {
  return {
    type: Types.FETCH_STUDIO_ERROR,
    payload,
  };
}

/**
 * Invite studio
 */
export const inviteStudioRequest = () => {
  return {
    type: Types.INVITE_STUDIO_REQUEST,
  };
};
export const inviteStudioError = (payload) => {
  return {
    type: Types.INVITE_STUDIO_ERROR,
    payload,
  };
};
export const inviteStudioSuccess = (studio) => {
  return {
    type: Types.INVITE_STUDIO_SUCCESS,
    studio: studio,
  };
};
export const inviteStudio = (payload) => {
  return async (dispatch) => {
    dispatch(inviteStudioRequest());
    try {
      let response = await Api.doCall(
        Const.GET_STUDIOS(),
        'POST',
        payload,
        null,
        false
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(userInfo());
        return dispatch(inviteStudioSuccess(response.data));
      } else {
        let errorStr = response.data.error;
        return dispatch(inviteStudioError({ error: errorStr }));
      }
    } catch (error) {
      dispatch(inviteStudioError({ error: 'Studio is already exists.' }));
      return Promise.reject(error.message);
    }
  };
};

export function changeStudioStep(step) {
  return async (dispatch) => {
    dispatch({ type: Types.CHANGE_STUDIO_STEP, step: step });
  };
}

/*** STUDIO LOCATION */
const addStudioLocationRequest = () => {
  return {
    type: Types.ADD_STUDIO_LOCATION_REQUEST,
  };
};
const addStudioLocationSuccess = (res) => {
  return {
    type: Types.ADD_STUDIO_LOCATION_SUCCESS,
    payload: res,
  };
};
const addStudioLocationError = () => {
  return {
    type: Types.ADD_STUDIO_LOCATION_ERROR,
  };
};
export const studioLocation = (payload) => {
  return async (dispatch) => {
    dispatch(addStudioLocationRequest());
    try {
      payload.forEach(async (element) => {
        if (element.id) {
          element.rooms = isArray(element.rooms)
            ? element.rooms
            : [element.rooms];
          let response = await Api.doCall(
            Const.STUDIO_LOCATION() + `/${element.id}`,
            'PUT',
            element,
            null,
            false
          );
          if (response.status === 200 || response.status === 201) {
            dispatch(addStudioLocationSuccess(response));
          }
        } else {
          if (!element.address) element.address = element.streetAddress;
          if (!element.city) element.city = element.cityName;
          if (!element.zipcode) element.zipcode = element.zipCode;
          if (!element.more_info)
            element.more_info = element.streetAddress1 || 'None';

          if (element.address) {
            let response = await Api.doCall(
              Const.STUDIO_LOCATION(),
              'POST',
              element,
              null,
              false
            );
            if (response.status === 200 || response.status === 201) {
              dispatch(addStudioLocationSuccess(response));
            }
          }
        }
      });
    } catch (error) {
      dispatch(addStudioLocationError({ error: error.message }));
      return Promise.reject(error.message);
    }
  };
};

/**
 * Add room
 */
export const addRoom = (payload) => {
  return async (dispatch) => {
    let response = await Api.doCall(
      Const.STUDIO_ROOMS(),
      'POST',
      payload,
      null,
      false
    );
    if (response) {
      dispatch(addStudioLocationSuccess(response));
      return response;
    }
  };
};
/**
 * DELETE STUIOD LOCATION
 */

export const deleteLocation = (id) => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_STUDIO_LOCATION_REQUEST });
    try {
      let response = await Api.doCall(
        Const.STUDIO_LOCATION() + `/${id}`,
        'DELETE',
        null,
        false
      );
      if (response.status === 200 || response.status === 201) {
        return dispatch({
          type: Types.DELETE_STUDIO_LOCATION_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      dispatch({ type: Types.DELETE_STUDIO_LOCATION_ERROR });
    }
  };
};

/**
 * Delete Room from location Arr
 */
export const deleteRoom = (id) => {
  return async (dispatch) => {
    let response = await Api.doCall(
      Const.STUDIO_ROOMS() + `/${id}`,
      'DELETE',
      null,
      false
    );
    if (response) {
      dispatch(addStudioLocationSuccess(response));
    }
  };
};

/**
 * Delete Room from location Arr
 */
export const updateRoom = (id, payload) => {
  return async (dispatch) => {
    let response = await Api.doCall(
      Const.STUDIO_ROOMS() + `/${id}`,
      'PUT',
      payload
    );
    if (response) {
      dispatch(addStudioLocationSuccess(response));
    }
  };
};

// STUDIO SEASONS

export const getStudioSeasons = (body) => {
  return async (dispatch) => {
    dispatch({ type: Types.GET_STUDIO_SEASON_REQUEST });
    try {
      let response = await Api.doCall(
        Const.CREATE_STUDIO_SEASON(),
        'GET',
        null,
        false
      );
      if (response.status === 200 || response.status === 201) {
        return dispatch({
          type: Types.GET_STUDIO_SEASON_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      return dispatch({ type: Types.GET_STUDIO_SEASON_ERROR, error: true });
    }
  };
};

export const createStudioSeasons = (body) => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_STUDIO_SEASON_REQUEST });
    try {
      let response = await Api.doCall(
        Const.CREATE_STUDIO_SEASON(),
        'POST',
        body,
        false
      );
      if (response.status === 200 || response.status === 201) {
        return dispatch({
          type: Types.CREATE_STUDIO_SEASON_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      return dispatch({ type: Types.CREATE_STUDIO_SEASON_ERROR, error: true });
    }
  };
};
export const deleteStudioSeason = (id) => {
  return async (dispatch) => {
    dispatch({ type: Types.DELETE_STUDIO_SEASON_REQUEST });
    try {
      let response = await Api.doCall(
        Const.CREATE_STUDIO_SEASON(),
        'DELETE',
        { id },
        false
      );
      if (response.status === 200 || response.status === 201) {
        return dispatch({
          type: Types.DELETE_STUDIO_SEASON_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      return dispatch({ type: Types.DELETE_STUDIO_SEASON_ERROR, error: true });
    }
  };
};

export const clonseStudioSeason = async (id) => {
  let rs = await Api.doCall(Const.CLONE_SEASON(id), 'POST');

  if (rs.status === 200) {
    return rs.data;
  }
};

export const getStudioCatalog =
  ({
    studioId,
    classId = '',
    onRegisterClick = noop,
    type = '',
    loader = true,
    query = {},
  }) =>
  async (dispatch) => {
    try {
      if (loader) {
        dispatch({
          type: Types.FETCH_STUDIO_CATALOG_REQUEST,
          payload: { studioId },
        });
      }
      const urlParams = new URLSearchParams(window.location.search);

      const params = {
        classType: type,
        ...query,
      };
      if (
        !params.hasOwnProperty('date') &&
        Boolean(urlParams.get('selected-date'))
      ) {
        params.date = moment(urlParams.get('selected-date')).format(
          'MM-DD-YYYY'
        );
      }
      let response = await Api.doCall(
        `${Const.GET_STUDIO_CATALOG(studioId)}?${queryString.stringify(
          params
        )}`,
        'GET'
      );
      if (get(response, 'data.data')) {
        dispatch({
          type: Types.FETCH_STUDIO_CATALOG_SUCCESS,
          payload: get(response, 'data.data'),
        });
        onRegisterClick(classId);
      }
      return response;
    } catch (error) {
      dispatch({
        type: Types.FETCH_STUDIO_CATALOG_ERROR,
        payload: error.toString(),
      });
    }
  };

export const registerMySpot = async (data) => {
  data.source = 'web';
  let rs = await Api.doCall(
    Const.CLASSES_REGISTER(),
    'POST',
    data,
    noop,
    false
  );

  if (rs.status === 200) {
    return rs.data;
  }
};

export const cancelRegisterMySpot = async (data, dispatch) => {
  let rs = await Api.doCall(
    Const.CLASSES_REGISTER_RESERVE_SPOT(),
    'DELETE',
    data,
    noop,
    false
  );

  if (rs.status === 200) {
      let response = await Api.doCall(Const.USER_INFO(), 'GET');
      dispatch({ type: USER_INFO_SUCCESS, payload: response.data });
    return rs.data;
  }
};

export const registerStudioUser =
  (studioId, body, classId = '', { setLoading, timeSlot, instructor }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: Types.STUDIO_USER_REGISTER_REQUEST,
        payload: { studioId },
      });
      const query = get(window, 'location.search');

      const parsedQuery = qs.parse(query);

      const selectedDateQuery = get(parsedQuery, 'selected-date');
      let response = await Api.doCall(
        Const.STUDIO_USER_REGISTER(studioId),
        'POST',
        body
      );
      const data = get(response, 'data.data') || {};
      const socialType = localStorage.getItem('socialType') || '';
      const isSocial = Boolean(localStorage.getItem('socialType'));
      if (data && !isSocial) {
        const webAuthConfig = {
          domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
          redirectUri:
            process.env.REACT_APP_AUTH0_REDIRECT_URI ||
            'http://localhost:3000/login.html',
          clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
          scope: 'openid email profile',
          responseType: 'id_token token code',
        };
        const myself = body.students.find((obj) => obj.myself === true);
        const password = myself.password;
        const loginRedirect = encodeURIComponent(window.location.pathname);
        let loginUrl = `${webAuthConfig.redirectUri}?loginRedirect=${loginRedirect}&action=register`;
        if (classId) {
          loginUrl += `&classId=${classId}`;
        }

        if (selectedDateQuery) {
          loginUrl += `&selected-date=${moment(selectedDateQuery).format(
            'MM/DD/YYYY'
          )}`;
        }
        if (timeSlot) {
          loginUrl += `&time-slot=${timeSlot}`;
        }
        if (instructor) {
          loginUrl += `&instructor=${instructor}`;
        }
        webAuthConfig.redirectUri = loginUrl;
        // const loginRedirect = encodeURIComponent(window.location.pathname);
        // webAuthConfig.redirectUri = `${webAuthConfig.redirectUri}?loginRedirect=${loginRedirect}&action=register&classId=${classId}&`;
        const webAuth = new WebAuth(webAuthConfig);
        webAuth.signup(
          {
            connection: 'Username-Password-Authentication',
            email: data.parent.email,
            password: password,
            userMetadata: {
              postData: JSON.stringify(''),
            },
          },
          (err) => {
            if (err) {
              ToastManager.show({
                message: err.error_description || err.description,
                autoDismiss: 3,
                level: 'error',
              });
              dispatch(authError(err.message));
              setLoading(false);
            } else {
              webAuth.login(
                {
                  username: data.parent.email.toLowerCase(),
                  password: password,
                },
                (err, res) => {
                  if (err) {
                    ToastManager.show({
                      message: err.error_description,
                      autoDismiss: 3,
                      level: 'error',
                    });
                    setLoading(false);
                    dispatch(authError(err.error_description));
                  }
                }
              );
            }
            return true;
          }
        );

        dispatch({
          type: Types.STUDIO_USER_REGISTER_SUCCESS,
        });
        return { data };
      } else {
        if (socialType === 'google') {
          loginWithGoogle(
            `loginRedirect=${encodeURIComponent(
              window.location.pathname
            )}&action=register&classId=${classId}`
          );
        }
        if (socialType === 'facebook') {
          loginWithFacebook(
            `loginRedirect=${encodeURIComponent(
              window.location.pathname
            )}&action=register&classId=${classId}`
          );
        }
        clearSocialStorage();
      }
    } catch (error) {
      dispatch({
        type: Types.STUDIO_USER_REGISTER_ERROR,
        payload: error.toString(),
      });
      throw error;
    }
  };

export const createStudiowaivers = (body) => {
  return async (dispatch) => {
    dispatch({ type: Types.CREATE_STUDIO_WAIVER_REQUEST });
    try {
      let response = await Api.doCall(
        Const.CREATE_STUDIO_WAIVER(),
        'POST',
        body
      );
      if (response.status === 200 || response.status === 201) {
        return dispatch({
          type: Types.CREATE_STUDIO_WAIVER_SUCCESS,
          payload: response,
        });
      }
    } catch (error) {
      return dispatch({ type: Types.CREATE_STUDIO_WAIVER_ERROR, error: true });
    }
  };
};

export const reservePrivateSession = async (data, dispatch) => {
  let rs = await Api.doCall(
    Const.RESERVE_PRIVATE_SESSION(),
    'POST',
    data,
    noop,
    false
  );

  if (rs.status === 200) {
    dispatch({ type: USER_INFO_PUNCHPASS, payload: rs.data.data.passes });
    return rs.data;
  }
};

export const reserveStudioRental = async (data, dispatch) => {
  let rs = await Api.doCall(
    Const.RESERVE_STUDIO_RENTAL(),
    'POST',
    data,
    noop,
    false
  );

  if (rs.status === 200) {
    dispatch({ type: USER_INFO_PUNCHPASS, payload: rs.data.data.passes });
    return rs.data;
  }
};
