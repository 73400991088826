import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Col, Alert, Button } from "reactstrap";
import FormUploadFile from "components/UploadFile/FormUploadFile";
import Player from "components/Player";
import Image from "components/Image";
import { FormattedMessage } from "react-intl";
import { FaSpinner, FaTrash } from "react-icons/lib/fa";
import { getFilenameFromPath } from "./helper";
import { isMp3 } from "utils/helpers";
import PlayerUI from "components/PlayerUI";

const Buttons = styled.div`
  display: flex;
`;
const ButtonHandle = styled.div`
  margin-right: 5px;
`;
const DeleteIcon = styled.div`
  position: absolute;
  top: calc(50% - 15px);
  right: -40px;
  cursor: pointer;
  display: block;
`;
const FilesWrapper = styled.div`
  position: relative;
  width: ${ props => props.showRemoveIcon ? 'calc(100% - 30px)' : '100%'};
  ${'' /* display: inline-block; */}
`;
const UploadFile = ({
  isLoading,
  error,
  file,
  onFormSubmit,
  onDeleteFile,
  onDownloadFile,
  isShowFormUpload,
  setIsShowFormUpload,
  mimes,
  mode,
  actions,
  acceptMultiple,
  fieldName,
  showRemoveIcon,
  positionActions,
  attachText,
  width,
  height,
  process,
  isBubble,
  disabled
}) => {
  let fileView;
  if (file) {
    if (isMp3(file.mime)) {
      fileView = <Player
        url={file.path}
        width={width}
        height={height}
        isBubble={isBubble}
        process={process}
      />;
    } else {
      fileView = <Image url={file.path} />;
    }
  }
  let filename;
  if (file) {
    filename = getFilenameFromPath(file.path);
  }
  const actionsView = (
    <Buttons>
      {file && actions.includes("download") && (
        <Button
          onClick={() => onDownloadFile(file)}
          size="sm"
          color="link">
          {acceptMultiple ? (
            filename
          ) : (
            <FormattedMessage id="upload > button download" />
          )}
        </Button>
      )}
      {file && actions.includes("discard") && (
        <Button
          onClick={() => onDeleteFile(file)}
          size="sm"
          color="link">
          <FormattedMessage id="upload > button discard" />
        </Button>
      )}
      {!isShowFormUpload && actions.includes("replace") && (
        <Button
          onClick={() => setIsShowFormUpload(true)}
          color="link"
          size="sm">
          <FormattedMessage id="upload > button replace" />
        </Button>
      )}
    </Buttons>
  )
  return (
    <div>
      {/* {file && file.path} */}
      {isLoading ? (
        <Row>
          <Col className="d-flex align-items-center justify-content-center">
            <FaSpinner size="40" className={"cr-icon-spin"} />
          </Col>
        </Row>
      ) : mode === "view" ? (
        <Row>
          <Col xs={12}>
            {error && <Alert color="danger">{error}</Alert>}
            {file ? (
              <FilesWrapper showRemoveIcon={showRemoveIcon}>
                {fileView}
                {showRemoveIcon && (
                  <DeleteIcon onClick={() => onDeleteFile(file)}>
                    <FaTrash size={30} color="#795afa" />
                  </DeleteIcon>
                )}
              </FilesWrapper>
            ) : (
              <div>
                <PlayerUI
                  width={width}
                  height={height}
                  volume={0}
                  setVolume={() => {}}
                  onClickPlaying={() => {}}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "12px",
                    left: "5%",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    width: "90%"
                  }}>
                  <FormattedMessage id="upload > not submitted yet" />
                </span>
              </div>
            )}
            {file && actions.includes("download") && (
              <ButtonHandle>
                <Button
                  onClick={() => onDownloadFile(file)}
                  size="sm"
                  color="link">
                  {acceptMultiple ? (
                    filename
                  ) : (
                    <FormattedMessage id="upload > button download" />
                  )}
                </Button>
              </ButtonHandle>
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            {error && <Alert color="danger">{error}</Alert>}
            {isShowFormUpload && (
              <FormUploadFile
                mimes={mimes}
                onSubmit={onFormSubmit}
                acceptMultiple={acceptMultiple}
                fieldName={fieldName}
                attachText={attachText}
                disabled={disabled}
              />
            )}
            {
              positionActions === 'top'
              && actionsView
            }
          </Col>
          {file && <Col xs={12}>
            <FilesWrapper  showRemoveIcon={showRemoveIcon}>
              {fileView}
              {showRemoveIcon && (
                <DeleteIcon onClick={() => onDeleteFile(file)}>
                  <FaTrash size={30} color="#795afa" />
                </DeleteIcon>
              )}
            </FilesWrapper>
            {
              positionActions === 'bottom'
              && actionsView
            }
          </Col>}
        </Row>
      )}
    </div>
  );
};

UploadFile.defaultProps = {
  actions: ["discard", "download", "replace"],
  fieldName: '',
  showRemoveIcon: true,
  positionActions: "top",
  disabled: false
};

UploadFile.propsTypes = {
  mimes: PropTypes.array,
  mode: PropTypes.oneOf(["view"]),
  actions: PropTypes.arrayOf(
    PropTypes.oneOf(["discard", "download", "replace"])
  ),
  acceptMultiple: PropTypes.bool,
  fieldName: PropTypes.string,
  positionActions: PropTypes.oneOf(["top", "bottom"]),
  disabled: PropTypes.bool
};
export default UploadFile;
