import React, { useState, useEffect } from 'react';

import classes from 'pages/PreviewLiveMode/PreviewLiveMode.module.scss';
import cs from 'classnames';
import get from 'lodash/get';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import pencilIcon from 'assets/img/icons/pencil.svg';
import downIcon from 'assets/img/icons/icon/Icon/triangle_down.svg';
import upIcon from 'assets/img/icons/icon/Icon/triangle_up.svg';
import saveIcon from 'assets/img/icons/icon/Icon/save.svg';
import dragIcon from 'assets/img/icons/icon/Icon/drag_drop.png';
import StartEndButton from 'pages/PreviewLiveMode/components/StartEndButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  updatePerformances,
  SendNotificationFamily,
} from 'store/actions/recitals';

const SendNotifications = (props) => {
  const { data } = props;

  const viewMode = get(props, 'viewMode', 'live_streaming');

  const [columns, setColumns] = useState([]);

  const [arrage, setArrage] = useState(false);

  const toogleArrage = () => {
    if (arrage) saveChanges(columns);
    setArrage(!arrage);
  };

  const [showPerformed, setShowPerformed] = useState(false);
  const toogle = () => setShowPerformed(!showPerformed);

  const courses = useSelector(({ auth }) => {
    const courses = get(auth, 'info.courses', []);
    return courses || [];
  });

  useEffect(() => {
    const performances = get(data, 'performances', []) || [];
    const ps = [
      {
        id: 4,
        name: 'Performing',
        data: performances.filter((p) => p.status === 4),
      },
      {
        id: 3,
        name: 'On Deck',
        data: performances.filter((p) => p.status === 3),
      },
      {
        id: 2,
        name: 'Getting Ready',
        data: performances.filter((p) => p.status === 2),
      },
      {
        id: 1,
        name: 'Schedule',
        data: performances.filter((p) => p.status === 1),
      },
      {
        id: -1,
        name: 'Performed',
        data: performances.filter((p) => p.status === -1),
      },
    ];

    setColumns(ps);
  }, [data]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const getList = (id) => {
    const index = id.replace('column-', '');
    return get(columns, `${index}.data`, []) || [];
  };

  const saveChanges = async (passData) => {
    let arr = [];
    let index = 1;
    passData.forEach((e) => {
      const rs = get(e, 'data', []);
      const status = e.id;

      rs.forEach((p) => {
        arr.push({
          id: p.id,
          orders: index,
          status,
        });
        index++;
      });
    });

    const result = await updatePerformances(data.id, { performances: arr });

    props.setRecital(result.data.data);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    let data = [...columns];

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      const index = source.droppableId.replace('column-', '');
      data[index]['data'] = items;

      setColumns(data);
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      for (const property in result) {
        const rs = result[property];
        const index = property.replace('column-', '');
        data[index]['data'] = rs;
      }
      setColumns(data);
    }
  };

  const sendNofityButton = (item) => {
    const disabled = item.send_notification === 1;

    if (item.performing === 1 && item.status === 4) return '';
    if (item.status === -1) return '';

    if (viewMode !== 'live_streaming') {
      return (
        <button disabled={true}>
          Send Notification
          <div className={classes.tooltip}>
            You can only send notifications when you are live streaming the
            recital.
          </div>
        </button>
      );
    }

    return (
      <button
        disabled={disabled}
        onClick={async () => {
          const rs = await SendNotificationFamily(item.recital_id, item.id);
          props.setRecital(rs.data.data);
        }}
      >
        {disabled ? 'Notification Sent' : 'Send Notification'}
      </button>
    );
  };

  const displayPerformance = (item, index) => {
    const performance = courses.find((p) => p.id === item.class_id);

    const media = get(
      performance,
      'media_selected.meta.filePath',
      '/img/default_media.png'
    );

    const className = get(performance, 'class_name')

    const name = get(item, 'performance_song', null) || className;

    const isPerforming = item.performing === 1 && item.status === 4;
    const isPerformed = item.status === -1;
    const locked = isPerforming === true;

    var minutes = 0;
    var seconds = 0;
    if (parseInt(item.performance_length) > 0) {
      minutes = Math.floor(item.performance_length / 60);
      seconds = item.performance_length - minutes * 60;
    }

    if (parseInt(seconds) < 10) seconds = `0${seconds}`;

    return (
      <Draggable
        draggableId={`draggable-${item.id}`}
        index={index}
        isDragDisabled={locked === true}
        key={item.id}
      >
        {(provided) => (
          <div
            className={classes.performance}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div className={classes.content}>
              <img
                src={dragIcon}
                className={cs(
                  classes.drag,
                  arrage === false && classes.hide,
                  isPerforming === true && classes.hide,
                  isPerformed === true && classes.hide
                )}
                alt="drag"
                {...provided.dragHandleProps}
              />
              <img className={classes.avatar} src={media} alt="" /> {name}{' '}
              <span>
                ({minutes}:{seconds})
              </span>
            </div>
            {arrage === false && item.status !== 4 && sendNofityButton(item)}
            {arrage === false && item.status === 4 && (
              <StartEndButton
                {...item}
                viewMode={viewMode}
                arrage={arrage}
                onChange={(data) => props.setRecital(data)}
              />
            )}
          </div>
        )}
      </Draggable>
    );
  };

  const performedSection = (item) => {
    const filterPerformance = item.data;

    return (
      <Row key={`box-${item.id}`}>
        <Droppable droppableId={`column-4`}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="w-100"
            >
              <Col sm={12} className={classes.container}>
                <div className={cs(classes.header)}>
                  <div>
                    {item.name} <span>({filterPerformance.length})</span>
                  </div>

                  {filterPerformance.length > 0 && (
                    <>
                      {showPerformed ? (
                        <img src={upIcon} alt="up " onClick={() => toogle()} />
                      ) : (
                        <img
                          src={downIcon}
                          alt="down"
                          onClick={() => toogle()}
                        />
                      )}
                    </>
                  )}
                </div>

                {showPerformed &&
                  filterPerformance.map((item, index) =>
                    displayPerformance(item, index)
                  )}

                {provided.placeholder}
              </Col>
            </div>
          )}
        </Droppable>
      </Row>
    );
  };

  return (
    <div className={classes.sendNofitication}>
      <div className={classes.arrangeBt}>
        {arrage ? (
          <button onClick={() => toogleArrage()}>
            <img src={saveIcon} alt="" />
            Save
          </button>
        ) : (
          <button className={classes.arrange} onClick={() => toogleArrage()}>
            <img src={pencilIcon} alt="" />
            REARRANGE SCHEDULE
          </button>
        )}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        {columns.map((item, index) => {
          if (item.id === -1) return performedSection(item);

          const filterPerformance = item.data;

          let isDropDisabled = false;

          // disable drop once have one item for performing and on deck section
          if ([0, 1].indexOf(index) > -1 && filterPerformance.length > 0) {
            isDropDisabled = true;
          }

          return (
            <Row key={`box-${index}`}>
              <Droppable
                droppableId={`column-${index}`}
                isDropDisabled={isDropDisabled}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="w-100"
                  >
                    <Col sm={12} className={classes.container}>
                      <div
                        className={cs(
                          classes.header,
                          item.name === 'Performing' && classes.perfomning
                        )}
                      >
                        {item.name}
                      </div>
                      {filterPerformance.map((item, index) =>
                        displayPerformance(item, index)
                      )}
                      {provided.placeholder}
                    </Col>
                  </div>
                )}
              </Droppable>
            </Row>
          );
        })}
      </DragDropContext>
    </div>
  );
};

export default React.memo(SendNotifications);
