import * as Const from "utils/constants";
import * as mimeTypes from "mime-types";
import Api from "utils/api";
import { calculateDurationMp3, isMp3 } from "utils/helpers";
export const deleteFile = file => async () => {
  try {
    const { status } = await Api.doCall(Const.FILE_DELETE(), "DELETE", {
      file_path: file && file.file!==undefined ? file.meta.filePath : file.path
    }, null, false);
    if (status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const uploadFile = (file) => async() => {
  try {
    if(file.push) file=file[0]
    const formData = new FormData();
    formData.append("file_name", file);
    if(isMp3(file.type)){
      const duration = await calculateDurationMp3(file);
      if(duration){
        formData.append("duration", duration);
      }
    }
    let { data, status } = await Api.doCall(Const.FILE_UPLOADS(), "POST", formData);
    
    if (status === 200) {
      const path = data.path;
      const mime = mimeTypes.lookup(path)
      return {
        path,
        mime
      };
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const uploadFiles = (files = []) => async () => {
  try {
    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      formData.append("file_name[]", file);
    }
    let { data, status } = await Api.doCall(
      Const.FILES_UPLOADS(),
      "POST",
      formData
    );
    if (status === 200 && data.files instanceof Array) {
      let items = [];
      for (let index = 0; index < data.files.length; index++) {
        const item = data.files[index];
        const path = item.url;
        // const mime = mimeTypes.lookup(item.path);
        items.push({
          path,
          type: item.type,
          name: item.name
        });
      }
      return items;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};