import AddProcessPage from "./view";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { getProcess, initNewProcess } from "store/actions/processes";
import { getTemplate } from "store/actions/template";

const preparePage = (props, spotSwitching) => {
  const { match } = props;
  let process_id = get(match, "params.process_id");
  let template_id = get(match, "params.template_id");
  template_id = (template_id + ".").split(".")[0];

  // if only a template id was passed, on creation
  if (
    !props.template_state.loading &&
    (!props.template || props.template.key !== template_id)
  ) {
    props.getTemplate(template_id);
  }
  if (!process_id) {
    props.initNewProcess({
      data: {
        fields: {
          course_studios: {
            is_dirty: true,
            value: props.auth.info.studios.map(s => s.studio_key)
          },
          template_key: {
            is_dirty: true,
            value: template_id
          },
          role_sales_person: {
            is_dirty: true,
            value: {
              id: props.auth.user.id,
              name: props.auth.user.name,
              role: 3
            }
          }
        }
      },
      error: null,
      loading: null
    });
  }
  const loaded_process_key =
    props.process.key ||
    (props.process.data.fields && props.process.data.fields.key
      ? props.process.data.fields.key.value
      : false);
  if (
    process_id &&
    // !props.process.loading &&
    loaded_process_key !== process_id
  ) {
    props.getProcess({ template_id, process_id, spotSwitching });
  }
};

export default compose(
  connect(
    (state, props) => {
      const { match } = props;
      let process_id = get(match, "params.process_id");
      let process = state.processes.process;
      let template_id = get(match, "params.template_id");
      let ui_hint = (template_id + ".").split(".")[1];
      return {
        match_process_id: process_id,
        ui_hint,
        process,
        template: state.templates.template.data,
        template_state: state.templates.template,
        auth: state.auth,
        isLoading:
          state.templates.template.loading || state.processes.process.loading
      };
    },
    {
      initNewProcess,
      getProcess,
      getTemplate
    }
  ),
  lifecycle({
    componentWillUpdate(nextProps) {
			if (this.props.match_process_id !== nextProps.match_process_id) {
				preparePage(nextProps, true);
			}
    },
    componentDidMount() {
      preparePage(this.props);

    }
  })
)(AddProcessPage);
