import * as Types from '../types/users';
import get from 'lodash/get';
import history from '../../components/History';
import Api from '../../utils/api';
import * as Const from '../../utils/constants';
import URL from '../../utils/urls';
import queryString from 'query-string';
import { userInfo } from './auth';
import { adminTabs } from '../../utils/config';
import { NAV_LINKS } from '../../pages/Users/util';
import { METHODS } from './util';
import noop from 'lodash/noop';
import ToastManager from "../../components/ToastManager";

export const getUsers =
  (state, role_id = '') =>
  async (dispatch) => {
    let errorStr = '';
    try {
      const { pageSize, page, sorted, filtered, isScroll, studioId, status } =
        state;
      dispatch({ type: Types.FETCH_USERS_REQUEST, isScroll });
      let params = {
        page: page + 1,
        rpp: pageSize,
      };

      if (get(state, 'all', false) === true) {
        delete params.page;
        delete params.rpp;
      }

      if (studioId) {
        params.studio_id = studioId;
      }

      if (sorted && sorted.length > 0) {
        // const sortedColumns = map(sorted, 'id').join(',');
        // params.sort = `${sortedColumns} ${
        //   get(sorted, `${sorted.length - 1}.desc`) ? 'desc' : 'asc'
        // }`;

        params.sort = sorted.join(',');
      } else {
        params.sort = ' ';
      }
      const index = filtered.findIndex((item) => item.id === 'q');
      if (index !== -1) {
        params.q = filtered[index].value;
      }
      if (status) {
        params.status = status;
      }
      let response = null;
      if (role_id === NAV_LINKS.AUDITION.label) {
        response = await Api.doCall(
          `${Const.GET_AUDITION_LIST()}?${queryString.stringify(params)}`,
          'GET'
        );
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          response.data.data = response.data.data.map((i) => ({
            ...i,
            type: NAV_LINKS.AUDITION.label,
          }));
        }
      } else if (role_id === NAV_LINKS.LEAD.label) {
        response = await Api.doCall(
          `${Const.GET_LEADS_LIST()}?${queryString.stringify(params)}`,
          'GET'
        );
        if (
          response &&
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          response.data.data = response.data.data.map((i) => ({
            ...i,
            type: NAV_LINKS.LEAD.label,
          }));
        }
      } else {
        response = await Api.doCall(
          `${Const.GET_USERS(role_id)}?${queryString.stringify(params)}`,
          'GET'
        );
      }

      if (response.status === 200 || response.status === 201) {
        if (!response.data.error) {
          dispatch({
            type: Types.FETCH_USERS_SUCCESS,
            payload: response.data,
            isScroll,
          });

          if (state.callback) state.callback(response.data);
          return;
        }
        errorStr = response.data.error;
      }
    } catch (error) {
      errorStr = error.message;
    }
    dispatch({
      type: Types.FETCH_USERS_ERROR,
      payload: errorStr,
    });
  };

export const getUser = (id) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.FETCH_USER_REQUEST, payload: { id } });
    let response = await Api.doCall(Const.GET_USER(id), 'GET');
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({
          type: Types.FETCH_USER_SUCCESS,
          payload: { id, data: response.data },
        });
        return;
      }
      errorStr = response.data.error;

      return { data: response.data };
    }
  } catch (error) {
    errorStr = error.message;
  }
  dispatch({
    type: Types.FETCH_USER_ERROR,
    payload: { id, error: errorStr },
  });
};

export const addUser = (data) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.ADD_USER_REQUEST });
    let response = await Api.doCall(
      Const.ADD_USERS(),
      'POST',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      dispatch({ type: Types.ADD_USER_SUCCESS, payload: response.data.data });
      dispatch(userInfo());
      history.push(URL.ADMINS(), {
        activeTab: adminTabs.TAB_USERS,
      });
      return Types.ADD_USER_SUCCESS;
    }
  } catch (error) {
    dispatch({ type: Types.ADD_USER_ERROR, payload: error.message });
    return Promise.reject(error.message);
  }
};

export const updateUser = (id, data, isOwnUsersList) => async (dispatch) => {
  let errorStr = '';
  let response;
  try {
    dispatch({ type: Types.UPDATE_USER_REQUEST });
    response = await Api.doCall(Const.GET_USER(id), 'PUT', data, null, false);
    if (response.status === 200 || response.status === 201) {
      dispatch({
        type: Types.UPDATE_USER_SUCCESS,
        payload: response.data.data,
        id: id,
      });
      if (!isOwnUsersList) {
        history.push(URL.ADMINS(), {
          activeTab: adminTabs.TAB_USERS,
        });
      }

      // dispatch(userInfo());
      return Types.UPDATE_USER_SUCCESS;
    }
    errorStr = response.data.error.message;
  } catch (error) {
    errorStr = error.message;
  }
  dispatch({ type: Types.UPDATE_USER_ERROR, payload: errorStr });
  return errorStr;
};

export const updateAuditionUser =
  ({ id, data }) =>
  async (dispatch) => {
    let errorStr = '';
    let response;
    try {
      dispatch({ type: Types.UPDATE_USER_REQUEST });
      response = await Api.doCall(
        Const.UPDATE_AUDITION_LIST(id),
        'PUT',
        data,
        null,
        false
      );
      if (response.status === 200 || response.status === 201) {
        return Types.UPDATE_USER_SUCCESS;
      }
      errorStr = response.data.error.message;
    } catch (error) {
      errorStr = error.message;
    }
    dispatch({ type: Types.UPDATE_USER_ERROR, payload: errorStr });
    return errorStr;
  };

export const deleteUser =
  (id, isOwnUsersList, studioId) => async (dispatch) => {
    let errorStr = '';
    try {
      dispatch({ type: Types.DELETE_USER_REQUEST });
      let response = await Api.doCall(Const.GET_USER(id), 'DELETE', {
        studioId,
      });
      if (response.status === 200 || response.status === 201) {
        dispatch({ type: Types.DELETE_USER_SUCCESS, payload: id });
        ToastManager.show({
          message: 'User deleted successfully.',
          autoDismiss: 2,
          level: 'success',
        });
        dispatch(userInfo());
        if (!isOwnUsersList)
          history.push(URL.ADMINS(), {
            activeTab: adminTabs.TAB_USERS,
          });
        return;
      }
      errorStr = response.data.error;
    } catch (error) {
      errorStr = error.message;
    }
    dispatch({ type: Types.DELETE_USER_ERROR, payload: errorStr });
  };

export const registerNotifyUser = (params) => async () => {
  try {
    let { status } = await Api.doCall(
      `${Const.REGISTER_NOTIFY_USER()}?${queryString.stringify(params)}`,
      'GET'
    );
    if (status === 200) {
      return {
        status: true,
        message: 'You have been successfully subscribed.',
      };
    } else {
      return {
        status: false,
        message: 'Something went wrong, Please try again later.',
      };
    }
  } catch (error) {
    return {
      status: false,
      message: 'Something went wrong, Please try again later.',
    };
  }
};

export const inviteBulkUsersRequest = () => {
  return {
    type: Types.BULK_USERS_REQUEST,
  };
};
export const inviteBulkUsersSuccess = () => {
  return {
    type: Types.BULK_USERS_SUCCESS,
  };
};
export const inviteBulkUsersError = () => {
  return {
    type: Types.BULK_USERS_ERROR,
  };
};
export const inviteBulkUsers = (payload) => {
  return async (dispatch) => {
    dispatch(inviteBulkUsersRequest());
    try {
      let response = await Api.doCall(
        Const.INVITE_BULK_USERS(),
        'POST',
        payload
      );
      if (response.status === 200 || response.status === 201) {
        dispatch(userInfo());
        return dispatch(inviteBulkUsersSuccess());
      } else {
        return dispatch(inviteBulkUsersError());
      }
    } catch (error) {
      return dispatch(inviteBulkUsersError());
    }
  };
};

export const inviteUsersById = (payload) => {
  let errorStr = '';
  return async (dispatch, getState) => {
    try {
      let response = await Api.doCall(Const.INVITE_EMAIL(), 'POST', payload);
      if (response.status === 200 || response.status === 201) {
        return {
          status: true,
          message: 'Sent invite successfully.',
        };
      }
      errorStr = response.data.error;
      return {
        status: false,
        message: errorStr
          ? errorStr
          : 'Your invite could not be sent. Please try again.',
      };
    } catch (error) {
      return {
        status: false,
        message:
          error && error.length
            ? error[0]
            : 'Your invite could not be sent. Please try again.',
      };
    }
  };
};

export const clearUsers = () => {
  return (dispatch) => {
    dispatch({ type: Types.CLEAR_USERS });
  };
};

export const InviteFamily = async (data) => {
  try {
    let response = await Api.doCall(
      Const.ADD_USERS(),
      'POST',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  } catch (error) {
    console.log('error 222', error);
    return error;
  }
};

export const getAuditionList = async (data) => {
  try {
    let rs = await Api.doCall(Const.GET_AUDITION_LIST(), 'GET');

    if (get(rs, 'status') === 200) {
      return rs;
    }
  } catch (error) {
    return error;
  }
};

export const deleteAudition = async (id) => {
  try {
    let rs = await Api.doCall(Const.GET_AUDITION(id), 'DELETE');

    if (get(rs, 'status') === 200) {
      ToastManager.show({
        message: 'Audition deleted successfully.',
        autoDismiss: 2,
        level: 'success',
      });
      return rs;
    }
  } catch (error) {
    return error;
  }
};

export const AddFamily = async (data) => {
  try {
    let response = await Api.doCall(
      Const.ADD_FAMILY(),
      'POST',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const UpdateFamily = async (id, data) => {
  try {
    let response = await Api.doCall(
      Const.UPDATE_FAMILY(id),
      'PUT',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const deleteFamily = (userId, familyId) => async (dispatch) => {
  try {
    let response = await Api.doCall(
      Const.REMOVE_FAMILY(userId, familyId),
      'DELETE'
    );
    if (response.status === 200 || response.status === 201) {
      dispatch(userInfo());

      return;
    }
  } catch (error) {}
};

export const getAllRoomAvailability = async ({ state }) => {
  let errorStr = '';
  try {
    const response = await Api.doCall(
      `${Const.GET_All_ROOM_AVAILABLE()}`,
      'GET'
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        return {
          payload: response.data,
        };

        if (state.callback) state.callback(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.message;
    return {
      payload: errorStr,
      type: 'error',
    };
  }
};

export const geStaffAvailability = async ({ state }) => {
  let errorStr = '';
  try {
    const { id } = state;
    let params = {
      user_id: id,
    };

    const response = await Api.doCall(
      `${Const.GET_STAFF_AVAILABLE()}?${queryString.stringify(params)}`,
      'GET'
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        return {
          payload: response.data,
        };

        if (state.callback) state.callback(response.data);
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.message;
    return {
      payload: errorStr,
      type: 'error',
    };
  }
};

export const addStaff = async ({ data }) => {
  let errorStr = '';
  try {
    const response = await Api.doCall(
      Const.ADD_STAFF_AVAILABLE(),
      'POST',
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        return {
          payload: response.data,
        };
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.message;
    return {
      payload: errorStr,
      type: 'error',
    };
  }
};

export const deleteStaff = async ({ id }) => {
  const rs = await Api.doCall(Const.DELETE_STAFF_AVAILABLE(id), 'DELETE');
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getRoomAvailability = async ({ state }) => {
  let errorStr = '';
  try {
    const { id } = state;
    let params = {
      room_id: id,
    };
    const rs = await Api.doCall(
      `${Const.GET_ROOM_AVAILABLE()}?${queryString.stringify(params)}`,
      'GET'
    );
    if (get(rs, 'status') === 200) {
      return rs;
    }
  } catch (error) {
    errorStr = error.message;
    return {
      payload: errorStr,
      type: 'error',
    };
  }
};

export const addRoom = async ({ data }) => {
  let errorStr = '';
  try {
    const response = await Api.doCall(Const.GET_ROOM_AVAILABLE(), 'POST', data);

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        return {
          payload: response.data,
        };
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.message;
    return {
      payload: errorStr,
      type: 'error',
    };
  }
};

export const deleteRoom = async ({ id }) => {
  const rs = await Api.doCall(Const.DELETE_ROOM_AVAILABLE(id), 'DELETE');
  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const addLead = async ({ body }) => {
  const rs = await Api.doCall(Const.LEADS(), METHODS.post, body, noop, false);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const addLeadSecure = async ({ id, body }) => {
  const rs = await await Api.doCall(Const.LEADS_ID(id), METHODS.post, body);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const deleteLead = async ({ id }) => {
  const rs = await Api.doCall(Const.LEADS_ID(id), METHODS.delete);
  if (get(rs, 'status') === 200) {
    ToastManager.show({
      message: 'Lead deleted successfully.',
      autoDismiss: 2,
      level: 'success',
    });
    return rs;
  }
};
export const updateLead = async ({ id, body }) => {
  const rs = await Api.doCall(Const.LEADS_ID(id), METHODS.put, body);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const updateLeadSecure = async ({ id, body }) => {
  const rs = await Api.doCall(Const.LEADS_ID(id), METHODS.put, body);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const resetTrialClass = async ({ id }) => {
  const rs = await Api.doCall(Const.RESET_TRIAL(id), METHODS.post);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};
export const sendInviteTrialClass = async (data) => {
  const rs = await Api.doCall(Const.SEND_INVITE(), METHODS.post, data);
  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const updateStaff = async ({ id, data }) => {
  let errorStr = '';
  try {
    const response = await Api.doCall(
      Const.UPDATE_STAFF_AVAILABLE(id),
      'PUT',
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        return {
          payload: response.data,
        };
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.message;
    return {
      payload: errorStr,
      type: 'error',
    };
  }
};
export const updateRoom = async ({ id, data }) => {
  let errorStr = '';
  try {
    const response = await Api.doCall(
      Const.UPDATE_ROOM_AVAILABLE(id),
      'PUT',
      data
    );

    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        return {
          payload: response.data,
        };
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    errorStr = error.message;
    return {
      payload: errorStr,
      type: 'error',
    };
  }
};

export const getUsersWaiverById = async (id) => {
  try {
    const response = await Api.doCall(Const.GET_USERS_WAIVER(id), METHODS.get);
    return response;
  } catch (error) {
    return error;
  }
};
