import * as mimeTypes from "mime-types";

export const download = async (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export const getFileObjectFromURL = url => {
  if (url) {
    const path = url.replace(/^https:\/\//i, "http://");
    const mime = mimeTypes.lookup(path);
    return {
      path,
      mime
    };
  }
  return null;
};

export const getFilenameFromPath = path => {
  let pos = path.lastIndexOf("/");
  return path.substring(pos + 1);
};
