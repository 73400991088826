export const INIT_NEW_PROCESS = "INIT_NEW_PROCESS"
export const PROCESS_VALUE_CHANGED = "PROCESS_VALUE_CHANGED"
export const SAVE_FORM_VALUES_REQUEST = "SAVE_FORM_VALUES_REQUEST"
export const SAVE_FORM_VALUES_SUCCESS = "SAVE_FORM_VALUES_SUCCESS"
export const SAVE_FORM_VALUES_ERROR = "SAVE_FORM_VALUES_ERROR"

export const FETCH_PROCESS_REQUEST = "FETCH_PROCESS_REQUEST";
export const FETCH_PROCESS_SUCCESS = "FETCH_PROCESS_SUCCESS"
export const FETCH_PROCESS_ERROR = "FETCH_PROCESS_ERROR"

export const DELETE_PROCESS_REQUEST = "DELETE_PROCESS_REQUEST";
export const DELETE_PROCESS_SUCCESS = "DELETE_PROCESS_SUCCESS";
export const DELETE_PROCESS_ERROR = "DELETE_PROCESS_ERROR";

export const UPDATE_PROCESS_REQUEST = "UPDATE_PROCESS_REQUEST";
export const UPDATE_PROCESS_SUCCESS = "UPDATE_PROCESS_SUCCESS"
export const UPDATE_PROCESS_ERROR = "UPDATE_PROCESS_ERROR";
export const UPDATE_PROCESS_INCOMING = "UPDATE_PROCESS_INCOMING";

export const FETCH_PROCESSES_REQUEST = "FETCH_PROCESSES_REQUEST";
export const FETCH_PROCESSES_SUCCESS = "FETCH_PROCESSES_SUCCESS";
export const FETCH_PROCESSES_ERROR = "FETCH_PROCESSES_ERROR";
export const FETCH_FORCE_PROCESSES_SUCCESS = "FETCH_FORCE_PROCESSES_SUCCESS";
export const FETCH_PROCESSES_INCOMING_REQUEST = "FETCH_PROCESSES_INCOMING_REQUEST";
export const FETCH_PROCESSES_INCOMING_SUCCESS = "FETCH_PROCESSES_INCOMING_SUCCESS";


export const ADD_PROCESS_REQUEST = "ADD_PROCESS_REQUEST";
export const ADD_PROCESS_SUCCESS = "ADD_PROCESS_SUCCESS";
export const ADD_PROCESS_ERROR = "ADD_PROCESS_ERROR";

export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_TAB_TO_EDIT = "SET_TAB_TO_EDIT";
export const RESET_TAB = "RESET_TAB";

export const FORGET_PRCESSES = "FORGET_PRCESSES";

export const UPDATE_PROCESSES_INCOMING = "UPDATE_PROCESSES_INCOMING";

export const GET_MY_CALENDAR_REQUEST = 'GET_MY_CALENDAR_REQUEST';
export const GET_MY_CALENDAR_SUCCESS = 'GET_MY_CALENDAR_SUCCESS';
export const GET_MY_CALENDAR_ERROR = 'GET_MY_CALENDAR_ERROR';
