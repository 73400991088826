import React from 'react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

class ToastManager extends React.Component {
  show(params) {
    return this.notificationSystem.addNotification(params);
  }

  hide(notif) {
    this.notificationSystem.removeNotification(notif);
  }

  render() {
    return (
      <NotificationSystem
        dismissible={'none'}
        ref={(notificationSystem) =>
          (this.notificationSystem = notificationSystem)
        }
        style={
          this.props.style !== undefined
            ? this.props.style
            : NOTIFICATION_SYSTEM_STYLE
        }
        allowHTML={this.props.allowHTML}
        newOnTop={this.props.newOnTop}
      />
    );
  }
}

ToastManager.defaultProps = {
  newOnTop: true,
  allowHTML: false,
};

export default ToastManager;
