

import LoadingButton from '@mui/lab/LoadingButton';
import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from 'utils/colors';

const PrimaryButton = styled(LoadingButton)({
  color: COLORS.white,
  fontSize: 16,
  fontWeight: 600,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  textTransform: 'none',
  padding: '0.5rem',
  '&.Mui-disabled': {
    backgroundColor: COLORS.gray,
    pointerEvents: 'all',
    cursor: 'not-allowed',
    opacity: '0.7',
  },
});
const MembershipButton = styled(LoadingButton)({
  color: COLORS.orange,
  background: '#FCF7F4',
  fontSize: 16,
  fontWeight: 600,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  textTransform: 'none',
  padding: '0.5rem',
  '&.Mui-disabled': {
    backgroundColor: COLORS.gray,
    pointerEvents: 'all',
    cursor: 'not-allowed',
    opacity: '0.7',
  },
});

const MembershipButtonCancel = styled(LoadingButton)({
  color: '#FCF7F4',
  background: 'none',
  fontSize: 16,
  fontWeight: 600,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  textTransform: 'none',
  padding: '0.5rem',
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
    color: '#FCF7F4',
    pointerEvents: 'all',
    cursor: 'not-allowed',
    opacity: '0.7',
  },
});

const PrimaryButtonLink = styled(Button)({
  fontSize: 16,
  fontWeight: 600,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  textTransform: 'none',
  '&.Mui-disabled': {
    backgroundColor: COLORS.gray,
    pointerEvents: 'all',
    cursor: 'not-allowed',
    opacity: '0.7',
  },
});

const DefaultButtonLink = styled(Button)({
  fontSize: 16,
  fontWeight: 600,
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  textTransform: 'none',
  color: '#9C9CA3',
  '&.Mui-disabled': {
    backgroundColor: COLORS.gray,
    pointerEvents: 'all',
    cursor: 'not-allowed',
    opacity: '0.7',
  },
});

const PrimaryIconButton = styled(IconButton)({
  width: '24px',
  height: '24px',
  color: '#51BCDA',
  cursor: 'pointer',
  '&.Mui-disabled': {
    color: `${COLORS.gray} !important`,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
});

const SecondaryIconButton = styled(IconButton)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  color: '#EF8157',
  cursor: 'pointer',
  '&.Mui-disabled': {
    color: `${COLORS.gray} !important`,
    cursor: 'not-allowed',
    pointerEvents: 'auto',
  },
});

export {
  PrimaryIconButton,
  SecondaryIconButton,
  PrimaryButtonLink,
  PrimaryButton,
  DefaultButtonLink,
  MembershipButton,
  MembershipButtonCancel,
};

export default PrimaryButton;
