import * as Types from '../types/users';
import { LOGOUT_USER } from '../types/auth';
import get from 'lodash/get';

const initialState = {
  users: {
    // This object contains all users list
    // this is mainly required in users listing page
    // where we show all users list and loading icon if users are loading
    data: [],
    error: null,
    loading: null,
    isLast: null,
  },
  user: {
    // This object contains particular user object with id mapping
    // this is mainly required in user details page
    // where we show particular user detail and loading icon if user details is loading
    // below is example object
    // "<id>": {
    //   data: null,
    //   error: null,
    //   loading: null
    // }
  },
  addUser: {
    // This object will be useful to show loading icon and status when user want to create new studio
    data: null,
    error: null,
    loading: false,
  },
  updateUser: {
    // This object will be useful to show loading icon and status when user want to update studio
    data: null,
    error: null,
    loading: false,
  },
  deleteUser: {
    // This object will be useful to show loading icon and status when user want to delete studio
    data: null,
    error: null,
    loading: false,
    isDeleted: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    // users Actions

    case Types.FETCH_USERS_REQUEST: {
      let obj = { error: null, loading: true };
      if (!action.isScroll) {
        obj.data = [];
      }
      return {
        ...state,
        users: { ...state.users, ...obj },
      };
    }
    case Types.FETCH_USERS_SUCCESS: {
      if (action.isScroll) {
        if (
          state.users.data &&
          state.users.data.data !== undefined &&
          state.users.data.data.length
        ) {
          let getUsers = { ...state.users };
          getUsers.data.data = [...getUsers.data.data, ...action.payload.data];
          getUsers.error = null;
          getUsers.loading = false;
          getUsers.isLast = false;
          if (action.payload.data.length === 0) {
            getUsers.isLast = true;
          }
          return {
            ...state,
            users: { ...getUsers },
          };
        }
      }
      return {
        ...state,
        users: {
          data: action.payload,
          error: null,
          loading: false,
          isLast:
            action.payload &&
            action.payload.data &&
            action.payload.data.length === 0
              ? true
              : null,
        },
      };
    }
    case Types.FETCH_USERS_ERROR: {
      return {
        ...state,
        users: {
          data: null,
          error: action.payload,
          loading: false,
          isLast: true,
        },
      };
    }

    // Particular User Actions
    case Types.FETCH_USER_REQUEST: {
      const existingUsers = get(state, 'user');
      const { id } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            ...(existingUsers[id] || {}),
            loading: true,
            error: null,
          },
        },
      };
    }
    case Types.FETCH_USER_SUCCESS: {
      const existingUsers = get(state, 'user');
      const { id, data } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error: null,
            data,
          },
        },
      };
    }
    case Types.FETCH_USER_ERROR: {
      const existingUsers = get(state, 'user');
      const { id, error } = action.payload;
      return {
        ...state,
        user: {
          ...existingUsers,
          [id]: {
            loading: false,
            error,
          },
        },
      };
    }

    // Add User Actions
    case Types.ADD_USER_REQUEST: {
      return {
        ...state,
        addUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.ADD_USER_SUCCESS: {
      let usersList = state.users;
      usersList.data.data = [action.payload, ...usersList.data.data];
      return {
        ...state,
        addUser: {
          data: action.payload,
          loading: false,
          error: null,
        },
        users: usersList,
      };
    }
    case Types.ADD_USER_ERROR: {
      return {
        ...state,
        addUser: {
          loading: false,
          error: action.payload,
        },
      };
    }

    // Update User Actions
    case Types.UPDATE_USER_REQUEST: {
      return {
        ...state,
        updateUser: {
          loading: true,
          error: null,
        },
      };
    }
    case Types.UPDATE_USER_SUCCESS: {
      let usersList = state.users;
      usersList.data.data = [...usersList.data.data];
      let getIndex = usersList.data.data.findIndex(
        (item) => item.id === action.id
      );
      if (getIndex !== -1) {
        usersList.data.data[getIndex] = action.payload;
      }
      return {
        ...state,
        updateUser: {
          data: action.payload,
          loading: false,
          error: null,
        },
        users: usersList,
      };
    }
    case Types.UPDATE_USER_ERROR: {
      return {
        ...state,
        updateUser: {
          loading: false,
          error: action.payload,
        },
      };
    }

    // DELETE User Actions
    case Types.DELETE_USER_REQUEST: {
      return {
        ...state,
        deleteUser: {
          loading: true,
          error: null,
          isDeleted: false,
        },
      };
    }
    case Types.DELETE_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            data: state.users.data.data.filter(
              (user) => user.id !== action.payload
            ),
          },
        },
        deleteUser: {
          loading: false,
          error: null,
          isDeleted: true,
        },
      };
    }
    case Types.DELETE_USER_ERROR: {
      return {
        ...state,
        deleteUser: {
          loading: false,
          error: action.payload,
          isDeleted: false,
        },
      };
    }
    case Types.CLEAR_USERS: {
      return {
        ...state,
        users: initialState.users,
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
