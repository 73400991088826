import React, { Component } from "react";
import ComponentFactory from "./componentFactory";
// import safeEval from "safe-eval";
import { get, pick } from "lodash";
import { Row, Col, Button } from "reactstrap";
import history from "components/History";
import URL from "utils/urls";
import { Form, SectionTitle, SectionFields } from "components/Elements";
import { FormattedMessage } from "react-intl";
// import { includes } from "lodash";
class DynamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isView: (document.location.pathname.split("/")[2] === "view") ? true : false,
      isAdd: (document.location.pathname.split("/")[2] === "add") ? true : false
    };
  }

  static getDerivedStateFromProps(props, state){
    if (document.location.pathname.split("/")[2] === "view" && !state.isView){
      return{
        isView: true
      }
    }
    return null;
  }

  componentDidMount(){ 
    // const {step, process, template, user} = this.props
    // let isTabUndefined = (process.lastEditTab in window)
    // let lastEditTab=get(process, "lastEditTab", [])
    // if(document.location.href.indexOf('processes/add')===-1
    //   && process.data.fields.key
    // ){
    //   if ( !isTabUndefined && !includes(lastEditTab, process.activeTab)) {
    //     this.switchToView(step, process, template, user)
    //   }else if (!isTabUndefined && includes(lastEditTab, process.activeTab)) {
    //     this.switchToEdit(step, process, template, user)
    //   }else if (!isTabUndefined && includes(lastEditTab, step.key)) {
    //     this.switchToEdit(step, process, template, user);
    //   }else if(lastEditTab.length>0) {
    //     this.switchToView(step, process, template, user)
    //   }
    // }
  }

  validate = (step, item, process, template, user) => {
    let valid = true;
    let ex_messages = [];
    const { intl } = this.props;
    step.fields.map(item => {
      let validation_result = true;
      if (item.validation) {
        let validation = item.validation;
        let value = "";
        if (
          process.data.fields[item.field] &&
          process.data.fields[item.field].value
        )
          value = process.data.fields[item.field].value;
        try {
          validation_result = validation(
            step,
            item,
            process.data,
            template,
            user,
            value
          );
        } catch (e) {
          console.log(e);
        }
        if (validation_result !== true) {
          valid = false;
          this.setState({
            [item.field]: { validation_error: validation_result }
          });
          if (item.mandatory && !valid) ex_messages.push(item)// throw { item }; // eslint-disable-line
        }
      }
      return false;
    });
    let keyTranslate;
    if (ex_messages.length > 0) {
      let message = '';
      if (ex_messages.length === 1 && (ex_messages[0].label ? ex_messages[0].label : ex_messages[0].field)) {
        keyTranslate = 'process > field ' + get(ex_messages, "0.field", "").replace(/_/g, ' ');
        message = `${intl.formatMessage({id: 'process > error please correct'})} ${intl.formatMessage({id: keyTranslate}).toLowerCase()}`
      }
      else {
        message = intl.formatMessage({id: 'process > error please correct the hilighted fields'})
      }
      let error = { message };
      throw error
    }
    return valid;
  };

  clearValidationError = item => {
    return this.setState({ [item.field]: { validation_error: "" } });
  };

  saveFormValues = payload => {
    return this.props.saveFormValues(payload);
  };
  switchToEdit = (step, process, template, user) => {
    this.props.setActiveEditTab(step.key);
    this.setState({isView:false})
    history.replace(
      URL.UPDATE_PROCESS({
        template_id: process.data.fields.template_key.value,
        process_id: process.data.fields.key.value
      })
    );
  };
  switchToView = (step, process, template, user) => {
    this.setState({isView:true})
    history.replace(
      URL.VIEW_PROCESS({
        template_id: process.data.fields.template_key.value,
        process_id: process.data.fields.key.value
      })
    );
  };

  render() {
    let {isView, isAdd} = this.state;
    let step = this.props.step;
    let process = this.props.process;
    let template = this.props.template;
    let formValueChanged = this.props.formValueChanged;
    let user = this.props.user;
    let info = this.props.info;
    let ui_hint = this.props.ui_hint;
    let tab_toggle = this.props.tab_toggle;
    let validate = this.validate.bind(this);
    let clearValidationError = this.clearValidationError.bind(this);
    let saveFormValues = this.saveFormValues.bind(this);
    let no_edit_button = step.no_edit_button;
    if(!no_edit_button && step.key !== 'log'){
      let found=false
      Object.keys(user.roles).map(r=>{if (step.step_editors_roles.indexOf(parseInt(r))>-1) found=true})
      if(!found) no_edit_button=true
    }


    if (ui_hint) {
      return (
        <ComponentFactory
          ui_hint={ui_hint}
          formValueChanged={formValueChanged}
          user={user}
          info={info}
          process={process}
          template={template}
          tab_toggle={tab_toggle}
          validate={validate}
          clearValidationError={clearValidationError}
          saveFormValues={saveFormValues}
          validationState={this.state}
        />
      );
    }

    if (
      this.props.match_process_id &&
      !this.state.validated &&
      this.props.process.data.fields[step.key + "_status"] &&
      this.props.process.data.fields[step.key + "_status"].value === "draft"
    ) {
      this.setState({ validated: true });
      let valid = false;
      try {
        valid = this.validate(step, null, process, template, user);
      } catch (e) { }
      if (!valid) {
        let tab_index = 0;
        Object.keys(template.steps).map((key, ind) => {
          if (key === step.key) tab_index = ind;
          return true;
        });
        tab_toggle(tab_index);
      }
    }
    let stepContainer = get(step, "step_container");
    let all_fields = {}
    let component_index=0
    Object.keys(step.fields).map((key, ind) => {
      let item = step.fields[key];
      let item_container = 'main'
      if (item.container) item_container = item.container
      if (!all_fields[item_container]) all_fields[item_container] = []

      if (!item.component) return null;
      if (item.is_hidden && item.is_hidden(user, process, step))
        return null;
      let component = item.component && (
        <ComponentFactory
          component_index={component_index}
          formValueChanged={formValueChanged}
          user={user}
          info={info}
          process={process}
          template={template}
          step={step}
          item={item}
          tab_toggle={tab_toggle}
          validate={validate}
          clearValidationError={clearValidationError}
          validation_error={
            this.state[item.field] &&
            this.state[item.field].validation_error
          }
          saveFormValues={saveFormValues}
          validationState={this.state}
        />
      );
      component_index++
      let row;
      if (item.label && item.component) {
        row = (
          <Row key={"key_" + ind}>
            <Col xs={4} className={"m-0 text-truncate"}>
              {item.label}
            </Col>
            <Col className={"w-50"}>{component}</Col>
          </Row>
        );
      } else if (item.label) {
        row = (
          <Row key={"key_" + ind}>
            <Col colSpan={2} className={"w-50"}>
              {item.label}
            </Col>
          </Row>
        );
      } else {
        row = (
          <Row key={"key_" + ind}>
            <Col colSpan={2} >
              {component}
            </Col>
          </Row>
        )
      }
      if(item_container==='main') all_fields[item_container].push(row);
      else all_fields[item_container].push(component);
      return true
    })

    let form = (
      <div>
        {
          stepContainer !== 'none' ? (
            <Form {...pick(step, ["className"])}>
              {step.description && <SectionTitle>{step.description}</SectionTitle>}
              <SectionFields>{all_fields.main}</SectionFields>
            </Form>
          ) : (
              <div>
                {step.description && <h3 className="mb-5">{step.description}</h3>}
                <div className="panel panel-default p50 uth-panel">
                  {all_fields.main}
                </div>
              </div>
            )
        }
        {all_fields.bottom_container && <div><br/><Form>
              <SectionFields>{all_fields.bottom_container}</SectionFields>
            </Form></div>}
        <br />
        { isView && !isAdd && !no_edit_button && <Button
                  block
                  color="blue"
                  style={{
                    borderRadius: 30,
                    width: '95px',
                    float:'right',
                    marginRight: '10px',
                    paddingRight: ' 20px',
                    paddingLeft: '20px'
                  }}
                  onClick={() => {
                        this.switchToEdit(step, process, template, user);
                      }}>
                  <FormattedMessage id="process > button edit" />
              </Button> }
          {/* { !isView && !isAdd && <Button
              block
              color="blue"
              style={{
                borderRadius: 30,
                width: '95px',
                float:'right',
                marginRight: '10px',
                paddingRight: ' 20px',
                paddingLeft: '20px'
              }}
              onClick={() => {
                    this.switchToView(step, process, template, user);
                  }}>
              VIEW
          </Button> } */}
        {all_fields.bottom_buttons}
      </div>
    );
    return form;
  }
}

export default DynamicForm;

