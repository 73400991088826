import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './SetupSeasons.module.scss';
import { Row, Col, FormGroup, CardBody, CardFooter, Button } from 'reactstrap';

import Input from 'components/FormFields/InputText';
import imagelogodelete from 'assets/img/icons/delete.svg';
import cs from 'classnames';
import moment from 'moment';
import { formatDisplayDate } from 'utils/helpers';
import { deleteStudioSeason } from 'store/actions/studios';

import {
  CardHeading,
  CustomCardHeader,
  CustomCard,
  FlexContainer,
  SeasionForm,
  InputLabel,
  CardFooterRight,
  CardFooterLeft,
  // FormRowBox,
  // SessionsInputBox,
  // SessionsDateInputBox,
  SeassionEnterText,
  SeassinFormList,
  ListBoxSessionWrap,
  SessionCardTitle,
  SessionCardDate,
} from 'pages/StudioDetails/styles';

import get from 'lodash/get';

import { createStudioSeasons, updateStudio } from 'store/actions/studios';
const cStep = 8;

const SetupSeasons = (props, formProps) => {
  const { step, setStep, modal, newSeasonName, isSetupStudio } = props;
  const dispatch = useDispatch();
  const refAddBt = useRef(null);

  const [isEdit, setIsEdit] = useState(false);
  const [seasonName, setSeasonName] = useState(newSeasonName);
  const [season, setSeason] = useState([
    {
      name: '',
      start_date: '',
      end_date: '',
      errors: {
        name: undefined,
        start_date: undefined,
        end_date: undefined,
      },
    },
  ]);
  const addSeason = (e) => {
    setSeason([
      ...season,
      {
        name: seasonName || '',
        start_date: '',
        end_date: '',
        errors: {
          name: undefined,
          start_date: undefined,
          end_date: undefined,
        },
      },
    ]);

    setSeasonName('');
  };

  const studioId = useSelector(({ auth }) => {
    return get(auth, 'info.current_studio_id', null);
  });

  const studio = useSelector(({ studios }) => {
    return get(studios, `studio.${studioId}.data.data`, null);
  });
  const maxStep = get(studio, 'setup_step', 0);

  const isCompleted = maxStep >= cStep;

  const studioSeasons = useSelector((state) =>
    get(state, 'studios.seasons.data.seasons', [])
  );

  const setSeasonsArray = () => {
    const result = studioSeasons.map((s) => {
      return {
        ...s,
        start_date: moment(s.start_date).format('MM/DD/YYYY'),
        end_date: moment(s.end_date).format('MM/DD/YYYY'),
        errors: {
          name: undefined,
          start_date: undefined,
          end_date: undefined,
        },
      };
    });
    setSeason(result);
  };

  useEffect(() => {
    if (step !== cStep) setIsEdit(false);
  }, [step, cStep]);

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => {
        refAddBt.current.click();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (studioSeasons.length) {
      setSeasonsArray();
    }
  }, [step]);

  if (isSetupStudio && step < cStep && isCompleted === false) {
    return (
      <Row>
        <Col>
          <CustomCard disabled={true}>
            <CustomCardHeader className="border-bottom-none">
              <CardHeading>Seasons</CardHeading>
            </CustomCardHeader>
          </CustomCard>
        </Col>
      </Row>
    );
  }

  const handleSeasonChange = (name, value, index) => {
    let data = [...season];
    data[index][name] = value;
    data[index].errors[name] = undefined;
    setSeason(data);
  };
  const activeSeason = season[season.length - 1];

  const disabledAddSeasonButton =
    !activeSeason.name.trim().length ||
    !activeSeason.start_date.trim().length ||
    !activeSeason.end_date.trim().length;

  const createSeasons = async () => {
    dispatch({ type: 'SETUP_STUDIO_COMPLETED', payload: step });
    setStep(isSetupStudio ? (isCompleted ? maxStep + 1 : step + 1) : 0);

    if (!isCompleted) {
      const post = { setup_step: cStep };
      await dispatch(updateStudio(studioId, post, 'onboarding'));
    }

    const body = season.map((s) => {
      let obj = {
        start_date: moment(s.start_date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        end_date: moment(s.end_date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        name: s.name,
      };
      if (s.id) {
        obj = { ...obj, id: s.id };
      }
      return obj;
    });
    const result = await dispatch(createStudioSeasons({ seasons: body }));
    if (!result.error) {
      props.onCreate();
    }
  };

  const handleDisable = () =>
    season.find(
      (item) =>
        item.errors.start_date || item.errors.name || item.errors.end_date
    );

  const validateDate = (dateStr) => {
    return moment(dateStr, 'MM-DD-YYYY').isValid();
  };

  const handleSave = () => {
    let data = [...season];
    data.forEach((x, i) => {
      if (!x.name) x.errors.name = true;
      if (
        !x.start_date ||
        x.start_date.includes('M') ||
        x.start_date.includes('D') ||
        x.start_date.includes('Y') ||
        !validateDate(x.start_date)
      ) {
        if (!x.start_date) {
          x.errors.start_date = 'This field is required.';
        } else {
          x.errors.start_date = 'Invalid date';
        }
      }
      if (
        !x.end_date ||
        x.end_date.includes('M') ||
        x.end_date.includes('D') ||
        x.end_date.includes('Y') ||
        !validateDate(x.end_date)
      ) {
        if (!x.end_date) {
          x.errors.end_date = 'This field is required.';
        } else {
          x.errors.end_date = 'Invalid date';
        }
      }
      if (moment(x.end_date).isBefore(moment(x.start_date))) {
        x.errors.end_date = 'Start Date cannot be greater than End Date.';
      }
    });
    setSeason(data);
    if (
      data.find(
        (item) =>
          item.errors.start_date || item.errors.name || item.errors.end_date
      )
    ) {
      return;
    }
    createSeasons();
  };

  const onCancel = () => {
    const finalStep = isSetupStudio
      ? isCompleted
        ? maxStep + 1
        : step + 1
      : 0;
    setStep(finalStep);
  };

  if (step === cStep && (isEdit || isSetupStudio)) {
    return (
      <div className={classes.root}>
        <Row>
          <Col>
            <CustomCard>
              {modal === false && (
                <CustomCardHeader>
                  <CardHeading>
                    <FlexContainer>
                      <div className="left-wrap">
                        <CardHeading>Seasons</CardHeading>
                      </div>
                      <span
                        className={'skip-text'}
                        onClick={() => {
                          dispatch({
                            type: 'SETUP_STUDIO_COMPLETED',
                            payload: step,
                          });
                          setStep(maxStep + 1);

                          if (!isCompleted) {
                            const post = { setup_step: cStep };
                            dispatch(
                              updateStudio(studioId, post, 'onboarding')
                            );
                          }
                        }}
                      >
                        Skip For Now
                      </span>
                    </FlexContainer>
                  </CardHeading>
                </CustomCardHeader>
              )}

              <CardBody className="pb-0">
                <SeassionEnterText>
                  Grouping your classes by season will allow you to quickly and
                  easily create your schedule for future seasons. Enter season
                  information in the fields below.
                </SeassionEnterText>
                {season.map((item, i, season) => {
                  return (
                    <SeasionForm key={i}>
                      <FormGroup>
                        <Row>
                          <Col xl="3">
                            <Input
                              label={
                                <InputLabel>
                                  Season Name
                                  {/* <span className="requiredicon"> *</span> */}
                                </InputLabel>
                              }
                              type="text"
                              error={
                                item.errors.name
                                  ? 'This field is required.'
                                  : false
                              }
                              onBlur={formProps.handleBlur}
                              className="inner-input mt-2"
                              placeholder="i.e. Spring 2021"
                              value={item.name}
                              onChange={(e) => {
                                handleSeasonChange('name', e.target.value, i);
                              }}
                            />
                          </Col>
                          <Col xl="3" xs={10}>
                            <Input
                              label={
                                <InputLabel>
                                  Start Date
                                  {/* <span className="requiredicon"> *</span> */}
                                </InputLabel>
                              }
                              type="date"
                              selected={
                                item.start_date
                                  ? new Date(item.start_date)
                                  : null
                              }
                              placeholderText="MM/DD/YYYY"
                              error={item.errors.start_date}
                              onChange={(date) => {
                                if (date)
                                  handleSeasonChange(
                                    'start_date',
                                    moment(date).format('MM/DD/YYYY'),
                                    i
                                  );
                                else handleSeasonChange('start_date', '', i);
                              }}
                              className="inner-input mt-2 w-100"
                            />
                          </Col>
                          <Col
                            xl={1}
                            className={cs(classes.toText, 'hidden-xs')}
                          >
                            to
                          </Col>
                          <Col xl="3" xs={10}>
                            <Input
                              label={
                                <InputLabel>
                                  End Date
                                  {/* <span className="requiredicon"> *</span> */}
                                </InputLabel>
                              }
                              type="date"
                              disabled={!item.start_date}
                              selected={
                                item.end_date ? new Date(item.end_date) : null
                              }
                              placeholderText="MM/DD/YYYY"
                              error={
                                // here we want to show error in end date field only if start date field doesn't have error,
                                // as per this task: https://tasks.getventive.com/projects/C4B33-0EB, 5th point
                                item.errors.end_date && !item.errors.start_date
                                  ? item.errors.end_date
                                  : ''
                              }
                              onChange={(date) => {
                                if (date)
                                  handleSeasonChange(
                                    'end_date',
                                    moment(date).format('MM/DD/YYYY'),
                                    i
                                  );
                                else handleSeasonChange('end_date', '', i);
                              }}
                              className="inner-input mt-2 w-100"
                            />

                            {season.length > 1 && (
                              <span
                                className="close-session-list"
                                onClick={async () => {
                                  setSeason(season.filter((y, j) => j !== i));
                                  if (get(item, 'id'))
                                    await dispatch(deleteStudioSeason(item.id));
                                }}
                              >
                                <img
                                  src={imagelogodelete}
                                  className="image"
                                  alt="imageicon"
                                />
                              </span>
                            )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </SeasionForm>
                  );
                })}
              </CardBody>

              <CardFooter
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <CardFooterLeft
                  onClick={!disabledAddSeasonButton ? addSeason : () => {}}
                  ref={refAddBt}
                >
                  <p
                    className={`addanother-sessions ${
                      disabledAddSeasonButton && 'disbale'
                    }`}
                  >
                    + Add Another Season
                  </p>
                </CardFooterLeft>
                <CardFooterRight>
                  <Button
                    type="button"
                    className={classes.btCancel}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      width: 160,
                    }}
                    color="primary"
                    type="submit"
                    className={`btNext ${
                      typeof handleDisable() === 'object' && 'disbale'
                    }`}
                    onClick={() => handleSave()}
                    disabled={typeof handleDisable() === 'object'}
                  >
                    {modal === true
                      ? 'Add Season'
                      : !isSetupStudio
                      ? 'Save Changes'
                      : 'Next'}
                  </Button>
                </CardFooterRight>
              </CardFooter>
            </CustomCard>
          </Col>
        </Row>
      </div>
    );
  }

  if (step !== cStep || !isSetupStudio || !isEdit) {
    return (
      <Row>
        <Col>
          <CustomCard>
            <CustomCardHeader className="border-none">
              <FlexContainer>
                <div className="left-wrap">
                  <img src="/img/check.svg" alt="" />
                  <CardHeading>Seasons</CardHeading>
                </div>
                <span
                  style={{ cursor: 'pointer' }}
                  className={'edit-text'}
                  onClick={() => {
                    setStep(cStep);
                    setIsEdit(true);
                  }}
                >
                  Edit
                </span>
              </FlexContainer>
            </CustomCardHeader>
            <div className="m-4">
              <SeassinFormList>
                {studioSeasons.length
                  ? studioSeasons.map((s, index) => (
                      <ListBoxSessionWrap key={index}>
                        <SessionCardTitle>
                          {get(s, 'name', '')}
                        </SessionCardTitle>
                        <SessionCardDate>
                          {`${formatDisplayDate(
                            get(s, 'start_date')
                          )} - ${formatDisplayDate(get(s, 'end_date'))}`}
                        </SessionCardDate>
                      </ListBoxSessionWrap>
                    ))
                  : undefined}
              </SeassinFormList>
            </div>
          </CustomCard>
        </Col>
      </Row>
    );
  }
};

SetupSeasons.defaultProps = {
  modal: false,
  newSeasonName: '',
  onCreate: () => {},
};
export default React.memo(SetupSeasons);
