import React from "react";
import { Alert } from "reactstrap";
import classnames from "classnames";
import bn from "utils/bemnames";
import { MdCheckCircle } from "react-icons/lib/md";
import { MdWarning } from "react-icons/lib/md";
import { MdInfo } from "react-icons/lib/md";
const bem = bn.create("alert");
class AlertView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
    this.onDismiss = this.onDismiss.bind(this);
  }

  renderIcon(iconClass){
    const classIcon  = this.props.className;
    switch (classIcon) {

      case "warning": return <MdWarning className={iconClass}/>;
      case "success": return <MdCheckCircle  className={iconClass}/>;
      case "info": return <MdInfo  className={iconClass}/>;

      default: return <MdWarning color="#F55B5B" size={18} />
    }
  }
  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Alert className={classnames(bem.b())} color={this.props.className} isOpen={this.state.visible}>
        {this.renderIcon('icon')} <div className={classnames(bem.e("message"))}>{this.props.content}</div>
      </Alert>
    );
  }
}

export default AlertView;