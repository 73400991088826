import styled from 'styled-components';
import { Card, CardHeader } from 'reactstrap';

export const Headingwrap = styled.div`
  padding-left: 3rem;
  max-width: 880px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  h4 {
    font-size: 42px;
    line-height: 52px;
    color: #22294e;
    font-weight: normal;
    margin: 0px;
  }
  ${'' /* font-weight: bold; */}
`;

export const CardWrap = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  padding: 40px 40px;
  // max-width: 880px;
  margin: 0 auto;
  // margin-top:40px;
  @media (max-width: 425.98px) {
    padding: 0px;
    background: none;
  }
  .box-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #22294e;
    text-transform: capitalize !important;
  }
  .box-text {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 16px;
    text-transform: initial;
    max-width: 434px;
  }
  .box-btn {
    margin-top: 40px;
    width: 200px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    font-family: Poppins;
    text-transform: capitalize;
    color: #ffffff !important;
    border-radius: 5px;
    margin-left: 0px;
  }
  .card-body {
    padding: 24px;
  }
  .card-header {
    padding: 24px;
  }
  .inner-input {
    font-family: Muli;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414 !important;
    background-color: transparent;
    textarea {
      border-bottom: 1px solid #22294e;
      border-radius: 0px;
      padding: 3px 0px !important;
      min-height: 120px;
      resize: none;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
      }
      &::placeholder {
        color: #9c9ca3 !important;
      }
    }
    &::placeholder {
      color: #9c9ca3 !important;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .text-danger {
    display: flex;
    align-items: center;
    margin-top: 3px;
    svg {
      margin: 0px;
      margin-top: 6px;
    }
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: Muli;
    color: #141414 !important;
  }
`;
export const CardHeading = styled.h4`
  font-family: Poppins;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #22294e;
  text-transform: capitalize;
  margin-bottom: 0px;
  &.text-right {
    display: flex;
    justify-content: flex-end;
  }
  span {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    color: #9c9ca3;
  }
`;
export const EnterText = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
  margin-left: 0px;
  font-family: 'Muli';
  margin-bottom: 24px;
`;
export const InputLabel = styled.div`
  font-family: 'Mulish' !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #22294e;
  margin-bottom: ${(p) => (p.woLabel ? '6px' : '0px')};
  text-transform: capitalize;
  display: flex;
  align-items: center;
  span {
    color: #f55b5b;
    margin-left: 4px;
  }
  .requiredicon {
    color: #ea734f !important;
  }
`;

export const InputLabel2 = styled.div`
  font-family: 'Mulish' !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #22294e;
  margin-bottom: ${(p) => (p.woLabel ? '6px' : '0px')};
  display: flex;
  align-items: center;
  span {
    color: #f55b5b;
    margin-left: 4px;
  }
  .requiredicon {
    color: #ea734f !important;
  }
`;
export const MobileViewText = styled.h5`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #22294e;
  margin-bottom: 16px;
`;
export const UploadLogoText = styled.p`
  font-family: 'Muli';
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
  margin-bottom: 24px;
  width: 50%;
  @media (max-width: 1024.98px) {
    width: 100%;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
  .left-wrap {
    display: flex;
    flex: 1;
  }
  .studio-inner-wrap {
    display: block;
    padding: 24px;
    overflow: auto;
    h4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #141414;
      margin-bottom: 0px;
    }
    a {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #ea734f !important;
      display: block;
    }
    p {
      font-family: Muli !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
      margin-top: 24px;
      margin-bottom: 16px;
      width: 50%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .edit-text {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    font-family: Poppins;
    line-height: 22px;
    color: #ea734f;
    text-transform: capitalize;
  }
  .skip-text {
    text-transform: capitalize;
    cursor: pointer;
    color: #9c9ca3;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const NonFlexContainer = styled.div`
  display: block;
  align-items: center;
  .studio-inner-wrap {
    display: block;
    padding: 24px;
    h4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #141414;
      margin-bottom: 0px;
    }
    a {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #ea734f !important;
      display: block;
      margin-bottom: 24px;
    }
    p {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #141414;
      margin-bottom: 4px;
    }
    span {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      color: #515254;
    }
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 40px;
  margin-top: 30px;
`;
export const ButtonWrap = styled.div`
  button {
    width: 160px;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    text-transform: capitalize;
    margin-left: auto;
  }
`;
export const ErrorWrap = styled.div`
  color: red;
  background-color: #fff;
  padding: 8px;
  width: 300px;
  box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 8rem;
  left: 39rem;
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #141414;
    margin-bottom: 0px;
    flex: 1;
    font-family: 'Muli';
  }
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

export const CustomCardHeader = styled(CardHeader)`
  border-bottom: 1px solid #f8e6df !important;
  &.border-none {
    border-bottom: 1px solid #f8e6df !important;
  }
  &.border-bottom-none {
    border-bottom: 0px !important;
    .disable-creation.disbale {
      background: #e0e0e0;
      color: #fcfcfc;
    }
  }
`;
export const CustomCard = styled(Card)`
  margin-bottom: ${(p) => (p.wrap ? '24px' : '10px')};
  border: 1px solid ${(p) => (p.disabled ? '#E0E0E0' : '#f8e6df')} !important;
  border-radius: 0 !important;
  .card-footer {
    border-top: 1px solid #f8e6df !important;
    flex-wrap: wrap;
  }
  .card-header > h4 {
    ${(p) => (p.disabled ? 'color : #9C9CA3 !important' : '')}
  }
  ${(p) => (p.disabled ? 'background-color: #FCFCFC !important;' : '')}
`;

export const StudioButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  border-top: 1px solid #f8e6df;
  padding: 18px 18px;
  margin: 0px -10px;
  box-sizing: content-box;
  padding-bottom: 0px;
  margin-top: 20px;
  margin-bottom: -20px;
`;
export const BottomWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const BottomLogo = styled.div`
  margin-right: 4px;
  padding-top: 20px;
  margin-left: 20px;
  margin-bottom: 10px;
`;
export const BottomRightWrap = styled.div`
  margin-left: -47px;
  overflow: hidden;
`;

export const LocationForm = styled.div`
  width: 70%;
  @media (max-width: 425.98px) {
    width: 100%;
  }
`;
export const LocationEnterText = styled.div`
  font-family: 'Mulish' !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
  margin-bottom: 16px;
  width: 40%;
  @media (max-width: 1024.98px) {
    width: 100%;
  }
`;
export const AddressText = styled.h4`
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #141414;
  margin-bottom: 8px;
  &:hover {
    color: ${(p) => (p.hover ? '#F5B399' : '#141414')};
  }
`;
export const SelectWrap = styled.div`
  .react-select-container.error {
    border: none !important;
    border-bottom: 1px solid #22294e !important;
  }
  .react-select-container {
    background-color: transparent;
    border-bottom: 1px solid #22294e;
    padding: 0.375rem 0;
    border-radius: 0px;
  }
  .react-select__value-container {
    padding-left: 0px !important;
  }
  .inner-input {
    height: 43px;
  }
  .zip-code {
    label {
      margin-bottom: 3px;
    }
  }
`;

export const RoomWrap = styled.div`
  display: flex;
  align-items: flex-start;
  .inner-input {
    height: 30px;
  }
`;
export const PeopleText = styled.p`
  font-family: 'Muli';
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
  margin-bottom: 0px;
  margin-top: 8px;
  margin-left: 4px;
`;
export const AddButtonWrap = styled.button`
  border: 1px solid #ea734f;
  border-radius: 5px;
  width: 120px;
  height: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
  color: #ea734f;
  text-transform: capitalize;
  background-color: transparent;
  cursor: pointer;
  span {
    margin-right: 2px;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: #f5b399;
    color: #ffffff;
    border: unset;
  }
  &:disabled {
    color: rgb(156, 156, 163);
    border: 1px solid rgb(156, 156, 163);
    background: none;
  }
  @media (max-width: 425.98px) {
    letter-spacing: 0;
  }
`;

export const FacebookButton = styled.span`
  margin: '0.625rem 0rem';
  text-transform: 'none';
  background: #1877f2;
  border-radius: '4px';
  font-size: '1rem';
  line-height: '24px';
  font-weight: 600;
  letter-spacing: '0.05em';
  font-style: 'normal';
  font-family: 'Poppins';
`;

export const GoogleButton = styled.span`
  margin: '0.625rem 0rem';
  font-family: 'Poppins';
  text-transform: 'none';
  color: #000000;
  border-radius: '4px';
  font-size: '1rem';
  line-height: '24px';
  font-weight: 600;
  letter-spacing: '0.05em';
  font-style: 'normal';
  background: #ffffff;
`;

export const CancelButton = styled.button`
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #9c9ca3;
  background-color: transparent;
  margin-right: 20px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const LocationFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const FooterLeftWrap = styled.div`
  flex: 1;
  button {
    font-weight: 600;
    background-color: transparent;
    border: none;
    font-size: ${(p) => (p.location ? '14px' : '16px')};
    line-height: ${(p) => (p.location ? '19px' : '22px')};
    color: ${({ location }) => (location ? 'red' : '#ea734f')};
    text-transform: capitalize;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:hover {
      color: #f5b399;
    }
    &:disabled {
      color: #e0e0e0;
    }
  }
`;

export const AlertWrap = styled.div`
  background: #ffffff;
  box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
  border-radius: 5px;
  min-width: 208px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 6px 9px;
  position: absolute;
  right: 50px;
  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-family: Muli;
    color: #141414;
    margin: 0px;
    margin: 0px 8px;
  }
  .alert-close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cr-icons {
    width: 16px;
    height: 16px;
    margin: 0px;
  }
`;
export const Breadcrumbs = styled.div`
  // width:197px;
  height: 38px;
  background: #fcf7f4;
  border-radius: 5px;
  padding: 12.5px 24px;
  margin-left: 23px;
  display: flex;
  align-items: center;
  h4 {
    font-weight: normal;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    color: #22294e;
    margin: 0px;
    margin-left: 8px;
    font-family: Poppins;
  }
  .arrow {
    margin: 0px 8px;
  }
  p {
    margin: 0px;
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
    color: #9c9ca3;
    font-family: Poppins;
  }
  p.active {
    font-weight: normal;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    color: #22294e;
    margin: 0px;
    font-family: Poppins;
  }
`;
export const RightDoneWrap = styled.div`
  width: 130px;
  margin-left: auto;
  img {
    display: block;
    margin-left: auto;
    margin-top: 17px;
  }
  p {
    margin: 0px;
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    font-family: Poppins;
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: capitalize;
    color: #9c9ca3;
  }
  @media (max-width: 425.98px) {
    display: none;
  }
`;
export const SeasionForm = styled.div`
  margin-top: 24px;
  position: relative;
  margin-bottom: 24px;
  span.close-session-list {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -30px;
    top: 40px;
  }
`;
export const CardFooterRight = styled.div`
  display: flex;
  button.btn.btn-secondary {
    background: #fff0;
    color: #9c9ca3;
    width: 53px;
    margin-right: 24px;
    padding: 0px 0px;
    height: 19px;
  }
  .btn:active {
    background: #ea734f00 !important;
    color: #9c9ca3 !important;
    border: none;
  }
  .btNext.disbale {
    background: #e0e0e0;
    color: #fcfcfc;
  }
  .disabled {
    background: #e0e0e0 !important;
    color: #fff !important;
  }
`;
export const CardFooterLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p.addanother-sessions {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #ea734f;
    margin-bottom: 0;
  }
  p.addanother-sessions.disbale {
    color: #e0e0e0;
  }
`;
export const FormRowBox = styled.div`
  display: flex;
  flex-direction: row;
  span {
    // margin: 35px 8px 0 8px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
  }
  button.btn.btn-secondary {
    background: #fff0;
    color: #9c9ca3;
    width: 160px;
  }
  .to-text {
    margin: 29px 8px 0 8px;
    display: block;
  }
  @media (max-width: 1050px) {
    flex-direction: column;
    .to-text {
      display: none;
    }
  }
`;
export const SessionsInputBox = styled.div`
  margin-right: 24px;
  @media (max-width: 768px) {
    margin-right: 0;
  }
  p {
    font-family: Mulish !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
  }
  input {
    width: 301px;
    margin-top: 0 !important;
    @media (max-width: 920px) {
      width: 100%;
    }
  }
  input:focus::-webkit-input-placeholder {
    color: #9c9ca3 !important;
  }
  .border-box {
    position: relative;
    .error-image {
      position: absolute;
      top: 32px;
      right: 8px;
    }
    input {
      border: 1px solid #f55b5b;
    }
    span.error-message {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #f55b5b;
      margin: 0 !important;
      padding-top: 8px;
    }
    input[type='date']::-webkit-calendar-picker-indicator,
    input[type='date']::-webkit-inner-spin-button {
      display: none !important;
      opacity: 0;
    }
    input {
      background-color: #fcf7f4 !important;
      color: #9c9ca3 !important;
    }
  }
`;
export const SessionsDateInputBox = styled.div`
  position: relative;
  input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  input[type='date'] {
    text-transform: uppercase;
  }
  input:focus::-webkit-input-placeholder {
    color: #9c9ca3 !important;
  }
  input {
    width: 135px;
  }
  p {
    font-family: Mulish !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
  }
  .border-box {
    position: relative;
    .error-image {
      position: absolute;
      top: 32px;
      right: 8px;
    }
    span.error-message {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #f55b5b;
      margin: 0 !important;
      display: block;
      width: 135px;
      padding-top: 8px;
    }
    .react-date-picker {
      background: #fcf7f4 !important;
      color: #9c9ca3 !important;
      border: 1px solid #f55b5b;
    }
    input {
      color: #9c9ca3 !important;
    }
    span.react-date-picker__inputGroup__divider {
      color: #9c9ca3 !important;
    }
  }
  p.label-text {
    span {
      color: #f55b5b;
      margin-left: 4px;
    }
    .requiredicon {
      color: #ea734f !important;
    }
  }
  .start-end-date {
    font-family: Muli;
    font-weight: normal;
    line-height: 20px;
    color: #141414 !important;
    background-color: transparent;
    min-height: 40px;
    border: 1px solid #f8e6df;
    border-radius: 5px;
    font-size: 14px;
    width: 135px;
    cursor: pointer;
    :focus {
      border: 1px solid #ea734f !important;
      outline: none;
      box-shadow: none;
    }
  }
  p.label-text {
    margin-bottom: 0;
  }
`;
export const SeassionEnterText = styled.p`
  font-family: 'Muli';
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
  margin-bottom: 0;
  width: 433px;
  @media (max-width: 1024.98px) {
    width: 100%;
  }
`;
export const SeassinFormList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ListBoxSessionWrap = styled.div`
  width: 48%;
  margin-bottom: 24px;
  margin-right: 15px;
`;
export const SessionCardTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #141414;
  margin-bottom: 8px;
`;
export const SessionCardDate = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
`;
