import TextareaEvaluator from './view';
import { convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { compose, withState, withHandlers } from 'recompose';
import { hhmmss, counter } from './helper';

const DEFAULT_SLIDER = 180;
const DEFAULT_TIMER = "0 sec.";
const DEFAULT_WORDS_PER_MINUTE = 180;
export default compose(
  withState('timer', 'setTimer', DEFAULT_TIMER),
  withState('wordCounting', 'setWordCounting', 0),
  withState('seconds', 'setSeconds', 0),
  withState('slider', 'setSlider', DEFAULT_SLIDER),
  withHandlers({
    onUpdateSeconds: ({ setSeconds, setTimer, wordsPerMinute }) => (wordCounting, slider) => {
      let speed = wordsPerMinute;
      if(!wordsPerMinute){
        speed = DEFAULT_WORDS_PER_MINUTE;
      }
      const seconds = (wordCounting / slider) * 60;
      setSeconds(seconds);
      const timer = hhmmss(seconds);
      setTimer(timer);
    },
  }),
  withHandlers({
    onChangeSlider: ({
      wordCounting,
      setSlider,
      onUpdateSeconds,
    }) => slider => {
      setSlider(slider);
      onUpdateSeconds(wordCounting, slider);
    },
    onChangeEditorCallback: ({
      slider,
      setWordCounting,
      onUpdateSeconds,
      onEvaluatorCallback,
    }) => (contentState) => {
      console.log("onChangeEditorCallback", contentState);
      const text = contentState.getPlainText();
      const wordCounting = counter(text);
      setWordCounting(wordCounting);
      onUpdateSeconds(wordCounting, slider);
			//callback
      onEvaluatorCallback(stateToHTML(contentState), contentState);
    }
  })
)(TextareaEvaluator);
