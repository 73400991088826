import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { COLORS } from './colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#EA734F',
    },
    secondary: {
      main: '#EA9277',
    },
    default: {
      main: '#9C9CA3',
    },
    tertiary: {
      main: '#22294E',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:disabled': {
            backgroundColor: COLORS.gray,
            pointerEvents: 'all',
            cursor: 'not-allowed',
            opacity: '0.7',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '1rem',
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '22px',
          textTransform: 'capitalize',
          '&:disabled': {
            backgroundColor: COLORS.gray,
            pointerEvents: 'all',
            cursor: 'not-allowed',
            opacity: '0.7',
          },
        },
      },
    },
  },
  typography: {
    h3: {
      fontSize: '1.5rem',
      color: '#22294E',
      lineHeight: '32px',
      fontStyle: 'normal',
      fontFamily: 'Poppins',
    },
    h4Regular: {
      fontSize: '20px',
      color: '#22294E',
      lineHeight: '28px',
      fontStyle: 'normal',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    h3semiBold: {
      fontWeight: 600,
      fontSize: '24px',
      color: '#22294E',
      lineHeight: '32px',
      fontStyle: 'normal',
      fontFamily: 'Poppins',
    },
    paragraphRegular: {
      fontSize: '14px',
      color: '#141414',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontFamily: 'Mulish',
      fontWeight: 400,
    },
    paragraphBold: {
      fontSize: '14px',
      color: '#141414',
      lineHeight: '20px',
      fontStyle: 'normal',
      fontFamily: 'Mulish',
      fontWeight: 700,
    },
    h1Bold: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '34px',
      lineHeight: '41px',
      color: '#22294E',
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '150%',
      color: '#22294E',
    },
    h1: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
    },
    mulishRegular: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#141414',
    },
    mulishRegularCaption: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#515254',
    },
    mulishSemiBold: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#22294E',
    },
    label: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#515254',
    },
    captionRegular: {
      fontSize: '12px',
      color: '#515254',
      lineHeight: '16px',
      fontStyle: 'normal',
      fontFamily: 'Mulish',
      fontWeight: 400,
    },

    h5Semibold: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      color: '#22294E',
    },

    poppin600Bold: {
      fontFamilyBold: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '28px',
      color: '#22294E',
    },
    poppin500: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#22294E',
    },
    poppin400: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '24px',
      color: '#141414',
    },
    poppin600: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '28px',
      color: '#22294E',
    },
    MulishNormal: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#141414',
    },
    Mulish400: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#141414',
    },
    Mulish400_12: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#9C9CA3',
    },
    Mulish300: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#141414',
    },
    Mulish700: {
      fontFamily: 'Mulish',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#141414',
    },

    fontFamily: [
      '-apple-system',
      'Poppins',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      'Mulish',
    ].join(','),
  },
});

export default theme;
