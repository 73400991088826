import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { LOGOUT_USER } from 'store/types/auth';

import authReducer from './auth';
import serverReducer from './servers';
import processReducer from './processes';
import templatesReducer from './templates';
import studioReducer from './studios';
import userReducer from './users';
import messagesReducer from './messages';
import chatReducer from './chat';
import eventsReducer from './events';
import importReducer from './imports';
import productsReducer from './products';
import alertReducer from './alert';
import confirmReducer from './confirm';
import onboardReducer from './onboard';
import membersReducer from './members';
import classesReducer from './classes';
import walletReducer from './wallet';
import insightsReducer from './insights';
import themeReducer from './theme';
import parentReducer from './parent';
import notificationsReducer from './notification';

const reducers = {
  auth: authReducer,
  servers: serverReducer,
  processes: processReducer,
  templates: templatesReducer,
  studios: studioReducer,
  users: userReducer,
  messages: messagesReducer,
  chat: chatReducer,
  events: eventsReducer,
  imports: importReducer,
  products: productsReducer,
  alert: alertReducer,
  form: formReducer,
  parent: parentReducer,
  confirm: confirmReducer,
  onboard: onboardReducer,
  members: membersReducer,
  classes: classesReducer,
  wallet: walletReducer,
  insights: insightsReducer,
  theme: themeReducer,
  notification: notificationsReducer,
};

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
