import React from 'react';
import classes from './ClassSchedule.module.scss';
import get from 'lodash/get';
import cs from 'classnames';
import moment from 'moment';
import { daysInWeek } from 'utils/config';
import WeeklyRecurringItem from 'components/ProcessesList/Classes/components/ClassSchedule/WeeklyRecurringItem';

const WeeklyRecurring = (props) => {
  const { values, setFieldValue } = props;
  const { step, process, template, user, valueChanged } = props;

  let recurring_repeat_on = null;

  if (step)
    recurring_repeat_on = step.fields.find(
      (f) => f.field === 'recurring_repeat_on'
    );

  return (
    <div className={classes.repeatOn}>
      <div>Repeat on</div>
      <div className="d-flex mt-2 mb-2">
        {daysInWeek.map((day) => {
          return (
            <button
              type="button"
              key={day.id}
              className={cs(classes.activeDay, {
                [classes.active]: get(
                  values.recurring_repeat_on,
                  `${day.id}.id`
                ),
              })}
              onClick={() => {
                const selected = get(
                  values.recurring_repeat_on,
                  `${day.id}`,
                  null
                );

                if (!selected) {
                  const currentMinute = moment().format('m');

                  const data = {
                    ...values.recurring_repeat_on,
                    [day.id]: {
                      id: day.id,
                      label: day.label,
                      value: day.value,
                      shortValue: day.shortValue,
                      from: moment()
                        .add(60 - currentMinute, 'minute')
                        .format('YYYY-MM-DD HH:mm:00'),
                      to: moment()
                        .add(120 - currentMinute, 'minute')
                        .format('YYYY-MM-DD HH:mm:00'),
                    },
                  };
                  setFieldValue('recurring_repeat_on', data);
                  if (step)
                    valueChanged(recurring_repeat_on, process, template, user, {
                      value: data,
                    });
                } else {
                  const recurringRepeatOn =
                    get(values, 'recurring_repeat_on', {}) || {};
                  const count = Object.keys(recurringRepeatOn).length;
                  if (count <= 1) return false;

                  let obj = { ...values.recurring_repeat_on };
                  obj[day.id] = null;
                  setFieldValue('recurring_repeat_on', obj);
                  if (step)
                    valueChanged(recurring_repeat_on, process, template, user, {
                      value: obj,
                    });
                }
              }}
            >
              {day.label}
            </button>
          );
        })}
      </div>

      {Object.keys(values.recurring_repeat_on).map((key) => {
        if (!get(values.recurring_repeat_on, `${key}.id`)) return true;

        const day = get(values.recurring_repeat_on, key);

        return <WeeklyRecurringItem day={day} {...props} key={key} />;
      })}
    </div>
  );
};
export default WeeklyRecurring;
