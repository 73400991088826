import { API_URL, daysInWeek } from './config';
import { Row } from 'reactstrap';
import ToggleSwitch from '../components/ToggleSwitch';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';

export const BASE_URL = API_URL() + '/api';

export const MESSENGER_URL = process.env.REACT_APP_MESSENGER_API_URL;

export const PRIVILEGES = {
  ADD_STUDIOS: 'create_studio',
  EDIT_STUDIOS: 'edit_studio',
  VIEW_STUDIOS: 'view_studio',
  DELETE_STUDIOS: 'delete_studio',
  ADD_SERVERS: 'add_servers',
  EDIT_SERVERS: 'edit_servers',
  VIEW_SERVERS: 'view_servers',
  DELETE_SERVERS: 'delete_servers',
  ADD_USERS: 'add_users',
  EDIT_USERS: 'edit_users',
  VIEW_USERS: 'view_users',
  DELETE_USERS: 'delete_users',
  ASSIGN_PRIVILEGES: 'assign_privileges',
  REVOKE_PRIVILEGES: 'revoke_privileges',
  VIEW_TEMPLATES: 'view_templates',
  EDIR_TEMPLATES: 'edit_templates',
  DELETE_TEMPLATES: 'delete_templates',
  MY_PROFILE: 'my_profile',
  SETTINGS: 'settings',
  HELP: 'help',
  ADD_EVENT: 'add_events',
  EDIT_EVENT: 'edit_events',
  DELETE_EVENT: 'delete_events',
  VIEW_PROCESSES: 'view_process',
  EDIT_PROCESSES: 'edit_process',
  DELETE_PROCESSES: 'delete_process',
};

export const PUSHER = () => BASE_URL + `/message`;
// User Authentication URLs
export const REGISTER_USER = () => BASE_URL + '/register';
export const UPDATE_LOGIN_USER = () => BASE_URL + '/admin/update';
export const LOGIN_USER = () => BASE_URL + '/login';
export const USER_INFO = () => BASE_URL + '/user/info';
export const USER_INFO_COURSES = () => BASE_URL + '/user/info-courses';
export const USER_INFO_BY_EMAIL = (email, relogin) =>
  BASE_URL + `/user/profileByEmail/${email}?source=web&relogin=${relogin}`;

// User Invite URLs
export const CREATE_ACCOUNT = () => BASE_URL + '/create_account';
export const FORGOT_PASSWORD = () => BASE_URL + '/auth0/forgot-password';
export const DELETE_USER = () => BASE_URL + '/auth0/delete-user';
export const RESET_PASSWORD = () => BASE_URL + '/auth0/reset-password';

// User Servers URLs
export const GET_SERVERS = () => BASE_URL + '/servers';
export const GET_SERVER = (id) => BASE_URL + `/servers/${id}`;
// export const EDIT_SERVER = id => BASE_URL + `/servers/${id}/edit`;

// User studios urls
export const GET_STUDIOS = () => BASE_URL + '/studios';
export const GET_STUDIO = (id) => BASE_URL + `/studio/${id}`;
export const DELETE_STUDIO = (id) => BASE_URL + `/studios/${id}`;
export const UPDATE_STUDIO = (id) => BASE_URL + `/studio/${id}`;
export const GET_STUDIO_CATALOG = (studioId) =>
  BASE_URL + `/classes/studio/${studioId}`;
export const STUDIO_USER_REGISTER = (studioId) =>
  BASE_URL + `/studio/${studioId}/register`;
export const CLASSES_REGISTER = () => BASE_URL + `/class/register`;
export const CLASSES_REGISTER_RESERVE_SPOT = () =>
  BASE_URL + `/class/reserve-spot`;

// Class-pass / Membership
export const PUNCHPASS_REGISTER = (userId) =>
  BASE_URL + `/payment/punch-passes/${userId}`;
export const MEMBERSHIP_DETAILS = (userId) =>
  BASE_URL + `/membership-details/${userId}`;
export const MEMBERSHIP_DELETE = (membershipId) =>
  BASE_URL + `/membership-details/${membershipId}`;

export const CLASS_CONTACT_US = (classId) =>
  BASE_URL + `/class/${classId}/contact-us`;

//User Studios
export const GET_USER_STUDIO = () => BASE_URL + '/userStudio';

// User events urls
export const GET_EVENTS = () => BASE_URL + '/events';
export const GET_EVENT = (id) => BASE_URL + `/events/${id}`;

// Users api urls
export const GET_USERS = (id) => BASE_URL + `/users/filter/${id}`;
export const ADD_USERS = () => BASE_URL + `/users`;
export const GET_AUDITION_LIST = () => BASE_URL + `/auditions`;
export const GET_LEADS_LIST = () => BASE_URL + `/leads`;

export const GET_AUDITION = (id) => BASE_URL + `/auditions/${id}`;
export const UPDATE_AUDITION_LIST = () => BASE_URL + `/auditions/edit`;

export const ADD_FAMILY = () => BASE_URL + `/user/add-family`;
export const UPDATE_FAMILY = (id) => BASE_URL + `/user/update-family/${id}`;
export const REMOVE_FAMILY = (userId, friendId) =>
  BASE_URL + `/users/remove-family/${userId}/${friendId}`;
export const GET_USER = (id) => BASE_URL + `/users/${id}`;
export const UPDATE_USER = () => BASE_URL + '/users/my_profile';
export const REGISTER_NOTIFY_USER = () => API_URL() + '/app/comingsoonnotifyme';
export const INVITE_BULK_USERS = () => BASE_URL + `/users/store-bulk`;
export const INVITE_EMAIL = () => BASE_URL + `/users/invite-email`;

// User Processes URLs
export const GET_PROCESSES = (filter) =>
  BASE_URL + `/processes${filter ? '/filter/' + filter : ''}`;
export const GET_PROCESS = (id) => BASE_URL + `/processes/${id ? id : '__new'}`;
export const DELETE_PROCESS = (id, type) =>
  BASE_URL + `/delete-event/${id}/${type}`;
export const DUPLICATE_PROCESS = (id) =>
  BASE_URL + `/processes/duplicate/${id}`;
export const AUTOSUGGEST_CLIENTS = (filter) =>
  BASE_URL + `/clients${filter ? '?q=' + filter : ''}`;
export const AUTOSUGGEST_CONTRACTS = (id, filter) =>
  BASE_URL + `/client_contracts/${id}${filter ? '?q=' + filter : ''}`;
export const EVENTS_IN_CLASS = () => BASE_URL + `/get-events`;
export const OTHER_EVENTS_IN_CLASS = (id) =>
  BASE_URL + `/get-other-events/${id}`;

export const GET_CLASS = (id) => BASE_URL + `/classes/${id}`;
export const GET_CLASS_EVENT = (id, userId) =>
  BASE_URL + `/classes/${id}/${userId}`;
export const DELETE_CLASS_EVENT = (userId, classId) =>
  BASE_URL + `/users/${userId}/processes/${classId}`;
export const DELETE_CLASS_SELECTED_EVENTS = (userId, eventIds) =>
  BASE_URL + `/users/${userId}/events/${eventIds}`;
export const GET_PRIZES = (filter) =>
  BASE_URL + `/store${filter ? '/filter/' + filter : ''}`;
export const GET_PRIZE = (id) => BASE_URL + `/store/${id}`;
export const ADD_PRIZES = () => `${BASE_URL}/store`;
export const GET_PRIZES_ALL = () => BASE_URL + '/store';
export const DUPLICATE_PRIZE = (id) => BASE_URL + `/store/duplicate/${id}`;

export const GET_TEMPLATES = () => BASE_URL + `/templates`;
export const GET_TEMPLATE = (id) => BASE_URL + `/templates/${id}`;
export const SAVE_TEMPLATE = () => BASE_URL + `/templates/save`;

// upload file
export const FILE_UPLOADS = () => BASE_URL + `/file/uploads`;
export const FILES_UPLOADS = () => BASE_URL + `/file/uploadsfiles`;
export const FILE_DELETE = () => BASE_URL + `/file/delete`;

// settings
export const UPDATE_SETTINGS = () => BASE_URL + `/settings`;
// send mail
export const SEND_MAIL = () => BASE_URL + `/mail`;

// importing files
export const IMPORT_RECORD = () => BASE_URL + `/import`;
export const PURGE_RECORD = () => BASE_URL + `/purgerecords`;
export const DELETE_INDEXES = () => BASE_URL + `/deleteindexes`;
export const DELETE_INDEX = (id) => BASE_URL + `/deleteindex/${id}`;

export const CHECKLIST = () => BASE_URL + '/checklists';
export const CHECKLIST_DELETE = (item) => BASE_URL + `/checklists/${item}`;

export const GUIDE = () => BASE_URL + '/guide';

// wallet
export const WALLET_AUTH = () => BASE_URL + '/payment/generate-auth';
export const WALLET_RETRY_AUTH = () => BASE_URL + '/payment/retry-payment-uuid';
export const TUTION_INFO = (tuitionId) =>
  BASE_URL + `/payment/tuition-info/${tuitionId}`;
export const ADD_CREDIT = (userId) => BASE_URL + `/payment/credit/${userId}`;
export const EDIT_CREDIT = (userId) => BASE_URL + `/payment/credit/${userId}`;

export const GET_PUNCHPASSES = (studioId) =>
  BASE_URL + `/payment/punch-passes/${studioId}`;

export const TUTION_CLASS = (classId) => BASE_URL + `/tuition/class/${classId}`;

//studio locations
export const STUDIO_LOCATION = () => BASE_URL + `/location`;
export const STUDIO_ROOMS = () => BASE_URL + `/room`;

// my class
export const GET_MY_CALENDAR = () => BASE_URL + `/my-calendar`;
export const GET_CHECKIN = (classId, date) =>
  BASE_URL + `/checkin/${classId}/${date}`;
export const ADD_CHECKIN = (classId) => BASE_URL + `/checkin/${classId}`;
// feed
export const GET_FEED = () => BASE_URL + `/feed`;
export const FEED_BY_ID = (id) => BASE_URL + `/feed/${id}`;

export const GET_CATEGORIES = () => BASE_URL + `/categories`;
export const UPDATE_CATEGORY = () => BASE_URL + `/studio-categories`;

export const GET_LEVELS = () => BASE_URL + `/levels`;
export const UPDATE_LEVEL = () => BASE_URL + `/studio-levels`;

// members
export const GET_MEMBERS = (filter = '') =>
  BASE_URL + `/users/filter/${filter}`;
export const GET_MEMBER = (id) => BASE_URL + `/user/${id}`;
export const GET_CARD_DETAILS = (id) =>
  BASE_URL + `/payment/card-details/${id}`;
export const GET_BANK_DETAILS = (id) =>
  BASE_URL + `/payment/bank-details/${id}`;
export const GET_PAYMENT_TRANSACTIONS = (id, pageNo, pageSize) =>
  BASE_URL +
  `/payment/transactions/${id}?page_no=${pageNo}&page_size=${pageSize}`;
export const GET_PAYMENT_TRANSACTION = (id, ref) =>
  BASE_URL + `/payment/transaction-all-info/${id}/${ref}`;
export const POST_RETRY_FAILED_TRANSACTION = () =>
  BASE_URL + '/payment/retry-failed-transaction';
export const GET_RECURRING_PAYMENT_TRANSACTIONS = (id, status) =>
  BASE_URL + `/payment/recurring-transactions/${id}/${status}`;
export const GET_MEMBER_AUTH = (id) =>
  BASE_URL + `/payment/generate-auth-member/${id}`;

export const EDIT_PAYMENT = () =>
  BASE_URL + `/payment/recurring-transactions/edit`;

export const EDIT_CASH_PAYMENT = () => BASE_URL + `/payment/cash/status`;

export const ADVANCE_CASH_PAYMENT = () =>
  BASE_URL + `/payment/cash/pay-in-advance`;

export const ADVANCE_PAYMENT = () =>
  BASE_URL + `/payment/scheduled/pay-in-advance`;

export const RETRY_PAYMENT = () =>
  BASE_URL + `/payment/retry-failed-scheduled-transaction`;
export const CANCEL_PAYMENT = () =>
  BASE_URL + `/payment/cancel-scheduled-transaction`;

export const GET_SCHEDULE_PAYMENT = (id) =>
  BASE_URL + `/payment/recurring-transactions/${id}/details`;

export const GET_ALL_PAYMENT = (id) =>
  BASE_URL + `/payment/recurring-transactions/group/${id}`;

export const EDIT_ALL_DATE_PAYMENT = () =>
  BASE_URL + `/payment/recurring-transactions/bulk/date`;

export const REFUND_PAYMENT = (transactionRef) =>
  BASE_URL + `/payment/transaction/${transactionRef}/refund`;

export const EDIT_ALL_AMOUNT_PAYMENT = () =>
  BASE_URL + `/payment/recurring-transactions/bulk/amount`;

export const EDIT_ALL_DATE_INSTRUMENT = () =>
  BASE_URL + `/payment/recurring-transactions/bulk/instrument`;

export const CANCEL_REFUND_ALL = (id) =>
  BASE_URL + `/payment/cancel-refund-all/${id}`;

export const GET_ONE_TIME_PAYMENT = (id) =>
  BASE_URL + `/payment/one-time/${id}/card-details`;
//user profile classes
export const GET_MEMBER_PROCESSES = (id) => BASE_URL + `/user/${id}/processes`;
export const GET_MEMBER_ELIGIBLE_PROCESSES = (id, data) =>
  BASE_URL +
  `/student/${id}/eligible-processes?categories=${data.category}&levels=${data.level}&seasons=${data.season}`;
export const REGISTER_FOR_CLASSES = (id) =>
  BASE_URL + `/student/${id}/processes`;

// studio seasons
export const CREATE_STUDIO_SEASON = () => BASE_URL + `/studio-seasons`;

// studio seasons
export const GET_STUDIO_SEASON = () => BASE_URL + `/studio-seasons`;
export const CLONE_SEASON = (id) => BASE_URL + `/clone-studio-seasons/${id}`;

export const GET_RECITALS = () => BASE_URL + `/recitals`;
export const GET_RECITAL = (id) => BASE_URL + `/recitals/${id}`;
export const UPDATE_STATUS_RECITAL = (id) =>
  BASE_URL + `/recitals/update-status/${id}`;
export const UPDATE_RECITAL_PERFORMANCES = (id) =>
  BASE_URL + `/recital/${id}/update-performances`;
export const SEND_NOTIFICATION_FAMILY = (recitalId, id) =>
  BASE_URL + `/recital/${recitalId}/send-notification/${id}`;
export const START_END_PERFORMENCE = (recitalId, id) =>
  BASE_URL + `/recital/${recitalId}/performance/${id}`;
export const DOWNLOAD_RECITAL = (type) =>
  BASE_URL + `/download-recital/${type}`;

export const GET_RESOURCES = () => BASE_URL + `/resources`;
export const GET_RESOURCE = (id) => BASE_URL + `/resources/${id}`;
export const GET_SIGNED_WAIVER = (id) => BASE_URL + `/get-resource/${id}`;
export const POST_SIGNE_WAIVER_BY_EMAIL = () =>
  BASE_URL + `/sign-waiver-by-email`;

export const GET_SIGN_WAIVER = (id) =>
  BASE_URL + `/user/sign-waivers?student_id=${id}`;
export const ADD_SIGN_WAIVER = () => BASE_URL + `/user/sign-waivers`;
export const SEND_NOTIFY_SIGN_WAIVER = () =>
  BASE_URL + `/user/notify-sign-waivers`;
export const SEND_NOTIFY_USER_SIGN_WAIVER = () =>
  BASE_URL + `/user/notify-sign-event-waivers`;

export const CREATE_STUDIO_WAIVER = () => BASE_URL + `/studio-waivers`;
export const RESERVE_PRIVATE_SESSION = () =>
  BASE_URL + `/class/private-session/reserve`;
export const RESERVE_STUDIO_RENTAL = () =>
  BASE_URL + `/class/studio-rental/reserve`;

export const INSIGHTS_POPULAR_CLASSES = (startDate, endDate) =>
  `insights/classes-popular?start_date=${startDate}&end_date=${endDate}`;
export const INSIGHTS_ABSENTEES = (startDate, endDate) =>
  `insights/absentees?start_date=${startDate}&end_date=${endDate}`;
export const INSIGHTS_ATTENDANCE_BY_CLASS = (startDate, endDate, classId) =>
  `insights/attendance-by-class?start_date=${startDate}&end_date=${endDate}&class_id=${classId}`;
export const INSIGHTS_CLASS_LIST = () => `insights/classes-list`;
export const INSIGHTS_ATTENDANCE_OVERVIEW = (startDate, endDate) =>
  `insights/attendance-overview?start_date=${startDate}&end_date=${endDate}`;
export const INSIGHTS_REVENUE = (year) =>
  `insights/classes-revenue?year=${year}`;
export const INSIGHTS_CLASSES_BY_DATE = (date) =>
  `insights/classes-by-date?date=${date}`;

export const SEND_APP_DOWNLOAD_SMS = () => BASE_URL + `/download-app`;

// feeds api
export const GET_GROUPS = () => BASE_URL + `/groups`;
export const EDIT_GROUPS = (id) => BASE_URL + `/groups/${id}`;

export const EMAIL_RENDER = () => BASE_URL + `/messaging/email/render`;
export const GET_PRESIGNED = () => BASE_URL + `/file/presigned`;

// messanger url
export const CREATE_MESSAGE = () => MESSENGER_URL + `/messanger/emails`;
export const GET_MESSAGE = () => MESSENGER_URL + `/messanger/emails`;
export const GET_MESSAGE_BY_ID = (id) =>
  MESSENGER_URL + `/messanger/emails/${id}`;
export const DELETE_MESSAGE_BY_ID = (id) =>
  MESSENGER_URL + `/messanger/emails/${id}`;
export const EDIT_MESSAGE_BY_ID = (id) =>
  MESSENGER_URL + `/messanger/emails/${id}`;
export const SEND_MESSAGE_BY_ID = (id) =>
  MESSENGER_URL + `/messanger/emails/${id}/send`;
export const EDIT_STARED_MESSAGE_BY_ID = (id) =>
  MESSENGER_URL + `/messanger/emails/${id}/starred`;

// chat urls
export const GET_CHATS = () => BASE_URL + `/chats`;
export const GET_CHAT_USERS = (id) => BASE_URL + `/chats/users?group_id=${id}`;
export const SEND_CHAT_MESSAGE = () => BASE_URL + `/chats/message`;
export const NEW_CHAT = () => BASE_URL + `/chats/new`;
export const CHAT_AUTH = () => BASE_URL + `/pusher/user-auth`;
export const CHAT_RECEIPT_BY_ID = (id) => BASE_URL + `/chats/receipts/${id}`;

// report urls
export const CREATE_ENROLLMENT_REPORT = () => `/enrollment-report`;
export const CREATE_STUDENT_REPORT = () => `/generate-report`;
export const CREATE_PAYMENT_REPORT = () => `/payment/report/transactions`;
export const CREATE_ATTENDANCE_REPORT = () => `/generate-report`;
export const GET_DASHBOARD_DATA = () => `/insights/classes-by-date-new`;
export const UPDATE_CLASS_EVENT = (id) => `/update-class-event/${id}`;

// notification urls

export const GET_NOTIFICATIONS = (id) => BASE_URL + `/notifications/${id}`;
export const EDIT_NOTIFICATIONS = () =>
  BASE_URL + '/notifications-status-update';
export const POST_NOTIFICATION = () => BASE_URL + `/notifications`;

export const NOTIFICATION_CASH_OR_OTHER = () =>
  BASE_URL + `/notifications?notificationType=cash-or-other`;

export const LOG_IFRAME_ERRORS = () => BASE_URL + `/log-errors`;

export const MARK_ATTENDANCE = (id) => BASE_URL + `/event/${id}/attendance`;

// staff availability
export const GET_All_ROOM_AVAILABLE = () =>
  BASE_URL + `/availability/studio/rooms`;
export const GET_STAFF_AVAILABLE = () => BASE_URL + `/availability/staff`;
export const ADD_STAFF_AVAILABLE = () => BASE_URL + `/availability/staff`;
export const DELETE_STAFF_AVAILABLE = (id) =>
  BASE_URL + `/availability/staff/${id}`;
export const GET_ROOM_AVAILABLE = () => BASE_URL + `/availability/rooms`;
export const DELETE_ROOM_AVAILABLE = (id) =>
  BASE_URL + `/availability/rooms/${id}`;
export const UPDATE_STAFF_AVAILABLE = (id) =>
  BASE_URL + `/availability/staff/${id}/update`;
export const UPDATE_ROOM_AVAILABLE = (id) =>
  BASE_URL + `/availability/rooms/${id}/update`;

// shop urls
export const GET_SHOP_LIST = () => `/shop/products`;
export const GET_SHOPPING_CATEGORY_LIST = () => '/shop/categories';
export const CREATE_PRODUCT = () => '/shop/product';
export const UPDATE_PRODUCT = (id) => `/shop/product/${id}`;
export const CREATE_ORDER = () => '/shop/order/create';
export const CREATE_INVOICE = () => '/shop/invoices';
export const PAYOUT_REPORT = () => '/payment/report/payout-report';

// skill api
export const GET_SKILL = () => BASE_URL + `/skills`;
export const GET_SKILL_ID = (id) => BASE_URL + `/skills/${id}`;
export const USER_SKILLS = () => BASE_URL + `/user-skills`;
export const USER_SKILLS_BY_ID = (id) => BASE_URL + `/user-skills/${id}`;
export const USER_SKILLS_ID = ({ skillId, userId }) =>
  BASE_URL + `/user-skills/${skillId}/${userId}`;

export const PAYOUT_DETAIL = (id) =>
  `/payment/report/payout-report/${id}/details`;
export const ORDER_INVOICE = () => '/shop/order/invoice-mail';
export const DOWNLOAD_INVOICE = () => '/shop/order/invoice-download';
export const GET_SHOP_BY_ID = (id) => `/shop/products/${id}`;
export const DELETE_SHOP = (id) => `/shop/product/${id}`;

export const LEADS = () => '/leads';
export const LEADS_ID = (id) => `/leads/${id}`;
export const RESET_TRIAL = (id) => `/leads/${id}/reset-trial`;
export const SEND_INVITE = () => `/leads/send-invite`;
export const DELETE_FULL_CLASS = () => BASE_URL + `/cancel-class`;

export const GET_USERS_WAIVER = (id) => BASE_URL + `/user/waivers/${id}`;

export const QB_LINK_SUCCESS = () => BASE_URL + `/studio/qb-accounting/link-success`;

export const NOTIFICATION_SYSTEM_STYLE = {
  NotificationItem: {
    DefaultStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      borderRadius: '4px',
      fontSize: '14px',
      padding: 0,
      marginTop: 120,
      paddingRight: 20,
    },

    success: {
      borderTop: 0,
      background: 'transparent',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    error: {
      borderTop: 0,
      background: 'transparent',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    warning: {
      borderTop: 0,
      background: 'transparent',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },

    info: {
      borderTop: 0,
      background: 'transparent',
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      boxShadow: 0,
    },
  },

  Title: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
      paddingRight: 5,
      color: '#fff',
      display: 'inline-flex',
      fontSize: 20,
      fontWeight: 'bold',
      // left: '15px',
      // position: 'absolute',
      // top: '50%',
    },
  },

  MessageWrapper: {
    DefaultStyle: {
      display: 'block',
      color: '#fff',
      width: '100%',
    },
  },

  Dismiss: {
    DefaultStyle: {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'inherit',
      fontSize: 20,
      color: '#f2f2f2',
      position: 'relative',
      margin: 0,
      padding: 0,
      background: 'none',
      borderRadius: 0,
      opacity: 1,
      width: 20,
      height: 20,
      textAlign: 'initial',
      float: 'none',
      top: 'unset',
      right: 'unset',
      lineHeight: 'inherit',
    },
  },

  Action: {
    DefaultStyle: {
      background: '#fff',
      borderRadius: '2px',
      padding: '6px 20px',
      fontWeight: 'bold',
      margin: '10px 0 0 0',
      border: 0,
    },

    success: {
      backgroundColor: '#45b649',
      color: '#fff',
    },

    error: {
      backgroundColor: '#f85032',
      color: '#fff',
    },

    warning: {
      backgroundColor: '#ffd700',
      color: '#fff',
    },

    info: {
      backgroundColor: '#00c9ff',
      color: '#fff',
    },
  },

  ActionWrapper: {
    DefaultStyle: {
      margin: 0,
      padding: 0,
    },
  },
};

export const stateList = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const timeZones = [
  {
    label: 'Hawaii Standard Time (HST)',
    value: 'HST',
  },
  {
    label: 'Hawaii-Aleutian Daylight Time (HDT)',
    value: 'HDT',
  },
  {
    label: 'Alaska Daylight Time (AKDT)',
    value: 'AKDT',
  },
  {
    label: 'Pacific Daylight Time (PDT)',
    value: 'PDT',
  },
  {
    label: 'Mountain Standard Time (MST)',
    value: 'MST',
  },
  {
    label: 'Mountain Daylight Time (MDT)',
    value: 'MDT',
  },
  {
    label: 'Central Daylight Time (CDT)',
    value: 'CDT',
  },
  {
    label: 'Central Standard Time (CST)',
    value: 'CST',
  },
  {
    label: 'Eastern Standard Time (EST)',
    value: 'EST',
  },
  {
    label: 'Eastern Daylight Time (EDT)',
    value: 'EDT',
  },
  {
    label: 'Pacific Standard Time (PST)',
    value: 'PST',
  },
];

export const PAGINATION_START = 1;
export const PAGINATION_PER_PAGE = 7;
export const CASH = 'CASH/OTHER';
export const BANK = 'ACH/BANK';
export const CARD = 'CARD';
export const PAYMENT_PAGINATION_SIZE = 1000;
export const DEFAULT_DATE = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT = 'MM/DD/YY';
export const SYSTEM_DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const SKILL_DATE_FORMAT = 'M/D/YY';
export const SYSTEM_CUSTOM_DATE_FORMAT = 'MMM DD';
export const SYSTEM_CUSTOM_DATE_FORMAT_MM_DD = 'MMM-DD';
export const MEMBERSHIP_PAGINATION_PER_PAGE = 5;
export const ROLES = {
  parents: [1, 2],
  instructor: 3,
  student: 4,
};
export const TUITION_TYPE_CONTACT_HOURS = 1;
export const TUITION_TYPE_CUSTOM = 2;
export const TUITION_RATE_TYPE_WEEKLY = 1;
export const TUITION_RATE_TYPE_MONTHLY = 2;
export const TUITION_RATE_TYPE_FULL = 3;
export const GET_CLASS_TIME = (hours, minutes) => {
  let classTime = hours;
  let min = minutes;
  if (min > 0) {
    return classTime + min / 60;
  }
  return classTime;
};

export const clearSocialStorage = () => {
  localStorage.removeItem('socialType');
  localStorage.removeItem('registeringFor');
  localStorage.removeItem('classId');
  localStorage.removeItem('loginRedirect');
};
export const PASSWORD_VALIDATOR =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const EMAIL_SUPPORT = 'noreply@suitepeach.com';

export const expiryStatus = (statusCode) => {
  if (statusCode === 1) return 'will never expire';
  else if (statusCode === 2) return 'will automatically renew';
  else if (statusCode === 3) return '30 days';
  else if (statusCode === 4) return '3 months';
  else if (statusCode === 5) return '6 months';
  else if (statusCode === 6) return '1 year';
  else if (statusCode === 7) return '1.5 years';
  else if (statusCode === 8) return '2 years';
};
export const rateStatus = (statusCode) => {
  if (statusCode == 1) return 'per week';
  else if (statusCode == 2) return 'per month';
};

export const PHONE_FORMAT = '+1 (###) ### ####';
export const DEFAULT_MASK_PHONE_FORMAT = '+1(999) 999–9999';
export const DEFAULT_MASK_PHONE_FORMAT_PLACEHOLDER = '+1(___) ___–____';

export const CLASSES_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DRAFT: 'draft',
  DELETED: 'deleted',
};
export const CLASS_PASS_TYPE = 'class_pass';
export const SUPPORT_EMAIL = 'support@getventive.com';

export const ROLES_OBJECT = {
  'Parent/Guardian': {
    id: 1,
    name: 'Parent/Guardian',
    route: 'Parent',
    header: 'Parents',
  },
  'Parent/Guardian (Secondary)': {
    id: 2,
    name: 'Parent/Guardian (Secondary)',
    route: 'Parent',
    header: 'Parent',
  },
  Instructor: {
    id: 3,
    name: 'Instructor',
    route: 'Instructor',
    header: 'Instructor',
  },
  Student: {
    id: 4,
    name: 'Student',
    route: 'Student',
    header: 'Students',
  },
  Staff: {
    id: 5,
    name: 'Staff',
    route: 'Staff',
    header: 'Staff',
  },
  'Studio Admin': {
    id: 6,
    name: 'Studio Admin',
    route: 'studio_admin',
    header: 'Studio Admin',
  },
  'Studio Owner': {
    id: 7,
    name: 'Studio Owner',
    route: 'studio_owner',
    header: 'Studio Owner',
  },
  // Hiding roles as confirmed by AM
  // 'Enterprise Admin': {
  //   id: 8,
  //   name: 'Enterprise Admin',
  //   route: 'enterprise_admin',
  // },
  // Fan: {
  //   id: 9,
  //   name: 'Fan',
  //   route: 'fan',
  // },
};
export const CART_TYPE_PASS = 'PASS';
export const CART_TYPE_CURRICULUM = 'curriculum';
export const CART_TYPE_DROPIN = 'drop-in';
export const NOTIFICATION_POSITION = {
  TOP_RIGHT: 'tr',
  TOP_LEFT: 'tl',
  TOP_CENTER: 'tc',
  BOTTOM_CENTER: 'bc',
  BOTTOM_LEFT: 'bf',
  BOTTOM_END: 'be',
  BOTTOM_RIGHT: 'br',
  // : tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center)
};
export const NOTIFICATION_AUTO_DISMISS = 5;
export const renderRecurringToggle = ({
  values,
  classes,
  setFieldValue,
  valueChanged,
  recurring_event,
  user,
  template,
  process,
  step,
  isAdd,
  start_date,
  end_date,
  initialValues,
}) => {
  return (
    <Row>
      <ToggleSwitch
        name="recurring_event"
        className={classes.toggleSwitch}
        checked={get(values, 'recurring_event', true)}
        rightComponent={
          <span className={classes.toggleText}>
            {get(values, 'recurring_event', true)
              ? 'Recurring Event'
              : 'Custom'}
          </span>
        }
        switchProps={{
          checked: Boolean(get(values, 'recurring_event', true)),
          offColor: '#515254',
          offHandleColor: '#515254',
          onColor: '#F5B399',
          onHandleColor: '#EA734F',
          uncheckedIcon: false,
          checkedIcon: false,
          inputProps: { 'aria-label': 'controlled' },
          onChange: (e) => {
            const checked = e.currentTarget.checked;
            setFieldValue('recurring_event', checked);
            if (step)
              valueChanged(recurring_event, process, template, user, {
                value: checked,
              });
            if (checked === false && isAdd === true) {
              const startDate = moment()
                .add(1, 'day')
                .format('YYYY-MM-DD 11:00:00');

              const endtDate = moment()
                .add(1, 'day')
                .format('YYYY-MM-DD 12:00:00');

              setFieldValue('start_date', startDate);
              if (step)
                valueChanged(start_date, process, template, user, {
                  value: startDate,
                });

              setFieldValue('end_date', endtDate);
              if (step)
                valueChanged(end_date, process, template, user, {
                  value: endtDate,
                });
            }
            if (initialValues && initialValues.recurring_event !== checked) {
              const startDate = moment().format('YYYY-MM-DD 11:00:00');

              const endtDate = moment()
                .add(7, 'day')
                .format('YYYY-MM-DD 12:00:00');

              setFieldValue('start_date', startDate);
              setFieldValue('end_date', endtDate);
              const starDateValue = moment().format('dddd');
              const currentMinute = moment().format('m');
              const day = daysInWeek.find(
                (item) => item.value === starDateValue
              );
              setFieldValue('recurring_repeat_on', {
                [day.id]: {
                  id: day.id,
                  label: day.label,
                  value: day.value,
                  shortValue: day.shortValue,
                  from: moment()
                    .add(60 - currentMinute, 'minute')
                    .format('YYYY-MM-DD HH:mm:00'),
                  to: moment()
                    .add(120 - currentMinute, 'minute')
                    .format('YYYY-MM-DD HH:mm:00'),
                },
              });
              setFieldValue('recurring_type', {
                value: 'Weekly',
                label: 'Weekly',
                unit: 'week',
              });
            } else {
              setFieldValue('start_date', initialValues.start_date);
              setFieldValue('end_date', initialValues.end_date);
              setFieldValue(
                'recurring_repeat_on',
                initialValues.recurring_repeat_on
              );
              setFieldValue('recurring_type', initialValues.recurring_type);
            }
          },
        }}
      />
    </Row>
  );
};

export const USA_STATE_LIST = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    id: 0,
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    id: 1,
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
    id: 2,
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    id: 3,
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    id: 4,
  },
  {
    name: 'California',
    abbreviation: 'CA',
    id: 5,
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    id: 6,
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    id: 7,
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    id: 8,
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
    id: 9,
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
    id: 10,
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    id: 11,
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    id: 12,
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
    id: 13,
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    id: 14,
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    id: 15,
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    id: 16,
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    id: 17,
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    id: 18,
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    id: 19,
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    id: 20,
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    id: 21,
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    id: 22,
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
    id: 23,
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    id: 24,
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    id: 25,
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    id: 26,
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    id: 27,
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    id: 28,
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    id: 29,
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    id: 30,
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    id: 31,
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    id: 32,
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    id: 33,
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    id: 34,
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    id: 35,
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    id: 36,
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    id: 37,
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    id: 38,
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
    id: 39,
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    id: 40,
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    id: 41,
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    id: 42,
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
    id: 43,
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    id: 44,
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
    id: 45,
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    id: 46,
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    id: 47,
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    id: 48,
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    id: 49,
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    id: 50,
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    id: 51,
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    id: 52,
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
    id: 53,
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    id: 54,
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    id: 55,
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    id: 56,
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    id: 57,
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    id: 58,
  },
];

export const NOTIFICATION_ACTION_AUDITION = 'audition';
export const NOTIFICATION_ACTION_CLASS_REGISTRATION = 'class_registration';
export const NOTIFICATION_ACTION_CLASS_REGISTRATION_FULL =
  'class_registration_full';
export const NOTIFICATION_ACTION_USER_UPDATE = 'user_update';
export const NOTIFICATION_ACTION_USER_CREATED = 'new_user_created';
export const NOTIFICATION_ACTION_LEAD_CREATED = 'new_lead_created';
export const NOTIFICATION_ACTION_BOOKED_TRIAL = 'booked_trial_session';
export const NOTIFICATION_ACTION_CHAT_MESSAGE = 'chat_message';
export const NOTIFICATION_ACTION_PRIVATE_RENTAL_BOOKING =
  'prevate_rental_booking';
export const NOTIFICATION_ACTION_FAILED_PAYMENT = 'failed_transaction';
export const NOTIFICATION_ACTION_CASH_OR_OTHER = 'cash_or_other';
export const NOTIFICATION_ACTION_CANCELLED_EVENT = 'cancelled_event';
export const CHARACTER_LIMIT = 50;

export const StautsType = {
  0: {
    name: 'Success',
    status: 'SUCCESS',
    value: 0,
  },
  1: {
    name: 'Pending',
    status: 'PENDING',
    value: 1,
  },
  2: {
    name: 'Processing',
    status: 'PROCESSING',
    value: 2,
  },
  3: {
    name: 'Declined',
    status: 'DECLINED',
    value: 3,
  },
  4: {
    name: 'On Hold',
    status: 'ONHOLD',
    value: 4,
  },
  5: {
    name: 'Sent',
    status: 'SENT',
    value: 5,
  },
  6: {
    name: 'Pending Approval',
    status: 'PENDING_APPROVAL',
    value: 6,
  },
  7: {
    name: 'Scheduled',
    status: 'SCHEDULED',
    value: 7,
  },
  8: {
    name: 'On Hold Funds',
    status: 'ONHOLD_FUNDS',
    value: 8,
  },
  98: {
    name: 'Cancelled',
    status: 'CANCELLED',
    value: 98,
  },
  99: {
    name: 'Failed',
    status: 'FAILED',
    value: 99,
  },
  10: {
    name: 'Past Due',
    status: 'PAST_DUE',
    value: 10,
  },
  11: {
    name: 'Partially Paid',
    status: 'PARTIALLY_PAID',
    value: 11,
  },
};

export const paymentReportStatusType = {
  0: {
    name: 'Success',
    status: 'SUCCESS',
    value: 0,
  },
  1: {
    name: 'Pending',
    status: 'PENDING',
    value: 1,
  },
  3: {
    name: 'Declined',
    status: 'DECLINED',
    value: 3,
  },
  6: {
    name: 'Pending Approval',
    status: 'PENDING_APPROVAL',
    value: 6,
  },
  7: {
    name: 'Scheduled',
    status: 'SCHEDULED',
    value: 7,
  },
  8: {
    name: 'On Hold Funds',
    status: 'ONHOLD_FUNDS',
    value: 8,
  },
  98: {
    name: 'Cancelled',
    status: 'CANCELLED',
    value: 98,
  },
  99: {
    name: 'Failed',
    status: 'FAILED',
    value: 99,
  },
  10: {
    name: 'Past Due',
    status: 'PAST_DUE',
    value: 10,
  },
  11: {
    name: 'Partially Paid',
    status: 'PARTIALLY_PAID',
    value: 11,
  },
};

export const PaymentTypes = {
  'Credit/Debit Card': {
    key: 1,
    value: 'Credit/Debit Card',
    instrumentType: 2,
  },
  ACH: {
    key: 2,
    value: 'ACH',
    instrumentType: 1,
  },
  Cash: {
    key: 3,
    value: 'Cash',
    instrumentType: 3,
  },
};

export const PaymentTypeKeyHash = {
  2: {
    value: 'Credit/Debit Card',
    instrumentType: 2,
  },
  1: {
    value: 'ACH',
    instrumentType: 1,
  },
  3: {
    value: 'Cash',
    instrumentType: 3,
  },
};

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
export const MEMBERSHIP_DELETE_ADVANCE = 'MEMBERSHIP_DELETE_ADVANCE';

export const getStateAbbreviation = (stateName) => {
  const state = USA_STATE_LIST.find((state) => state.name === stateName);
  return state ? state.abbreviation : 'N/A';
};
export const convertTimeFormat = (timeString) => {
  // Split the time string into start and end times
  let times = timeString.split('-');
  let startTime = times[0].trim();



  // Remove ':00' from the times
  startTime = startTime.replace(':00', '');


  // Remove leading zero from start hour if present
  if (startTime.startsWith('0')) {
    startTime = startTime.substring(1);
  }


  // Remove space between time and AM/PM
  startTime = startTime.replace(' ', '');


  if (times.length > 1) {
    let endTime = times[1].trim();
    endTime = endTime.replace(':00', '');
    if (endTime.startsWith('0')) {
      endTime = endTime.substring(1);
    }
    endTime = endTime.replace(' ', '');
    return startTime + ' - ' + endTime;
  }
  return startTime;
}
