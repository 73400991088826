import React from 'react';
import { SvgIcon } from '@mui/material';

const SuccessGreenIcon = (props) => (
  <SvgIcon
    fontSize="small"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        fill="#7BDBC4"
        stroke="#7BDBC4"
      />
      <path
        d="M9.74496 17.5125C9.85496 17.6775 10.02 17.7325 10.24 17.7325C10.4325 17.7325 10.5975 17.6775 10.735 17.5125L18.8475 9.42754C18.9575 9.31754 19.04 9.15254 19.04 8.93254C19.04 8.74004 18.9575 8.57504 18.8475 8.43754L17.83 7.44754C17.6925 7.31004 17.5275 7.22754 17.335 7.22754C17.1425 7.22754 16.9775 7.31004 16.84 7.44754L10.24 14.0475L7.15996 10.9675C6.99496 10.83 6.82996 10.7475 6.63746 10.7475C6.44496 10.7475 6.27996 10.83 6.16996 10.9675L5.15246 11.9575C5.01496 12.095 4.95996 12.26 4.95996 12.4525C4.95996 12.6725 5.01496 12.8375 5.15246 12.9475L9.74496 17.5125Z"
        fill="white"
      />
    </svg>
  </SvgIcon>
);

export default SuccessGreenIcon;
