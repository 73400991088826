import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl  } from 'react-intl';
import { formValueChanged, saveFormValues, setActiveEditTab } from "store/actions/processes";
import FormView from "./formView";

const Enhanced = injectIntl(connect(
  (state, props) => ({
    user: state.auth.user,
    info: state.auth.info,
    process: props.process,
    template: props.template,
    step: props.step,
    toggle: props.toggle
  }),
  {
    formValueChanged,
    saveFormValues,
    setActiveEditTab
  }
)(FormView));
export default withRouter(Enhanced);
