import React from 'react';

import classes from './DropdownAsync.module.scss';

import AsyncSelect from 'react-select/async'

const DropdownAsync = (props) => {
  const { ...rest } = props;

  return (
    <div className={classes.root}>
      <AsyncSelect
        classNamePrefix="rselect"
        defaultOptions
        {...rest}
      />
    </div>
  );
};

DropdownAsync.defaultProps = {
  
};

export default DropdownAsync;
