import React from 'react';
import classes from './MainContentReview.module.scss';

const CustomHeader = ({ date, localizer }) => {
  return (
    <div className={classes.header}>
      <div className={classes.day}>{localizer.format(date, 'ddd')}</div>
      <div className={classes.numberDay}>{localizer.format(date, 'D')}</div>
    </div>
  );
};

export default CustomHeader;
