import * as React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
// import bn from 'utils/bemnames';
import cx from 'classnames';
// const bem = bn.create('checkbox');

class Checkbox extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    text: PropTypes.any,
    value: PropTypes.any,
    name: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    onClick: () => {},
    checked: false,
    disabled: false,
    text: '',
    name: 'demo',
  };
  render() {
    const {
      onChange,
      onClick,
      text,
      checked,
      value,
      disabled,
      className,
      name,
    } = this.props;

    return (
      <>
      <FormGroup
        className={cx('cr-checkbox', className)}
        onClick={() => onClick()}
      >
        <Input
          checked={checked}
          type="checkbox"
          onClick={(e) => {
            onChange(e.target.checked);
          }}
          disabled={disabled}
          value={value}
          id={value}
          name={name}
        />
        <Label for={value}>{text}</Label>
      </FormGroup>
      </>
    );
  }
}

export default Checkbox;
