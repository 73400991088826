import {get, isEqual} from "lodash";
import {connect} from "react-redux";
import {compose, withState, lifecycle} from "recompose";
import {injectIntl} from "react-intl";

import Participants from './view';
import { setActiveEditTab } from "store/actions/processes";

export default injectIntl(compose(
    connect(
        state => ({
            users: get(state, "auth.info.users", [])
        }),
        {
            setActiveEditTab
        }
    ),
    withState("userList", "setUserList", ({users}) => users),
    lifecycle({
        componentWillUpdate(nextProps) {
            if (!isEqual(get(this.props, "users", []), get(nextProps, "users", []))) {
                this.props.setUserList(get(nextProps, "users", []));
            }
        }
    })
)(Participants));