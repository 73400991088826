import React from "react";
import { Label } from "reactstrap";
import Select from "react-select";
import { get } from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Checkbox from "components/Checkbox";

class SelectStudios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeMenuOnSelect: true
    };
  }

  componentDidMount() {
    // if (!this.props.checked){
    //   this.props.onChange(get(this.props, "user.studios", []).map(item => get(item, "studio_key")));
    // }
  }

  setCloseMenuOnSelect = (closeMenuOnSelect) => {
    this.setState({closeMenuOnSelect})
  }
  
  checked_checkboxes=[]

  render() {
    const {
      user,
      name,
      checked,
      disabled,
      auth,
      item,
      allowSelectAll,
      allOption,
      validationState,
      onChange
    } = this.props;
    const {
      closeMenuOnSelect
    } = this.state;
    const checked_items = checked || get(user, "studios", []).map(item => ({value: get(item, "studio_key")}));
    let studios = auth.info.studios;
    let checked_studios = checked_items.value
    let is_sold = !this.props.process || !this.props.process.data.fields.order_sold || this.props.process.data.fields.order_sold.value
    
    if (
      is_sold && 
      !this.props.contract && 
      this.props.process &&
      this.props.process.data.fields.contract_studios &&
      this.props.process.data.fields.contract_studios.value
    ){
      studios = this.props.process.data.fields.contract_studios.value.map(
        s => ({ studio_key: s })
      );

      checked_studios = (checked_studios || []).filter(s=>studios.find(s1=> s1.studio_key === s))
    }
    if(disabled){
      return <span><Label>{item.title}</Label><br/>{(checked_studios||[]).map((s, i)=><span key={i} style={{paddingRight: '20px',display: 'inline-block'}}>{s}</span>)}</span>
    }

    const value = (checked_studios || []).map(studio => ({
      value: studio,
      label: studio
    }));
    this.checked_checkboxes = value.map(v=>v.value)

    const studioOptions = (studios || []).map(studio => ({
      value: studio.studio_key,
      label: studio.studio_key
    }));
    
    let options = studioOptions;
    if(allowSelectAll && studioOptions.length > 1 && !this.props.checkboxes){
      options = [allOption, ...studioOptions];
    }
    // if (options.length === 1) return null;
    return (
      <div>
        <Label for={name}>
          <span> Studio<span style={{ color: "red" }}>*</span></span>
        </Label>
        {this.props.checkboxes ? <div>
          {options.length===0 ? <span>No studios selected in contract</span>
            : options.map(o=><span
              key={'studio_' + o.value}
              >
            <Checkbox
                checked={this.checked_checkboxes.indexOf(o.value)>-1}
                style={{display: 'inline-block',marginRight: '10px'}}
                onChange={checked => {
                  let cbx=this.checked_checkboxes
                  if(!checked){
                    let ind = cbx.indexOf(o.value)
                    if(ind>-1)  cbx.splice(ind,1)
                  } else cbx.push(o.value)
                  onChange(cbx);
                }}
                text={o.label}
              />
          </span>)}
        </div>
         :
        <Select
          ref={ref => this.selectRef = ref }
          options={options}
          className="react-select-container"
          classNamePrefix="react-select"
          isMulti
          value={value}
          closeMenuOnSelect={closeMenuOnSelect}
          onChange={selected => {
            if (
              (selected.length > 0 && selected[selected.length - 1].value === allOption.value)
              || (selected.length === studioOptions.length)
            ) {
              this.setCloseMenuOnSelect(true); // close dropdown
              this.selectRef.blur(); // close dropdown
              return onChange(studioOptions.map(item => item.value));
            }
            this.setCloseMenuOnSelect(false); // close dropdown
            return onChange(selected.map(item => item.value));
          }}
        />}
      </div>
    );
  }
};
SelectStudios.propTypes = {
  name: PropTypes.string,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
};

SelectStudios.defaultProps = {
  allowSelectAll: true,
  allOption: {
    label: "Select All",
    value: "*"
  },
  name: "select_studios"
}
export default SelectStudios;
