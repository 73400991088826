import View from "./view";
import moment from "moment";
import { get, forEach, has } from "lodash";
import { compose, lifecycle, withState, withHandlers } from "recompose";
export default compose(
    withState('logs', 'setLogs', null),
    withHandlers({
        generateLogs: ({ setLogs }) => (messages) => {
            let logs = {};
            forEach(messages, message => {
                const seconds = get(message, "_source.created_ts") * 1000;
                const dateTime = moment(seconds).format("MMMM DD YYYY");
                const timer = moment(seconds).format("hh:mm a");
                const dataLog = get(message, "_source");
                const user = get(dataLog, "from");

                let text = '';
                text = `${get(user, "name")} saved step ${get(dataLog, "from_step", "").replace(/_/, ' ', 'gi')}`;
                if(get(dataLog, "chat")){
                    text = `${get(user, "name")} wrote ${get(dataLog, "chat")}`;
                }
                const log = {
                    timer,
                    text
                };
                
                if(has(logs, dateTime)){
                    logs[dateTime].push(log);
                }else{
                    logs[dateTime] = [];
                    logs[dateTime].push(log);
                }
            });
            setLogs(logs);
        }
    }),
    lifecycle({
        componentDidMount(){
            const messages = get(this.props, "process.data.fields.messages.value", []);
            this.props.generateLogs(messages);
        },
        componentWillReceiveProps(nextProps){
            const nextMessages = get(nextProps, "process.data.fields.messages.value", []);
            const prevMessages = get(this.props, "process.data.fields.messages.value", []);
            if(prevMessages.length !== nextMessages.length ){
                this.props.generateLogs(nextMessages);
            }
        }
    })
)(View);