import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import classes from './Editor.module.scss';
import cs from 'classnames';

const Editor = (props) => {
  const {
    id,
    value,
    onReady,
    onChange,
    onBlur,
    onFocus,
    error,
    config,
    ...rest
  } = props;
  return (
    <div className={cs(classes.root, { [classes.error]: error })}>
      <CKEditor
        id={id}
        config={config}
        editor={ClassicEditor}
        data={value}
        onReady={onReady}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data, event, editor);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        {...rest}
      />
    </div>
  );
};

Editor.defaultProps = {
  id: 'editor',
  value: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onReady: () => {},
  config: {
    toolbar: [
      'alignment', '|',
      'bold',
      'italic',
      'strikethrough',
      'underline',
      'subscript',
      'superscript',
      '|',
      'link',
      '|',
      'outdent',
      'indent',
      '|',
      'bulletedList',
      'numberedList',
      'todoList',
      '|',
      'undo',
      'redo',
    ],
  },
};

export default Editor;
