import React from "react";
import { map, get } from "lodash";
import FormattedTaggedMessage from '../FormattedTaggedMessage'
import bn from "utils/bemnames";
const bem = bn.create("activity-log");

const View = ({
    logs
}) => (
    <div className={bem.b()}>
        {
            logs
            ? (
                <ul className={bem.e('list')}>
                    {
                        map(logs, (timers, date) => {
                            return (
                                <li key={date} className={bem.e('log-section')}>
                                    <h6 className={bem.e('log-date')}>{date}</h6>
                                    {
                                        map(timers, (value, index) => (
                                            <p key={index} className={bem.e('log-item')}>
                                                <strong className={bem.e('log-time')}>{get(value, "timer", "")}</strong>
                                                <span className={bem.e('log-text')}>
                                                <FormattedTaggedMessage text={get(value, "text", "")} />
                                                </span>
                                            </p>
                                        ))
                                    }
                                    
                                </li>
                            )
                        })
                    }
                </ul>
            ): null
        }
    </div>
);
export default View;