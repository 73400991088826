import * as Types from "../types/servers";
import { LOGOUT_USER } from "../types/auth";
import get from "lodash/get";

const initialState = {
  servers: {
    // This object contains all servers list
    // this is mainly required in servers listing page
    // where we show all servers list and loading icon if srevers are loading
    data: [],
    error: null,
    loading: false,
  },
  step : 1,
  server: {
    // This object contains particular server object with id mapping
    // this is mainly required in server details page
    // where we show particular server detail and loading icon if srever details is loading
    // below is example object
    // "<id>": {
    //   data: null,
    //   error: null,
    //   loading: null
    // }
  },
  addServer: {
    // This object will be useful to show loading icon and status when user want to create new server
    is_added: false,
    data: null,
    error: null,
    loading: false
  },
  updateServer: {
    // This object will be useful to show loading icon and status when user want to update server
    data: null,
    error: null,
    loading: false
  },
  deleteServer: {
    // This object will be useful to show loading icon and status when user want to delete server
    is_deleted: false,
    data: null,
    error: null,
    loading: false
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Servers Actions

    case Types.FETCH_SERVERS_REQUEST: {
      return {
        ...state,
        servers: { data: [], error: null, loading: true }
      };
    }
    case Types.FETCH_SERVERS_SUCCESS: {
      return {
        ...state,
        servers: {
          data: action.payload,
          error: null,
          loading: false
        }
      };
    }
    case Types.FETCH_SERVERS_ERROR: {
      return {
        ...state,
        servers: {
          data: null,
          error: action.payload,
          loading: false
        }
      };
    }

    // Particular Server Actions
    case Types.FETCH_SERVER_REQUEST: {
      const existingServers = get(state, "server");
      const { id } = action.payload;
      return {
        ...state,
        server: {
          ...existingServers,
          [id]: {
            ...(existingServers[id] || {}),
            loading: true,
            error: null
          }
        }
      };
    }
    case Types.FETCH_SERVER_SUCCESS: {
      const existingServers = get(state, "server");
      const { id, data } = action.payload;
      return {
        ...state,
        server: {
          ...existingServers,
          [id]: {
            loading: false,
            error: null,
            data
          }
        }
      };
    }
    case Types.FETCH_SERVER_ERROR: {
      const existingServers = get(state, "server");
      const { id, error } = action.payload;
      return {
        ...state,
        server: {
          ...existingServers,
          [id]: {
            loading: false,
            error
          }
        }
      };
    }

    // Add Server Actions
    case Types.ADD_SERVER_REQUEST: {
      return {
        ...state,
        addServer: {
          is_added: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.ADD_SERVER_SUCCESS: {
      if(state.step===1){
        return{
          ...state,
          addServer: {
            is_added: true,
            data: action.payload,
            loading: false,
            error: null
          },
          step : 2
        };
      };
      return {
        ...state,
        addServer: {
          is_added: true,
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }
    case Types.ADD_SERVER_ERROR: {
      return {
        ...state,
        addServer: {
          is_added: false,
          loading: false,
          error: action.payload
        }
      };
    }

    // Update Server Actions
    case Types.UPDATE_SERVER_REQUEST: {
      return {
        ...state,
        updateServer: {
          loading: true,
          error: null
        }
      };
    }
    case Types.UPDATE_SERVER_SUCCESS: {
      if(state.step===1 || state.step===2){
        return{
          ...state,
          updateServer: {
            data: action.payload,
            loading: false,
            error: null
          },
          step : state.step + 1
        };
      };
      return {
        ...state,
        updateServer: {
          data: action.payload,
          loading: false,
          error: null,
          step : 1
        }
      };
    }
    case Types.UPDATE_SERVER_ERROR: {
      return {
        ...state,
        updateServer: {
          loading: false,
          error: action.payload
        }
      };
    }

    // DELETE Server Actions
    case Types.DELETE_SERVER_REQUEST: {
      return {
        ...state,
        deleteServer: {
          is_deleted: false,
          loading: true,
          error: null
        }
      };
    }
    case Types.DELETE_SERVER_SUCCESS: {
      return {
        ...state,
        deleteServer: {
          is_deleted: true,
          loading: false,
          error: null
        }
      };
    }
    case Types.DELETE_SERVER_ERROR: {
      return {
        ...state,
        deleteServer: {
          is_deleted: false,
          loading: false,
          error: action.payload
        }
      };
    }
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
