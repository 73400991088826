import {
  REQUEST_NOTIFICATION,
  ERROR_NOTIFICATION,
  NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_SUCCESS,
  EDIT_NOTIFICATION_REQUEST,
  ADD_NEW_NOTIFICATION,
  MARK_NOTIFICATIONS_AS_READ,
} from '../types/notification';
import { get } from 'lodash';

const initialState = {
  notifications: [],
  raw: {},
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_NOTIFICATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case NOTIFICATION_SUCCESS: {
      return {
        ...state,
        raw: action.payload,
        notifications: [
          ...state.notifications,
          ...get(action.payload, 'data.data', []),
        ],
        loading: false,
      };
    }
    case ADD_NEW_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }
    case EDIT_NOTIFICATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case EDIT_NOTIFICATION_SUCCESS: {
      const notificationList = state.notifications.map((item) => {
        if (action.payload.ids.includes(item.id)) {
          item.is_read = 1;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        notifications: notificationList,
      };
    }

    case MARK_NOTIFICATIONS_AS_READ: {
      const notificationList = state.notifications.map((item) => {
        if (action.payload.ids.includes(item.id)) {
          item.is_read = 1;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        notifications: notificationList,
      };
    }

    case ERROR_NOTIFICATION: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    default:
      return state;
  }
}
