import View from "./view";
import { compose, withState, withHandlers } from "recompose";
export default compose(
    withState('isExpand', 'setIsExpand', ({defaultExpand}) => !!defaultExpand),
    withHandlers({
        onToggle: ({ isExpand, setIsExpand, onToggleCallback}) => async() => {
            setIsExpand(!isExpand);
            onToggleCallback(!isExpand)
        }
    }),
)(View);