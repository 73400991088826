import QueryString from 'query-string';
import {
  AUTH_USER,
  AUTH_ERROR,
  LOGOUT_USER,
  USER_INFO_SUCCESS,
  SET_NOTIFICATION,
  USER_UPDATE_SUCCESS,
  TOKEN_USER,
  ID_TOKEN_USER,
  SET_LANGUAGE,
  REQUEST_AUTH_USER,
  SET_SELECTED_USER,
  USER_INFO_COURSES,
  REQUEST_USER_INFO_COURSES,
  START_AUTH_LOADER,
  STOP_AUTH_LOADER,
} from '../types/auth';
import { SET_MESSAGES, SET_HEADER_NOTIFICATIONS } from '../types/messages';
import * as Const from '../../utils/constants';
import history from '../../components/History';
import URL from '../../utils/urls';
import get from 'lodash/get';
import Api from '../../utils/api';
import { WebAuth } from 'auth0-js';
import store from './../../store';
import { getEmails } from './feed';
import { METHODS } from './util';
import ToastManager from '../../components/ToastManager';
import {getChecklist} from "./onboard";

const webAuthConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  redirectUri:
    process.env.REACT_APP_AUTH0_REDIRECT_URI ||
    'http://localhost:3000/login.html',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  scope: 'openid email profile',
  responseType: 'id_token token code',
};
export const webAuth = new WebAuth(webAuthConfig);

export const userInfoByEmail = async ({ shouldRedirect = '', email = '' }) => {
  try {
    const userEmail = localStorage.getItem('email');
    let response = await Api.doCall(
      Const.USER_INFO_BY_EMAIL(email || userEmail, 0),
      'GET',
      {},
      false,
      false
    );

    if (get(response, 'status') === 200) {
      // localStorage.setItem('email', get(response, 'data.info.email'));
      //TODO fix the lambda
      getEmails({
        starred: false,
        type: 'email',
        studio: get(response, 'data.user.current_studio.id'),
        user: get(response, 'data.user.id'),
      });
      const objIDToken = {
        IDToken: get(response, 'data.info.email'),
      };
      store.dispatch(setIDToken(objIDToken));
      // set the auth user data in redux store
      store.dispatch({
        type: AUTH_USER,
        payload: response.data,
      });
      store.dispatch(setLanguage(get(response, 'data.user.lang')));
      // wait till user info is set in redux state
      store.dispatch(userInfo());
      if (shouldRedirect.length < 1) {
        history.push(URL.HOME());
      } else {
        history.push(shouldRedirect);
      }
      return response;
    } else if (get(response, 'status') === 401) {
      if (shouldRedirect.length < 1) {
        store.dispatch(authError('User does not exist!'));
        history.push(URL.LOGIN());
      } else {
        history.push(shouldRedirect);
      }
      return response;
    } else {
      store.dispatch(authError('Login failure'));
      history.push(URL.LOGIN());
      return { error: 'Login Failure' };
    }
  } catch (error) {
    history.push(URL.LOGIN());
    console.error({ error });
    store.dispatch(
      authError(get(error, 'response.data.message', 'Something went wrong.'))
    );
  }
};

export const loginUser =
  ({ email, password, shouldRedirect = true }) =>
  async (dispatch) => {
    dispatch({
      type: REQUEST_AUTH_USER,
    });
    webAuth.login(
      {
        username: email.toLowerCase(),
        password: password,
      },
      (err, res) => {
        if (err) return dispatch(authError(err.error_description));
      }
    );
  };

export const loginWithGoogle = (redirectUri = '', hint = '') => {
  const googleConfig = webAuthConfig;
  googleConfig.connection = 'google-oauth2';
  if (typeof redirectUri === 'string' && redirectUri !== '') {
    googleConfig.redirectUri = `${googleConfig.redirectUri}?${redirectUri}`;
  }
  if (typeof hint === 'string' && hint !== '') {
    googleConfig.login_hint = hint;
  }
  store.dispatch({
    type: REQUEST_AUTH_USER,
  });
  try {
    webAuth.authorize(googleConfig);
  } catch (err) {
    return store.dispatch(authError(err.message));
  }
};

export const loginWithApple = (redirectUri = '', hint = '') => {
    const appleConfig = webAuthConfig;
    appleConfig.connection = 'apple';
    if (typeof redirectUri === 'string' && redirectUri !== '') {
        appleConfig.redirectUri = `${appleConfig.redirectUri}?${redirectUri}`;
    }
    if (typeof hint === 'string' && hint !== '') {
        appleConfig.login_hint = hint;
    }
    store.dispatch({
        type: REQUEST_AUTH_USER,
    });
    try {
        webAuth.authorize(appleConfig);
    } catch (err) {
        return store.dispatch(authError(err.message));
    }
};
export const registerWithGoogle = (props, change) => {
  const googleConfig = webAuthConfig;
  if (props.redirectUri) {
    googleConfig.redirectUri = `${process.env.REACT_APP_AUTH0_REGISTER_REDIRECT_URI}?${props.redirectUri}`;
  } else {
    googleConfig.redirectUri = `${
      process.env.REACT_APP_AUTH0_REGISTER_REDIRECT_URI
    }?register=true&email=${btoa(props.email)}&token=${props.token}&phone=${
      props.phone
    }`;
  }
  googleConfig.connection = 'google-oauth2';
  if (change) {
    change('social', 'google-oauth2');
  }
  store.dispatch({
    type: REQUEST_AUTH_USER,
  });
  try {
    webAuth.authorize(googleConfig);
  } catch (err) {
    return store.dispatch(authError(err.message));
  }
};
export const registerWithApple = (props, change) => {
    const appleConfig = webAuthConfig;
    if (props.redirectUri) {
        appleConfig.redirectUri = `${process.env.REACT_APP_AUTH0_REGISTER_REDIRECT_URI}?${props.redirectUri}`;
    } else {
        appleConfig.redirectUri = `${
            process.env.REACT_APP_AUTH0_REGISTER_REDIRECT_URI
        }?register=true&email=${btoa(props.email)}&token=${props.token}&phone=${
            props.phone
        }`;
    }
    appleConfig.connection = 'apple';
    if (change) {
        change('social', 'apple');
    }
    store.dispatch({
        type: REQUEST_AUTH_USER,
    });
    try {
        webAuth.authorize(appleConfig);
    } catch (err) {
        return store.dispatch(authError(err.message));
    }
};
export const registerWithFacebook = (props, change) => {
  const facebookConfig = webAuthConfig;
  if (props.redirectUri) {
    facebookConfig.redirectUri = `${process.env.REACT_APP_AUTH0_REGISTER_REDIRECT_URI}?${props.redirectUri}`;
  } else {
    facebookConfig.redirectUri = `${
      process.env.REACT_APP_AUTH0_REGISTER_REDIRECT_URI
    }?register=true&email=${btoa(props.email)}&token=${props.token}&phone=${
      props.phone
    }`;
  }

  facebookConfig.connection = 'facebook';
  if (change) {
    change('social', 'facebook');
  }
  store.dispatch({
    type: REQUEST_AUTH_USER,
  });
  try {
    webAuth.authorize(facebookConfig);
  } catch (err) {
    return store.dispatch(authError(err.message));
  }
};

export const loginWithFacebook = (redirectUri = '') => {
  const facebookConfig = webAuthConfig;
  facebookConfig.connection = 'facebook';
  if (typeof redirectUri === 'string' && redirectUri !== '') {
    facebookConfig.redirectUri = `${facebookConfig.redirectUri}?${redirectUri}`;
  }
  store.dispatch({
    type: REQUEST_AUTH_USER,
  });
  try {
    webAuth.authorize(facebookConfig);
  } catch (err) {
    return store.dispatch(authError(err.message));
  }
};

export const createUser =
  ({
    name,
    email,
    phone,
    password,
    token,
    birthday,
    invitedUser,
    socialType,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REQUEST_AUTH_USER,
      });
      let postData = {
        appType: 'studio-app',
        name,
        email,
        phone,
        token,
        password,
        birthday,
        is_studio_admin: 1,
      };

      if (token) {
        postData = {
          appType: 'studio-app',
          name,
          email,
          phone,
          token,
          password,
          birthday,
          source: 'web',
          invitedUser,
          socialType,
        };
      }
      if (socialType) {
        let response = await Api.doCall(
          Const.CREATE_ACCOUNT(),
          'POST',
          postData,
          null,
          false
        );

        if (response.status === 200) {
          if (socialType === 'google') {
            loginWithGoogle('', email);
          }
          if (socialType === 'facebook') {
            loginWithFacebook('');
          }
          return true;
        } else {
          dispatch(authError('Signup failure'));
        }
      } else {
        webAuth.signup(
          {
            connection: 'Username-Password-Authentication',
            email: email,
            password: password,
            userMetadata: {
              postData: JSON.stringify(postData),
            },
          },
          (err) => {
            if (err) return store.dispatch(authError(err.message));
            webAuth.login(
              {
                username: email.toLowerCase(),
                password: password,
              },
              (err, res) => {
                if (err) return dispatch(authError(err.error_description));
              }
            );
            return true;
          }
        );
      }
    } catch (error) {
      dispatch(authError(error.message));
    }
  };

export const forgotPassword =
  ({ email_or_mobile }) =>
  async (dispatch) => {
    try {
      let response = await Api.doCall(
        Const.FORGOT_PASSWORD(),
        'POST',
        {
          email_or_mobile,
        },
        null,
        false
      );
      if (response.status === 200 && response.data) {
        return {
          status: true,
          message: get(response, 'data.message'),
        };
      } else {
        dispatch(authError('Send password link failure'));
        return {
          status: false,
          message: 'Send password link failure',
        };
      }
    } catch (error) {
      dispatch(authError(error.message));
      return {
        status: false,
        message: error.message,
      };
    }
  };

export const deleteUser =
  ({ email_or_mobile }) =>
  async (dispatch) => {
    try {
      let response = await Api.doCall(
        Const.DELETE_USER(),
        'POST',
        {
          email_or_mobile,
        },
        null,
        false
      );
      if (response.status === 200 && response.data) {
        return {
          status: true,
          message: get(response, 'data.message'),
        };
      } else {
        dispatch(authError('User with given email does not exist'));
        return {
          status: false,
          message: 'User with given email does not exist',
        };
      }
    } catch (error) {
      dispatch(authError(error.message));
      return {
        status: false,
        message: error.message,
      };
    }
  };

export const resetPassword =
  ({ email_or_mobile, reset_token, password }) =>
  async (dispatch) => {
    try {
      let response = await Api.doCall(
        Const.RESET_PASSWORD(),
        'POST',
        {
          email_or_mobile,
          reset_token,
          password,
        },
        null,
        false
      );
      if (response.status === 200 && response.data) {
        history.push(
          QueryString.stringifyUrl({
            url: URL.LOGIN(),
            query: { resetPassword: 'success' },
          })
        );
      } else {
        dispatch(authError('Reset password failure'));
      }
    } catch (error) {
      dispatch(authError(error.message));
    }
  };

export const getUserCourses = () => (dispatch) => {
  dispatch({
    type: REQUEST_USER_INFO_COURSES,
  });
  Api.doCall(Const.USER_INFO_COURSES(), 'GET')
    .then((res) => {
      if (res.status === 200 && res.data) {
        dispatch({ type: USER_INFO_COURSES, payload: res.data });
      }
    })
    .catch((error) => dispatch(authError(error.toString())));
};

// gets the user info
export const userInfo = () => async (dispatch) => {
  try {
    // to invoke it twice, we need to check if the user is already loaded

    dispatch({
      type: REQUEST_AUTH_USER,
    });
    let response = await Api.doCall(Const.USER_INFO(), 'GET');
    if (response.data.messages) {
      const messages = response.data.messages.map((m) => ({
        message: m._source,
        m_id: m._id,
        dismissable:
          m._source.needs_confirmation &&
          m._source.needs_confirmation.indexOf(response.data.id) > -1,
      }));
      dispatch({ type: SET_MESSAGES, payload: { messages } });
      let notifications = messages.filter((n) => n.dismissable);
      if (notifications.length > 0)
        dispatch({
          type: SET_HEADER_NOTIFICATIONS,
          payload: { notifications },
        });

      delete response.data.messages;
    }
    dispatch({ type: USER_INFO_SUCCESS, payload: response.data });
    dispatch(getUserCourses());
    return response;
  } catch (error) {
    dispatch(authError(error.toString()));
  }
};

export const setLanguage = (lang) => async (dispatch) => {
  dispatch({ type: SET_LANGUAGE, payload: lang });
};
export const registerUser =
  ({ name, email, password, c_password }) =>
  async (dispatch) => {
    try {
      let response = await Api.doCall(Const.REGISTER_USER(), 'POST', {
        name,
        email,
        password,
        c_password,
      });
      if (response.status === 200 && response.data.user) {
        dispatch({ type: AUTH_USER, payload: get(response, 'data') });
        history.push(URL.HOME());
      }
    } catch (error) {
      dispatch(authError(error.toString()));
    }
  };
export const updateLoginUser =
  ({ postData, relogin = false }) =>
  async (dispatch) => {
    try {
      dispatch({ type: START_AUTH_LOADER });

      let rs = await Api.doCall(
        Const.UPDATE_LOGIN_USER(),
        METHODS.put,
        postData
      );
      if (rs.status === 200) {
        const userEmail = localStorage.getItem('email');
        const email = get(rs, 'data.data.email');
        let response = await Api.doCall(
          Const.USER_INFO_BY_EMAIL(email || userEmail, 1),
          'GET',
          {},
          false,
          false
        );

        if (get(response, 'status') === 200) {
          // localStorage.setItem('email', get(response, 'data.info.email'));
          //TODO fix the lambda
          getEmails({
            starred: false,
            type: 'email',
            studio: get(response, 'data.user.current_studio.id'),
            user: get(response, 'data.user.id'),
          });
          const objIDToken = {
            IDToken: get(response, 'data.info.email'),
          };
          dispatch(setIDToken(objIDToken));
          // set the auth user data in redux store
          dispatch({
            type: AUTH_USER,
            payload: response.data,
          });
          dispatch(setLanguage(get(response, 'data.user.lang')));
          // wait till user info is set in redux state
          await dispatch(userInfo());
          // wait till checklist information is collected to avoid redirecting to the studio setup page
          await dispatch(getChecklist());

        }

        if (relogin) {
          history.push(URL.ADMINS());
          ToastManager.show({
            message: 'Logged in As, Super Admin',
            autoDismiss: 2,
            level: 'success',
          });
        } else {
          history.push(URL.HOME());
          ToastManager.show({
            message: `Logged in As, ${get(rs, 'data.data.name')}`,
            autoDismiss: 2,
            level: 'success',
          });
        }
        dispatch({ type: STOP_AUTH_LOADER });
      }
    } catch (error) {
      dispatch(authError(error.toString()));
    }
  };

export const updateProfile =
  ({
    avatar,
    name,
    email,
    first_name,
    last_name,
    language,
    password,
    phone,
    location,
  }) =>
  async (dispatch) => {
    try {
      let response = await Api.doCall(Const.UPDATE_USER(), 'POST', {
        avatar,
        name,
        email,
        password,
        first_name,
        last_name,
        language: get(language, 'value'),
        phone,
        location,
      });
      if (response.status === 200) {
        dispatch({ type: USER_UPDATE_SUCCESS, payload: response.data });
        dispatch(setLanguage(get(language, 'value')));
        dispatch(userInfo());
        return {
          status: true,
          message: 'Updated',
        };
      }
      dispatch(authError('Something went wrong...'));
    } catch (error) {
      dispatch(authError(error.toString()));
    }
  };
export const updateSettings =
  ({ notify, language, email, first_name, last_name, location }) =>
  async (dispatch) => {
    try {
      let response = await Api.doCall(Const.UPDATE_USER(), 'POST', {
        notify,
        language,
        email,
        first_name,
        last_name,
        location,
      });
      if (response.status === 200) {
        dispatch({ type: USER_UPDATE_SUCCESS, payload: response.data });
        dispatch(setLanguage(language));
        dispatch(userInfo());
        return {
          status: true,
          message: 'Updated',
        };
      }
      return {
        status: true,
        message: 'Updated',
      };
    } catch (error) {
      dispatch(authError(error.toString()));
    }
  };

export function setIsNotification(status) {
  return {
    type: SET_NOTIFICATION,
    payload: status,
  };
}
export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}
export function setUser(user) {
  return { type: TOKEN_USER, payload: user };
}
export function setIDToken(user) {
  return { type: ID_TOKEN_USER, payload: user };
}

export function logoutUser() {
  return { type: LOGOUT_USER };
}

export const sendAppDownloadSms = (phone) => async (dispatch) => {
  try {
    let rs = await Api.doCall(
      Const.SEND_APP_DOWNLOAD_SMS(),
      'POST',
      {
        phone,
      },
      null,
      false
    );

    if (rs.status === 200) {
      return true;
    }
    return false;
  } catch (err) {
    dispatch(authError(err.message));
    return undefined;
  }
};

export const setSelectedUser = (id, info) => async (dispatch) => {
  await dispatch({ type: SET_SELECTED_USER, payload: id });
  if (window.location.pathname.includes('UserProfile')) {
    const currentId = id === null ? get(info, 'id', '') || '' : id;
    history.push(URL.PROFILE({ id: currentId }));
  } else {
    window.location.reload();
  }
};
