import View from "./view";
import { get } from "lodash";
import {EditorState, RichUtils, convertFromRaw, convertFromHTML, ContentState} from 'draft-js';
import { compose, withState, withHandlers, withPropsOnChange, lifecycle } from "recompose";
import createStyles from 'draft-js-custom-styles';
import { DEFAULT_FONT_SIZE, DEFAULT_STEP_FONT_SIZE, styleMap } from "./config";
export const { styles, customStyleFn } = createStyles(['font-size', 'color', 'text-transform'], 'CUSTOM_', styleMap);
export default compose(
  withState('editorState', 'setEditorState', EditorState.createEmpty()),
  withState('fontSize', 'setFontSize', DEFAULT_FONT_SIZE),
  withHandlers({
    toggleFontSize: ({ fontSize, setEditorState, editorState, setFontSize }) => (inlineStyle) => {
      let newFontSize = fontSize;
      if(inlineStyle === 'INCREMENT_FONTSIZE'){
        newFontSize = fontSize + DEFAULT_STEP_FONT_SIZE;
        setFontSize(newFontSize);
        
      }else{
        if(fontSize > DEFAULT_STEP_FONT_SIZE){
          newFontSize = fontSize - DEFAULT_STEP_FONT_SIZE;
          setFontSize(newFontSize);
        }
      }
      const newEditorState = styles.fontSize.add(editorState, `${newFontSize}px`);
      setEditorState(newEditorState);
    }
  }),
  withHandlers({
    handleKeyCommand: ({editorState, setEditorState}) => async(command) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return true;
      }
      return false;
    },
    toggleBlockType: ({setEditorState, editorState}) => (blockType) => {
      setEditorState(
        RichUtils.toggleBlockType(
          editorState,
          blockType
        )
      );
    },
    toggleInlineStyle: ({setEditorState, editorState, toggleFontSize}) => (inlineStyle) => {
      if(inlineStyle === 'INCREMENT_FONTSIZE' || inlineStyle === 'DECREMENT_FONTSIZE'){
        toggleFontSize(inlineStyle);
      }else{
        setEditorState(
          RichUtils.toggleInlineStyle(
            editorState,
            inlineStyle
          )
        );
      }
    },
    onTab: ({setEditorState, editorState}) => (e)=> {
      const maxDepth = 4;
      setEditorState(RichUtils.onTab(e, editorState, maxDepth));
    }
  }),
  withPropsOnChange(['editorState'], ({ editorState, onChangeEditor, text }) => {
    if(editorState.getCurrentContent().hasText() || editorState.getCurrentContent().getPlainText() === ''){
      onChangeEditor(editorState.getCurrentContent());
    }
  }),
  lifecycle({
    componentDidMount(){
      const { text } = this.props;
      if(text){
        let initEditorState;
        try {
          initEditorState = convertFromRaw(JSON.parse(text));
          return (typeof json === 'object');
        } catch (e) {
          const blocksFromHTML = convertFromHTML(text.replace(/\"/g,""));
          let editorState;
          if(blocksFromHTML.contentBlocks){
            initEditorState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
          );
          editorState = EditorState.createWithContent(initEditorState);
          }else{
            editorState = EditorState.createEmpty()
          }
          this.props.setEditorState(editorState);
        }
        // const initEditorState = convertFromRaw(JSON.parse(text));
      }
    },
    componentWillReceiveProps(nextProps, nextState){
      if(get(this.props, "text") !== get(nextProps, "text") && !get(nextProps, "text")){
        this.props.setEditorState(EditorState.createEmpty());
      }
    }
  }),
)(View);