import { getApiUrl } from '../components/Api';
import Pusher from 'pusher-js';

export const API_URL = () => getApiUrl();

export const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

export const languageList = [
  {
    value: 'en-US',
    label: 'English',
  },
  {
    value: 'es-ES',
    label: 'Spanish',
  },
];
export const PhoneNumberFormat = '+1 (###) #### ###';
export const RotationFormatSuffix = '%';
export const DateFormat = 'MM/DD/YYYY';
export const DateTimeFormat = 'MM/DD/YYYY - hh:mm A';
export const pageSize = 25;
export const pageSizeOptions = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

export const adminTabs = {
  TAB_BROADCUSTING_GROUP: 'broadcusting_group',
  TAB_STUDIOS: 'studios',
  TAB_USERS: 'users',
  TAB_CITIES: 'cities',
  TAB_GROUPS: 'groups',
};

export const categoryList = [
  { value: 'tickets', label: 'Tickets' },
  { value: 'cash', label: 'Cash' },
];
export const fulfillmentList = [
  { value: 'pickup_studio', label: 'Pick up at Studio' },
  { value: 'pickup_client', label: 'Pick up at Client' },
];

export const categoryValues = { tickets: 'Tickets', cash: 'Cash' };
export const fulfillmentValues = {
  pickup_studio: 'Pick up at Studio',
  pickup_client: 'Pick up at Client',
};

export const daysInWeek = [
  {
    id: 1,
    label: 'S',
    value: 'Sunday',
    shortValue: 'Sun',
  },
  {
    id: 2,
    label: 'M',
    value: 'Monday',
    shortValue: 'Mon',
  },
  {
    id: 3,
    label: 'T',
    value: 'Tuesday',
    shortValue: 'Tue',
  },
  {
    id: 4,
    label: 'W',
    value: 'Wednesday',
    shortValue: 'Wed',
  },
  {
    id: 5,
    label: 'T',
    value: 'Thursday',
    shortValue: 'Thu',
  },
  {
    id: 6,
    label: 'F',
    value: 'Friday',
    shortValue: 'Fri',
  },
  {
    id: 7,
    label: 'S',
    value: 'Saturday',
    shortValue: 'Sat',
  },
];

export const MobileAppInfo = {
  iOSAppId: 'id1538013854',
  androidPackageName: 'com.suitepeach.app',
};

 
export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: 'mt1',
  userAuthentication: {
    endpoint: '/api/pusher/user-auth',
    transport: "ajax",
    params: {},
    headers: {
      Accept: 'application/json',
    },
    paramsProvider: null,
    headersProvider: null,
    customHandler: null,
  },
});
