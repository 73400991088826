import React from 'react';
import {
  Box,
  IconButton,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import classes from './PaymentHistory.module.scss';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import { Alert } from '@mui/lab';
import CloseModalIcon from '../../../../components/SvgIcons/CloseModalIcon';
import FailedIcon from '../../../../components/SvgIcons/FailedIcon';
import WaitingIcon from '../../../../components/SvgIcons/WaitingIcon';
import SuccessGreenIcon from '../../../../components/SvgIcons/SuccessGreenIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { downloadCSV } from '../../../Feed/util';
import NumberFormat from 'react-number-format';
import ToastManager from '../../../../components/ToastManager';
export const TAB_SCHEDULED = 'scheduled';
export const TAB_PAST = 'past';

export const StautsType = {
  0: {
    name: 'Success',
    header: 'Success',
    status: 'SUCCESS',
    value: 0,
    icon: <SuccessGreenIcon />,
  },
  1: {
    name: 'Pending',
    header: 'Pending',
    status: 'PENDING',
    value: 1,
    icon: <WaitingIcon />,
  },
  2: {
    name: 'Processing',
    header: 'Processing',
    status: 'PROCESSING',
    value: 2,
    icon: <WaitingIcon />,
  },
  3: {
    name: 'Declined',
    header: 'Declined',
    status: 'DECLINED',
    value: 3,
    icon: <FailedIcon />,
  },
  4: {
    name: 'On Hold',
    header: 'On Hold',
    status: 'ONHOLD',
    value: 4,
    icon: <WaitingIcon />,
  },
  5: {
    name: 'Sent',
    header: 'Sent',
    status: 'SENT',
    value: 5,
    icon: <SuccessGreenIcon />,
  },
  6: {
    name: 'Pending Approval',
    header: 'Pending Approval',
    status: 'PENDING_APPROVAL',
    value: 6,
    icon: <WaitingIcon />,
  },
  7: {
    name: 'Scheduled',
    header: 'Scheduled',
    status: 'SCHEDULED',
    value: 7,
    icon: <AccessTimeIcon color={'secondary'} />,
  },
  8: {
    name: 'On Hold Funds',
    header: 'On Hold Funds',
    status: 'ONHOLD_FUNDS',
    value: 8,
    icon: <WaitingIcon />,
  },
  98: {
    name: 'Cancelled',
    header: 'Cancelled',
    status: 'CANCELLED',
    value: 98,
    icon: <FailedIcon />,
  },
  99: {
    name: 'Failed',
    header: 'Failed',
    status: 'FAILED',
    value: 99,
    icon: <FailedIcon />,
  },
  10: {
    name: 'Past Due',
    header: 'Past Due',
    status: 'PAST_DUE',
    value: 10,
    icon: <WaitingIcon />,
  },
  11: {
    name: 'Partially Paid',
    header: 'Partially Paid',
    status: 'PARTIALLY_PAID',
    value: 11,
    icon: <WaitingIcon />,
  },
  12: {
    name: (
      <div>
        Success{' '}
        <Tooltip title="Pending Settlement">
          <em style={{ color: 'red' }}>*</em>
        </Tooltip>
      </div>
    ),
    header: 'Collection Pending',
    status: 'COLLECTION_PENDING',
    value: 12,
    icon: <SuccessGreenIcon />,
  },
  14: {
    name: (
      <div>
        Success{' '}
        <Tooltip title="Pending Settlement">
          <em style={{ color: 'red' }}>*</em>
        </Tooltip>
      </div>
    ),
    header: 'Pending Settlement',

    status: 'PENDING_SETTLEMENT',
    value: 14,
    icon: <SuccessGreenIcon />,
  },
};

export const PaymentTypes = {
  'Credit/Debit Card': {
    key: 1,
    value: 'Credit/Debit Card',
    instrumentType: 2,
  },
  ACH: {
    key: 2,
    value: 'ACH',
    instrumentType: 1,
  },
  Cash: {
    key: 3,
    value: 'Cash',
    instrumentType: 3,
  },
  Other: {
    key: 4,
    value: 'Other',
  },
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <th
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              headCell.sortable && orderBy === headCell.id ? order : false
            }
          >
            {headCell.sortable ? (
              <div>
                <TableSortLabel
                  active={
                    headCell.sortable && orderBy === headCell.id ? true : null
                  }
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={
                    headCell.sortable ? createSortHandler(headCell.id) : null
                  }
                  className={classes.sortableHeader}
                >
                  {headCell.label}
                  {headCell.sortable && orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </div>
            ) : (
              <div>
                <span>{headCell.label}</span>
              </div>
            )}
          </th>
        ))}
      </TableRow>
    </TableHead>
  );
};
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const MenuHeader = ({
  viewHandle,
  payHandle,
  transaction,
  openEditHandle,
  openRetryHandle,
  openCancelHandle,
  activeTab,
  openRefundHandle,
  ViewTitle = 'View',
  EditTitle = 'Edit',
  PayTitle = 'Pay',
  CancelTitle = 'Cancel',
  isPaymentHistory = true,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title="Payment settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& li': {
              '&:hover': {
                color: '#EA9277',
              },
            },
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {[
          0,
          StautsType['3'].value,
          StautsType['14'].value,
          StautsType['12'].value,
        ].includes(transaction.status) && (
          <MenuItem onClick={() => viewHandle(transaction)}>
            {ViewTitle}
          </MenuItem>
        )}
        {StautsType['7'].value == transaction.status && (
          <MenuItem onClick={() => openEditHandle(transaction)}>
            {EditTitle}
          </MenuItem>
        )}
        {transaction.status === StautsType['99'].value &&
          !transaction.reProcess && (
            <MenuItem onClick={() => payHandle(transaction)}>
              {PayTitle}
            </MenuItem>
          )}
        {transaction.instrumentType === PaymentTypes.Cash.instrumentType &&
          [
            StautsType['6'].value,
            StautsType['11'].value,
            StautsType['7'].value,
          ].includes(transaction.status) && (
            <MenuItem onClick={() => openEditHandle(transaction, true)}>
              {PayTitle}
            </MenuItem>
          )}
        {[
          PaymentTypes['Credit/Debit Card'].instrumentType,
          PaymentTypes['ACH'].instrumentType,
        ].includes(transaction.instrumentType) &&
          [StautsType['7'].value].includes(transaction.status) && (
            <MenuItem onClick={() => openEditHandle(transaction, true)}>
              {PayTitle}
            </MenuItem>
          )}
        {activeTab === TAB_PAST &&
          transaction.instrumentType !== PaymentTypes.Cash.instrumentType &&
          [0].includes(transaction.status) &&
          !transaction.isRefunded &&
          !transaction.isRefundPayment && (
            <MenuItem
              onClick={() => {
                const refundPayment =
                  window.localStorage.getItem('refundPayment');
                if (
                  refundPayment &&
                  transaction.reference &&
                  JSON.parse(refundPayment)[transaction.reference]
                ) {
                  ToastManager.show({
                    message: 'Payment Already Processed!',
                    autoDismiss: 2,
                    level: 'info',
                  });
                  return;
                }

                openRefundHandle(transaction);
              }}
            >
              Refund
            </MenuItem>
          )}

        {isPaymentHistory && activeTab === TAB_SCHEDULED && (
          <MenuItem
            onClick={() => {
              openEditHandle({ ...transaction, cancelRefund: true });
            }}
          >
            Cancel and Refund Group
          </MenuItem>
        )}
        {activeTab === TAB_PAST &&
          [StautsType['99'].value].includes(transaction.status) && (
            <MenuItem
              onClick={() => {
                openRetryHandle({ ...transaction });
              }}
            >
              Retry Payment
            </MenuItem>
          )}
        {[StautsType['7'].value].includes(transaction.status) && (
            <MenuItem
              onClick={() => {
                openCancelHandle({ ...transaction });
              }}
            >
              Cancel Payment
            </MenuItem>
          )}
      </Menu>
    </>
  );
};

export const HeaderNode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f8e6df;
  height: 76px;
  .seacondary-close {
    padding: 1rem;
    svg {
      font-size: 10px;
    }
  }
  .title {
    font-family: 'Poppins';
    padding: 0rem 1.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #22294e;
  }
`;

export const WarningIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.256 13.1112C16.7829 14 16.1215 15.1109 15.0692 15.1109H1.37164C0.317326 15.1109 -0.340944 13.9983 0.184884 13.1112L7.03377 1.55492C7.56089 0.665783 8.88111 0.667394 9.40728 1.55492L16.256 13.1112ZM8.22053 10.722C7.49538 10.722 6.90753 11.2941 6.90753 11.9998C6.90753 12.7055 7.49538 13.2776 8.22053 13.2776C8.94567 13.2776 9.53353 12.7055 9.53353 11.9998C9.53353 11.2941 8.94567 10.722 8.22053 10.722ZM6.97395 6.12906L7.18568 9.90683C7.19559 10.0836 7.34578 10.222 7.52769 10.222H8.91336C9.09527 10.222 9.24547 10.0836 9.25537 9.90683L9.4671 6.12906C9.47781 5.93811 9.32159 5.77756 9.1251 5.77756H7.31593C7.11943 5.77756 6.96324 5.93811 6.97395 6.12906Z"
      fill="#EA734F"
    />
  </svg>
);
export const AlertWarning = ({ message }) => (
  <Alert icon={<WarningIcon />} className={'alert'} severity="warning">
    {message}
  </Alert>
);

export const API_PAGE_OFFSET = 10;
export const VISIBLE_PAGES_COUNT = 10;
export const PER_PAGE = 12;

export const headerNode = (closeCancel, title) => (
  <HeaderNode>
    <Box className="title">{title}</Box>
    <Box className="seacondary-close">
      <Tooltip title={'Close'}>
        <IconButton onClick={closeCancel}>
          <CloseModalIcon />
        </IconButton>
      </Tooltip>
    </Box>
  </HeaderNode>
);
export const downloadHandle = ({
  data,
  activeTab,
  columns = [
    'Class Name',
    'Description',
    'Bank Name',
    'Email',
    'Amount',
    'Payment Date',
    'status',
  ],
}) => {
  downloadCSV({
    filename: `${activeTab}.csv`,
    data,
    columns,
  });
};

export const isDataChanged = ({ currentData, previousData }) => {
  return currentData !== previousData;
};

export const scduleStatus = {
  Scheduled: {
    label: 'Scheduled',
    value: 'scheduled',
  },
};

export const errorSvg = (requestFrom) => {
  return (
    <svg
      className="error-image"
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7974 13.7504C18.3742 14.7502 17.6502 16 16.4981 16H1.5017C0.347415 16 -0.373272 14.7483 0.202415 13.7504L7.70073 0.749531C8.27782 -0.25075 9.72322 -0.248937 10.2993 0.749531L17.7974 13.7504ZM9.00001 11.0625C8.2061 11.0625 7.56251 11.7061 7.56251 12.5C7.56251 13.2939 8.2061 13.9375 9.00001 13.9375C9.79391 13.9375 10.4375 13.2939 10.4375 12.5C10.4375 11.7061 9.79391 11.0625 9.00001 11.0625ZM7.63523 5.89544L7.86704 10.1454C7.87788 10.3443 8.04232 10.5 8.24147 10.5H9.75854C9.95769 10.5 10.1221 10.3443 10.133 10.1454L10.3648 5.89544C10.3765 5.68063 10.2055 5.5 9.99035 5.5H8.00963C7.79451 5.5 7.62351 5.68063 7.63523 5.89544Z"
        fill="#F55B5B"
      />
    </svg>
  );
};
export const isValidationError = (rest, index, action) => {
  if (
    rest.touched.users !== undefined &&
    rest.touched.users[index] !== undefined &&
    rest.touched.users[index][action] !== undefined &&
    rest.errors.users !== undefined &&
    rest.errors.users[index] !== undefined &&
    rest.errors.users[index][action]
  ) {
    console.log(rest.errors.users[index][action]);
    return (
      <span>
        {errorSvg('array')}
        {rest.errors.users[index][action]}
      </span>
    );
  } else {
    return false;
  }
};

export const REVIEW_CHANGES = 'Review Changes';
export const SAVE_CHANGES = 'Save Changes';
export const initialReviewState = {
  state: false,
  title: REVIEW_CHANGES,
};

export const allStatus = {
  paid: {
    label: 'Paid',
    value: 'paid',
    status: 0,
  },
  partiallypaid: {
    label: 'Partially Paid',
    value: 'partiallypaid',
    status: 11,
  },
  pastDue: {
    label: 'Past Due',
    value: 'pastDue',
    status: 10,
  },
};

export const allStatusRefund = {
  full_amount: {
    label: 'Full Amount',
    value: 'full_amount',
    status: 0,
  },
  partially_amount: {
    label: 'Partial Amount',
    value: 'partially_amount',
    status: 11,
  },
};

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const isValidPayment = ({
  selectedPaymentInstance,
  cardDetails,
  bankDetails,
}) => {
  if (
    selectedPaymentInstance === PaymentTypes['Credit/Debit Card'].value &&
    !cardDetails
  ) {
    return { value: true, message: 'Please update your card details' };
  } else if (
    selectedPaymentInstance === PaymentTypes['ACH'].value &&
    !bankDetails
  ) {
    return { value: true, message: 'Please update bank card details' };
  } else if (selectedPaymentInstance === PaymentTypes.Cash.value) {
    return { value: false, message: '' };
  } else {
    return { value: false, message: '' };
  }
};
