import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Input, Label } from 'reactstrap';
import bn from 'utils/bemnames';
import errorIcon from 'assets/img/icons/error.svg';
const bem = bn.create('textinput');

class TextInput extends React.Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    error: PropTypes.any,
    name: PropTypes.string,
    leftComponent: PropTypes.node,
    rightComponent: PropTypes.node,
    labelProps: PropTypes.object,
    outline: PropTypes.bool,
    ableToAddNew: PropTypes.bool, // whether to give functionality to add new textfield
    onAddNew: PropTypes.func,
    enableErrorMessage: PropTypes.bool,
    required: PropTypes.bool,
    showLabelInvaid: PropTypes.bool,
    fields: PropTypes.shape({
      error: PropTypes.any,
      name: PropTypes.string,
      // value: PropTypes.string,
      type: PropTypes.string,
    }), // this is useful in case of we want to support multiple textbox , see `ableToAddNew` prop
  };

  static defaultProps = {
    type: 'text',
    labelProps: {},
    leftComponent: null,
    rightComponent: null,
    ableToAddNew: false,
    onAddNew: () => {},
    outline: false,
    enableErrorMessage: true,
    required: false,
    innerRef: null,
    showLabelInvaid: false,
  };

  state = {
    isFocused: false,
  };

  renderRightComponent = () => {
    const { rightComponent } = this.props;
    if (!rightComponent) {
      return null;
    }
    return <div className={bem.e('right-component')}>{rightComponent}</div>;
  };

  renderLeftComponent = () => {
    const { leftComponent } = this.props;
    if (!leftComponent) {
      return null;
    }
    return <div className={bem.e('left-component')}>{leftComponent}</div>;
  };

  onFocus = () => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) {
      this.props.onFocus();
    }
  };

  onBlur = () => {
    this.setState({ isFocused: false });
    // if (this.props.onBlur) {
    //   this.props.onBlur();
    // }
  };

  render() {
    const {
      label,
      labelProps,
      leftComponent,
      rightComponent,
      ableToAddNew,
      name,
      error,
      value,
      type,
      fields,
      onAddNew,
      onChange,
      outline,
      placeholder,
      enableErrorMessage,
      required,
      touched,
      innerRef,
      showLabelInvaid,
      ...rest
    } = this.props;
    let finalFields = fields || [{ name, error, value, type }];

    return (
      <div className={bem.b()} {...rest}>
        {!!label && (
          <Label
            for={name}
            className={cx(bem.e('label-wrapper'), {
              invalid: showLabelInvaid && Boolean(error),
              'is-focus': this.state.isFocused,
            })}
            {...labelProps}
          >
            {label} {required && <span className={bem.e('required')}>*</span>}
          </Label>
        )}
        {finalFields.map((field, index) => (
          <div style={{ marginBottom: 5 }} key={'txt_' + index}>
            <div
              className={cx(bem.e('input-wrapper'), {
                // invalid: Boolean(error),
                textarea: field.type === 'textarea',
              })}
            >
              {this.renderLeftComponent()}
              <div
                className={cx(bem.e('input-field'), {
                  textarea: field.type === 'textarea',
                  outline: outline === true,
                })}
              >
                <Input
                  innerRef={innerRef}
                  type={field.type}
                  name={field.name}
                  // invalid={Boolean(field.error)}
                  value={field.value}
                  onChange={(e) => {
                    onChange(e, index);
                  }}
                  {...rest}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  placeholder={placeholder}
                />
              </div>
              {this.renderRightComponent()}
            </div>
            {!!field.error && enableErrorMessage && (
              <span className={cx(bem.e('text-danger'), 'text-danger')}>
                <img src={errorIcon} alt="error" />
                {field.error}
              </span>
            )}
          </div>
        ))}
        {ableToAddNew && (
          <div className={bem.e('add-new-link')} onClick={onAddNew}>
            + Add New
          </div>
        )}
      </div>
    );
  }
}

export default TextInput;
