import fp from 'lodash/fp';
export const pad = (num) => {
  const time = parseInt(num);
  if(time < 10){
    return ("0"+ parseInt(num));
  }
  return parseInt(num);
}
export const hhmmss = (secs) => {
  const hours = Math.floor(secs / 3600);
  secs %= 3600;
  const minutes = Math.floor(secs / 60);
  const seconds = secs % 60;
  let timer = '';
  if(hours > 0){
    timer += `${pad(hours)}:`;
  }
  if(minutes > 0){
    timer += `${parseInt(minutes)}:`;
  }
  if(secs > 10){
    timer += `${pad(seconds)} sec.`;
  }else{
    timer += `${parseInt(seconds)} sec.`;

  }
  return timer;
}
/*
When entering multiple spaces or breaks (Enter key), the array will come
with empty elements. This function removes those elements. 
i.e. ["a dog", "", "a cat"]
*/
const specialWords = ["a", "the", ""];

export const removeSpecialElements = arr => {
  let pos = -1;
  arr.forEach((element, index) => {
    if(specialWords.includes(element)){
      pos = index;
    }
  });

  if (pos === -1) 
    return arr;

  arr.splice(pos, 1);

  return removeSpecialElements(arr)
};

export const counter = (value) => {
  const trimmedValue = value.trim();
  const words = fp.compose(removeSpecialElements)(trimmedValue.split(' '));
  const dots = ( trimmedValue.match( RegExp('\\.','g') ) || [] ).length;
  const breaks = ( trimmedValue.match(/[^\n]*\n[^\n]*/gi) || [] ).length;
  return parseInt(words.length) + parseInt(dots) + parseInt(breaks);
}