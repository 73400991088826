import React from 'react';
import classes from './ClassSchedule.module.scss';
import DatePicker from 'components/DatePicker';
import ToggleSwitch from 'components/ToggleSwitch';
import { Container } from 'reactstrap';
import cs from 'classnames';
import TimePicker from 'components/TimePicker';
import moment from 'moment';
import get from 'lodash/get';
import Alert from 'components/Alert';
import calendarIcon from 'assets/img/icons/calendar.svg';
import { weekDays } from '../../../../../pages/StudioAvailable/util';
import { daysInWeek } from '../../../../../utils/config';
import PrimaryButton, { PrimaryButtonLink } from '../../../../StyledUI/Buttons';
import { Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import CustomRemoveIcon from '../../../../SvgIcons/CustomRemoveIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IconButton from '@mui/material/IconButton';

const convertFormatDate = 'YYYY-MM-DD HH:mm:ss';

const DateTimeNoRecurring = (props) => {
  const { values, setFieldValue, validateDate, validateTime } = props;
  const { step, process, template, user, valueChanged } = props;
  const fields = get(step, 'fields', []) || [];
  let start_date = null;
  let end_date = null;
  let recurring_event = null;

  if (step) {
    start_date = fields.find((f) => f.field === 'start_date');
    end_date = fields.find((f) => f.field === 'end_date');
    recurring_event = fields.find((f) => f.field === 'recurring_event');
  }

  return (
    <Container className={classes.root}>
      {Object.values(values.recurring_repeat_on).map((day, index) => {
        const hasError =
          moment(day.to).format('X') - moment(day.from).format('X') > 0
            ? false
            : true;
        return (
          <div
            key={day}
            className={classes.dateTime}
            style={{ gap: '0.625rem' }}
          >
              <div
                  style={{
                      display: 'flex',
                      gap: '0.625rem',
                      alignItems: 'center',
                  }}
              ><img
                  src={calendarIcon}
                  alt="Calendar"
                  className={cs('mr-2 mt-2')}
              />
              </div>
                  <div className={classes.dateTime} style={{gap: '0.625rem'}}>
                      <div className="mb-2 d-flex" style={{gap: '2.5rem'}}>
                          <DatePicker
                              className="mr-2"
                  hasCalendarIcon={false}
                  value={moment(day.from, convertFormatDate).toDate()}
                  changeHandler={(data) => {
                    const hh = moment(day).format('HH');
                    const mm = moment(day).format('mm');
                    const ss = moment(day).format('ss');
                    const fromhh = moment(day.from).format('HH');
                    const frommm = moment(day.from).format('mm');
                    const tohh = moment(day.to).format('HH');
                    const tomm = moment(day.to).format('mm');
                    const date = moment(data)
                      .hour(hh)
                      .minute(mm)
                      .second(ss)
                      .format('YYYY-MM-DD HH:mm:ss');
                    const newDay = daysInWeek[moment(data).day()];
                    let updatedRecurringRepeatOn = Object.values(
                      values.recurring_repeat_on
                    );
                    if (index >= 0 && index < updatedRecurringRepeatOn.length) {
                      updatedRecurringRepeatOn[index] = {
                        id: index,
                        label: newDay.label,
                        value: newDay.value,
                        shortValue: newDay.shortValue,
                        from: moment(data)
                          .add(fromhh, 'hour')
                          .add(frommm, 'minute')
                          .format('YYYY-MM-DD HH:mm:00'),
                        to: moment(data)
                          .add(tohh, 'hour')
                          .add(tomm, 'minute')
                          .format('YYYY-MM-DD HH:mm:00'),
                      };

                      setFieldValue(
                        'recurring_repeat_on',
                        updatedRecurringRepeatOn
                      );
                    }

                    if (step)
                      valueChanged(start_date, process, template, user, {
                        value: date,
                      });
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    gap: '0.625rem',
                    alignItems: 'center',
                  }}
                >
                  <IconButton color="primary" aria-label="add an alarm">
                    <AccessTimeIcon />
                  </IconButton>
                  <TimePicker
                    name="start_time"
                    id="start_time"
                    className={cs(classes.input)}
                    selected={moment(day.from, convertFormatDate).toDate()}
                    onChange={(data) => {
                      const date = moment(data).format('YYYY-MM-DD HH:mm:ss');
                      setFieldValue('start_date', date);
                      let updatedRecurringRepeatOn = Object.values(
                        values.recurring_repeat_on
                      );
                      if (
                        index >= 0 &&
                        index < updatedRecurringRepeatOn.length
                      ) {
                        updatedRecurringRepeatOn[index] = {
                          id: index,
                          label: day.label,
                          value: day.value,
                          shortValue: day.shortValue,
                          from: moment(data).format('YYYY-MM-DD HH:mm:00'),
                          to: day.to,
                        };

                        setFieldValue(
                          'recurring_repeat_on',
                          updatedRecurringRepeatOn
                        );
                      }
                      if (step)
                        valueChanged(start_date, process, template, user, {
                          value: date,
                        });
                    }}
                  />
                  <span
                    className="ml-2 mr-2"
                    style={{
                      fontFamily: 'Mulish',
                      fontSize: '0.875rem',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '1.25rem',
                    }}
                  >
                    to
                  </span>
                  <div className="">
                    <div className={classes.endDateTime}>
                      <TimePicker
                        name="end_time"
                        id="end_time"
                        className={cs(classes.input, 'mr-2', {
                          [classes.error]: hasError === true,
                        })}
                        selected={moment(day.to, convertFormatDate).toDate()}
                        onChange={(data) => {
                          const date = moment(data).format(
                            'YYYY-MM-DD HH:mm:ss'
                          );
                          setFieldValue('end_date', date);
                          let updatedRecurringRepeatOn = Object.values(
                            values.recurring_repeat_on
                          );
                          if (
                            index >= 0 &&
                            index < updatedRecurringRepeatOn.length
                          ) {
                            updatedRecurringRepeatOn[index] = {
                              id: index,
                              label: day.label,
                              value: day.value,
                              shortValue: day.shortValue,
                              from: day.from,
                              to: moment(data).format('YYYY-MM-DD HH:mm:00'),
                            };

                            setFieldValue(
                              'recurring_repeat_on',
                              updatedRecurringRepeatOn
                            );
                          }
                          if (step)
                            valueChanged(end_date, process, template, user, {
                              value: date,
                            });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {index > 0 && (
                <Button
                  className={classes.removeButton}
                  onClick={() => {
                    const updatedRecurringRepeatOn = Object.values(
                      values.recurring_repeat_on
                    ).filter((_, i) => i !== index);
                    setFieldValue(
                      'recurring_repeat_on',
                      updatedRecurringRepeatOn
                    );
                  }}
                >
                  <CustomRemoveIcon />
                </Button>
              )}
            </div>
          </div>
        );
      })}
      <div>
        <PrimaryButtonLink
          className={classes.addButton}
          type={'button'}
          onClick={() => {
            const recurringRepeatOn = Object.values(values.recurring_repeat_on);
            const lastIndexOfRecurringRepeatOn = recurringRepeatOn.length - 1;
            const lastElement = recurringRepeatOn[lastIndexOfRecurringRepeatOn];
            const maxId = recurringRepeatOn.reduce(
              (max, obj) => (obj.id > max ? obj.id : max),
              -Infinity
            );
            const updatedRecurringRepeatOn = {
              ...lastElement,
              id: maxId + 1,
            };

            recurringRepeatOn.push(updatedRecurringRepeatOn);

            setFieldValue('recurring_repeat_on', recurringRepeatOn);
          }}
        >
          + Add Another
        </PrimaryButtonLink>
      </div>
    </Container>
  );
};

export default DateTimeNoRecurring;
