import React, { useEffect, useState } from 'react';
import classes from './CreateClass.module.scss';
import Page from 'components/Page';
import ClassInformation from '../components/ClassInformation';
import cs from 'classnames';
import { injectIntl } from 'react-intl';
import { Card, CardBody, Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ClassSchedule from '../components/ClassSchedule';
import moment from 'moment';
import get from 'lodash/get';
import { daysInWeek } from 'utils/config';
import { useSelector } from 'react-redux';
import URL from 'utils/urls';
import urls from '../../../../utils/urls';
const queryString = require('query-string');

const CreateClass = (props) => {
  const getQueryString = queryString.parse(window.location.search);
  const getAction = get(getQueryString, 'action', null) || null;
  const cloneStep = get(getQueryString, 'step', null) || null;
  const t = props.intl.formatMessage;
  const { process } = props;

  const isAdd = document.location.pathname.split('/')[2] === 'add';

  let title =
    isAdd || getAction === 'clone'
      ? t({ id: 'classes > form > Create New Class' })
      : t({ id: 'classes > form > Edit Class' });

  const isUpdate = get(process, `data.loaded_fields.created_ts.value`, false);

  const className = get(process, `data.loaded_fields.class_name.value`, '');
  const classCapacity = get(
    process,
    `data.loaded_fields.class_capacity.value`,
    ''
  );
  const classLevel = get(process, `data.loaded_fields.class_level.value`, '');

  const [stepForm, setStep] = useState(1);

  const currentMinute = moment().format('m');
  const currentHour = moment().format('HH');

  let starDate = get(process, `data.loaded_fields.start_date.value`, '');
  if (parseInt(currentHour) !== 22) {
    if (!starDate)
      starDate = moment()
        .add(60 - currentMinute, 'minutes')
        .format('YYYY-MM-DD HH:00:00');
  } else {
    if (!starDate) starDate = moment().format('YYYY-MM-DD 22:00:00');
  }

  let endDate = get(process, `data.loaded_fields.end_date.value`, '');
  if (parseInt(currentHour) !== 22) {
    if (!endDate)
      endDate = moment()
        .add(7, 'days')
        .add(120 - currentMinute, 'minutes')
        .format('YYYY-MM-DD HH:00:00');
  } else {
    if (!endDate)
      endDate = moment().add(7, 'days').format('YYYY-MM-DD 23:00:00');
  }

  const recurringEvent = get(
    process,
    `data.loaded_fields.recurring_event.value`,
    true
  );

  const recurringType = get(
    process,
    `data.loaded_fields.recurring_type.value`,
    {
      value: 'Weekly',
      label: 'Weekly',
      unit: 'week',
    }
  ) || {
    value: 'Weekly',
    label: 'Weekly',
    unit: 'week',
  };

  let recurringRepeatOn = get(
    process,
    `data.loaded_fields.recurring_repeat_on.value`,
    ''
  );

  if (!recurringRepeatOn) {
    const starDateValue = moment().format('dddd');
    const day = daysInWeek.find((item) => item.value === starDateValue);

    const currentMinute = moment().format('m');

    recurringRepeatOn = {
      [day.id]: {
        id: day.id,
        label: day.label,
        value: day.value,
        shortValue: day.shortValue,
        from: moment()
          .add(60 - currentMinute, 'minute')
          .format('YYYY-MM-DD HH:mm:00'),
        to: moment()
          .add(120 - currentMinute, 'minute')
          .format('YYYY-MM-DD HH:mm:00'),
      },
    };
  }

  let recurringNumber =
    get(process, `data.loaded_fields.recurring_number.value`, 1) || 1;

  let eventNote = get(process, `data.loaded_fields.event_note.value`, '') || '';

  let eventGuests =
    get(process, `data.loaded_fields.event_guests.value`, []) || [];

  let recurringRepeatOnMonthly =
    get(process, `data.loaded_fields.recurring_repeat_on_monthly.value`, 1) ||
    1;

  let location = get(process, `data.loaded_fields.location.value`, '') || '';
  let room = get(process, `data.loaded_fields.room.value`, '') || '';

  useEffect(() => {
    if (isUpdate && getAction !== 'clone') setStep(2);
    else if (getAction === 'clone' && cloneStep === '1') setStep(1);
    else if (getAction === 'clone' && cloneStep === '2') setStep(2);
    else setStep(1);
  }, [isUpdate]);

  const schema = Yup.object().shape({
    class_name: Yup.string().required(
      t({
        id: 'classes > form > validate > Class name is required',
      })
    ),
  });

  const checklistsCompleted = useSelector(
    ({ onboard }) => get(onboard, 'data.data', []) || []
  );

  const isDone = checklistsCompleted.length === 5 ? true : false;

  return (
    <div className={classes.root}>
      <Page
        title={title}
        breadcrumbs={[
          {
            name: t({ id: 'breadcrumbs > home' }),
            route: !isDone ? URL.ON_BOARDING() : '/',
          },
          {
            name: t({ id: 'classes' }),
            route: urls.CLASSES(),
          },
          {
            name: title,
            active: true,
          },
        ]}
      >
        <Card className={cs('cr-card p-4', classes.container)}>
          <CardBody className="cr-card-body">
            <Formik
              enableReinitialize
              initialValues={{
                class_name: className,
                class_capacity: classCapacity,
                class_level: classLevel,
                start_date: starDate,
                end_date: endDate,
                recurring_event: recurringEvent,
                recurring_type: recurringType,
                recurring_repeat_on: recurringRepeatOn,
                recurring_repeat_on_monthly: recurringRepeatOnMonthly,
                recurring_number: recurringNumber,
                event_note: eventNote,
                event_guests: eventGuests,
                location: location,
                room: room,
              }}
              validationSchema={schema}
              onSubmit={() => {}}
            >
              {(formProps) => {
                return (
                  <Form onSubmit={formProps.handleSubmit}>
                    <ClassInformation
                      {...formProps}
                      {...props}
                      stepForm={stepForm}
                      setStep={setStep}
                      schema={schema}
                    />
                    {getAction !== 'clone' ||
                    (getAction === 'clone' && cloneStep === '2') ? (
                      <ClassSchedule
                        {...formProps}
                        {...props}
                        stepForm={stepForm}
                        setStep={setStep}
                      />
                    ) : null}
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      </Page>
    </div>
  );
};

export default injectIntl(CreateClass);
