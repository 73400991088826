import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_LOAD_MORE_USERS_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_CARD_SUCCESS,
  GET_USER_BANK_SUCCESS,
  GET_USER_PAYMENTS_SUCCESS,
  GET_USER_AUTH_SUCCESS,
  GET_USER_RECURRING_PAYMENTS_SUCCESS,
  GET_USER_MEMBER_ERROR,
  GET_USER_TRANSACTION_SUCCESS,
  GET_MEMBER_ELIGIBLE_PROCESSES_REQUEST,
  GET_MEMBER_ELIGIBLE_PROCESSES_ERROR,
  GET_MEMBER_ELIGIBLE_PROCESSES_SUCCESS,
  GET_MEMBER_PROCESSES_REQUEST,
  GET_MEMBER_PROCESSES_ERROR,
  GET_MEMBER_PROCESSES_SUCCESS,
  SET_RETRY_FAILED_TRANSACTION_SUCCESS,
  RESET_MEMBER_DATA,
  GET_MEMBERSHIPDETAIL_SUCCESS, GET_MEMBERSHIP_DETAIL,
} from '../types/members';

import get from 'lodash/get';

const initialState = {
  users: {
    data: [],
    error: false,
    message: null,
    loadig: false,
  },
  user: {
    data: null,
    error: false,
    message: null,
    loadig: false,
    cardDetails: [],
    bankDetails: [],
    paymentTransactions: {
      results: [],
      page: null,
    },
    failedPaymentTransactions: {
      results: [],
      page: null,
    },
    accessToken: null,
    transactionInfo: null,
    retryResponse: null,
  },
  eligibleProcesses: {
    data: [],
    error: false,
    message: null,
    loadig: false,
  },
  processes: {
    data: [],
    error: false,
    message: null,
    loadig: false,
  },
  membershipDetails: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_REQUEST: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
          error: false,
          message: null,
        },
      };
    }

    case GET_USER_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          data: action.payload,
          message: get(action, 'message', null),
        },
      };
    }

    case GET_USER_ERROR: {
      return {
        ...state,
        user: {
          loading: false,
          error: true,
          message: action.payload,
        },
      };
    }

    case GET_USERS_REQUEST: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          error: false,
          message: null,
        },
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: false,
          data: action.payload,
          message: get(action, 'message', null),
        },
      };
    }

    case GET_LOAD_MORE_USERS_SUCCESS: {
      let data = action.payload;
      data.data = [...state.users.data.data, ...data.data];

      return {
        ...state,
        users: {
          ...state.users,
          loading: false,
          error: false,
          data: data,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_USERS_ERROR: {
      return {
        ...state,
        users: {
          loading: false,
          error: true,
          message: action.payload,
        },
      };
    }
    case GET_USER_CARD_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          cardDetails: action.payload,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_USER_BANK_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          bankDetails: action.payload,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_USER_PAYMENTS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          paymentTransactions: {
            results: Array.from(action.payload.results),
            // TO check why this was concat with the results
            // results: [
            //   ...state.user.paymentTransactions.results,
            //   ...action.payload.results,
            // ],
            page: action.payload.page,
          },
          message: get(action, 'message', null),
        },
      };
    }
    case GET_USER_TRANSACTION_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          transactionInfo: action.payload,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_USER_MEMBER_ERROR: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: true,
          transactionInfo: null,
          message: action.payload,
        },
      };
    }
    case GET_USER_RECURRING_PAYMENTS_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          failedPaymentTransactions: action.payload,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_USER_AUTH_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          accessToken: action.payload,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_MEMBER_ELIGIBLE_PROCESSES_REQUEST: {
      return {
        ...state,
        eligibleProcesses: {
          ...state.eligibleProcesses,
          loading: true,
          error: false,
          message: null,
        },
      };
    }
    case GET_MEMBER_ELIGIBLE_PROCESSES_SUCCESS: {
      return {
        ...state,
        eligibleProcesses: {
          ...state.eligibleProcesses,
          loading: false,
          error: false,
          data: action.payload,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_MEMBER_ELIGIBLE_PROCESSES_ERROR: {
      return {
        ...state,
        eligibleProcesses: {
          ...state.eligibleProcesses,
          loading: false,
          error: true,
          message: action.payload,
        },
      };
    }
    case SET_RETRY_FAILED_TRANSACTION_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false,
          error: false,
          message: get(action, 'message', null),
          retryResponse: action.payload,
        },
      };
    }
    case RESET_MEMBER_DATA: {
      return {
        ...state,
        user: {
          ...state.user,
          data: null,
          error: false,
          message: null,
          loadig: false,
          loading: false,
          cardDetails: [],
          paymentTransactions: {
            results: [],
            page: null,
          },
          failedPaymentTransactions: {
            results: [],
            page: null,
          },
          accessToken: null,
          transactionInfo: null,
          retryResponse: null,
        },
      };
    }
    case GET_MEMBER_PROCESSES_REQUEST: {
      return {
        ...state,
        processes: {
          ...state.processes,
          loading: true,
          error: false,
          message: null,
        },
      };
    }
    case GET_MEMBER_PROCESSES_SUCCESS: {
      return {
        ...state,
        processes: {
          ...state.processes,
          loading: false,
          error: false,
          data: action.payload,
          message: get(action, 'message', null),
        },
      };
    }
    case GET_MEMBER_PROCESSES_ERROR: {
      return {
        ...state,
        processes: {
          ...state.processes,
          loading: false,
          error: true,
          message: action.payload,
        },
      };
    }
    case GET_MEMBERSHIP_DETAIL: {
      return {
        ...state,
        membershipDetails: {
          loading: true,
          data: null,
        },
      };
    }
    case GET_MEMBERSHIPDETAIL_SUCCESS: {
      return {
        ...state,
        membershipDetails: {
          loading: false,
          data: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
