import axios from 'axios';
import { BASE_URL, MESSENGER_URL } from './constants';
import store from 'store';
import ToastManager from 'components/ToastManager';
import get from 'lodash/get';
import { logoutUser } from 'store/actions/auth';
class Api {
  constructor() {
    this.axios = axios.create({
      baseURL: BASE_URL,
    });
    this.axiosMessengerInstance = axios.create({
      baseURL: MESSENGER_URL,
    });
    // Add a request interceptor
    /* eslint-disable */
    this.axios.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => Promise.reject(error)
    );
    // Add a response interceptor
    this.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => Promise.reject(error)
    );

    //messanger setting for axios
    // Add a request interceptor
    /* eslint-disable */
    this.axiosMessengerInstance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => Promise.reject(error)
    );
    // Add a response interceptor
    this.axiosMessengerInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => Promise.reject(error)
    );
    /* eslint-enable */
  }

  get(name) {
    if (
      (name = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)').exec(
        window.location.search
      ))
    )
      return decodeURIComponent(name[1]);
  }

  doDownload = async (path, method, body) => {
    const auth = store.getState().auth;
    let token = auth.token;
    let IDToken = auth.IDToken;

    let headers = {
      'Content-Type': 'application/json',
      'Access-From': 'web',
      responseType: 'blob',
    };
    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
        IDToken: IDToken,
      };
    }

    const res = await this.axios.request({
      url: path,
      method,
      data: body,
      headers,
    });

    return res;
  };

  doCall = async (
    path = '',
    method,
    body,
    callback,
    isShowToast = true,
    config = {}
  ) => {
    const auth = store.getState().auth;
    let token = auth.token;
    let IDToken = auth.IDToken;

    if (!token) token = window.location.search.replace('?token=', '');

    let headers = {
      'Content-Type': 'application/json',
      'Access-From': 'web',
    };
    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
        IDToken: IDToken,
      };
    }
    try {
      const res = await this.axios.request({
        url: path,
        method,
        data: body,
        headers,
        ...config,
      });
      const errorMessage = get(res, 'data.error');
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      if (callback) callback(res);
      return res;
    } catch (err) {
      // common error point
      const status = get(err, 'response.status');
      if (status === 401 && path.indexOf('api/login') === -1) {
        // Unauthorized
        store.dispatch(logoutUser());
      } else {
        const apiError = get(err, 'response.data.error');

          let error = apiError ? new Error(apiError) : err;
          if (get(err, 'response.status') === 422) {
            error = apiError ? apiError : err;
          }
          if (isShowToast) {
            ToastManager.show({ message: error.toString(), level: 'error' });
          }
          throw error;
      }
    }
  };

  doMessangerCall = async (
    path = '',
    method,
    body,
    callback,
    timeout = 30000
  ) => {
    const auth = store.getState().auth;
    let token = auth.token;

    if (!token) token = window.location.search.replace('?token=', '');

    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await this.axios.request({
        url: path,
        method,
        data: body,
        timeout: timeout,
        headers,
      });
      const errorMessage = get(res, 'data.error');
      if (errorMessage) {
        throw new Error(errorMessage);
      }
      if (callback) callback(res);
      return res;
    } catch (err) {
      throw err;
    }
  };

  uploadFile = async (path, method, body, onUploadProgress) => {
    const auth = store.getState().auth;
    let token = auth.token;
    let IDToken = auth.IDToken;

    let headers = {};
    if (token) {
      headers = {
        'Content-Type': 'multipart/form-data',
        'Access-From': 'web',
        Authorization: `Bearer ${token}`,
        IDToken: IDToken,
      };
    }

    const res = await this.axios.request({
      url: path,
      method,
      data: body,
      headers,
      onUploadProgress,
    });

    return res;
  };
}

export default new Api();
