import {
  GET_POPULAR_CLASSES_INSIGHTS_ERROR,
  GET_POPULAR_CLASSES_INSIGHTS_REQUEST,
  GET_POPULAR_CLASSES_INSIGHTS_SUCCESS,
  GET_ABSENTEES_ERROR,
  GET_ABSENTEES_SUCCESS,
  GET_ABSENTEES_REQUEST,
  GET_ATTENDANCE_BY_CLASS_INSIGHTS_ERROR,
  GET_ATTENDANCE_BY_CLASS_INSIGHTS_REQUEST,
  GET_ATTENDANCE_BY_CLASS_INSIGHTS_SUCCESS,
  GET_ATTENDANCE_CLASS_LIST_SUCCESS,
  GET_ATTENDANCE_OVERVIEW_INSIGHTS_ERROR,
  GET_ATTENDANCE_OVERVIEW_INSIGHTS_REQUEST,
  GET_ATTENDANCE_OVERVIEW_INSIGHTS_SUCCESS,
  GET_REVENUE_INSIGHTS_ERROR,
  GET_REVENUE_INSIGHTS_REQUEST,
  GET_REVENUE_INSIGHTS_SUCCESS,
  GET_INSIGHTS_CLASSES_BY_DATE_REQUEST,
  GET_INSIGHTS_CLASSES_BY_DATE_SUCCESS,
  GET_INSIGHTS_CLASSES_BY_DATE_ERROR,
} from '../types/insights';
import get from 'lodash/get';

const initialState = {
  popularClasses: {
    data: [],
    loading: false,
  },
  attendanceByClass: {
    data: [],
    loading: false,
    classes: [],
  },
  attendanceOverView: {
    data: [],
    loading: false,
  },
  revenue: {
    data: {},
    loading: false,
  },
  classesByDate:{
    loading:false,
    data:[]
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ABSENTEES_REQUEST: {
      return {
        ...state,
        absentee: {
          data: [],
          loading: true,
        },
      };
    }
    case GET_ABSENTEES_ERROR: {
      return {
        ...state,
        absentee: {
          data: [],
          loading: false,
        },
      };
    }
    case GET_ABSENTEES_SUCCESS: {
      return {
        ...state,
        absentee: {
          data: get(action, 'payload'),
          loading: false,
        },
      };
    }
    case GET_POPULAR_CLASSES_INSIGHTS_REQUEST: {
      return {
        ...state,
        popularClasses: {
          data: [],
          loading: true,
        },
      };
    }
    case GET_POPULAR_CLASSES_INSIGHTS_SUCCESS: {
      return {
        ...state,
        popularClasses: {
          data: get(action, 'payload'),
          loading: false,
        },
      };
    }
    case GET_POPULAR_CLASSES_INSIGHTS_ERROR: {
      return {
        ...state,
        popularClasses: {
          data: [],
          loading: false,
        },
      };
    }

    case GET_ATTENDANCE_CLASS_LIST_SUCCESS: {
      return {
        ...state,
        attendanceByClass: {
          ...state.attendanceByClass,
          classes: get(action, 'payload'),
        },
      };
    }

    case GET_ATTENDANCE_BY_CLASS_INSIGHTS_REQUEST: {
      return {
        ...state,
        attendanceByClass: {
          ...state.attendanceByClass,
          data: [],
          loading: true,
        },
      };
    }
    case GET_ATTENDANCE_BY_CLASS_INSIGHTS_SUCCESS: {
      return {
        ...state,
        attendanceByClass: {
          ...state.attendanceByClass,
          data: get(action, 'payload'),
          loading: false,
        },
      };
    }
    case GET_ATTENDANCE_BY_CLASS_INSIGHTS_ERROR: {
      return {
        ...state,
        attendanceByClass: {
          ...state.attendanceByClass,
          data: [],
          loading: false,
        },
      };
    }

    case GET_ATTENDANCE_OVERVIEW_INSIGHTS_REQUEST: {
      return {
        ...state,
        attendanceOverView: {
          ...state.attendanceOverView,
          data: [],
          loading: true,
        },
      };
    }
    case GET_ATTENDANCE_OVERVIEW_INSIGHTS_SUCCESS: {
      return {
        ...state,
        attendanceOverView: {
          ...state.attendanceOverView,
          data: get(action, 'payload'),
          loading: false,
        },
      };
    }
    case GET_ATTENDANCE_OVERVIEW_INSIGHTS_ERROR: {
      return {
        ...state,
        attendanceOverView: {
          ...state.attendanceOverView,
          data: [],
          loading: false,
        },
      };
    }

    case GET_REVENUE_INSIGHTS_REQUEST: {
      return {
        ...state,
        revenue: {
          ...state.revenue,
          loading: true,
        },
      };
    }
    case GET_REVENUE_INSIGHTS_SUCCESS: {
      const { year, data } = get(action, 'payload', {}) || {};
      let existingData = get(state, 'revenue.data', {}) || {};

      return {
        ...state,
        revenue: {
          ...state.revenue,
          data: {
            ...existingData,
            [year]: data,
          },
          loading: false,
        },
      };
    }
    case GET_REVENUE_INSIGHTS_ERROR: {
      return {
        ...state,
        revenue: {
          ...state.revenue,
          loading: false,
        },
      };
    }
    case GET_INSIGHTS_CLASSES_BY_DATE_REQUEST: {
      return {
        ...state,
        classesByDate: {
          loading: true,
          data: [],
        },
      };
    }
    case GET_INSIGHTS_CLASSES_BY_DATE_SUCCESS: {
      return {
        ...state,
        classesByDate: {
          loading: false,
          data:action.payload,
        },
      };
    }
    case GET_INSIGHTS_CLASSES_BY_DATE_ERROR: {
      return {
        ...state,
        classesByDate: {
          loading: false,
          data:[],
        },
      };
    }
    default:
      return state;
  }
}
