import * as Types from '../types/onboard';
import history from '../../components/History';
import Api from '../../utils/api';
import * as Const from '../../utils/constants';
import URL from '../../utils/urls';
import { addGuide } from 'store/actions/guide';

export const setChecklist = (data, navigate = true, path = null) => async (
  dispatch
) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.SET_CHECKLIST_REQUEST });
    let response = await Api.doCall(
      Const.CHECKLIST(),
      'POST',
      data,
      null,
      false
    );
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.SET_CHECKLIST_SUCCESS, payload: response.data });
        if (navigate) {
          if (!path) history.push(URL.ON_BOARDING());
          else history.push(path);
          return;
        }
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    dispatch({ type: Types.SET_CHECKLIST_ERROR, payload: errorStr });
    return Promise.reject(error.message);
  }
};

export const getChecklist = () => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.SET_CHECKLIST_REQUEST });
    let response = await Api.doCall(Const.CHECKLIST(), 'GET');
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        return dispatch({
          type: Types.SET_CHECKLIST_SUCCESS,
          payload: response.data,
        });
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    dispatch({ type: Types.SET_CHECKLIST_ERROR, payload: errorStr });
    return Promise.reject(error.message);
  }
};

export const deleteChecklist = (item) => async (dispatch) => {
  let errorStr = '';
  try {
    dispatch({ type: Types.SET_CHECKLIST_REQUEST });
    let response = await Api.doCall(Const.CHECKLIST_DELETE(item), 'DELETE');
    if (response.status === 200 || response.status === 201) {
      if (!response.data.error) {
        dispatch({ type: Types.SET_CHECKLIST_SUCCESS, payload: response.data });
        return;
      }
      errorStr = response.data.error;
    }
  } catch (error) {
    dispatch({ type: Types.SET_CHECKLIST_ERROR, payload: errorStr });
    return Promise.reject(error.message);
  }
};

export const closeWelcomeModal = () => (dispatch) => {
  // dispatch({ type: Types.CLOSE_WELCOME_MODAL });
  dispatch(addGuide('welcomeModalShown', true))

};

export const closeTooltipUserChecklist = () => (dispatch) => {
  // dispatch({ type: Types.CLOSE_TOOLTIP_USER_CHECKLIST });
  dispatch(addGuide('openTooltipUserChecklist', false))
};
