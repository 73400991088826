import React, { useState } from 'react';
import classes from './ClassSchedule.module.scss';
import DatePicker from 'components/DatePicker';
import get from 'lodash/get';
import moment from 'moment';
import cs from 'classnames';
import Alert from 'components/Alert';
import { Box } from '@mui/material';
const CalendarIcon = ({ color }) => (
  <svg width="16" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2222 1.8H13.3333V0H11.5556V1.8H4.44444V0H2.66667V1.8H1.77778C0.791111 1.8 0.00888888 2.61 0.00888888 3.6L0 16.2C0 17.19 0.791111 18 1.77778 18H14.2222C15.2 18 16 17.19 16 16.2V3.6C16 2.61 15.2 1.8 14.2222 1.8ZM14.2222 16.2H1.77778V6.3H14.2222V16.2Z"
      fill={color}
    />
  </svg>
);
const convertFormatDate = 'YYYY-MM-DD HH:mm:ss';

const DateTimeWeekly = ({
  values,
  setFieldValue,
  validateDate,
  validateTime,
  step,
  process,
  template,
  user,
  valueChanged,
  withDateIcon = false,
  ...props
}) => {
  const [isChangedEndDate, setChangeEndDate] = useState(false);

  const fields = get(step, 'fields', []) || [];
  let start_date = null;
  let end_date = null;

  if (step) {
    start_date = fields.find((f) => f.field === 'start_date');
    end_date = fields.find((f) => f.field === 'end_date');
  }

  return (
    <Box className={`${classes.dateTime} date-hook`}>
      {withDateIcon && <CalendarIcon color={'#EA9277'} />}
      <div className="mb-2 d-flex">
        <DatePicker
          hasCalendarIcon={false}
          value={moment(values.start_date, convertFormatDate).toDate()}
          changeHandler={(data) => {
            const hh = moment(values.start_date).format('HH');
            const mm = moment(values.start_date).format('mm');
            const ss = moment(values.start_date).format('ss');
            const date = moment(data)
              .hour(hh)
              .minute(mm)
              .second(ss)
              .format('YYYY-MM-DD HH:mm:ss');

            if (isChangedEndDate === false) {
              const secondStartDate = moment(
                date,
                'YYYY-MM-DD 00:00:00'
              ).format('x');
              const secondEndDate = moment(
                values.end_date,
                'YYYY-MM-DD 00:00:00'
              ).format('x');

              if (secondStartDate >= secondEndDate) {
                const endDate = moment(date)
                  .add(7, 'days')
                  .add(1, 'hour')
                  .format('YYYY-MM-DD HH:mm:ss');

                setFieldValue('end_date', endDate);
                if (step)
                  valueChanged(end_date, process, template, user, {
                    value: endDate,
                  });
              }
            }

            setFieldValue('start_date', date);
            if (step)
              valueChanged(start_date, process, template, user, {
                value: date,
              });
          }}
        />
      </div>

      <span className="mr-2 ml-2">to</span>

      <div className={cs(classes.endDateTime)}>
        <DatePicker
          hasCalendarIcon={false}
          className={cs({
            error: validateDate === false,
          })}
          value={moment(values.end_date, convertFormatDate).toDate()}
          changeHandler={(data) => {
            const hh = moment(values.end_date).format('HH');
            const mm = moment(values.end_date).format('mm');
            const ss = moment(values.end_date).format('ss');
            const date = moment(data)
              .hour(hh)
              .minute(mm)
              .second(ss)
              .format('YYYY-MM-DD HH:mm:ss');
            setFieldValue('end_date', date);
            if (step)
              valueChanged(end_date, process, template, user, {
                value: date,
              });

            setChangeEndDate(true);
          }}
        />

        {(validateDate === false || validateTime === false) && (
          <div className={cs(classes.alert, 'ml-2')}>
            <Alert
              className="warning"
              content="Event must end after it starts."
            />
          </div>
        )}
      </div>
    </Box>
  );
};

export default DateTimeWeekly;
