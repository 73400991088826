

import React from "react";
import { SvgIcon } from "@mui/material";

const CloseModalIcon = ({color='#9C9CA3',...props}) => (
  <SvgIcon
    fontSize="small"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
   <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0958 11.7797L11.7797 10.0958C12.0734 9.80213 12.0734 9.33038 11.7797 9.03671L8.74304 6L11.7797 2.96329C12.0734 2.66962 12.0734 2.19787 11.7797 1.90419L10.0958 0.220255C9.80213 -0.0734184 9.33038 -0.0734184 9.03671 0.220255L6 3.25696L2.96329 0.220255C2.66962 -0.0734184 2.19787 -0.0734184 1.90419 0.220255L0.220255 1.90419C-0.0734184 2.19787 -0.0734184 2.66962 0.220255 2.96329L3.25696 6L0.220255 9.03671C-0.0734184 9.33038 -0.0734184 9.80213 0.220255 10.0958L1.90419 11.7797C2.19787 12.0734 2.66962 12.0734 2.96329 11.7797L6 8.74304L9.03671 11.7797C9.32726 12.0703 9.80213 12.0703 10.0958 11.7797Z" fill={color}/>
</svg>
  </SvgIcon>
);

export default CloseModalIcon;
