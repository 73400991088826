import * as Const from 'utils/constants';
import Api from 'utils/api';
import store from 'store';
import get from 'lodash/get';

export const getRecitals = async (page = 1, keyword = '', status = '') => {
  let rs = await Api.doCall(
    `${Const.GET_RECITALS()}?page=${page}&q=${keyword}&status=${status}`,
    'GET'
  );

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const getRecitalById = async (id) => {
  let rs = await Api.doCall(`${Const.GET_RECITAL(id)}`, 'GET');

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const addRecital = async (data) => {
  let rs = await Api.doCall(Const.GET_RECITALS(), 'POST', data);

  if (get(rs, 'status') === 200) {
    return rs;
  }
};

export const deleteRecital = async (id) => {
  let rs = await Api.doCall(Const.GET_RECITAL(id), 'DELETE');

  if (rs.status === 200) {
    return rs;
  }
};

export const updateRecital = async (id, data) => {
  let rs = await Api.doCall(Const.GET_RECITAL(id), 'PUT', data);

  if (rs.status === 200) {
    return rs;
  }
};

export const updateStatusRecital = async (id, status) => {
  let rs = await Api.doCall(Const.UPDATE_STATUS_RECITAL(id), 'POST', {
    status,
  });

  if (rs.status === 200) {
    return rs;
  }
};

export const updatePerformances = async (id, data) => {
  let rs = await Api.doCall(Const.UPDATE_RECITAL_PERFORMANCES(id), 'PUT', data);

  if (rs.status === 200) {
    return rs;
  }
};

export const SendNotificationFamily = async (recitalId, id) => {
  let rs = await Api.doCall(
    Const.SEND_NOTIFICATION_FAMILY(recitalId, id),
    'GET'
  );

  if (rs.status === 200) {
    return rs;
  }
};

export const startEndPerformence = async (recitalId, id, action) => {
  let url = Const.START_END_PERFORMENCE(recitalId, id);
  url += `?action=${action}`;

  let rs = await Api.doCall(url, 'GET');

  if (rs.status === 200) {
    return rs;
  }
};

export const downloadRecital = async (type, data) => {
  const path = `${Const.DOWNLOAD_RECITAL(type)}`;

  const auth = store.getState().auth;

  const post = {
    ...data,
    studio: get(auth, 'info.current_studio.studio_id', ''),
  };
  openWindowWithPost(path, post);
};

function openWindowWithPost(url, data) {
  var form = document.createElement('form');
  form.target = '_blank';
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';

  for (var key in data) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;

    if (Array.isArray(data[key])) {
      input.value = JSON.stringify(data[key]);
    } else {
      input.value = data[key];
    }
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
