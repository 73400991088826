import ChatBoxView from "./view";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";

const Enhancer = compose(
  connect((state,props) => ({
    users: get(state, "auth.info.users", []).filter(user => user.name && !!user.name.trim()),
    props
  })),
  withState("inputValue", "setInputValue", {
    plainValue: "",
    value: "",
    mentions: []
  }),
  withState("isEmojiPickerOpen", "setIsEmojiPickerOpen", false),
  withHandlers({
    toggleEmojiPicker: ({ isEmojiPickerOpen, setIsEmojiPickerOpen }) => e => {
			console.log("called");
      setIsEmojiPickerOpen(!isEmojiPickerOpen);
    },
    onSend: ({ inputValue, setInputValue, onSubmit }) => () => {
      onSubmit(inputValue);
      setInputValue({
        plainValue: "",
        value: "",
        mentions: []
      });
    }
  })
)(ChatBoxView);

export default Enhancer;
