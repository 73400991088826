import React, { useState } from 'react';
import classes from './InputNumber.module.scss';
import cs from 'classnames';
import { Input } from 'reactstrap';

import arrowDown from 'assets/img/icons/arrow_down.svg';

const InputNumber = (props) => {
  const { value, className, onChange, error, ...rest } = props;

  const [showStepper, setShowStepper] = useState(false);

  const arrow = (status = 'active', orrient = 'down') => {
    if (orrient === 'down')
      return (
        <img
          className={cs({ [classes.disabled]: status !== 'active' })}
          src={arrowDown}
          alt="arrow"
        />
      );

    return (
      <img
        className={cs({ [classes.disabled]: status !== 'active' })}
        src={arrowDown}
        alt="arrow"
        style={{ transform: `rotate(-180deg)` }}
      />
    );
  };

  const step = (action = 'up') => {
    switch (action) {
      case 'up':
        let number = value;
        if (number === '') number = 0;
        onChange(number + 1);
        break;

      default:
        if (value > 1) onChange(value - 1);
        break;
    }
  };

  const onToggleOpen = (value) => setShowStepper(value);

  return (
    <div
      className={classes.root}
      onMouseEnter={() => onToggleOpen(true)}
      onMouseLeave={() => onToggleOpen(false)}
    >
      <Input
        type="number"
        value={value}
        className={cs(className, { [classes.error]: error === true})}
        onChange={(e) => {
          let number = e.target.value;
          if (number === '') {
            onChange(number);
          } else {
            number = parseInt(number);
            onChange(number);
          }
        }}
        {...rest}
      />

      {showStepper && (
        <>
          <button className={classes.up} onClick={() => step('up')}>
            {arrow('active', 'up')}
          </button>
          <button className={classes.down} onClick={() => step('down')}>
            {arrow(value !== '' && value > 1 ? 'active' : '', 'down')}
          </button>
        </>
      )}
    </div>
  );
};

InputNumber.defaultProps = {
  value: '',
  className: '',
  onChange: () => {},
  error: false
};

export default InputNumber;
