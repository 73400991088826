import React from "react";
import PropsType from "prop-types";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import { IoLink } from "react-icons/lib/io";
import { MdImportantDevices } from "react-icons/lib/md";
import ToastManager from "components/ToastManager";
import {join} from "lodash";

const getColor = props => {
  if (props.isDragReject) {
    return "#c66";
  }
  if (props.isDragActive) {
    return "#6c6";
  }
  return "#666";
};

const DropTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DropText = styled.div`
  text-align: center;
  color: #896aff;
  font-size: 18px;
`;
const DropArea = styled.div`
  border-width: 2px;
  border-radius: 5px;
  border-color: ${props => getColor(props)};
  border-style: ${props =>
    props.isDragReject || props.isDragActive || props.mode === "view"
      ? "solid"
      : "dashed"};
  background-color: #eff3f6;
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  outline: none;
	flex-wrap: wrap;
	cursor: pointer;
`;

class FormUploadFile extends React.Component {
  onDrop = (acceptedFiles, rejectedFiles, mimes, fieldName) => {
    if(rejectedFiles.length){
      let message = `Only accepted these mimes: ${join(mimes, ",")}`;

      if(fieldName === 'avatar'){
        message = `The ${fieldName} must be an image`;
      }
      ToastManager.show({
        title: <MdImportantDevices />,
        message,
        autoDismiss: 1.5,
        level: 'error'
      });
    }

    if(acceptedFiles.length){
      const formData = new FormData();
      acceptedFiles.map(file => formData.append("file_name", file));
      this.props.onSubmit(acceptedFiles);
    }
  };

  renderDropAreaText = ({ isDragActive, isDragReject }) => {
    const { mode, attachText } = this.props;
    if (mode === "view") {
      return null;
    }
    let dropDom = (
      <DropTextWrapper>
        <IoLink color="#896aff" size="25" style={{ marginRight: 10 }} />
        <DropText>{attachText}</DropText>
      </DropTextWrapper>
    );
    if (isDragActive) {
      dropDom = (
        <div>
          <DropText>Drop files here...</DropText>
        </div>
      );
    }
    if (isDragReject) {
      dropDom = <DropText>File is not valid</DropText>;
    }
    return dropDom;
  };

  render() {
    const { acceptMultiple, mimes, fieldName, disabled } = this.props;
    return (
      <Dropzone
        onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles, mimes, fieldName)}
        multiple={acceptMultiple}
        disabled={disabled}
        accept={join(mimes, ",")}>
        {({
          getRootProps,
          isDragActive,
          getInputProps,
          // isDragAccept,
          isDragReject,
          // acceptedFiles
        }) => {
          return (
            <DropArea
              isDragActive={isDragActive}
              isDragReject={isDragReject}
              {...getRootProps()}>
              <input {...getInputProps()} />
              {this.renderDropAreaText({ isDragActive, isDragReject })}
            </DropArea>
          );
        }}
      </Dropzone>
    );
  }
}
FormUploadFile.propsType = {
  onSubmit: PropsType.func.isRequired,
  acceptMultiple: PropsType.bool,
  disabled: PropsType.bool,
  attachText: PropsType.string
};

FormUploadFile.defaultProps = {
  acceptMultiple: false,
  attachText: "Attach File",
  disabled: false
};

export default FormUploadFile;
