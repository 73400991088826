import * as Types from '../types/confirm';

const initialState = {
  autoConfirm: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case Types.SET_NO_ASK_CONFIRM: {
      return {
        ...state,
        autoConfirm: true,
      };
    }
    default:
      return state;
  }
}
