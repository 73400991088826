export const classType = {
  curriculum: {
    header: 'Curriculum Class',
    label: 'Curriculum',
    value: 'curriculum',
  },
  drop_in: { header: 'Drop-in Classes', label: 'Drop-in', value: 'drop_in' },
  private_session: {
    header: 'Private Sessions',
    label: 'Private Session',
    value: 'private_session',
  },
  studio_rental: {
    header: 'Studio Rentals',
    label: 'Studio Rental',
    value: 'studio_rental',
  },
  trial_class: {
    header: 'Trial Classes',
    label: 'Trial Class',
    value: 'trial_class',
  },
};
