import React, { useEffect } from 'react';

import classes from 'pages/PreviewLiveMode/PreviewLiveMode.module.scss';

import useInterval from 'react-use/lib/useInterval';
import useBoolean from 'react-use/lib/useBoolean';
import get from 'lodash/get';
import { startEndPerformence } from 'store/actions/recitals';
import moment from 'moment';
import LiveIcon from 'assets/img/icons/icon/Icon/live.svg';

const StartEndButton = (props) => {
  const { status, performing, performance_length, arrage, viewMode } = props;

  const isPerforming = status === 4 && performing === 1;

  const [count, setCount] = React.useState(0);
  const [isRunning, toggleIsRunning] = useBoolean(isPerforming ? true : false);

  const startTimeAt = get(props, 'start_perform_at');

  useInterval(
    () => {
      const startTime = moment.utc(startTimeAt);
      const end = moment().utc();
      var duration = moment.duration(end.diff(startTime));

      const minutes = parseInt(duration.minutes());
      const seconds = parseInt(duration.seconds());

      const sc = seconds + minutes * 60;

      if (sc > parseInt(performance_length))
        setCount(parseInt(performance_length));
      else setCount(sc);
    },
    isRunning ? 1000 : null
  );

  const updateStatus = async (action) => {
    const rs = await startEndPerformence(props.recital_id, props.id, action);
    props.onChange(rs.data.data);

    if (action === 'start' && isRunning === false) {
      toggleIsRunning();
    }

    if (action === 'end') {
      setCount(0);
    }
  };

  useEffect(() => {
    if (performance_length && count >= performance_length) {
      toggleIsRunning();
      updateStatus('end');
    }
  }, [count]);

  const showStartButton = () => {
    return (
      <button
        className={classes.startButton}
        disabled={viewMode !== 'live_streaming'}
        onClick={() => updateStatus('start')}
      >
        Start
      </button>
    );
  };

  const showEndButton = () => {
    if (arrage === true) {
      return (
        <div className={classes.LiveIcon}>
          <img src={LiveIcon} alt="" />
          Live
        </div>
      );
    }

    var minutes = 0;
    var seconds = 0;
    if (parseInt(count) > 0) {
      minutes = Math.floor(count / 60);
      seconds = count - minutes * 60;
    }

    if (parseInt(seconds) < 10) seconds = `0${seconds}`;

    return (
      <>
        <span>
          {minutes}:{seconds}
        </span>{' '}
        <button
          className={classes.endButton}
          onClick={() => updateStatus('end')}
        >
          End
        </button>
      </>
    );
  };

  return (
    <div>
      {!isPerforming && showStartButton()}
      {isPerforming && showEndButton()}
    </div>
  );
};

export default React.memo(StartEndButton);
