import styled from "styled-components";
import { Button, Table } from "reactstrap";
export const UserListWrap = styled.div`
    .add-user{
        width:165px;
        span{
            margin-right: 5px;
        }
    }
`;
export const FullWrap = styled.div`
    padding: 3rem 0;
    .cr-page__title{
        font-weight: normal;
        font-size: 42px;
        line-height: 52px;
        color: #22294E;
    }
`;

export const SearchInput = styled.div`
    width: 375px;
    margin-left: auto;
    button{
        background: #22294E;
        opacity: 0.9 !important;
        width:40px;
        height:40px;
        color:#fff;
        padding: 0px !important;
        border-radius: 0px !important;
        margin: 0px !important;
        svg{
            height: 20px;
            width: 20px;
        }
    }
    input{
        margin-left: -5px !important;
        background-color: #fff !important;
        padding: 0px 17px !important;
        border: none !important;
        font-weight: bold;
        font-family: 'Muli';
        color: #141414;
        font-size: 14px;
        line-height: 20px;
        &::placeholder{
            color:#9C9CA3 !important;
        }
        &:focus{
            border: 1px solid #EA734F !important;
        }
    }
    .active{
        background: #EA734F;
    }
`;
export const TopWrap = styled.div`
    padding:8px 25px;
    height:72px;
    background-color:#FFFFFF80;
    .col-sm-2,.col{
        margin-bottom: 0px !important;
    }
    .cr-textinput div{
        margin-bottom:0px !important;
    }
    .row{
        align-items: center;
    }
    button{
        margin: 0 !important
    }
`;

export const TableWrap = styled.div`
    background: #FFFFFF;
    // box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
    .ReactTable{
        border:none;
    }
    .ReactTable .rt-thead.-header{
        box-shadow: 0px 1.5px 4px rgba(35,36,41,0.2);
        margin-bottom: 3px;
    }
    .rt-resizable-header-content{
        text-align: left;
    }
    .rt-th{
        span{
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #515254;
            vertical-align: middle;
        }
    }
    .rt-resizable-header{
        padding: 4.5px 6px !important;
        border:none !important;
    }
    .rt-tbody .rt-td{
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #141414;
        padding: 16px 6px !important;
        border:none !important;
        font-family: Muli !important;
        svg{
            margin-right:11px;
            width: 24px;
            height: 24px;
        }
    }
    .btn-group .btn-primary{
        background-color: transparent;
        color: #9C9CA3 !important;
        padding: 0px !important;
        margin: 0px;
        width: 40px;
        height: 40px;
        border-radius: 50% !important;
        margin-right: 6px;
        &:hover{
            color:#EA734F !important;
            background-color:#faf1ef;
            opacity: 1;
        }
        &:active{
            color:#EA734F !important;
            background-color:#faf1ef  !important;
            opacity: 1;
        }
        &:focus{
            color:#EA734F !important;
            background-color:#faf1ef  !important;
            opacity: 1;
        }
        svg{
            font-size: 18px;
            margin-right:0px;
            width: 20px;
            height: 20px;
        }
    }
    .btn-group .btn-primary.delete{
        &:hover{
        color:#F55B5B !important;
        background-color:#fbecec;
        opacity: 1;
        }
    }
    .btn-group{
        width: 100%;
    }
    .cr-checkbox [type="checkbox"]:not(:checked) + label:before{
        padding: 7px;
        margin: 0 auto;
        border: 1px solid #9C9CA3;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .cr-checkbox label{
        margin:0px;
    }
    .rounded-circle{
        margin-right:10px;
        border: 1px solid #22294E;
    }
    .rt-resizable-header-content .cr-checkbox{
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .rt-resizable-header-content .cr-checkbox label{
        display: block;
        margin-top: 23px;
        text-align: center;
    }
    .table-responsive{
        height: 522px;
        overflow: auto;
        background: #FCFCFC;
    }
    // .react-select-container {
    //     background: #E0E0E0 !important;
    // }
`;

export const NoRecord = styled.div`
    h3{
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #22294E;
    }
    svg{
        margin-bottom:20px;
    }
`;

export const Loader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    p{
        margin:0px; 
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #EA734F;
        margin-left:8px;
    }
`;

export const InitialLoader = styled.div`
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    p{
        margin:0px; 
        font-weight: normal;
        font-size: 24px !important;
        line-height: 32px !importatn;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #EA734F;
        margin-left:8px;
    }
`;

export const BulkBtn = styled(Button)`
    &&{
        border-radius: 5px;
        background: #22294E !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        width:160px;
        height:40px;
    }
    
`;

export const AddRow = styled.span`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #EA734F;
    cursor: pointer
    &:hover {
        color: #F5B399;
    }
`;
export const TableFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
    // box-shadow: 0px -1.5px 4px rgba(20, 20, 20, 0.2);
    border-top: 2px solid #dcdcdd !important;
    // height: 51px;
`;


export const TableWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const CustomTable = styled(Table)`
    &&{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600 !important;
        font-size: 16px;
        line-height: 22px;
        color: #515254;
        // min-height: 400px;
        padding: 40px;
        table-layout: fixed;
        margin-bottom: 0px;
    }

    thead tr:nth-child(1) th{
        background: white;
        position: sticky;
        top: 0;
        z-index: 10;
    }
    thead th{
        padding: 24px 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #515254;
        border: none !important;
        border-bottom: 2px solid #dcdcdd !important;
        background: #FFFFFF;
        height: 72px;
    }
    thead th:first-child{
        padding-left:24px;
    }
    thead th:last-child{
        padding-right: 24px;
    }
    tbody td:first-child{
        padding-left:24px;
    }
    tbody td{
        position : relative;
        border-bottom: 1px solid #FCF7F4 !important;
        padding: 14px 20px;
        height: 72px;
        .error-image{
            position: absolute;
            right: 27px;
            top: 27px;
        }
    }
    tbody td .form-control{
        height: 40px;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #141414;
        border: 1px solid #F8E6DF;
        box-sizing: border-box;
        border-radius: 5px;
        &::placeholder{
            color: #9C9CA3;
        }
        &:focus{
            box-shadow:unset;
            border-color:#F8E6DF;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    .isError{
        background: #FCF7F4 !important;
        border: 1px solid #F55B5B !important;
        box-sizing: border-box !important;
        border-radius: 5px !important;
    }
    .react-select__dropdown-indicator{
        svg{
            fill: #9C9CA3;
            width: 8px !important;
            height: 5px !important;
        }
    }
    .bulk-table-row{
        // background-color: rgba(252, 252, 252, 0.9);
        height: unset !important;
        background: #FCFCFC;
    }
    .react-select-container{
        border: 1px solid #F8E6DF;
        box-sizing: border-box;
        border-radius: 5px;
        background: #FFFFFF;
    }
    .react-select__single-value{
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #141414;
    }
    .react-select__placeholder{
        color: #141414 !important;
        font-weight: normal;
    }
    .trash{
        cursor: pointer;
    }
`;

export const ModalWrap = styled.div`
    padding:40px;
    position: relative;
    .modal-header{
        padding: 0px;
        border-bottom: none;
    }
    .modal-title{
        font-weight: 600;
        font-size: 24px !important;
        line-height: 32px !important;
        color: #22294E;
        margin-bottom: 8px;
    }
    .close{
        display:block;
        width:16px;
        height:16px;
        border-radius:50%;
        background-color: #898989;
        border: 1px solid #898989;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px !important;
        margin: 0px !important;
        position: absolute;
        right: 20px;
        top: 20px;
        span{
            font-size: 14px;
            color: #fff;
            font-weight: 300;
        }
    }
    .modal-body{
        padding: 0px 0px;
    }
    .fill-text{
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #141414;
        margin-bottom:24px;
    }
    .react-select-container{
        background-color: transparent;
        border-bottom: 1px solid #22294e;
        border-radius: 0px;
    }
    .modal-footer{
        margin: 0px -24px;
        margin-top: 61.35px;
        padding-bottom: 0px;
        border-top: 1px solid #F8E6DF;
    }
    .cancel-button{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #9C9CA3 !important;
        margin-right:15px !important;
        letter-spacing: 0.5px;
        cursor: pointer;
    }
    .cr-textinput{
        margin-bottom:16px;
    }
    .react-select__value-container{
        padding: 10px 16px 10px 0px !important;
    }
    .text-danger{
        display: flex;
        margin-top: 8px;
        align-items: center;
        svg{
            margin-right:4px;
        }
        span{
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #141414;
        }
    }
    .cr-textinput label{
        font-size:12px !important;
        //color: #22294E;
    }
    .invalid {
        color: #F55B5B
        }
    label{
        font-size:12px !important;
        color: #22294E;
        
    }
    
    .btn-primary{
        padding: 9px 28.5px !important;
        width: 160px !important;
        height: 60px !important;
    }
    .red{
        color:#F55B5B;
        margin-left:3px;
    }
    .react-select-container .react-select__control .react-select__indicator > svg{
        width: 8px;
        height: 5px;
        fill: #9C9CA3;
    }
    .react-select__placeholder{
        color: #9C9CA3;
    }
    .resend-invite{
        font-size: 16px;
        line-height: 22px;
        color: #EA734F;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        width: 100%;
    }
    .react-select__menu{
        height: 130px;
        width: 213px;
        overflow: auto;
        box-shadow: 0px 2px 3px rgba(76, 73, 105, 0.3);
        border-radius: 0px 0px 5px 5px;
        bottom: 2px;
        ::-webkit-scrollbar {
            width: 5px;
        }
         
        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #FFFFFF;
        }
         
        ::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: #F8E6DF;
        }
    }
    .react-select__menu-list{
        overflow: unset;
        bottom: 2px;
        backface-visibility:hidden;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
`;


export const Text = styled.p`
    display: flex;
    margin-top: 16px;
    margin-bottom: 0px;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
`;
export const FollowText = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #141414;
    margin-bottom:24px;
`

export const DownloadTemplate = styled.div`
&&{
    width: 223px;
    height: 30px;
    background: #22294E;
    border-radius: 5px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    align-items: center;
    font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
    cursor:pointer;
    &:hover{
        background: #515B7B;
    }
}
`;

export const DownloadTemplateText = styled.span`
&&{
align-items: center;
text-align: center;
letter-spacing: 0.5px;
text-transform: capitalize;
}
`;

export const Number = styled.span`
&&{
    background: #EA9277;
    border-radius: 30px;
    height: 16px;
    width: 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
}
`;

export const UploadContainer = styled.div`
    &&{
        border: none;
        box-sizing: border-box;
        width: 100%;
        height: 229px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23515254FF' stroke-width='4' stroke-dasharray='15%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 5px;
        margin-top: 24px;
        position:relative;
        padding-top:24px;
        background-color: #f6f6f6;
    }
    #react-csv-reader-input{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    &.is-drag{
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23EA734F' stroke-width='4' stroke-dasharray='15%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        background-color: #EA734F15;
    }
    &.is-drag .drag-text{
        color: #EA734F !important;
    }
`;

export const Panel = styled.div`
    &&{
        width: 100%;
        text-align: center;
        height: 27px;
    }
`;
export const DragText = styled.div`
    &&{
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        color: #141414;
        margin-top: 16px;
        margin-bottom:8px;
    }
`;

export const SupportedExtention = styled.div`
&&{
    width: 100%;
    text-align: center;
    margin-bottom: 8px;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    color: #515254;
}
`;

export const UploadButton = styled.div`
&&{
    width: 100%;
    text-align: center;
    margin-top: 8px;
    Button{
        background: #EA734F;
        border-radius: 5px;
        height: 30px;
        max-width: 161px;
        min-width: 112px;
        margin: 0 auto;
        padding: 9px 5px !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
    }
    .drop-btn{
        min-width: 161px;
    }
}
`;

export const InviteBtn = styled(Button)`
    &&{
        width: 200px;
        height: 30px;
        background: #EA734F !important;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #FFFFFF;
        padding: 9px 5px;
    }
`;

export const ValidationError = styled.span`
span {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #F55B5B !important;
    margin-top: 8px !important;
    display: block;
}
`;
export const Loading = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 18px;
p{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: #EA734F;
    margin:0px;
    margin-left:8px;
}
`;

export const AlertWrap = styled.div`
    width: 22%;
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 1.5px 4px rgba(35, 36, 41, 0.2);
    border-radius: 5px;
    min-width: 208px;
    height: auto;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 6px 10px;
    p{
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        font-family: Muli;
        color: #141414;
        margin:0px;
        margin: 0px 8px;
    }
    .alert-close{
        cursor: pointer;
        display: flex;
    align-items: center;
    justify-content: center;
    }
`;
