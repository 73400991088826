import React, { useState, useEffect } from 'react';
import SendNofityFeature from 'pages/PreviewLiveMode/components/SendNotifications';
import AudienceView from 'pages/NotificationsBarIframe/AudienceView';
import get from 'lodash/get';
import { getRecitalById } from 'store/actions/recitals';
import { userInfo } from 'store/actions/auth';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import cs from 'classnames';
import { Container } from 'reactstrap';

import classes from 'pages/PreviewLiveMode/PreviewLiveMode.module.scss';
const NotificationsBarIframe = (props) => {
  const dispatch = useDispatch();
  const id = get(props, 'match.params.id', null);
  const parsed = queryString.parse(props.location.search);
  const theme = get(parsed, 'theme', 'dark');
  const mode = get(parsed, 'mode', 'audience');

  const [recial, setRecital] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const getData = async (id) => {
      await dispatch(userInfo());
      const rs = await getRecitalById(id);

      if (rs.status === 200) {
        setRecital(get(rs, 'data.data', null));
        setIsAdmin(get(rs, 'data.is_admin', false));
      }
    };
    getData(id);
  }, [id]);

  return (
    <div
      className={cs(classes.root, {
        [classes.dark]: theme === 'dark',
      })}
    >
      <Container fluid={true}>
        {isAdmin && mode === 'moderator' ? (
          <SendNofityFeature data={recial} setRecital={setRecital} />
        ) : (
          <AudienceView data={recial} />
        )}
      </Container>
    </div>
  );
};

export default React.memo(NotificationsBarIframe);
