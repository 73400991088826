import ChatBoxView from "./view";
import get from "lodash/get";
import { connect } from "react-redux";
import { compose, withState, withHandlers } from "recompose";

const Enhancer = compose(
  connect(state => ({
    process: get(state, "processes.process")
  })),
  withState("minimized", "setMinimized", false),
  withHandlers({
    toggleMinimized: ({ minimized, setMinimized }) => () => {
      setMinimized(!minimized);
    }
  })
)(ChatBoxView);

export default Enhancer;
