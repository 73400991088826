import React from "react";
import PropTypes from "../../utils/propTypes";

import classNames from "classnames";

import { Card, CardBody, Button } from "reactstrap";

import Avatar from "../Avatar";
import { FaTrash } from "react-icons/lib/fa";

const UserListItem = ({
    avatar,
    avatarSize,
    title,
    subtitle,
    text,
    children,
    className,
    showDelete,
    onDeleteClick,
    ...restProps
}) => {
    const classes = classNames(className);

    return (
        <Card inverse className={classes} {...restProps}>
            <CardBody
                className="d-flex justify-content-center flex-column">
                <div className="d-flex justify-content-between">
                    <div>
                        <Avatar
                            src={avatar}
                            size={avatarSize}
                            style={{ marginRight: '10px' }}
                        />
                        {title}
                    </div>
                    {showDelete &&
                        <div>
                            {/* <PrivilegedComponent
                            requires={[PRIVILEGES.DELETE_USERS]}> */}
                            <Button
                                color="primary"
                                onClick={onDeleteClick}>
                                <FaTrash />
                            </Button>
                            {/* </PrivilegedComponent> */}
                        </div>}
                </div>
            </CardBody>
        </Card>
    );
};

UserListItem.propTypes = {
    avatar: PropTypes.string,
    avatarSize: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string
};

UserListItem.defaultProps = {
    avatarSize: 50
};

export default UserListItem;
