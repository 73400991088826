import React from 'react';
import classes from './MainContentReview.module.scss';
import moment from 'moment';
import get from 'lodash/get';

const CustomEvent = (props) => {
  const event = props.event;

  const startTime = moment(event.start).format('h:m').split(':');
  const startTimeA = moment(event.start).format('A');
  const endTime = moment(event.end).format('h:m').split(':');
  const endTimeA = moment(event.end).format('A');

  const color = get(event, 'color', '#a80000');
  const background = get(event, 'background', 'white');
  const borderColor = event.borderColor ? event.borderColor : event.background ? background : color;

  // calc diff time
  const start = moment(event.start);
  const end = moment(event.end);
  const diff = end.diff(start, 'minutes');

  const displayDuration = () => {
    return (
      <>
        {startTime[0]}
        {startTime[1] !== '0' && `:${startTime[1]}`}
        {startTimeA} - {endTime[0]}
        {endTime[1] !== '0' && `:${endTime[1]}`}
        {endTimeA}
      </>
    );
  };

  const displayTitle = () => {
    const duration = `${startTime[0]}${
      startTime[1] !== '0' ? `:${startTime[1]}` : ''
    }${startTimeA}-${endTime[0]}${
      endTime[1] !== '0' ? `:${endTime[1]}` : ''
    }${endTimeA}`;

    const room = `${event.room ? `, ${event.room}` : ''}`;
    const location = `${!event.room && event.location ? `, ${event.location}` : ``}`

    return `${event.title}, ${duration}${room}${location}`;
  };

  if (diff >= 90)
    // 1.5 hour
    return (
      <div
        className={classes.event}
        title={displayTitle()}
        style={{ color, borderColor, background }}
      >
        <div className={classes.studio}>{event.title}</div>
        <div className={classes.time}>{displayDuration()}</div>
        {event.room && <div className={classes.room}>{event.room}</div>}
        {!event.room && event.location ? (
          <div className={classes.room}>{event.location}</div>
        ) : (
          ''
        )}
      </div>
    );
  else if (diff >= 60) {
    // 1 hour
    return (
      <div
        className={classes.event}
        title={displayTitle()}
        style={{ color, borderColor, background }}
      >
        <div className={classes.studio}>{event.title}</div>
        <div className={classes.time}>
          {displayDuration()}
          {event.room && <>, {event.room}</>}
          {!event.room && event.location ? <>, {event.location}</> : ''}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={classes.event}
        title={displayTitle()}
        style={{ color, borderColor, background }}
      >
        <div className={classes.time}>
          <b>{event.title}</b>, {displayDuration()} {event.room && <>, {event.room}</>}
          {!event.room && event.location ? <>, {event.location}</> : ''}
        </div>
      </div>
    );
  }
};

export default CustomEvent;
