import {
  INIT_NEW_PROCESS,
  SAVE_FORM_VALUES_REQUEST,
  PROCESS_VALUE_CHANGED,
  SAVE_FORM_VALUES_SUCCESS,
  SAVE_FORM_VALUES_ERROR,
  FETCH_PROCESS_REQUEST,
  FETCH_PROCESS_SUCCESS,
  SET_ACTIVE_TAB,
  DELETE_PROCESS_REQUEST,
  DELETE_PROCESS_SUCCESS,
  DELETE_PROCESS_ERROR,
  FETCH_PROCESSES_REQUEST,
  FETCH_PROCESSES_SUCCESS,
  FETCH_PROCESSES_ERROR,
  FORGET_PRCESSES,
  SET_TAB_TO_EDIT,
  RESET_TAB,
  UPDATE_PROCESS_INCOMING,
  UPDATE_PROCESSES_INCOMING,
  FETCH_PROCESSES_INCOMING_REQUEST,
  FETCH_PROCESSES_INCOMING_SUCCESS,
  GET_MY_CALENDAR_REQUEST,
  GET_MY_CALENDAR_SUCCESS,
  GET_MY_CALENDAR_ERROR,
} from '../types/processes';
import { get, concat, uniq, omit, remove, findIndex } from 'lodash';
import { LOGOUT_USER } from '../types/auth';
import { pageSize } from '../../utils/config';
const initialState = {
  processes: {
    // This object contains all templates list
    // this is mainly required in templates listing page
    // where we show all templates list and loading icon if srevers are loading
    data: [],
    error: null,
    loading: false,
  },
  process: {
    // This object contains particular template object with id mapping
    // this is mainly required in template details page
    // where we show particular template detail and loading icon if srever details is loading
    // below is example object
    // "<id>": {
    data: { fields: {} },
    error: null,
    loading: null,
    spotSwitching: false,
    // }
  },
  deleteProcess: {
    // This object will be useful to show loading icon and status when user want to delete template
    data: { fields: [] },
    error: null,
    loading: false,
  },
  calendar: {
    data: [],
    error: null,
    loading: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROCESSES_REQUEST: {
      let data = [];
      if (
        // case: first search will reset data or change search text
        (get(action, 'payload.search') &&
          get(state, `processes.filter_${action.payload.filter}.search`) !==
            get(action, 'payload.search')) ||
        // case: empty search (not first load)
        (get(action, 'payload.search') === '' &&
          get(state, `processes.filter_${action.payload.filter}.search`)) ||
        // case: sort when list loaded
        get(action, 'payload.sortTotally')
      ) {
        data = [];
        // case: other
      } else {
        data = get(state, `processes.filter_${action.payload.filter}.data`, []);
      }
      return {
        ...state,
        processes: {
          ...state.processes,
          ['filter_' + action.payload.filter]: {
            data,
            pages: state.processes['filter_' + action.payload.filter]
              ? state.processes['filter_' + action.payload.filter].pages
              : null,
            page: state.processes['filter_' + action.payload.filter]
              ? state.processes['filter_' + action.payload.filter].page
              : 1,
            rpp: state.processes['filter_' + action.payload.filter]
              ? state.processes['filter_' + action.payload.filter].rpp
              : pageSize,
            error: null,
            loading: true,
          },
        },
      };
    }
    case FETCH_PROCESSES_SUCCESS: {
      const page = parseInt(get(action, 'payload.page', 1));
      return {
        ...state,
        process: {
          ...get(state, 'process'),
          lastEditTab: [],
          activeTab: null,
        },
        processes: {
          ...state.processes,
          ['filter_' + action.payload.filter]: {
            ...action.payload,
            data:
              page === 1
                ? [...action.payload.data]
                : [
                    ...state.processes['filter_' + action.payload.filter].data,
                    ...action.payload.data,
                  ],
            error: null,
            loading: false,
          },
        },
      };
    }
    case FETCH_PROCESSES_ERROR: {
      return {
        ...state,
        processes: {
          ...state.processes,
          ['filter_' + action.payload.filter]: {
            data: [],
            error: action.error,
            loading: false,
          },
        },
      };
    }
    case FORGET_PRCESSES: {
      let new_processes = { ...state.processes };
      Object.keys(new_processes).map((k) => {
        if (k.indexOf('filter_') === 0) new_processes[k].is_dirty = true;
        return true;
      });
      return {
        ...state,
        processes: new_processes,
      };
    }
    case FETCH_PROCESSES_INCOMING_REQUEST: {
      return {
        ...state,
        processes: {
          ...state.processes,
          ['filter_' + action.payload.filter]: {
            ...omit(get(state, `processes.filter_${action.payload.filter}`), [
              'is_dirty',
            ]),
          },
        },
      };
    }
    case FETCH_PROCESSES_INCOMING_SUCCESS: {
      let listFilter = get(state, `processes.filter_${action.payload.filter}`);
      let dataProcesses =
        state.processes['filter_' + action.payload.filter].data;
      if (get(action, 'payload.data', []).length > 0) {
        let indexExists = findIndex(
          listFilter.data,
          (item) => item._id === listFilter.current_dirty
        );
        // case update data
        if (indexExists !== -1) {
          dataProcesses[indexExists] = {
            ...dataProcesses[indexExists],
            _source: {
              ...dataProcesses[indexExists]._source,
              ...get(action, 'payload.data.0._source'), // array length always 1
            },
          };
        } else {
          dataProcesses.unshift(get(action, 'payload.data.0'));
        }
      } else {
        // case remove process from list
        remove(
          state.processes['filter_' + action.payload.filter].data,
          (item) => item._id === listFilter.current_dirty
        );
      }

      return {
        ...state,
        processes: {
          ...state.processes,
          ['filter_' + action.payload.filter]: {
            ...omit(get(state, `processes.filter_${action.payload.filter}`), [
              'current_dirty',
            ]),
            data: dataProcesses,
          },
        },
      };
    }
    case UPDATE_PROCESSES_INCOMING: {
      let new_processes = { ...state.processes };
      Object.keys(new_processes).map((k) => {
        if (k.indexOf('filter_') === 0) {
          let processKey = get(action, 'payload.message.process_key');
          new_processes[k].is_dirty = true;
          new_processes[k].current_dirty = processKey;
        }
        return true;
      });

      return {
        ...state,
        processes: new_processes,
      };
    }
    case INIT_NEW_PROCESS:
      return {
        ...state,
        process: action.payload,
      };
    case PROCESS_VALUE_CHANGED:
      let field = {};
      field = { ...state.process.data.fields[action.payload.item.field] };

      let history = {
        time: new Date(),
        user: action.payload.user.name,
        value: action.payload.value,
      };
      if (!field || field.history === undefined) {
        field = {
          history: [history],
        };
      } else if (field.is_dirty) {
        field.history[field.history.length - 1].time = new Date();
        field.history[field.history.length - 1].value = action.payload.value;
      } else field.history.push(history);

      field.is_dirty = true;
      field.value = action.payload.value;

      return {
        ...state,
        process: {
          ...state.process,
          data: {
            fields: {
              ...state.process.data.fields,
              [action.payload.item.field]: {
                ...state.process.data.fields[action.payload.item.field],
                ...field,
              },
            },
            loaded_fields: {
              ...state.process.data.loaded_fields,
            },
          },
        },
      };
    case SAVE_FORM_VALUES_SUCCESS:
      let fields = { ...state.process.data.fields };
      if (!action.payload.chat_message) {
        Object.keys(action.payload).map((field) => {
          if (!fields[field]) fields[field] = {};
          fields[field].value = action.payload[field];
          fields[field].is_dirty = false;

          return true;
        });
      }
      return {
        ...state,
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: {
              ...fields,
            },
            loaded_fields: {
              ...fields,
            },
          },
          loading: false,
          saving: false,
          error: null,
        },
      };
    case SAVE_FORM_VALUES_REQUEST: {
      if (action.payload.chat_message) return state;
      return {
        ...state,
        process: {
          ...state.process,
          loading: true,
          saving: true,
          error: null,
        },
      };
    }
    case SAVE_FORM_VALUES_ERROR: {
      return {
        ...state,
        process: {
          ...state.process,
          loading: false,
          saving: false,
          error: action.payload,
        },
      };
    }
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        process: {
          ...state.process,
          activeTab: action.payload,
        },
      };
    }
    case SET_TAB_TO_EDIT: {
      return {
        ...state,
        process: {
          ...state.process,
          activeTab: action.payload,
          lastEditTab: uniq(
            concat(get(state, 'process.lastEditTab', []), [action.payload])
          ),
        },
      };
    }
    case RESET_TAB: {
      return {
        ...state,
        process: {
          ...state.process,
          activeTab: null,
          lastEditTab: [],
        },
      };
    }
    case UPDATE_PROCESS_INCOMING:
      return {
        ...state,
        process: {
          ...state.process,
          data: {
            ...state.process.data,
            fields: {
              ...state.process.data.fields,
              ...get(action, 'payload.data'),
              messages: {
                value: [
                  ...get(state, 'process.data.fields.messages.value', []),
                  ...get(action, 'payload.data.messages.value', []),
                ],
              },
            },
            loaded_fields: {
              ...state.process.data.loaded_fields,
              ...get(action, 'payload.data'),
              messages: {
                value: [
                  ...get(
                    state,
                    'process.data.loaded_fields.messages.value',
                    []
                  ),
                  ...get(action, 'payload.data.messages.value', []),
                ],
              },
            },
          },
        },
      };
    case FETCH_PROCESS_SUCCESS:
      return {
        ...state,
        process: {
          ...state.process,
          key: action.payload.data.key ? action.payload.data.key.value : false,
          //activeTab: action.payload.data.process_step ? action.payload.data.process_step.value : false,
          data: {
            ...state.process.data,
            fields: {
              // ...state.process.data.fields,
              ...action.payload.data,
            },
            loaded_fields: {
              ...action.payload.data,
            },
          },
          loading: false,
          error: null,
          spotSwitching: false,
        },
      };
    case FETCH_PROCESS_REQUEST: {
      let newState = {};
      if (action.payload.spotSwitching) {
        newState = {
          ...state,
          process: {
            ...state.process,
            data: {
              fields: {
                ...get(state, 'process.data.fields', {}),
                key: {
                  value: action.payload.process_id,
                },
              },
              loaded_fields: {
                ...get(state, 'process.data.loaded_fields', {}),
                key: {
                  value: action.payload.process_id,
                },
              },
            },
            loading: true,
            error: null,
            spotSwitching: true,
          },
        };
      } else {
        newState = {
          ...state,
          process: {
            ...state.process,
            data: {
              fields: {},
              loaded_fields: {},
            },
            loading: true,
            error: null,
            spotSwitching: false,
          },
        };
      }
      return newState;
    }
    // DELETE Process Actions
    case DELETE_PROCESS_REQUEST: {
      return {
        ...state,
        deleteProcess: {
          loading: true,
          error: null,
        },
      };
    }
    case DELETE_PROCESS_SUCCESS: {
      let ind = state.processes.data.findIndex((s) => s._id === action.payload);
      return {
        ...state,
        processes: {
          data: [
            ...state.processes.data.slice(0, ind),
            ...state.processes.data.slice(ind + 1),
          ],
        },
        deleteProcess: {
          loading: false,
          error: null,
        },
      };
    }
    case DELETE_PROCESS_ERROR: {
      return {
        ...state,
        deleteProcess: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case LOGOUT_USER:
      return initialState;

    case GET_MY_CALENDAR_REQUEST: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: true,
          error: null,
        },
      };
    }

    case GET_MY_CALENDAR_ERROR: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: false,
          error: true,
        },
      };
    }

    case GET_MY_CALENDAR_SUCCESS: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    }

    default:
      return state;
  }
}
