import React from 'react';
import get from 'lodash/get';
import { Field, FastField } from 'formik';
import Input from './InputText';

const FormikInput = (props) => {
  const { name, type, fastField, ...rest } = props;
  const FieldInput = fastField ? FastField : Field;
  return (
    <FieldInput name={name}>
      {({ field, form }) => {
        const { touched, errors, setFieldValue } = form;
        const isTouched = get(touched, name);
        const error = get(errors, name);
        return (
          <Input
            type={type}
            name={name}
            value={field.value}
            selected={field.value}
            error={isTouched && error}
            // onChange={field.onChange}
            onBlur={field.onBlur}
            className="inner-input mt-2"
            placeholder=""
            onChange={(evt) => {
              if (type === 'date') {
                setFieldValue(name, evt);
              } else {
                setFieldValue(name, evt.target.value);
              }
            }}
            {...rest}
          />
        );
      }}
    </FieldInput>
  );
};

export default FormikInput;
