import React from "react";
import { FaSpinner } from "react-icons/lib/fa";
import PropTypes from "prop-types";
import { Backdrop } from "@mui/material";
const View = ({ isLoading, bgColor }) => (
    <Backdrop
        sx={{ color: '#fff', backgroundColor:{bgColor}, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
    >
        <FaSpinner size="40" className={"cr-icon-spin"} />
    </Backdrop>
);
View.propTypes = {
    isLoading: PropTypes.bool
}
View.defaultProps = {
    isLoading: false
}
export default View;
