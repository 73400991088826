import React, { Component } from "react";
import ChatBox from "components/ChatBox";
import {reverse, get} from "lodash";
import Checkbox from "components/Checkbox";
import { injectIntl } from "react-intl";

class NotificationsPusher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      chats: [],
      include_timestamp: false,
      showFrom: false,
      sendTo: false
    };
  }

  getTaggedUserIds = text =>{
    let arr = text.match(/(@\[.*?\]\(.*?\))/g);
    let idArr = [];
    if (arr) {
      for (let index = 0; index < arr.length; index++) {
        const item = arr[index]; // @[Sarju](1)
        const endSquareBracketPos = item.indexOf("]");
        const userId = item
          .substr(endSquareBracketPos + 1)
          .replace("(", "")
          .replace(")", "");
        const userIdInt = Number(userId);
        idArr.push(userIdInt);
      }
    }
    return idArr;
  }
  createMessage = payload => {
    const { user } = this.props;
    const { sendTo } = payload;
    const team = sendTo.type === 'user' ? [sendTo.id] : [];
    const courses = sendTo.type === 'course' ? [sendTo.id] : [];
    const studios = sendTo.type === 'studio' ? [sendTo.studio_id] : [];
    const text = payload.value.value;
    return {
      m_type: 'chat',
      from: {
        id: user.id,
        name: user.name,
        avatar_url: user.avatar_url,
      },
      tagged: this.getTaggedUserIds(text),
      studios,
      courses,
      team,
      chat: text,
      sendTo,
      // inReplyTo: payload.value.inReplyTo,
      medias: payload.medias ? [...payload.medias] : [],
    };
  };

  onSubmit = ( payload ) => {
    const chat_message = this.createMessage(payload);
    this.props.sendMessage(chat_message);
  };

  onScope = ( showFrom ) => {
    this.setState({showFrom});
  };
  
  render() {
    const { user, process, messages, step, intl } = this.props;
    console.log('this.state.showFrom: '+JSON.stringify(this.state.showFrom))
    const messagesToShow = messages
      .filter(
        ({ message }) => {
          let process_filter=true
          if(process){
            let process_key = process.key ? process.key : process.data.fields.key && process.data.fields.key.value ? process.data.fields.key.value : '-1'
            let message_process_key = message.process ? message.process : message.process_key
            process_filter = ((message_process_key && message_process_key === process_key) ||
            message_process_key === process_key ||
            message.process_key === 'current'
          )
          }
          let showFrom_filter = true
          if(this.state.showFrom){ 
            if(this.state.showFrom.type === 'my'){
              if(message.from.id === user.id &&  message.sendTo.type === 'my'){
                showFrom_filter = true
              } else {
                showFrom_filter = false
              }
            }
            else if(message.from.id === user.id && this.state.showFrom.type === 'user' && message.team && message.team.indexOf(this.state.showFrom.id)==-1){
              showFrom_filter = false
            }
            else if(this.state.showFrom.type!=='all' && this.state.showFrom.type!==message.sendTo.type){
              showFrom_filter = false
            }
            else if(this.state.showFrom && message.from.id !== user.id){
              switch (this.state.showFrom.type){
                case 'studio':{
                  showFrom_filter=message.studios && (!message.courses || message.courses.length===0) && (!message.team || message.team.length===0) && message.studios.indexOf(this.state.showFrom.studio_id)>-1
                  break;
                }
                case 'course':{
                  showFrom_filter=message.courses && (!message.team || message.team.length===0) && message.courses.indexOf(this.state.showFrom.id)>-1
                  break;
                }
                case 'user':{
                  showFrom_filter=message.team // && !message.studios && !message.courses
                  && message.team.indexOf(this.state.showFrom.id)>-1
                  break;
                }
              }
            }
          }
          if (
            message.chat &&
            (message.m_type === "chat" &&
              process_filter && showFrom_filter
              )) {
            return true
          }
          return false
        }
      )
      .map(({ message, m_id }) => {
        const isSender = message.from.id === user.id;
        let data = {
          id: m_id,
          text: message.chat,
          data: message,
          from: message.from,
          timestamp: message.created_ts * 1000,
          status: message.status || "delivered",
          position: isSender ? "right" : "left",
          message
        }
        return data;
      });
    return (
      <span>
        <ChatBox
          {...this.props}
          onSubmit={this.onSubmit}
          onScope={this.onScope}
          submitOnEnter
          // messages={reverse(messagesToShow)}
          messages={messagesToShow}
          intl={intl}
          user={user}
        />
      </span>
    );
  }
}

export default injectIntl(NotificationsPusher);
