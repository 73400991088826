const initialState=[];
const parent=(state=initialState,action)=>{
  switch(action.type){
    case 'CLEAR_LIST':{
      return initialState;
    }
    case 'ADD_USER':{
      return [...state,action.payload];
    }
    case 'SELECT_USER':{
      return state.slice(0,action.payload);
    }
  }
  return state;
}
export default parent;