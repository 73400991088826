import { GLOBAL_ERROR, GLOBAL_REQUEST, GLOBAL_SUCCESS } from "../types/classes";

const initialState = {
  class: {
    data: null,
    error: null,
    loading: false,
  },
  classDetails: {
    data: null,
    error: null,
    loading: false,
  },
  common:{
    data: null,
    error: null,
    loading: false,
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'GET_CLASS_REQUEST': {
      return {
        ...state,
        class: {
          ...state.class,
          loading: true,
        },
      };
    }
    case 'GET_CLASS_SUCCESS': {
      return {
        ...state,
        class: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case 'GET_CLASS_ERROR': {
      return {
        ...state,
        class: {
          ...state.class,
          error: action.payload,
        },
      };
    }
    // Class Details
    case 'GET_CLASS_DETAILS_REQUEST': {
      return {
        ...state,
        classDetails: {
          ...state.class,
          loading: true,
        },
      };
    }
    case 'GET_CLASS_DETAILS_SUCCESS': {
      return {
        ...state,
        classDetails: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case 'GET_CLASS_DETAILS_ERROR': {
      return {
        ...state,
        classDetails: {
          ...state.class,
          error: action.payload,
        },
      };
    }
    case GLOBAL_REQUEST: {
      return {
        ...state,
        common: {
          ...state.class,
          loading: true,
        },
      };
    }
    case GLOBAL_SUCCESS: {
      return {
        ...state,
        common: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }
    case GLOBAL_ERROR: {
      return {
        ...state,
        common: {
          ...state.class,
          error: action.payload,
        },
      };
    }
    default:
      return state;
  }
}
