import { connect } from "react-redux";
import { get } from "lodash";
import FormattedTaggedMessage from "./view";

export default connect(
    (state, props) => ({
        users: get(state, "auth.info.users", []).filter(user => user.name && !!user.name.trim()),
      ...props
    }),
    {
    }
  )(FormattedTaggedMessage);