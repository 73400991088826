import * as React from "react";
import PropTypes from "prop-types";
import Message from "./Message";
import get from "lodash/get";
import isEqual from "lodash/isEqual";

class MessageList extends React.Component {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.messages, prevProps.messages)) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.messagesEnd && !this.props.lockScroll) {
      // this.messagesEnd.scrollIntoView();
    }
  };

  render() {
    const { messages, process, onSubmit } = this.props;
    return (
      <div className="messages-wrapper">
        {messages.map((message, index) => {
          const fromId = get(message, "from.id");
          const nextFromId = get(messages, `${index + 1}.from.id`);
          const prevFromId = get(messages, `${index - 1}.from.id`);
          const process_key = get(message, "data.process_key");

          return (
            <Message
              {...message}
              key={message.id+' '+index}
              process={process}
              isLastInGroup={fromId !== nextFromId}
              isFirstInGroup={fromId !== prevFromId}
              status={process_key === 'current' ? "waiting" : "delivered"}
              allowReply={this.props.allowReply}
              onSubmit={onSubmit}
              message={message}
              user={this.props.user}
            />
          );
        })}
        <div
          ref={el => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }
}

MessageList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape(Message.propTypes)),
  lockScroll: PropTypes.bool
};

MessageList.defaultProps = {
  lockScroll: false
};

export default MessageList;
