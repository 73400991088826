import React from 'react';
import moment from 'moment';
import classes from './MainContentReview.module.scss';
import next from 'assets/img/icons/action/next.svg';
import prev from 'assets/img/icons/action/prev.svg';
const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onView('week');
    toolbar.onNavigate('TODAY');
  };

  const label = () => {

    switch (toolbar.view) {
      case 'day':
        const date = moment(toolbar.date);
        return <>{date.format('MMMM D, YYYY')}</>;

      case 'week':
      default:
        const startDate = moment(toolbar.date).startOf('week');
        const endDate = moment(toolbar.date).endOf('week');
        return (
          <>
            {startDate.format('MMMM D-')}
            {endDate.format('D, YYYY')}
          </>
        );
    }
  };

  return (
    <div className={classes.toolbarContainer}>
      <div className="flex-grow-1">
        <div className={classes.title}>{toolbar.title}</div>
      </div>

      <div className={classes.labelDate}>{label()}</div>

      <div className="d-flex">
        <button className={classes.btn} onClick={goToBack}>
          <img src={prev} alt="Prev" />
        </button>

        <button className={classes.btn} onClick={goToNext}>
          <img src={next} alt="Next" />
        </button>

        <button className={classes.today} onClick={goToCurrent}>
          today
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
