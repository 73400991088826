import React from "react";
import classes from "./ClassSchedule.module.scss";
import get from "lodash/get";
import moment from "moment";
import Dropdown from "components/Dropdown";

const MonthlyRecurring = (props) => {
  const { values, touched, errors, setFieldValue } = props;
  const { step, process, template, user, valueChanged } = props;

  let options = [];

  const startDate = get(values, "start_date");
  if (startDate) {

    const startDay = moment(startDate, "YYYY-MM-DD").format("Do");
    const startDayOfWeek = moment(startDate, "YYYY-MM-DD").format("dddd");
    
    options.push({
      value: 1,
      label: `the ${startDay} day`,
    });

    options.push({
      value: 2,
      label: `the first ${startDayOfWeek}`,
    });

    options.push({
      value: 3,
      label: `the second ${startDayOfWeek}`,
    });

    options.push({
      value: 4,
      label: `the third ${startDayOfWeek}`,
    });

    options.push({
      value: 5,
      label: `the forth ${startDayOfWeek}`,
    });

    options.push({
      value: 6,
      label: `the last ${startDayOfWeek}`,
    });

  }

  const selected = options.find(
    (item) => item.value === values.recurring_repeat_on_monthly
  );

  let recurring_repeat_on_monthly = null;
  
  if (step)
    recurring_repeat_on_monthly = step.fields.find(
      (f) => f.field === 'recurring_repeat_on_monthly'
    );

  return (
    <div className={classes.repeatOn}>
      <div>Repeat on</div>
      <div className="d-flex mt-2 mb-2 align-items-center">
        <div className={classes.dropdownMonth}>
          <Dropdown
            label={null}
            placeholder={null}
            outline={true}
            name="recurring_repeat_on_monthly"
            error={
              touched.recurring_repeat_on_monthly &&
              errors.recurring_repeat_on_monthly
            }
            onChange={(selectedOption) => {
              setFieldValue(
                'recurring_repeat_on_monthly',
                selectedOption.value
              );
              if (step)
                valueChanged(
                  recurring_repeat_on_monthly,
                  process,
                  template,
                  user,
                  {
                    value: selectedOption.value,
                  }
                );
            }}
            value={selected ? selected : 1}
            options={options}
          />
        </div>

        <span className="ml-2">of the month.</span>
      </div>
    </div>
  );
};
export default MonthlyRecurring;
