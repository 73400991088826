import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'utils/propTypes';
import bn from 'utils/bemnames';
import history from 'components/History';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import Typography from './Typography';
import debounce from 'lodash/debounce';
import cs from 'classnames';
import get from 'lodash/get';

const bem = bn.create('page');
const Page = ({
  title,
  breadcrumbs,
  tag: Tag,
  className,
  applyPadding,
  children,
  AlertMsg,
  sticky,
  parent,
  disableHomeIcon,
  sideNode,
  ...restProps
}) => {
  const classes = bem.b(applyPadding ? 'p-5' : '', className);
  const [isScroll, setIsScroll] = useState(false);
  const theme = useSelector(({ theme }) => {
    return get(theme, 'mode');
  });
  useEffect(() => {
    window.addEventListener('scroll', scrollFunc, true);
  }, []);

  const scrollFunc = useCallback(
    debounce((val) => handleScroll(val), 50),
    []
  );
  const dispatch = useDispatch();

  function handleScroll() {
    setIsScroll(true);
    if (window.scrollY >= 300) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    if (history.action === 'POP' && parent != undefined) {
      dispatch({
        type: 'SELECT_USER',
        payload: parent.length - 1,
      });
    }
  }, [history.location.pathname]);

  return (
    <>
      <Tag className={classes} {...restProps}>
        {(title || breadcrumbs) && (
          <div
            style={{
              display: 'flex',
              position: 'relative',
              justifyContent: 'space-between',
            }}
          >
            <div className={bem.e('header')}>
              {title && (
                <>
                  <Typography
                    type="h1"
                    className={cs(bem.e('title'), {
                      dark: theme === 'dark',
                    })}
                  >
                    {title}
                  </Typography>
                </>
              )}
              {breadcrumbs && breadcrumbs.length > 0 && (
                <Breadcrumb
                  className={cs(bem.e('breadcrumb'), {
                    dark: theme === 'dark',
                  })}
                >
                  {!disableHomeIcon && (
                    <BreadcrumbItem>
                      {theme === 'dark' ? (
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5553 8.64152V12.803C13.5553 13.1893 13.258 13.5 12.8886 13.5H9.66601C9.48266 13.5 9.33264 13.3432 9.33264 13.1515V9.89897C9.33264 9.7073 9.18263 9.55049 8.99928 9.55049H6.99907C6.81572 9.55049 6.6657 9.7073 6.6657 9.89897V13.1515C6.6657 13.3432 6.51569 13.5 6.33233 13.5H3.10978C2.7403 13.5 2.44305 13.1893 2.44305 12.803V8.64152C2.44305 8.53697 2.4875 8.43823 2.56528 8.37144L7.78804 3.87596C7.91027 3.77142 8.08807 3.77142 8.21308 3.87596L13.4358 8.37144C13.5108 8.43823 13.5553 8.53697 13.5553 8.64152ZM15.8778 6.87295L13.5553 4.87205V0.849939C13.5553 0.658271 13.4053 0.501452 13.2219 0.501452H11.6662C11.4829 0.501452 11.3328 0.658271 11.3328 0.849939V2.95828L8.84648 0.817994C8.35476 0.394002 7.64358 0.394002 7.15186 0.817994L0.120588 6.87295C-0.0210936 6.99492 -0.04054 7.21563 0.0761386 7.36373L0.784544 8.26399C0.901223 8.4121 1.11236 8.43242 1.25404 8.31045L7.78804 2.6853C7.91027 2.58075 8.08807 2.58075 8.21308 2.6853L14.7471 8.31045C14.8888 8.43242 15.0999 8.4121 15.2166 8.26399L15.925 7.36373C16.0417 7.21272 16.0194 6.99492 15.8778 6.87295Z"
                            fill="#51BCDA"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16"
                          height="14"
                          viewBox="0 0 16 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5553 8.64152V12.803C13.5553 13.1893 13.258 13.5 12.8886 13.5H9.66601C9.48266 13.5 9.33264 13.3432 9.33264 13.1515V9.89897C9.33264 9.7073 9.18263 9.55049 8.99928 9.55049H6.99907C6.81572 9.55049 6.6657 9.7073 6.6657 9.89897V13.1515C6.6657 13.3432 6.51569 13.5 6.33233 13.5H3.10978C2.7403 13.5 2.44305 13.1893 2.44305 12.803V8.64152C2.44305 8.53697 2.4875 8.43823 2.56528 8.37144L7.78804 3.87596C7.91027 3.77142 8.08807 3.77142 8.21308 3.87596L13.4358 8.37144C13.5108 8.43823 13.5553 8.53697 13.5553 8.64152ZM15.8778 6.87295L13.5553 4.87205V0.849939C13.5553 0.658271 13.4053 0.501452 13.2219 0.501452H11.6662C11.4829 0.501452 11.3328 0.658271 11.3328 0.849939V2.95828L8.84648 0.817994C8.35476 0.394002 7.64358 0.394002 7.15186 0.817994L0.120588 6.87295C-0.0210936 6.99492 -0.04054 7.21563 0.0761386 7.36373L0.784544 8.26399C0.901223 8.4121 1.11236 8.43242 1.25404 8.31045L7.78804 2.6853C7.91027 2.58075 8.08807 2.58075 8.21308 2.6853L14.7471 8.31045C14.8888 8.43242 15.0999 8.4121 15.2166 8.26399L15.925 7.36373C16.0417 7.21272 16.0194 6.99492 15.8778 6.87295Z"
                            fill="#EA9277"
                          />
                        </svg>
                      )}
                    </BreadcrumbItem>
                  )}
                  {breadcrumbs.length &&
                    breadcrumbs.map(
                      ({ name, active, route, onClick }, index) => (
                        <BreadcrumbItem key={index} active={active}>
                          {index !== 0 &&
                            (theme === 'dark' ? (
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  opacity="0.5"
                                  cx="5"
                                  cy="5"
                                  r="5"
                                  fill="white"
                                />
                                <path
                                  d="M6.78661 5.22659L4.34211 7.82263C4.22425 7.9478 4.03307 7.9478 3.91521 7.82263L3.63007 7.5198C3.51234 7.39477 3.51222 7.19225 3.62957 7.06695L5.56694 4.9999L3.6297 2.93272C3.51222 2.80742 3.51247 2.60491 3.6302 2.47988L3.91534 2.17705C4.03319 2.05188 4.22438 2.05188 4.34223 2.17705L6.78661 4.77322C6.90446 4.89838 6.90446 5.10143 6.78661 5.22659Z"
                                  fill="#4F4E72"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  opacity="0.5"
                                  cx="5"
                                  cy="5"
                                  r="5"
                                  fill="#141414"
                                />
                                <path
                                  d="M6.78661 5.22672L4.34211 7.82275C4.22425 7.94792 4.03307 7.94792 3.91521 7.82275L3.63007 7.51992C3.51234 7.39489 3.51222 7.19238 3.62957 7.06708L5.56694 5.00003L3.6297 2.93284C3.51222 2.80754 3.51247 2.60503 3.6302 2.48L3.91534 2.17717C4.03319 2.052 4.22438 2.052 4.34223 2.17717L6.78661 4.77334C6.90446 4.8985 6.90446 5.10155 6.78661 5.22672Z"
                                  fill="white"
                                />
                              </svg>
                            ))}
                          <h6
                            onClick={() => {
                              if (!active) {
                                if (onClick instanceof Function) {
                                  onClick();
                                }
                                if (route) {
                                  // console.log("route",route)
                                  history.push(route);
                                }
                              }
                            }}
                          >
                            {name}
                          </h6>
                        </BreadcrumbItem>
                      )
                    )}
                </Breadcrumb>
              )}
            </div>
            {sideNode && sideNode}
            {AlertMsg && <AlertMsg />}
          </div>
        )}

        {children}
      </Tag>
      {isScroll && (
        <Button
          className={bem.e('scrollToTop')}
          color="primary"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          TOP
        </Button>
      )}
    </>
  );
};
Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  sideNode: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  applyPadding: PropTypes.bool,
};
Page.defaultProps = {
  tag: 'div',
  title: '',
  applyPadding: true,
};
export default Page;
