import React, { Component } from "react";
import { FaSpinner, FaEdit, FaCheck, FaRepeat } from "react-icons/lib/fa";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import bn from "utils/bemnames";
import classnames from "classnames";
import DynamicForm from "./formIndex";
import Chat from "../NotificationsPusher";
import ButtonExpand from "components/ButtonExpand";
import StarIcon from "assets/img/tabs/Star.png";
import StarWhiteIcon from "assets/img/tabs/Star_white.png";
import LogIcon from "assets/img/tabs/Log.png";
import LogWhiteIcon from "assets/img/tabs/Log_white.png";
import TeamIcon from "assets/img/tabs/Team.png";
import TeamWhiteIcon from "assets/img/tabs/Team_white.png";
import ReviewIcon from "assets/img/tabs/Review.png";
import ReviewWhiteIcon from "assets/img/tabs/Review_white.png";
import { addLeadingZeros, countdown as countdownHelper } from "utils/helpers";
import { get } from "lodash";
import history from "components/History";
import URL from "utils/urls";
import { FormattedMessage } from "react-intl";

const bem = bn.create("add-update-process");

class Tabs extends Component {
  constructor(props) {
    super(props);
    this.refTabs = [];
    this.toggle = this.toggle.bind(this);
  }

  getTabIndexFromKey(tab_key) {
    let template = this.props.template;
    let tab_index = 0;
    Object.keys(template.steps).map((key, ind) => {
      if (key === tab_key) tab_index = ind;
      return true;
    });
    return tab_index;
  }

  getTabKeyFromIndex(tab_index) {
    let template = this.props.template;
    let tab_key = null;
    Object.keys(template.steps).map((key, ind) => {
      if (ind === tab_index) tab_key = key;
      return true;
    });
    return tab_key;
  }

  toggle(tab) {
  
    let process = this.props.process;
    let tab_key = this.getTabKeyFromIndex(tab);
    if (process.activeTab !== tab_key) {
      this.props.setActiveTab(tab_key);
      if(process.data.fields.key){
        history.replace(
          URL.VIEW_PROCESS({
            template_id: process.data.fields.template_key.value,
            process_id: process.data.fields.key.value
          })
        );
      }
    }
  }
  // switchToSpot(key) {
  //   if(key==='new'){
  //     ToastManager.show( {
  //       title: 'Not saved!',
  //       message: "Please save the order before switching to another spot",
  //       level: "error"
  //     })
  //     return
  //   }
  //   let process = this.props.process;
  //   let template = this.props.template;
  //   // if(this.props.process.data.fields.key===key) return
  //   history.push("/processes/edit/" + template.key + "/" + key);
  // }

  render() {
    let process = this.props.process;
    let template = this.props.template;
    let user = this.props.user;
    let toggle = this.toggle.bind(this);
    let current_step = null;
    let ui_hint = this.props.ui_hint;
    const startDate = get(process, "data.fields.order_start_date.value");
    const countdown = startDate ? countdownHelper(startDate) : null;
    if (template.no_tabs){
      return <DynamicForm
      step={template.steps[Object.keys(template.steps)[0]]}
      process={process}
      template={template}
      tab_toggle={toggle}
      match_process_id={this.props.match_process_id}
      />
    }
    if (!process.data || !process.data.fields || !process.data.fields.template_key) {
      return null
    }
    if (ui_hint)
      return (
        <DynamicForm
          ui_hint={ui_hint}
          process={process}
          template={template}
          tab_toggle={toggle}
          match_process_id={this.props.match_process_id}
        />
      );

    let activeTab = this.getTabIndexFromKey(process.activeTab);
    // let spots=[]
    // if (process.data.fields.key && this.props.process.data.fields.spots && this.props.process.data.fields.spots.value.length>1){
    //   this.props.process.data.fields.spots.value.map((spot,ind)=>spots.push({key:spot,component:<span> Spot {ind+1} </span>}))
    // }
    let previous_steps_completed = true; // eslint-disable-line

    let spots = [];

    let active_tab_is_hidden = false
    let first_visible_tab = false
    if (
      process.data.fields.key &&
      this.props.process.data.fields.spots &&
      this.props.process.data.fields.spots.value.length > 1
    ) {
      this.props.process.data.fields.spots.value.map((spot, ind) =>
        spots.push({ key: spot, component: <span> Spot {ind + 1} </span> })
      );
    }
    let tabs = (
      <div className={classnames("fluid-container", bem.b())}>
        <div className={bem.e("header")}>
          <div className={classnames("container", bem.e("header-client"))}>
            <div className={bem.e("client-name-wrapper")}>
              <div className={bem.e("client-name-icon")}>
                <div className={bem.e("icon-char")}>
                  {(process.data.fields.course_type &&
                  process.data.fields.course_type.value) && process.data.fields.course_type.value.label
                  }
                </div>
                <div className={bem.e("icon-text")}>Course</div>
              </div>
              <div>
                {/* <div className={bem.e("client-text")}>
                  {this.props.process.data.fields.client_name
                    ? this.props.process.data.fields.client_name.value
                    : <FormattedMessage id="process > client name" />}
                </div> */}
                <div className={bem.e("client-name")}>
                  {this.props.process.data.fields.course_name
                    ? this.props.process.data.fields.course_name.value
                    : 'Course Name'}
                  {/* {spot} */}
                </div>
              </div>
            </div>
            <div className={bem.e("header-right")}>
              {
                countdown && (
                  <div className={classnames(bem.e("header-timer"), {
                    'is-native':countdown.isNegative
                  })}>
                    { countdown.isNegative && <div className={bem.e('overdue')}>
                      <FormattedMessage id="process > overdue" />
                    </div>}
                    <div className={bem.e("header-countdown")}>
                      {
                        countdown.days > 0 && <strong className={bem.e("header-timer-days")}>{countdown.days} days</strong>
                      }
                      <strong className={bem.e("header-time")}>{addLeadingZeros(countdown.hours)}:{addLeadingZeros(countdown.min)} hrs</strong>
                    </div>
                  </div>
                )
              }
              <div className={bem.e("process-status")}>
                {process.data.fields.course_details_status && process.data.fields.course_details_status.value && process.data.fields.course_details_status.value === 'draft' ? 'DRAFT' 
                  : process.data.fields.process_step && process.data.fields.process_step.value
                  ? template.steps[process.data.fields.process_step.value] ? template.steps[process.data.fields.process_step.value].title
                  : process.data.fields.process_step.value.replace(/_/, " ", "gi").toUpperCase()
                  : <FormattedMessage id="process > new spot" />}
                  {/* {process.data.fields.team && <div style={{fontSize:'8px'}}>{'team: '+ JSON.stringify(process.data.fields.team.value)}</div>}
                  {process.data.fields.still_required_users && <div style={{fontSize:'8px'}}>{'still_required_users: '+ JSON.stringify(process.data.fields.still_required_users.value)}</div>}
                  {process.data.fields.next_users && <div style={{fontSize:'8px'}}>{'next_users: '+ JSON.stringify(process.data.fields.next_users.value)}</div>} */}
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* {
            spots.length>0 && <Nav tabs key="spots" style={{borderBottomWidth:'1px'}}>{
              spots.map((spot,ind)=><NavItem key={'spot_'+ind}><NavLink className={classnames({ active: spot.key === process.data.fields.key.value })}
              onClick={() => {this.switchToSpot(spot.key)}}>{spot.component}</NavLink></NavItem>)
              }</Nav>
					} */}
          <div className="container">
            <Nav tabs className={bem.e("tabs")}>
              {Object.keys(template.steps).map((key, ind) => {
                let step = template.steps[key];
                if (ind === activeTab) current_step = step;
                let is_disabled =
                  step.is_disabled &&
                  step.is_disabled(user, process, step);
                //if (!previous_steps_completed) is_disabled = true;
                let is_hidden =
                  step.is_hidden &&
                  step.is_hidden(user, process, step);
                if(!is_hidden && key !== 'log' && step.step_viewers_roles && step.step_viewers_roles.length > 0){
                  let found=false
                  Object.keys(user.roles).map(r=>{if (step.step_viewers_roles.indexOf(parseInt(r))>-1 || step.step_editors_roles.indexOf(parseInt(r))>-1) found=true})
                  if(!found) is_hidden=true
                }
                if(is_hidden && ind === activeTab) active_tab_is_hidden = true
                if(!is_hidden && !first_visible_tab) first_visible_tab = key 
                let state = "undone";
                const iconMap = {
                  course_details: StarIcon,
                  course_details_hover: StarWhiteIcon,
                  participants: TeamIcon,
                  participants_hover: TeamWhiteIcon,
                  attendance: TeamIcon,
                  attendance_hover: TeamWhiteIcon,
                  events: TeamIcon,
                  events_hover: TeamWhiteIcon,
                  log: LogIcon,
                  log_hover: LogWhiteIcon,
                  liner_schedule: LogIcon,
                  liner_schedule_hover: LogWhiteIcon,
                };
                let stateIconDom = null;
                if (this.props.process.data.fields[step.key + "_status"]) {
                  if (
                    this.props.process.data.fields[step.key + "_status"]
                      .value === "draft"
                  ) {
                    state = "draft";
                    stateIconDom = (
                      <span>
                        <FaEdit
                          color={activeTab === ind ? "white" : "orange"}
                        />
                        &nbsp;
                      </span>
                    );
                  } else if (
                    this.props.process.data.fields[step.key + "_status"]
                      .value === "rejected"
                  ) {
                    state = "rejected";
                    stateIconDom = (
                      <span>
                        <FaRepeat
                          color={activeTab === ind ? "white" : "#8165f3"}
                          className={bem.e('tab-checked-icon')}
                        />
                        &nbsp;
                      </span>
                    );
                  } else {
                    state = "done";
                    stateIconDom = (
                      <span>
                        <FaCheck
                          color={activeTab === ind ? "white" : "#8165f3"}
                          className={bem.e('tab-checked-icon')}
                        />
                        &nbsp;
                      </span>
                    );
                  }
                } else {
                  if (activeTab === ind) {
                    stateIconDom = (
                      <span>
                        <img src={iconMap[`${current_step.key}_hover`]} alt="Active" width={14} className={classnames("tabs-icon", current_step.key)} />
                        &nbsp;
                      </span>
                    );
                  } else if (iconMap[step.key]) {
                    stateIconDom = (
                      <span>
                        <img
                          src={iconMap[step.key]}
                          alt="Icon"
                          width={14}
                          className={classnames("tabs-icon", step.key)}
                          ref={rel => this.refTabs[step.key] = rel}
                        />
                        &nbsp;
                      </span>
                    );
                  }
                }

                let component = (
                  <NavItem
                    key={key}
                    {...is_hidden && { hidden: "hidden" }}
                    className={classnames(bem.e("tab_item"), {
                      active: activeTab === ind
                    })}
                    onMouseEnter={(e) => {
                      const tab = this.refTabs[step.key];
                      if(!tab) return null;
                      tab.src = iconMap[`${step.key}_hover`];
                    }}
                    onMouseLeave={(e) => {
                      const tab = this.refTabs[step.key];
                      if(!tab) return null;
                      tab.src = iconMap[`${step.key}`]
                    }}
                  >
                    <NavLink
                      disabled={is_disabled}
                      onClick={() => {
                        this.toggle(ind);
                      }}>
                      {stateIconDom}
                      {step.title}
                    </NavLink>
                  </NavItem>
                );

                if (!is_hidden && (state !== "done" && state !== "completed")) {
                  previous_steps_completed = false;
                }
                return component;
              })}
            </Nav>
          </div>
          <div className="container" style={{position: "relative"}}>
            <div
              style={
                {
                  // minHeight: "70vh"
                }
              }>
              <Row>
                <TabContent activeTab={activeTab} className={"col"}>
                  {Object.keys(template.steps).map((key, ind) => {
                    if (activeTab !== ind) return null;
                    let step = template.steps[key];
                    return (
                      <TabPane tabId={ind} key={"key_" + ind}>
                        <DynamicForm
                          step={step}
                          process={process}
                          template={template}
                          tab_toggle={toggle}
                          match_process_id={this.props.match_process_id}
                        />
                      </TabPane>
                    );
                  })}
                </TabContent>
                {/* <ButtonExpand
                  activeTab={process.activeTab}
                  defaultExpand={this.props.isCollapse}
                  onToggleCallback={this.props.onSetCollapse}
                  className={classnames("hidden-md hidden-sm hidden-xs")}
                /> */}
                {/* {(!step.hide_chat) && */}
                {/* <div
                  className={classnames(
                    "col hidden-md hidden-sm hidden-xs custom-collapse",
                    {
                      expanded: this.props.isCollapse,
                      unexpanded: !this.props.isCollapse
                    }
                  )}>
                  <span
                    className="targetCollapse"
                    style={{
                      // background: "#fff",
                      // position: "fixed",
                      width: "326px",
                      // top: "50vh",
                      // marginTop: "-50px"
                    }}>
                    <Chat
                      step={current_step}
                      process={process}
                      template={template}
                      user={user}
                    />
                  </span>
                </div> */}
                {/* } */}
              </Row>
            </div>
						{process.spotSwitching && (
							<Row style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.2)'}}>
								<Col className="d-flex align-items-center justify-content-center">
									<FaSpinner size="40" className={"cr-icon-spin"} />
								</Col>
							</Row>
						)}
          </div>
        </div>
        {(process.loading && !process.spotSwitching) && (
          <Row style={{ position: "fixed", top: "48vh", left: "48vw" }}>
            <Col className="d-flex align-items-center justify-content-center">
              <FaSpinner size="40" className={"cr-icon-spin"} />
            </Col>
          </Row>
        )}
      </div>
    );

    if(active_tab_is_hidden){
      if(first_visible_tab){
        this.props.setActiveTab(first_visible_tab)
      }
      return null
    }

    return tabs;
  }



}

export default Tabs;
