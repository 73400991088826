import * as React from "react";
import PropTypes from "prop-types";
import ChatInput from "components/ChatInput";
import MessageList from "./MessageList";
import Message from "./Message";
import bn from "utils/bemnames";
import { FormattedMessage } from "react-intl";
import Dropdown from "components/Dropdown";
import UploadFile from 'components/UploadFile/index';
import Select from "react-select";
import { Row, Col, Label } from 'reactstrap';

const bem = bn.create("chatbox");

class ChatBox extends React.Component {
  all_option={label:'All combined',value:{type:'all'}}
  my_option={label:'My wall',value:{type:'my'}}

  constructor(props) {
    super(props);
    const sendTargets=this.getSendTargets();
    this.state = {
      sendTargets: sendTargets, //props.sendTargets ? props.sendTargets : [{label:'Studio',id:'Studio'},{label:'Class - Jazz',id:'Class - Jazz'},{label:'Class - Jazz - Instructor',id:'Class - Jazz - Instructor'}],
      views:  sendTargets, //props.sendTargets ? props.sendTargets :  [{label:'Studio',id:'Studio'},{label:'Class - Jazz',id:'Class - Jazz'},{label:'Class - Jazz - Instructor',id:'Class - Jazz - Instructor'}],
      showFrom: props.filter ? props.filter : this.all_option,
      sendTo: props.filter ? props.filter : sendTargets[0],
    }
    this.sendTargets = sendTargets
  }
  
  getSendTargets(){
    var props=this.props;
    let info = props.auth.info;
    let user = props.auth.user;
    let list = [];

    // info.users.map(user_to=> {if(user_to.id !== user.id) list.push({name: user_to.name, avatar_url:user_to.avatar_url, type:'user', sendTo:{id:user_to.id,type:'user',name: user_to.name, avatar_url:user_to.avatar_url} })});
    // if(info.studios) info.studios.map(studio=> list.push({name: 'Studio: '+ studio.studio_key, avatar_url:'', type:'studio', sendTo:{studio_key:studio.key,studio_id:studio.studio_id,type:'studio', name: studio.studio_key, avatar_url:studio.avatar_url || '' }}));
    // if(info.courses) info.courses.map(course=> list.push({name: 'Course: '+ course.course_name , avatar_url:course.avatar_url || '', type:'course', sendTo:{id:course.id,type:'course', name:'Course: '+ course.course_name, avatar_url:course.avatar_url || '' }}));

    info.users.map(user_to => {
      if (user_to.id !== user.id)
        list.push({
          name: user_to.name,
          avatar_url: user_to.avatar_url,
          type: 'user',
          sendTo: {
            id: user_to.id,
            type: 'user',
            name: user_to.name,
            avatar_url: user_to.avatar_url,
          },
        });
    });
    if (info.studios)
      info.studios.map(studio =>
        list.push({
          name: 'Studio: ' + studio.studio_key,
          type: 'studio',
          sendTo: {
            studio_key: studio.key,
            studio_id: studio.studio_id,
            type: 'studio',
            name: studio.studio_key,
            avatar_url: studio.avatar_url || '',
          },
        })
      );
    if (info.courses)
      info.courses.map(course =>
        list.push({
          name: 'Course: ' + course.course_name,
          type: 'course',
          sendTo: {
            id: course.id,
            type: 'course',
            name: 'Course: ' + course.course_name,
            avatar_url: course.avatar_url || '',
          },
        })
      );
    list = list.map(target => {return { label:target.name, value:target.sendTo, ...target}});
    // var instructor_options = []
    // var courses_options = []
    // const studios_options = props.auth.info.studios.map(s=> {return {label:'Studio: ' + s.studio_key, value:{type:'studio',studio_key:s.key,studio_id:s.studio_id }}})
    // for (var studio_ind = 0; props.auth.info.studios && studio_ind<props.auth.info.studios.length;studio_ind++){
    //   const studio=props.auth.info.studios[studio_ind]
    //   if(studio.user_active_courses ){

    //     studio.user_active_courses.map(c=>{
    //       courses_options.push({label:'Studio: ' + studio.studio_key + ', Course: ' + c._source.course_name,value: {type:'course',key:c._id,studio_key:studio.key, studio_id:studio.studio_id} })
    //       return true
    //     })
    //   }  

    //   for (var user_ind = 0; studio.studio_users && user_ind<studio.studio_users.length;user_ind++){
    //     const user=studio.studio_users[user_ind]
    //     const sub_studio = user.studios.find(s=>s.key===studio.key)
    //     if(sub_studio){
    //       // if(sub_studio.user_active_courses && user.id===this.props.user.id){

    //       //   sub_studio.user_active_courses.map(c=>{
    //       //     courses_options.push({label:'Studio: ' + sub_studio.studio_key + ', Course: ' + c._source.course_name,value: {type:'course',id:c._id,studio_key:studio.key, studio_id:studio.studio_id} })
    //       //     return true
    //       //   })
    //       // }  
    //       if (Object.keys(sub_studio.user_roles).indexOf('3')>-1){ // instructor
    //         instructor_options.push({label:'Studio: ' + sub_studio.studio_key + ', Instructor: ' + user.name, value:{type:'user',id:user.id,studio_key:studio.key, studio_id:studio.studio_id}})// {id:user.id, avatar_url:user.avatar_url, name:user.name }}})
    //       }
    //     }

    //   }
    // }

    // var sendTargets=[].concat([this.my_option],studios_options,courses_options,instructor_options)
    return list
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.match.params.filter===this.props.match.params.filter) return
    const filter = nextProps.match.params.filter;
    let showFrom = this.all_option
    switch(filter){
      case 'my':{
        showFrom = this.my_option
        this.setState({showFrom,
          sendTo:this.my_option})
        break
      }
      default:{
        showFrom = this.all_option
        this.setState({showFrom,
          sendTo:this.sendTargets[0]})
        break
      }
    }
    this.props.onScope(showFrom.value)
  }
  render() {
    const {
      messages,
      minimized,
      style,
      submitOnEnter,
      hideScopes
    } = this.props;
    return (
      <div className={bem.b()} style={style}>
          {!hideScopes && <Row style={{ margin: '-49px -64px 0 -64px ' }}>
          {/* <Col>
            <Label className="float-right">Wall:</Label>
          </Col> */}
          <Col>
            <Dropdown
              value={this.state.showFrom}
              onChange={selectedOption => {
                this.setState({showFrom:selectedOption})
                if(selectedOption.value.type!=='all'){
                  this.setState({sendTo:selectedOption})
                }
                this.props.onScope(selectedOption.value)
              }}
              options={[this.all_option,...this.state.views]}
              name="showfrom"
            />
          </Col>
        </Row>}
        {!minimized && (
          <ChatInput
            onSubmit={(value)=> { this.props.onSubmit({value,sendTo:this.state.sendTo.value }) } }
            submitOnEnter={submitOnEnter}
          />
        )}
        {!hideScopes && <Row style={{ marginTop: '10px' }}>
        <Col>
         <Label className="float-right">Send messages to:</Label>
        </Col>
        <Col>
          <Dropdown
            value={this.state.sendTo}
            onChange={selectedOption => {
              this.setState({sendTo:selectedOption})
            }}
            options={this.state.sendTargets}
            name="sendto"
          />
          </Col>
        </Row>}

        

        {!minimized && (
          <MessageList messages={messages} process={this.props.process}  
          user={this.props.user}
          allowReply={true}
          onSubmit={(value,sendTo)=> { this.props.onSubmit({value,sendTo }) } }/>
        )}
      </div>
    );
  }
}

ChatBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  showNavbar: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.shape(Message.propTypes)),
  minimized: PropTypes.bool,
  onClick: PropTypes.func,
  toggleMinimized: PropTypes.func,
  onSubmit: PropTypes.func,
  submitOnEnter: PropTypes.bool
};

ChatBox.defaultProps = {
  title: "Wall",
};

export default ChatBox;
