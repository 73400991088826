import * as React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import moment from "moment";
import {get, reverse } from "lodash";
import Avatar from "../Avatar";
import {
  FaSpinner,
  FaClose,
  FaMailForward
} from "react-icons/lib/fa";
import { getSortUserName } from "utils/helpers"
import { toHHMMSS } from "utils/helpers";
import { DateTimeFormat } from "utils/config";
import FormattedTaggedMessage from '../FormattedTaggedMessage'
import ChatInput from "components/ChatInput";
import MessageList from "./MessageList";
import bn from "utils/bemnames";
const bem = bn.create("notifications");

class Message extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reply:false
    };
  }

  onClickSeek = (e) => {
    e.preventDefault();
    const { data } = this.props;


    const player = window.player;
    if(!get(player, "player.isPlaying")){
      window.setPlaying(true)
      // set delay for init player on first time
      setTimeout(() => {
        player.seekTo(parseFloat(get(data, "player_played_seconds", 0)));
      }, 10);
    }else{
      player.seekTo(parseFloat(get(data, "player_played_seconds", 0)));
    } 
  }

  getPrettyTimeForPlayed = () => {
    const { data } = this.props;
    if(!get(data, "include_timestamp")) return null;
    return (
      <span
        className={cx("message-played")}
        onClick={this.onClickSeek}
      >
        {toHHMMSS(get(data, "player_played_seconds", 0))}
      </span>
    )
  }
  getPrettyTime = () => {
    const { timestamp } = this.props;
    const currentTime = new Date().getTime();
    const diffInSec = (currentTime - timestamp) / 1000;
    if (diffInSec <= 3600 * 24 * 2) {
      return moment(timestamp).fromNow();
    } else {
      return moment(timestamp).format(DateTimeFormat);
    }
  };

  render() {
    const {
      position,
      from,
      isLastInGroup,
      isFirstInGroup,
      status,
      id,
      text,
      allowReply,
      onSubmit,
      message,
      user
    } = this.props;
    const time = this.getPrettyTime();
    const timePlayed = this.getPrettyTimeForPlayed();
    const messages=message.message && message.message.replys ? (message.message.replys.map(message=>{ 
      const isSender = message.from.id === user.id;
      let data = {
        id,
        text: message.chat,
        data: message,
        from: message.from,
        timestamp: message.created_ts * 1000,
        status: message.status || "delivered",
        position: isSender ? "right" : "left",
        message
      }
      return data;
    })) : false;
    return (
      <div>
        <div
        className={cx("message",`${id}`,{
          mine: position === "right",
          yours: position === "left"
        })}
        id={id}
      >
        {position === "left" && (
          <div className={"from"}>
            {
              from.avatar_url
              ? (
                <Avatar
                  src={from.avatar_url}
                  size={40}
                  className={cx("message-avatar")}
                  alt={get(from, "name")}
                />
              ): (
                <div className={cx("message-avatar-text")}>{getSortUserName(get(from, "name", ""))}</div>
              )
            }
          </div>
        )}
        <div className={cx("message-content")}>
          <div
            className={cx("text", {
              last: isLastInGroup,
              first: isFirstInGroup
            })}>
            <span>
              {status === "waiting" && (
                <span style={{ marginLeft: "-14px", paddingRight: "4px" }}>
                  <FaSpinner size="10" className={"cr-icon-spin"} />
                </span>
              )}
              {timePlayed}
              <FormattedTaggedMessage text={text} />
            </span>
          </div>
          {status !== "waiting" && <div className={"time"}>{time}</div>}
        </div>
        {position === "right" && (
          <div className={"from"}>
            <Avatar
              src={from.avatar_url}
              size={40}
              className={cx("message-avatar")}
              alt={get(from, "name")}
            />
          </div>
        )}
        </div>
        {allowReply && <div>
          {messages && <div className="pl-5"><MessageList messages={messages} allowReply={false}/></div>}
          {!this.state.reply ? <button
              className={bem.e("btn-reply")}
              onClick={(e) => this.setState({reply:true})}
            >
              <FaMailForward />
            </button> :
            <div className="pb-4">
              <ChatInput
                placeholder="Reply"
                onSubmit={(value)=> { onSubmit({...value, inReplyTo:id },message.message.sendTo) } }
                submitOnEnter={true}
              />
            </div>
          }</div>

        }
      </div>
    );
  }
}

Message.propTypes = {
  id: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["left", "right"]),
  text: PropTypes.string.isRequired,
  data: PropTypes.object,
  timestamp: PropTypes.any.isRequired,
  status: PropTypes.oneOf(["waiting", "delivered"]),
  from: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    avatar: PropTypes.string
  }),
  isLastInGroup: PropTypes.bool,
  isFirstInGroup: PropTypes.bool
};

export default Message;
