import React, {Component} from "react";
import {Button, Input, Label} from "reactstrap";
import {stateToHTML} from "draft-js-export-html";
import {injectIntl, FormattedMessage} from "react-intl";
import {differenceWith, get, isEqual, concat} from "lodash";
import SendMailDialog from "components/SendMailDialog";
import {API_URL} from "../../utils/config";
import SelectTeam from "components/SelectTeam";
// import DropdownCustomOption from "components/DropdownCustomOption";
import Dropdown from "components/Dropdown";
import UploadFile from "components/UploadFile";
import SelectStudios from "../SelectStudios";
import ToastManager from "components/ToastManager";
import TextareaEvaluator from "components/TextareaEvaluator";
import ConfirmationModal from "components/ConfirmationModal";
import Player from "components/Player";
import Image from "components/Image";
import ActivityLog from "components/ActivityLog";
import TextInput from "components/TextInput";
import Checkbox from "components/Checkbox";
import FormFactory from "./formFactory";
// import history from "components/History";
import {SectionTitle, Asterisk} from "components/Elements";
import Participants from "components/Participants";
import Attendance from "components/Attendance";
import CreateClass from "../ProcessesList/Classes/CreateClass";
import isArray from 'lodash/isArray';

class ComponentFactory extends Component {
    state = {
        contentState: null,
        studiosWithoutSpots: null,
        confirmationDialogProps: null,
    };

    render() {
        let factoryDom = null;
        switch (this.props.ui_hint) {
            case "form":
                factoryDom = (
                    <FormFactory
                        {...this.props}
                        valueChanged={this.valueChanged}
                        buttonClicked={this.buttonClicked}
                        validationState={this.props.validationState}
                    />
                );
                break;
            default:
                factoryDom = this.factory(
                    this.props.step,
                    this.props.item,
                    this.props.process,
                    this.props.template,
                    this.props.user
                );
        } //(this.props.component_index === 0 ?
        return (<React.Fragment>
                {factoryDom}
                <ConfirmationModal
                    {...this.state.confirmationDialogProps}
                    isOpen={!!this.state.confirmationDialogProps}
                />
            </React.Fragment>
        )
        // : factoryDom

    }

    getRoleCandidates(item, process, template, user) {
        if (
            !process ||
            !process.data ||
            !process.data.fields ||
            !process.data.fields.course_studios ||
            !item
        )
            return;
        let info = this.props.info;
        let studios = process.data.fields.course_studios.value;
        let roles = item.roles;
        // only studios targeted in this campaign
        let studios_info = info.studios.filter(
            e => studios.indexOf(e.studio_key) > -1
        );
        // only users linked to all those studios
        let users_info = [];
        let calc_studio_info = {};
        let calc_user_info = {};
        for (let i = 0; i < studios_info.length; i++) {
            let studio_info = studios_info[i];

            for (let i1 = 0; i1 < studio_info.studio_users.length; i1++) {
                let studio_user = studio_info.studio_users[i1];

                // for (let i2 = 0; i2 < studio_user.studios.length; i2++) {
                //   let user_studios = studio_user.studios[i2];

                for (let i3 = 0; i3 < studio_user.studios.length; i3++) {
                    let user_studio = studio_user.studios[i3];
                    let studio_key = user_studio.studio_key;
                    if (studios.indexOf(studio_key) === -1) continue;

                    for (
                        let i4 = 0;
                        i4 < Object.keys(user_studio.user_roles).length;
                        i4++
                    ) {
                        let user_role_id = Object.keys(user_studio.user_roles)[i4];
                        if (roles.indexOf(parseInt(user_role_id)) > -1) {
                            if (!calc_studio_info[studio_key])
                                calc_studio_info[studio_key] = [];
                            if (
                                calc_studio_info[studio_key].indexOf(studio_user.id) === -1
                            )
                                calc_studio_info[studio_key].push(studio_user.id);
                            if (!calc_user_info[studio_user.id])
                                calc_user_info[studio_user.id] = [];
                            if (
                                !calc_user_info[studio_user.id].find(
                                    si => si.studio_key === studio_key
                                )
                            )
                                calc_user_info[studio_user.id].push({
                                    studio_key,
                                    user_role_id
                                });
                        }
                    }
                }
                // }
            }
        }
        let valid_users = [];
        for (let i = 0; i < Object.keys(calc_user_info).length; i++) {
            let user_id = Object.keys(calc_user_info)[i];
            let user_studios = calc_user_info[user_id];
            if (user_studios.length === studios.length) {
                if (valid_users.indexOf(user_id) === -1) {
                    valid_users.push(user_id);
                }
            }
        }
        for (let i = 0; i < valid_users.length; i++) {
            let user_id = valid_users[i];
            let user = info.users.find(u => u.id === parseInt(user_id));
            if(user)
                users_info.push({
                value: user_id,
                label: user.name,
                role: calc_user_info[user_id][0].user_role_id,
                info: info.users.find(u => u.id === parseInt(user_id))
            });
        }

        // studios_info.map(studio_info=>
        //     studio_info.studio_users.map(studio_user=>{
        //         let role = 0
        //         if (studio_user.studios.filter(us=>{
        //             // studio is targeted
        //             let user_studios=us.map(ut=>ut.studio_key)
        //             let user_roles=us.map(ut=>ut.studio_key)
        //             if (studios.filter(studio=>user_studios.has(studio)).length===-1) return false
        //             // user holds the role in that studio
        //             if (Object.keys(us.user_roles)
        //                 .filter(r=>{
        //                     if (roles.indexOf(parseInt(r))>-1) {
        //                         role=parseInt(r);
        //                         return true
        //                     } return false
        //                 }).length >0) return true
        //                 return false
        //             // user holds the role in as many studios as specified
        //             }).length >= studios.length)
        //             {
        //                 // don't add more than once
        //                 if (users_info.map(u=>u.name).indexOf(u.name)===-1)
        //                 users_info.push({...u,role})
        //             }
        //             return true
        //         }
        //     )
        // )
        return users_info; //.map(u=>{return {value: u.id, label: u.name, role: u.role}})
    }

    valueChanged = (item, process, template, user, event) => {
        try {
            this.props.clearValidationError(item);
            this.props.formValueChanged({
                process,
                template,
                item,
                user,
                value: !event ? "" : Array.isArray(event) ? event : event.value !== undefined ? event.value : event.target.value
            });
        } catch (e) {
        }
    }

    valueEvaluatorChanged = (
        item,
        process,
        template,
        user,
        value,
        contentState
    ) => {
        this.props.clearValidationError(item);
        this.props.formValueChanged({
            process,
            template,
            item,
            user,
            value
        });
        this.setState({
            contentState
        });
    };
    spotsChanged = (item, process, template, user, event) => {
        this.props.clearValidationError(item);
        if (!process.data.fields.key) {
            ToastManager.show({
                title: this.props.intl.formatMessage({id: "toast > title not saved"}),
                message: this.props.intl.formatMessage({id: "toast > message please save the order before adding new spots"}),
                level: "error"
            });
            return;
        }
        let spots = process.data.fields.spots
            ? process.data.fields.spots.value
            : [process.data.fields.key.value];
        if (event === "+1") event = {target: {value: spots.length + 1}};
        if (
            spots.length > parseInt(event.target.value) &&
            spots[spots.length - 1] !== "new"
        ) {
            ToastManager.show({
                title: this.props.intl.formatMessage({id: "toast > title cant delete a saved spot"}),
                message: this.props.intl.formatMessage({id: "toast > message spots cannot be reduced here, you can delete a spot from the processes screen"})
            });
            return;
        }
        while (spots.length < parseInt(event.target.value)) {
            spots.push("new");
        }
        spots = spots.slice(0, parseInt(event.target.value));
        this.props.formValueChanged({
            process,
            template,
            item: {field: "spots"},
            user,
            value: spots
        });
        this.props.formValueChanged({
            process,
            template,
            item,
            user,
            value: event.value || event.target.value
        });
    };
    checkboxChanged = (item, process, template, user, event) => {
        this.props.clearValidationError(item);
        this.props.formValueChanged({
            process,
            template,
            item,
            user,
            value: event.target.checked
        });
    };

    checkboxGroupChanged = (item, process, template, user, event) => {
        this.props.clearValidationError(item);
        var value = event
        // var value = process.data.fields[item.field]
        //   ? [...process.data.fields[item.field].value]
        //   : [];
        // if (value.indexOf(event.target.value) > -1) {
        //   let ind = value.indexOf(event.target.value);
        //   value = [...value.slice(0, ind), ...value.slice(ind + 1)];
        // } else {
        //   value.push(event.target.value);
        // }
        // if (value.length === 0)
        //   ToastManager.show({
        //     title: this.props.intl.formatMessage({id: "toast > title can't uncheck"}),
        //     message: this.props.intl.formatMessage({id: "toast > message at least one studio must be selected"}),
        //     level: "warning"
        //   });
        // else
        this.props.formValueChanged({process, template, item, user, value});
    };

    multipleStudioSelectionChanged = (item, process, template, user, value) => {
        // if (value.length === 0)
        //   ToastManager.show({
        //     title: this.props.intl.formatMessage({id: "toast > title can't unselect"}),
        //     message: this.props.intl.formatMessage({id: "toast > message at least one studio must be selected"}),
        //     level: "warning"
        //   });
        // else
        this.props.clearValidationError(item);
        this.props.formValueChanged({process, template, item, user, value});
    };

    recomputeRoles = (step, item, process, template, user) => {
        let still_required_users = [];
        let next_users = [];
        // let still_required_roles =
        //   item.required_roles_func &&
        //   safeEval(item.required_roles_func)(user, template, process, step, item);
        // let next_roles =
        //   item.next_role_func &&
        //   safeEval(item.next_role_func)(user, template, process, step, item);

        let next_roles = template.steps[process.data.fields.process_step.value] ? template.steps[process.data.fields.process_step.value].step_roles : null;
        let still_required_roles = this.getStillRequiredRoles(user, template, process, step, item)
        let team = [];
        if(process.data.fields.participants && process.data.fields.participants.value) team = process.data.fields.participants.value;
        const equal = (arr1, arr2) => {
            if (!arr1) arr1 = [];
            if (!arr2) arr2 = [];
            arr1.length === arr2.length &&
            arr1.every(i => arr2.includes(i)) &&
            arr2.every(i => arr1.includes(i));
        }
        // if(!still_required_roles && !next_roles) return
        Object.keys(process.data.fields).map(f => {
            if (f.indexOf("role_") === 0) {
                let assignee = process.data.fields[f].value;
                let user_id = parseInt(assignee.id);
                let user_role_id = parseInt(assignee.role);
                if (team.indexOf(user_id) === -1) team.push(user_id);
                if (
                    still_required_roles &&
                    still_required_roles.indexOf(user_role_id) > -1 &&
                    still_required_users.indexOf(user_id) === -1
                )
                    still_required_users.push(user_id);
                if (
                    next_roles &&
                    next_roles.indexOf(user_role_id) > -1 &&
                    next_users.indexOf(user_id) === -1
                )
                    next_users.push(user_id);
            }
            return false;
        });
        var changed_still_required_users = []
        if (
            still_required_roles &&
            (!process.data.fields.still_required_users ||
                process.data.fields.still_required_users.value ||
                !equal(
                    still_required_users,
                    process.data.fields.still_required_users.value
                ))
        ) {
            if (!process.data.loaded_fields.still_required_users) changed_still_required_users = still_required_users
            else changed_still_required_users = [...differenceWith(still_required_users, process.data.loaded_fields.still_required_users.value, isEqual), ...differenceWith(process.data.loaded_fields.still_required_users.value, still_required_users, isEqual)]
            this.props.formValueChanged({
                process,
                template,
                item: {field: "still_required_users"},
                user,
                still_required_users
            });
            process.data.fields.still_required_users = {
                value: still_required_users,
                is_dirty: true
            };
        }
        var changed_next_users = []
        if (
            next_roles &&
            (!process.data.fields.next_users ||
                !equal(next_users, process.data.fields.next_users.value))
        ) {
            if (!process.data.loaded_fields.next_users) changed_next_users = next_users
            else changed_next_users = [...differenceWith(next_users, process.data.loaded_fields.next_users.value, isEqual), ...differenceWith(process.data.loaded_fields.next_users.value, next_users, isEqual)]
            this.props.formValueChanged({
                process,
                template,
                item: {field: "next_users"},
                user,
                next_users
            });
            process.data.fields.next_users = {value: next_users, is_dirty: true};
        }
        var changed_team = []
        if (
            team &&
            (!process.data.fields.team ||
                !equal(team, process.data.fields.team.value))
        ) {
            if (!process.data.loaded_fields.team) changed_team = team
            else changed_team = [...differenceWith(team, process.data.loaded_fields.team.value, isEqual), ...differenceWith(process.data.loaded_fields.team.value, team, isEqual)]
            this.props.formValueChanged({
                process,
                template,
                item: {field: "team"},
                user,
                team
            });
            process.data.fields.team = {value: team, is_dirty: true};
        }

        var all_changed_users = [...changed_still_required_users, ...changed_next_users, ...changed_team].filter(function (item, i, ar) {
            return ar.indexOf(item) === i;
        })
        this.props.formValueChanged({
            process,
            template,
            item: {field: "all_changed_users"},
            user,
            all_changed_users
        });
        process.data.fields.all_changed_users = {value: all_changed_users, is_dirty: true};

    };

    getStillRequiredRoles = (user, template, process, step, item) => {
        let still_required = [];
        Object.keys(template.steps).map((key, ind) => {
            let step = template.steps[key];
            // let is_disabled =
            //   step.is_disabled &&
            //   safeEval(step.is_disabled)(user, process, step);
            let is_hidden =
                step.is_hidden &&
                step.is_hidden(user, process, step);
            let state = "undone";
            if (process.data.fields[step.key + "_status"]) {
                if (process.data.fields[step.key + "_status"].value === "draft") state = "draft";
                else if (process.data.fields[step.key + "_status"].value === "rejected") state = "rejected";
                else state = "done";
            }
            if (!is_hidden && state !== 'done') {
                still_required = concat(still_required, get(step, 'step_roles', []));
            }
            return key;
        })
        let res = []
        still_required.map(e => {
            if (res.indexOf(e) === -1) res.push(e);
            return true
        })
        return res
    }

    firstUndoneStep = (user, template, process, step, item) => {
        let firstUndone = 'done';
        Object.keys(template.steps).map((key, ind) => {
            let step = template.steps[key];
            // let is_disabled =
            //   step.is_disabled &&
            //   safeEval(step.is_disabled)(user, process, step);
            let is_hidden =
                step.is_hidden &&
                step.is_hidden(user, process, step);
            let state = "undone";
            if (process.data.fields[step.key + "_status"]) {
                if (process.data.fields[step.key + "_status"].value === "draft") state = "draft";
                else if (process.data.fields[step.key + "_status"].value === "rejected") state = "rejected";
                else state = "done";
            }
            if (!is_hidden && firstUndone === 'done' && state !== 'done' && step.key !== 'log') firstUndone = step.key
            return key;
        })
        return firstUndone
    }
    buttonClicked = (step, item, process, template, user, event, callback) => {
        if (item.on_click) {
            let valid = true;
            if (item.on_click.validate) {
                try {
                    valid = this.props.validate(step, item, process, template, user);
                } catch (e) {
                    if (item.draft) {
                        valid = true
                    } else {
                        ToastManager.show({
                            title: this.props.intl.formatMessage({id: "toast > title not saved"}),
                            message: e.message,
                            level: "error"
                        });
                        if (document.location.href.indexOf('#testing') === -1) return;
                        valid = true
                    }
                }
            }
            if (valid) {
                if (item.confirmation_dialog_props && !this.state.confirmationDialogProps && !item.draft) {
                    this.setState({
                        confirmationDialogProps: {
                            ...item.confirmation_dialog_props,
                            onConfirm: () => {
                                this.buttonClicked(
                                    step,
                                    item,
                                    process,
                                    template,
                                    user,
                                    {}
                                )
                            },
                            onCancel: () => {
                                this.setState({confirmationDialogProps: null})
                            }
                        }
                    })
                    return;
                }
            }


            if (item.on_click.go_to_step && valid) {
                this.props.tab_toggle(item.on_click.go_to_step);
            }
            if (item.on_click.save) {
                // add the step status
                // if (!process.data.fields[step.key + "_status"]) {
                this.props.formValueChanged({
                    process,
                    template,
                    item: {field: step.key + "_status"},
                    user,
                    value: valid && !item.draft ? "completed" : "draft"
                });
                process.data.fields[step.key + "_status"] = {
                    value: valid && !item.draft ? "completed" : "draft",
                    is_dirty: true
                };
                // }

                // add class studio
                // it is not selected by the user
                // each user has just one studio
                // and that is what we use

                
                this.props.formValueChanged({
                    process,
                    template,
                    item: {field: "course_studios"},
                    user,
                    value: [user.studios[0].studio_id]
                });
                process.data.fields["course_studios"] = {
                    value: [user.studios[0].studio_id],
                    is_dirty: true
                };


                if (item.fields && !item.draft // && valid
                ) {
                    item.fields.map(field => {
                        if (!field.value) return false;
                        let value = field.value;
                        if (typeof (value) === 'function')
                            value = field.value(user, template, process, step, item);
                        if (field.key === 'process_step' && field.value === 'first_undone')
                            value = this.firstUndoneStep(user, template, process, step, item)
                        this.props.formValueChanged({process, template, item: {field: field.key}, user, value});
                        process.data.fields[field.key] = {value: value, is_dirty: true};
                        return false;
                    });
                }

                const ignored_fields = get(item, 'ignored_fields', []) || [];
                if (isArray(ignored_fields)) {
                    ignored_fields.map(item => {
                        delete process.data.fields[item];
                        return true;
                    })
                }

                // if (!process.data.fields.next_users ||
                //     !process.data.fields.next_users.value) process.data.fields.next_users = {value: []}

                // if (valid && !item.draft) {
                //     this.recomputeRoles(step, item, process, template, user);
                // } else if (
                //     !process.data.fields.next_users ||
                //     !process.data.fields.next_users.value.includes(user.id)
                // ) {
                //     // draft saved, current user must be in the next users list
                //     let next_users = process.data.fields.next_users
                //         ? [...process.data.fields.next_users.value, user.id]
                //         : [user.id];
                //     this.props.formValueChanged({
                //         process,
                //         template,
                //         item: {field: "next_users"},
                //         user,
                //         next_users
                //     });
                //     process.data.fields.next_users = {
                //         value: next_users,
                //         is_dirty: true
                //     };
                // }
                let success_message =
                    valid && !item.draft
                        ? ToastManager.show.bind(this, {
                            title: this.props.intl.formatMessage({id: "toast > title saved"}),
                            message: this.props.intl.formatMessage({id: "toast > message all good"}),
                            level: "info"
                        })
                        : ToastManager.show.bind(this, {
                            title: this.props.intl.formatMessage({id: "toast > title draft saved"}),
                            message: valid ? "" : this.props.intl.formatMessage({id: "toast > message please make the required corrections"}),
                            level: "warning"
                        });
                if (item.success_dialog_props) {
                    success_message = () => {
                        this.setState({
                            confirmationDialogProps: {
                                ...(!valid ? {
                                        "title": this.props.intl.formatMessage({id: "toast > title saved as draft"}),
                                        "state": "success"
                                    } :
                                    item.draft ? {
                                        "title": this.props.intl.formatMessage({id: "toast > title saved"}),
                                        "state": "success"
                                    } : item.success_dialog_props),
                                onCancel: () => {
                                    this.setState({confirmationDialogProps: null})
                                }
                            }
                        })

                    }
                }
                let success_callback = response => {
                    this.setState({confirmationDialogProps: null});
                    if (response.data.alert_contract_missing_spots) this.alertContractMissingSpots(response.data)
                    success_message();
                    // if(valid && !item.draft) history.replace("/processes/view/" + template.key + "/" + response.data.key);
                };
                if (callback) success_callback = callback
                //this.props.formValueChanged.bind(this, { process, template, item, user, value: "saved" })
                this.props.saveFormValues({
                    process,
                    template,
                    step,
                    user,
                    success_callback,
                    valid: valid && !item.draft
                });
                if (valid && !item.draft) this.forceUpdate();
                this.setState({confirmationDialogProps: null});
            }
            // }
        }
    };

    alertContractMissingSpots(response_data) {
        let studios = response_data.alert_contract_missing_spots
        this.setState({studiosWithoutSpots: studios})
    }

    filesUploaded = (item, process, template, user, event) => {
        let value = [...(process.data.fields[item.field] ? process.data.fields[item.field].value : []), ...event];
        this.props.clearValidationError(item);
        this.props.formValueChanged({process, template, item, user, value});
    };

    fileUploaded = (item, process, template, user, event) => {
        this.props.clearValidationError(item);
        this.props.formValueChanged({
            process,
            template,
            item,
            user,
            value: event.path || event
        });
    };

    fileDeleted = (item, process, template, user, event) => {
        this.props.clearValidationError(item);
        this.props.formValueChanged({process, template, item, user, value: ""});
    };

    filesDeleted = (item, process, template, user, event) => {
        this.props.clearValidationError(item);
        this.props.formValueChanged({
            process,
            template,
            item,
            user,
            value: event
        });
    };

    factory = (step, item, process, template, user) => {
        const isView = document.location.pathname.split("/")[2] === "view";
        switch (item.component) {
            case "class_details":
            return (
                <CreateClass
                    valueChanged={this.valueChanged}
                    buttonClicked={this.buttonClicked}
                    step={step}
                    process={process}
                    template={template}
                    user={user}
                    info={this.props.info}
                    validationState={this.props.validationState}
                    // {...isView && {mode: "view"}}
                    {...item.props}
                />
            );
            case "attendance":
                return (
                    <Attendance
                        valueChanged={this.valueChanged}
                        step={step}
                        process={process}
                        template={template}
                        user={user}
                        info={this.props.info}
                        roles={['Student']}
                        {...isView && {mode: "view"}}
                        {...item.props}
                    />
                );
            case "participants":
                return (
                    <Participants
                        valueChanged={this.valueChanged}
                        step={step}
                        process={process}
                        template={template}
                        user={user}
                        info={this.props.info}
                        roles={item.roles}
                        {...isView && {mode: "view"}}
                        {...item.props}/>
                );
            case "send_mail":
                return !item.is_hidden(user, process, step) && (
                    <SendMailDialog
                        {...item.props}
                        step={step}
                        process={process}
                        template={template}
                        user={user}
                    />
                )
            case "select_team":
                return (
                    <SelectTeam
                        {...item.props}
                        valueChanged={this.valueChanged}
                        step={step}
                        process={process}
                        template={template}
                        user={user}
                        validationState={this.props.validationState}
                        buttonClicked={this.buttonClicked}
                        getRoleCandidates={this.getRoleCandidates.bind(this)}
                        info={this.props.info}
                        {...isView && {mode: "view"}}
                    />
                );
            case "label":
                return <div {...item.props}>{process.data.fields[item.field]
                    ? process.data.fields[item.field].value && process.data.fields[item.field].value.split ? process.data.fields[item.field].value.split('\n').map((l, i) =>
                            <div key={i}>{l}</div>)
                        : item.value
                    : item.value
                        ? item.value
                        : ""}
                </div>
            case "div":
                return (
                    <div {...item.props}>
                        {process.data.fields[item.field]
                            ? process.data.fields[item.field].value && process.data.fields[item.field].value.split ? process.data.fields[item.field].value.split('\n').map((l, i) =>
                                    <div key={i}>{l}</div>)
                                : item.value
                            : item.value
                                ? item.value
                                : ""}
                    </div>
                );
            case "checkbox":
                return (
                    <Checkbox
                        {...item.props}
                        checked={
                            process.data.fields[item.field] &&
                            process.data.fields[item.field].value
                                ? true
                                : false
                        }
                        onChange={(checked) => {
                            const event = {target: {checked}}
                            this.checkboxChanged(
                                item,
                                process,
                                template,
                                user,
                                event
                            )
                        }}
                        {...isView && {disabled: true}}
                    />
                );
            case "title":
                return <SectionTitle>{item.value}</SectionTitle>
            case "text":
                // if (process.fields[step.key + '_status'] && process.fields[step.key + '_status'].value === 'saved')
                return isView ? (
                    <div className={item.class}>
                        {process.data.fields[item.field]
                            ? process.data.fields[item.field].value
                            : ""}
                    </div>
                ) : (
                    <span>
            <TextInput
                label={
                    <span>
                  {item.title}{item.mandatory && <Asterisk>*</Asterisk>}
                </span>
                }
                {...item.props}
                value={
                    process.data.fields[item.field]
                        ? process.data.fields[item.field].value
                        : ""
                }
                onChange={this.valueChanged.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
            />
            <div className="alert-danger">{this.props.validation_error}</div>
          </span>
                );
            case "textarea":
                return isView ? (
                    <div className={item.class}>
                        {process.data.fields[item.field]
                            ? process.data.fields[item.field].value
                            : ""}
                    </div>
                ) : (
                    <span>
            <TextInput
                {...item.props}
                type="textarea"
                className={"form-control " + item.class}
                value={
                    process.data.fields[item.field]
                        ? process.data.fields[item.field].value
                        : ""
                }
                onChange={this.valueChanged.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
            />
            <div className="alert-danger">{this.props.validation_error}</div>
          </span>
                );
            case "text_timer":
                return (
                    <span>
            <TextareaEvaluator
                readOnly={isView}
                {...item.props}
                text={
                    process.data.fields[item.field]
                        ? process.data.fields[item.field].value
                        : ""
                }
                process={process}
                onEvaluatorCallback={this.valueEvaluatorChanged.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
            />
            <div className="alert-danger">{this.props.validation_error}</div>
                        {!item.hide_open_in_new_window && <div><br/><Button
                            className={'btn-pull-left'}
                            color="primary"
                            onClick={() => {
                                if (this.state.contentState) {
                                    const contentState = this.state.contentState;
                                    const htmlContent = encodeURIComponent(
                                        stateToHTML(contentState)
                                    );
                                    const order_length = get(process, "data.fields.order_length.value");
                                    const order_title = get(process, 'data.fields.order_title.value');
                                    const client_name = get(process, 'data.fields.client_name.value');
                                    window.open(
                                        `${API_URL()}/app/script_viewer?q=${htmlContent}&order_title=${order_title}&client_name=${client_name}&order_length=${order_length}`,
                                        "script",
                                        "width=1000,height=650,resizable=1"
                                    );
                                }
                            }}>
                            <FormattedMessage id="process > button open in new window"/>
                        </Button></div>}
          </span>
                );
            case "date":
                return isView ? (
                    <div className={"input-group-text w-100 " + item.class}>
                        {process.data.fields[item.field]
                            ? process.data.fields[item.field].value
                            : ""}
                    </div>
                ) : (
                    <span>
            <Input
                {...item.props}
                type="date"
                className={item.class}
                value={
                    process.data.fields[item.field]
                        ? process.data.fields[item.field].value
                        : ""
                }
                onChange={this.valueChanged.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
            />
            <div className="alert-danger">{this.props.validation_error}</div>
          </span>
                );
            case "numeric":
                return isView ? (
                    <div className={"input-group-text w-100 " + item.class}>
                        {process.data.fields[item.field]
                            ? process.data.fields[item.field].value
                            : ""}
                    </div>
                ) : (
                    <span>
            <Input
                {...item.props}
                type="number"
                step="1"
                className={item.class}
                value={
                    process.data.fields[item.field]
                        ? process.data.fields[item.field].value
                        : ""
                }
                onChange={this.valueChanged.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
            />
            <div className="alert-danger">{this.props.validation_error}</div>
          </span>
                );
            case "file_upload":
                return (
                    <span>
            <UploadFile
                mode={isView ? "view" : ""}
                {...item.props}
                process={process}
                onUploaded={this.fileUploaded.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
                onDeleted={this.fileDeleted.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
                url={
                    process.data.fields[item.field]
                        ? process.data.fields[item.field].value
                        : ""
                }
                className={item.class}>
              <FormattedMessage id="process > button upload"/>
            </UploadFile>
            <div className="alert-danger">{this.props.validation_error}</div>
          </span>
                );
            case "select_studios":
                return (
                    <span>
            <SelectStudios
                item={item}
                {...item.props}
                {...isView && {disabled: "disabled"}}
                checked={process.data.fields[item.field]}
                onChange={this.checkboxGroupChanged.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
                className={item.class}
            />
          <div className="alert-danger">{this.props.validation_error}</div>
        </span>
                );
            case "select":
                return isView ? (
                    <span>
                  <Label>
                      {item.title}
                  </Label>
                  <div className={item.class}>
                  {process.data.fields[item.field]
                      ? process.data.fields[item.field].value.label
                      : ""}
                </div>
              </span>
                ) : <Dropdown
                    {...item.props}
                    label={
                        <span>
                            {item.title}{item.mandatory && <Asterisk>*</Asterisk>}
                        </span>
                    }
                    value={
                        process.data.fields[item.field]
                            ? process.data.fields[item.field].value
                            : ""
                    }
                    onChange={selectedOption => {
                        this.valueChanged(
                            item,
                            process,
                            template,
                            user,
                            {
                                value: selectedOption
                            }
                        );
                    }}
                />


            case "select_user":
                let options = this.getRoleCandidates(item, process, template, user);
                if (!options)
                    return (
                        <div className="text-black-50">
                            <FormattedMessage id="process > title studios still not selected"/>
                        </div>
                    );
                let value;
                if (process.data.fields[item.field])
                    value = {
                        value: process.data.fields[item.field].value.id,
                        label: process.data.fields[item.field].value.name,
                        role: process.data.fields[item.field].value.role
                    };
                else if (item.default && options && options.length > 0) {
                    value = options.reduce(e => e.value === user.id);
                    //value = {value:user.id,label:user.name, role:item.default}
                    if (value)
                        this.valueChanged(item, process, template, user, {
                            value: {id: user.id, name: user.name, role: item.default}
                        });
                }
                return isView ? (
                    <span>
            <Label>
                {item.title}
            </Label>
            <div className={item.class}>
            {process.data.fields[item.field]
                ? process.data.fields[item.field].value.name
                : ""}
          </div>
          </span>
                ) : (
                    <span>
            <Dropdown
                name={'select_' + item.field}
                {...item.props}
                options={options}
                label={
                    <span>
                        {item.title}{item.mandatory && <Asterisk>*</Asterisk>}
                      </span>
                }
                value={
                    process.data.fields[item.field]
                        ? value
                        : ""
                }
                onChange={selectedOption => {
                    this.valueChanged(
                        item,
                        process,
                        template,
                        user,
                        {
                            value: {
                                id: selectedOption.value,
                                name: selectedOption.label,
                                role: selectedOption.role
                            }
                        }
                    );
                }}
            />
                        {/* <DropdownCustomOption
              allowSelectAll={false}
              isMulti={false}
              {...item.props}
              value={value}
              onChange={selectedOption => {
                this.valueChanged(item, process, template, user, {
                  value: {
                    id: selectedOption.value,
                    name: selectedOption.label,
                    role: selectedOption.role
                  }
                });
              }}
              options={options}
              name={"select_" + step.key + "_" + item.key}
            /> */}
                        <div className="alert-danger">{this.props.validation_error}</div>
          </span>
                );

            // <span><Dropdown allowSelectAll={false} isMulti={false} {...item.props}
            // value={value}
            // onChange={selectedOption => {
            //     this.valueChanged(item, process, template, user,{value:{id:selectedOption.value, name:selectedOption.label, role:selectedOption.role}})
            //   }}
            // options={options}
            // name={"select_" + step.key + "_" + item.key}
            // />
            // <div className="alert-danger">{this.props.validation_error}</div>
            // </span>
            case "player":
                return !item.field ? (
                    <FormattedMessage id="process > title not uploaded yet"/>
                ) : item.field.indexOf(".mp3") > 0 ? (
                    <Player {...item.props} url={process.data.fields[item.field]}/>
                ) : (
                    <Image {...item.props} url={process.data.fields[item.field]}/>
                );
            case "spots":
                return isView ? (
                    <div className={"input-group-text w-100 " + item.class}>
                        {process.data.fields[item.field]
                            ? process.data.fields[item.field].value
                            : ""}
                    </div>
                ) : (
                    <span>
            <Input
                {...item.props}
                type="number"
                step="1"
                className={item.class}
                value={
                    process.data.fields[item.field]
                        ? process.data.fields[item.field].value
                        : ""
                }
                onChange={this.spotsChanged.bind(
                    this,
                    item,
                    process,
                    template,
                    user
                )}
            />
            <div className="alert-danger">{this.props.validation_error}</div>
          </span>
                );
            case "button":
                return isView ? null : (
                    <Button
                        className={'btn btn-primary btn-pull-left'}
                        {...item.props}
                        onClick={this.buttonClicked.bind(
                            this,
                            step,
                            item,
                            process,
                            template,
                            user
                        )}>
                        {process.loading ? <FormattedMessage id="process > button submitting"/> : item.text}
                    </Button>
                );
            case "log":
                return <ActivityLog process={process}/>
            default:
                return (
                    <div className={item.class}>
                        <b>{item.component}</b> component: NOT IMPLEMENTED YET:
                        <br/>
                        {process.data.fields[item.field]
                            ? process.data.fields[item.field].value
                            : ""}
                    </div>
                );
        }
    };
}

export default injectIntl(ComponentFactory);
