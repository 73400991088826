
import React from "react";
import { SvgIcon } from "@mui/material";

const CustomRemoveIcon = (props) => (
    <SvgIcon
        fontSize="small"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                  fill="#E0E0E0"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.9231 7.99991C4.9231 7.66004 5.01493 7.38452 5.12822 7.38452H10.8718C10.9851 7.38452 11.0769 7.66004 11.0769 7.99991C11.0769 8.33977 10.9851 8.61529 10.8718 8.61529H5.12822C5.01493 8.61529 4.9231 8.33977 4.9231 7.99991Z"
                  fill="#9C9CA3"/>
        </svg>

    </SvgIcon>
);

export default CustomRemoveIcon;
