import * as React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import bn from 'utils/bemnames';
import cs from 'classnames';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Switch } from '@mui/material';

const bem = bn.create('toggle-switch');
const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#EA9277' : '#EA9277',
        opacity: 1,
        border: 0,
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        backgroundColor: '#fff',
        width: 22,
        height: 22,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#EA9277',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    backgroundColor: '#515254',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#c2c4c7' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

class ToggleSwitch extends React.Component {
  static propTypes = {
    rightComponent: PropTypes.node,
    leftComponent: PropTypes.node,
    switchProps: PropTypes.object,
    label: PropTypes.node,
    labelProps: PropTypes.object,
    className: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    switchProps: {
      offColor: '#7F64F2',
      onColor: '#AFC3D0',
      uncheckedIcon: false,
      checkedIcon: false,
      inputProps: { 'aria-label': 'controlled' },
    },
  };

  render() {
    const { switchProps, leftComponent, rightComponent, label, labelProps } =
      this.props;
    return (
      <div>
        {!!label && <Label {...labelProps}>{label}</Label>}
        <div className={cs(bem.b(), this.props.className)}>
          <div className={bem.e('left-component')}>{leftComponent}</div>
          <FormControlLabel
            control={
              <CustomSwitch
                sx={{ m: 1 }}
                checked={Boolean(switchProps.checked)}
                onChange={(e) => switchProps.onChange(e)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label=""
          />
          <div className={bem.e('right-component')}>{rightComponent}</div>
        </div>
      </div>
    );
  }
}

export default ToggleSwitch;
