export const GET_POPULAR_CLASSES_INSIGHTS_REQUEST =
  'GET_POPULAR_CLASSES_INSIGHTS_REQUEST';
export const GET_POPULAR_CLASSES_INSIGHTS_SUCCESS =
  'GET_POPULAR_CLASSES_INSIGHTS_SUCCESS';
export const GET_POPULAR_CLASSES_INSIGHTS_ERROR =
  'GET_POPULAR_CLASSES_INSIGHTS_ERROR';
export const GET_ABSENTEES_REQUEST='GET_ABSENTEES_REQUEST';
export const GET_ABSENTEES_SUCCESS='GET_ABSENTEES_SUCCESS';
export const GET_ABSENTEES_ERROR='GET_ABSENTEES_ERROR';

export const GET_ATTENDANCE_BY_CLASS_INSIGHTS_REQUEST =
  'GET_ATTENDANCE_BY_CLASS_INSIGHTS_REQUEST';
export const GET_ATTENDANCE_BY_CLASS_INSIGHTS_SUCCESS =
  'GET_ATTENDANCE_BY_CLASS_INSIGHTS_SUCCESS';
export const GET_ATTENDANCE_BY_CLASS_INSIGHTS_ERROR =
  'GET_ATTENDANCE_BY_CLASS_INSIGHTS_ERROR';

export const GET_ATTENDANCE_CLASS_LIST_SUCCESS =
  'GET_ATTENDANCE_CLASS_LIST_SUCCESS';

export const GET_ATTENDANCE_OVERVIEW_INSIGHTS_REQUEST =
  'GET_ATTENDANCE_OVERVIEW_INSIGHTS_REQUEST';
export const GET_ATTENDANCE_OVERVIEW_INSIGHTS_SUCCESS =
  'GET_ATTENDANCE_OVERVIEW_INSIGHTS_SUCCESS';
export const GET_ATTENDANCE_OVERVIEW_INSIGHTS_ERROR =
  'GET_ATTENDANCE_OVERVIEW_INSIGHTS_ERROR';

export const GET_REVENUE_INSIGHTS_REQUEST = 'GET_REVENUE_INSIGHTS_REQUEST';
export const GET_REVENUE_INSIGHTS_SUCCESS = 'GET_REVENUE_INSIGHTS_SUCCESS';
export const GET_REVENUE_INSIGHTS_ERROR = 'GET_REVENUE_INSIGHTS_ERROR';

export const GET_INSIGHTS_CLASSES_BY_DATE_REQUEST='GET_INSIGHTS_CLASSES_BY_DATE_REQUEST';
export const GET_INSIGHTS_CLASSES_BY_DATE_SUCCESS='GET_INSIGHTS_CLASSES_BY_DATE_SUCCESS';
export const GET_INSIGHTS_CLASSES_BY_DATE_ERROR='GET_INSIGHTS_CLASSES_BY_DATE_ERROR'